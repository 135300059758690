<template>
    <span/>
</template>
<script>

    import mixin from "../mixin";
    import axiosInstance from "../../axiosInstance";
    import apiConfig from "../../apiConfig";

    export default {
        name: 'fileDownload',
        mixins: [mixin],
        methods: {
            download: function (file, fileName) {
                axiosInstance.get(apiConfig.filesApiUrl + file, {
                        responseType: 'blob',
                        timeout: 30000,
                        headers: this.$data.headers

                    },
                ).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const a = document.createElement('a');
                    a.href = url;
                    a.setAttribute('download', fileName);
                    document.body.appendChild(a);
                    a.click();
                });
            },
            get: function (file, fileName, filters) {
                axiosInstance.post(apiConfig.filesApiUrl + file, JSON.stringify(filters), {
                        responseType: 'blob',
                        timeout: 30000,
                        headers: this.$data.headers

                    },
                ).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const a = document.createElement('a');
                    a.href = url;
                    a.setAttribute('download', fileName);
                    document.body.appendChild(a);
                    a.click();
                });
            },
            getApi: function (file, fileName, filters) {
                axiosInstance.get(file,{
                        params: filters,
                        responseType: 'blob',
                        timeout: 30000,
                        headers: this.$data.headers
                    },
                ).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const a = document.createElement('a');
                    a.href = url;
                    a.setAttribute('download', fileName);
                    document.body.appendChild(a);
                    a.click();
                });
            }
        }
    }
</script>
