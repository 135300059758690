<template>
    <span>
        <a
                @click.prevent="editSwot(datatable.item)"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Edytuj analizę SWOT"
                v-if="canEdit()"
        >
            <vk-icon class="hover" icon="pencil"/>
        </a>
        <a
                @click.prevent="copySwot(datatable.item.id)"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Kopiuj analizę SWOT"
        >
            <vk-icon class="hover" icon="copy"/>
        </a>
        <a
                @click.prevent="swotPage(datatable.item.id)"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Szczegóły SWOT"
        >
            <vk-icon class="hover" icon="search"/>
        </a>
        <a
                @click.prevent="analysis(datatable.item)"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Podsumowanie analizy SWOT"
        >
            <vk-icon class="hover" icon="file-edit"/>
        </a>
        <a
                @click.prevent="deleteSwot(datatable.item.id)"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Usuń analizę SWOT"
        >
            <vk-icon class="hover" icon="trash"/>
        </a>
        <remove ref="remove" v-on:refresh="$emit('refresh')"/>
    </span>
</template>

<script>

    import mixin from "../../components/mixin";
    import apiConfig from "../../apiConfig";
    import remove from "../ui/remove";
    import axiosInstance from "../../axiosInstance";

    export default {
        name: 'swotsActions',
        components: {remove},
        mixins: [mixin],
        props: {
            datatable: Object
        },
        methods: {
          copySwot(analysisId) {
            axiosInstance
                .get(apiConfig.swotApiUrl+'/copy/'+analysisId,
                    {
                      headers: this.$data.headers
                    }).then(()=>{

              this.$emit('refresh')
            }).catch();
          },
          canEdit(){
            let CancelException = {}
            let vm = this
            let hasCorrelations = false
            try {
              vm.$props.datatable.item.swotElements.forEach(element => {
                if (element.secondaryElementCorrelations.length > 0) {
                  hasCorrelations = true
                  throw CancelException
                }
              })
            }
              catch(e) {
                if(e !== CancelException){
                  throw e
                }
              }
            return !hasCorrelations
          },
            editSwot(e) {
                if (e.type === 0) {
                    e.formattedSoa = (e.soa * 100).toFixed(2) + "%";
                    e.formattedWtc = (e.wtc * 100).toFixed(2) + "%";
                }
                this.$emit('showModal', e);
            },
            deleteSwot(e) {
                if (this.datatable.item.swotElements.length > 0) {
                    this.$refs.remove.remove(apiConfig.swotApiUrl + "/" + e, "analizę SWOT posiadającą przypisane elementy");
                } else {
                    this.$refs.remove.remove(apiConfig.swotApiUrl + "/" + e, "analizę SWOT");
                }
            },
            swotPage(swotId) {
                this.$router.push('/buildings/' + this.$route.params.buildingId + '/swots/' + swotId + '/elements')
            },
            analysis(swot) {
                if (swot.swotElements.length === 0) {
                    this.$router.push('/buildings/' + this.$route.params.buildingId + '/swots/' + swot.id + '/elements')
                } else {
                    this.$router.push('/buildings/' + this.$route.params.buildingId + '/swots/' + swot.id + '/result')
                }
            }

        }
    }
</script>
