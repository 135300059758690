<template>
    <div class="uk-flex-top uk-modal-container" container=".admin" id="wpip-parameters" uk-modal>
        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-modal-body">
                <h3>{{ modal.title }}</h3>
                <table :key="key" class="uk-table" ref="table">
                    <tr v-bind:key="user.id" v-for="(user,index) in building.users">
                        <td>{{ user.username }}</td>
                        <td>{{ user.email }}</td>
                        <td>{{ getFullName(user) }}</td>
                        <td>
                            <users-list-actions :building="building.id" :datatable="user" :index="index"
                                                v-on:refresh="deletedRelation"/>
                        </td>
                    </tr>
                </table>

            </div>
        </div>
    </div>
</template>

<script>
    import mixin from "../mixin";
    import UIkit from "uikit";
    import UsersListActions from './usersListActions'

    export default {
        name: 'buildingUsersListModal',
        components: {UsersListActions},
        props: {},
        data() {
            return {
                filterValue: "",
                modalDom: null,
                parameter: null,
                key: 0,
                buildingIndex: 0,
                building: {},
                modal:
                    {
                        title: "Lista użytkowników przypisanych do budynku"
                    },

            }
        },
        mixins: [mixin],
        methods: {
            showModal: function (building, index = null) {
                this.buildingIndex = index;
                this.building = building;
                this.modalDom.show();
            },
            deletedRelation: function (e) {
                //delete this.property.parameters[e];
                this.key++;
                this.$emit('refreshRelations', {property: this.propertyIndex, parameter: e});
                this.$emit('refresh');
            },
            getFullName: function (user) {
                if (user.person !== null) {
                    return user.person.firstName + " " + user.person.lastName;
                } else {
                    return 'brak'
                }
            }
        },
        mounted() {
            this.modalDom = UIkit.modal('#wpip-parameters');
        }

    }
</script>
<style scoped>
    .correlation-header {
        font-size: 1.3em;
    }
</style>
