<template>
    <admin>
        <div>
            
            <b-row>
              <b-col>
                <h3>Ustal korelacje elementów analizy TOWS</h3>
              </b-col>
              <b-col class="uk-text-right" cols="6" xl="3">
                <b-card header="Związek jest określany za pomocą" border-variant="success" align="center">
                  <b-card-text>
                    <vk-icon icon="check"></vk-icon> jest związek
                    <span class="ml-3">- nie ma związku</span>
                  </b-card-text>
                </b-card>        
              </b-col>
            </b-row>
            <div class="uk-margin-bottom uk-width-1-1" id="wpip-swot-korelacja" vk-grid>
                <div class="uk-width-1-1">
                    <table class="uk-table">
                        <tr>
                            <td colspan="2"></td>
                            <td
                                    :colspan="swot.strengths.data.length"
                                    class="uk-text-center uk-text-large"
                                    v-if="swot.strengths.data.length > 0"
                            >Silne strony (S)
                            </td>
                            <td
                                    :colspan="swot.weaknesses.data.length"
                                    class="uk-text-center uk-text-large"
                                    v-if="swot.weaknesses.data.length > 0"
                            >Słabe strony (W)
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2"></td>
                            <td
                                    :class="{'wpip-swot-last-vertical': index === swot.strengths.data.length - 1}"
                                    :key="'s_'+s.id"
                                    :uk-tooltip="s.name"
                                    class="uk-text-center"
                                    v-for="(s, index) in swot.strengths.data"
                            >{{ (index+1) + '.' }}
                            </td>
                            <td
                                    :key="'w_'+w.id"
                                    :uk-tooltip="w.name"
                                    class="uk-text-center"
                                    v-for="(w, index) in swot.weaknesses.data"
                            >{{ (index+1) + '.' }}
                            </td>
                        </tr>
                        <tr :key="'o'+o.id" v-for="(o, oindex) in swot.opportunities.data">
                            <td
                                    :rowspan="swot.opportunities.data.length"
                                    class="uk-text-large uk-text-middle uk-text-right  uk-width-1-6 "
                                    v-if="swot.opportunities.data.length > 0 && oindex === 0"
                            >Szanse (O)
                            </td>
                            <td
                                    :class="{'wpip-swot-last-horizontal': oindex === swot.opportunities.data.length - 1}"
                                    :uk-tooltip="o.name"
                                    class="uk-text-center"
                            >{{ (oindex+1) + '.' }}
                            </td>
                            <swot-correlation-cell v-bind:analysis-type="data.type" v-bind:correlation-type="'os'"
                                                   v-bind:e1="o"
                                                   v-bind:e2=os
                                                   v-bind:key="'os_'+os.id"
                                                   v-bind:last-horizontal="oindex === swot.opportunities.data.length - 1"
                                                   v-bind:last-vertical="index === swot.strengths.data.length - 1"
                                                   v-bind:modal="$refs.modal"
                                                   v-for="(os, index) in swot.strengths.data"/>
                            <swot-correlation-cell v-bind:analysis-type="data.type" v-bind:correlation-type="'ow'"
                                                   v-bind:e1="o"
                                                   v-bind:e2=ow
                                                   v-bind:key="'ow_'+ow.id"
                                                   v-bind:last-horizontal="oindex === swot.opportunities.data.length - 1"
                                                   v-for="(ow) in swot.weaknesses.data"/>

                        </tr>
                        <tr></tr>
                        <tr :key="'t_'+t.id" v-for="(t, tindex) in swot.threads.data">
                            <td
                                    :rowspan="swot.threads.data.length"
                                    class="uk-text-large uk-text-middle uk-text-right"
                                    v-if="swot.threads.data.length > 0 && tindex === 0"
                            >Zagrożenia (T)
                            </td>
                            <td :uk-tooltip="t.name" class="uk-text-center">{{ (tindex+1) + '.' }}</td>
                            <swot-correlation-cell v-bind:analysis-type="data.type" v-bind:correlation-type="'ts'"
                                                   v-bind:e1="t"
                                                   v-bind:e2=ts
                                                   v-bind:key="'ts_'+ts.id"
                                                   v-bind:last-vertical="index === swot.strengths.data.length - 1"
                                                   v-for="(ts, index) in swot.strengths.data"/>
                            <swot-correlation-cell v-bind:analysis-type="data.type" v-bind:correlation-type="'tw'"
                                                   v-bind:e1="t"
                                                   v-bind:e2=tw
                                                   v-bind:key="'tw_'+tw.id"
                                                   v-for="(tw) in swot.weaknesses.data"/>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="uk-grid uk-child-width-1-2@m" uk-grid>
                <div class="uk-text-left">
                    <router-link v-bind:to="prevStep">
                        <button class="uk-button wpip-button">Ustal korelację SWOT</button>
                    </router-link>
                </div>
                <div class="uk-text-right">
                    <router-link v-bind:to="nextStep">
                        <button class="uk-button wpip-button">Analizuj</button>
                    </router-link>
                </div>
            </div>
            <swots-correlation-modal ref="modal" v-on:send-data="sendData"></swots-correlation-modal>


        </div>
    </admin>
</template>

<script>

    import mixin from "../../components/mixin";
    import apiConfig from "../../apiConfig";
    import SwotsCorrelationModal from "../../components/swot/swotsCorrelationModal";
    import SwotCorrelationCell from "../../components/swot/swotCorrelationCell";
    import axiosInstance from "../../axiosInstance";

    export default {
        name: 'swotCorrelations',
        components: {
            swotsCorrelationModal: SwotsCorrelationModal,
            swotCorrelationCell: SwotCorrelationCell
        },
        mixins: [mixin],
        data() {
            return {

                sortBy: 'name',
                sortDesc: false,
                data: [],
                prevStep: "/buildings/" + this.$route.params.buildingId + "/swots/" + this.$route.params.swotId + "/correlation",
                nextStep: "/buildings/" + this.$route.params.buildingId + "/swots/" + this.$route.params.swotId + "/result",
                currentPage: 1,
                totalItems: '',
                showDetails: false,
                swot: {
                    strengths: {
                        title: "Silne strony",
                        inputName: "strength",
                        typeFilter: 's',
                        color: "uk-text-success",
                        data: []
                    },
                    weaknesses: {
                        title: "Słabe strony",
                        inputName: "weakness",
                        typeFilter: 'w',
                        color: "",
                        data: []
                    },
                    opportunities: {
                        title: "Szanse",
                        inputName: "oportunity",
                        typeFilter: 'o',
                        color: "uk-text-primary",
                        data: []
                    },
                    threads: {
                        title: "Zagrożenia",
                        inputName: "thread",
                        typeFilter: 't',
                        color: "uk-text-danger",
                        data: []
                    }
                }

            }
        },
        methods: {
            async get() {

                let vm = this;
                axiosInstance
                    .get(apiConfig.swotApiUrl + '/' + this.$route.params.swotId,
                        {
                            headers: vm.$data.headers
                        }).then(function (res) {
                    vm.data = res.data;
                });

                Object.keys(vm.swot).forEach(function (key) {
                    var params = {};
                    params['page'] = vm.currentPage;
                    params['swotAnalysis'] = vm.$route.params.swotId;
                    params['swotElementType'] = vm.swot[key].typeFilter;

                    axiosInstance
                        .get(apiConfig.swotElementsApiUrl,
                            {
                                params,
                                headers: vm.$data.headers
                            }).then(function (res) {
                        vm.swot[key].data = res.data['member'];
                    });
                });
            }
        },
        async mounted() {
            await this.$ability.getAbility();
            if (!this.$ability.checkManageIasAbility('SWOT')) {
                await this.$router.push({name: 'not-allowed'});
            }
            this.get();
        }

    }
</script>

<style scoped>
    table.wpip-swot td {
        border: 1px solid #eee;
    }

    .wpip-high {
        background-color: #eee;
    }

    td.wpip-swot-last-vertical {
        border-right: 2px solid #bf0e01;
    }

    td.wpip-swot-last-horizontal {
        border-bottom: 2px solid #bf0e01;
    }
</style>
