<template>
    <div class="card">
        <div class="card-title text-center">
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-5">
                    <div class="icon-big text-center">
                        <div v-if="$props.element.register.length!=1" class="icon-danger"><i
                                class="nc-icon nc-simple-remove text-danger uk-text-bold"></i></div>
                        <div v-else-if="!$props.element.register[0].valueExpected" class="icon-warning"><i
                                class="nc-icon nc-simple-remove text-warning uk-text-bold"></i></div>
                        <div v-else class="icon-success"><i class="nc-icon nc-check-2 text-success uk-text-bold"></i></div>
                    </div>
                </div>
                <div class="col-7">
                    <div class="numbers">
                      <div><p class="card-category">{{getName()}}</p> <h4 class="card-title">{{ getValue() }} <span v-if="$props.element.register.length>0">{{$props.element.register[0].unitOfMeasure}}</span></h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer" v-if="$props.element.register.length!==0">
            <hr>
            <div class="stats">
                <div><i class="nc-icon nc-refresh-02"/> {{
                    $props.element.register[0].lastValueDate?$props.element.register[0].lastValueDate:"BRAK DATY" }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import charLimiter from "../charLimiter";

    export default {
        name: 'dashboardElementValue',
        components: {},
        data() {
            return {
            }
        },
        props: {
            elementId: null,
            element: {}
        },
        mixins: [mixin, charLimiter],
        methods: {
            getName() {
                if (this.$props.element.register[0]) {
                    return this.$props.element.register[0].name;
                } else {
                    return "Nie wybrano rejestru";
                }
            },
            getValue() {
                if (this.$props.element.register[0]) {
                    if(this.$props.element.register[0].valueType === this.valueType.coil || this.$props.element.register[0].valueType === this.valueType.string){
                        return this.$props.element.register[0].lastValueString;
                    }
                    return this.$props.element.register[0].lastValue;
                } else {
                    return "";
                }
            }
        },
        mounted: function () {
            //this.get();
        }
    }
</script>
<style scoped>
    .icon-big {
        font-size: 3em;
    }
</style>
