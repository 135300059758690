<template>
    <admin>
        <div class="wpip-swot">
            <keizens-list></keizens-list>
            <div class="uk-container-expand uk-padding">
                    <router-link :to="{ name: 'tools', params: { buildingId: $route.params.buildingId } }">
                        <button class="uk-button uk-button-default"><vk-icon icon="thumbnails"></vk-icon> Lista metod</button>
                    </router-link>
            </div>
        </div>
    </admin>
</template>

<script>

    import mixin from "../../components/mixin";
    import KeizensList from '../../components/keizen/keizenList';

    export default {
        name: 'kaizens',
        components: {
            'keizensList': KeizensList
        },
        mixins: [mixin],
        data() {
            return {
                sortBy: 'name',
                sortDesc: false,
                data: [],
                currentPage: 1,
                totalItems: '',
                showDetails: false,
                buildingId: null
            }
        },
        async mounted() {
            await this.$ability.getAbility();
            if (!this.$ability.checkManageIasAbility('KEIZEN')) {
                await this.$router.push({name: 'not-allowed'});
            }
        }
    }
</script>
