<template>
  <admin>
    <div class="uk-container-expand uk-padding">
      <vk-grid>
        <sub-menu></sub-menu>
        <div class="uk-width-expand@m">
          <vk-grid class="uk-margin-bottom">
            <h3 class="uk-width-expand">Wynajem - Najemcy </h3>
            <button
                @click="$refs.list.addContractor()"
                class="uk-button wpip-button uk-width-auto"
            ><vk-icon icon="plus-circle"></vk-icon> Dodaj Najemcę
            </button>
          </vk-grid>
          <vk-grid class="uk-width-auto uk-margin-bottom">
            <div class="uk-width-1-6@m">

            </div>
            <div class="uk-width-1-3@m">
              <div class="card">
                <div class="card-title text-center">
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-5">
                      <div class="icon-big text-center">
                        <div class="icon-danger"><i
                            class="nc-icon nc-bell-55 text-danger uk-text-bold"></i></div>
                      </div>
                    </div>
                    <div class="col-7">
                      <div class="numbers">
                        <div><p class="card-category">Najemcy z zaległościami</p> <h4 class="card-title">
                          {{ overdue }}%</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <hr>
                  <div class="stats">
                    <div><i class="nc-icon nc-refresh-02"/> 2020-05-05</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="uk-width-1-3@m">
              <div class="card">
                <div class="card-title text-center">
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-5">
                      <div class="icon-big text-center">
                        <div class="icon-success"><i
                            class="nc-icon  nc-money-coins text-success uk-text-bold"></i></div>
                      </div>
                    </div>
                    <div class="col-7">
                      <div class="numbers">
                        <div><p class="card-category">Suma należności</p> <h4 class="card-title">{{ totalToPay }} PLN</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <hr>
                  <div class="stats">
                    <div><i class="nc-icon nc-refresh-02"/> 2020-05-05</div>
                  </div>
                </div>
              </div>
            </div>
          </vk-grid>
          <vk-card class="uk-margin-bottom">
            <renters-filters v-if="showFilters" @filter="filter" :filters="refreshFilters"></renters-filters>
          </vk-card>
          <vk-card>
            <renters-list ref="list" @toggleFilters="toggleFilters" :filters="filters" :filterNum="filterNum"></renters-list>
          </vk-card>
        </div>
      </vk-grid>

    </div>
  </admin>
</template>

<script>
import mixin from "../../components/mixin";
import RentersList from "../../components/renter/rentersList";
import RentersFilters from "../../components/renter/rentersFilters";
import axiosInstance from "../../axiosInstance";
import apiConfig from "../../apiConfig";
import SubMenu from "@/components/ui/subMenu";

export default {
  name: "renters",
  components: {SubMenu, RentersFilters, RentersList},
  data() {
    return {
      showFilters: false,
      overdue: '0.0',
      totalToPay: '0.00',
      filterNum: 0,
      filters: {
        buildings: [],
        beginDate: "",
        endDate: "",
        status: [],
        minValue: null,
        maxValue: null,
        minArea: null,
        maxArea: null
      },
      refreshFilters: {}
    };
  },
  mixins: [mixin],
  async mounted() {
    this.getDashboardData()
    if (localStorage.getItem('buildingFilter') !== null) {
      let building = JSON.parse(localStorage.getItem('buildingFilter'))
      this.filters.buildings.push(building)
      this.filter(this.filters)
      localStorage.removeItem('buildingFilter')
    }
  },
  methods: {
    toggleFilters: function () {
      this.showFilters = !this.showFilters
    },
    async getDashboardData(filters = {}) {
      let vm = this
      let params = {filters: filters}
      await axiosInstance
          .get(apiConfig.rentalsApiUrl + '/dashboards/renters',
              {
                params,
                headers: this.$data.headers
              }).then(function (result) {
            if(result.data.overdue + result.data.payed > 0) {
              vm.overdue = 100 * (result.data.overdue / (result.data.overdue + result.data.payed))
            }
            else
              vm.overdue = '0.0'
            vm.overdue = vm.overdue.toFixed(1)
            vm.totalToPay = result.data.totalToPay.toFixed(2)
          });
    },
    filter: function (filters) {
      this.filters = filters
      filters = {...filters}
      let buildings = [];
      let buildingsIds = []
      let filterNum = 0
      if (filters.buildings.length > 0) {
        filterNum++
      }
      filters.buildings.forEach(function (building) {
        buildings.push(building['@id'])
        buildingsIds.push(building['id'])
      })
      if (buildings.length > 0) {
        filters['rentals.spaces.building'] = buildings
        filters['buildingIds'] = buildingsIds
      }
      delete filters.buildings
      if (filters.endDate && filters.endDate.toLocaleString() !== "") {
        filters['paymentDate[before]'] = filters.endDate.toLocaleString()
        filterNum++
      }
      if (filters.beginDate && filters.beginDate.toLocaleString() !== "") {
        filters['paymentDate[after]'] = filters.beginDate.toLocaleString()
        filterNum++
      }
      if (filters.minValue) {
        filters['toPay[gte]'] = filters.minValue
        filterNum++
      }
      if (filters.maxValue) {
        filters['toPay[lte]'] = filters.maxValue
        filterNum++
      }
      if (filters.minArea) {
        filters['rentedArea[gte]'] = filters.minArea
        filterNum++
      }
      if (filters.maxArea) {
        filters['rentedArea[lte]'] = filters.maxArea
        filterNum++
      }
      if (filters.status[2]) {
        filters['isSuspended'] = true
        filterNum++
      }
      if (filters.status[1] && !filters.status[0]) {
        filters['paymentDate[before]'] = new Date().toLocaleString()
        filterNum++
      }
      if (filters.status[0] && !filters.status[1]) {
        filters['toPay[lte]'] = 0
        filterNum++
      }

      delete filters.beginDate
      delete filters.endDate
      delete filters.minValue
      delete filters.maxValue
      delete filters.minArea
      delete filters.maxArea
      delete filters.status
      this.filterNum = filterNum
      this.refreshFilters = filters
      this.$refs.list.get(filters)
      this.getDashboardData(filters)
    }
  }
};
</script>

<style scoped>
.uk-accordion li {
  list-style-type: none;
}

.uk-accordion-title {
  color: black;
  font-size: 1.3em;
}

.uk-accordion {
  padding-left: 0;
}

.icon-big {
  font-size: 3em
}
</style>
