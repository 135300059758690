<template>
  <div class="uk-flex-top uk-modal-container uk-modal" container=".admin" id="dashboard-element-modal">
    <div class="uk-modal-dialog uk-margin-auto-vertical">
      <button class="uk-modal-close-default" type="button" uk-close></button>
      <div class="uk-modal-body">
        <h3>{{ modal.title }}</h3>
        <div class="uk-form-controls">
          <label>
            Tytuł
          </label>
          <input
              type="text"
              class="uk-input"
              data-vv-as="tytuł"
              name="title"
              placeholder="Proszę podać nazwę elementu"
              v-model="dashboardElement.title"
              v-validate="'required|max:255'"
          />
          <span class="validation-error">{{ errors.first('title') }}</span>
        </div>


        <div class="uk-form-controls uk-margin">
          <label>Szerokość</label>
          <select class="uk-select" data-vv-as="szerokość" name="width" placeholder="" type="text"
                  v-model="dashboardElement.width"
                  v-validate="'required'">
            <option v-for="i in maxWidth" :key="'w'+i" :value="i">{{ i }}</option>
          </select>

          <span class="validation-error">{{ errors.first('width') }}</span>
        </div>


        <div class="uk-form-controls uk-margin">
          <label>Rodzaj</label>
          <select class="uk-select" data-vv-as="rodzaj" name="type" placeholder="" type="text"
                  v-model="dashboardElement.type"
                  v-validate="'required'" @change="changeType()">
            <option :value="0">Ostatnia wartość</option>
            <option :value="1">Ostatnia wartość wielu rejestrów</option>
            <option :value="2">Wykres</option>
            <option :value="3">Zegar</option>
            <option :value="4">Przycisk</option>
            <option :value="5">Wprowadzanie wartości</option>
            <option :value="6">...</option>
          </select>

          <span class="validation-error">{{ errors.first('type') }}</span>
        </div>

        <div class="uk-form-controls uk-margin" v-if="dashboardElement.type === 2">
          <label>Metoda agregacji</label>
          <select class="uk-select" data-vv-as="metoda agregacji" name="method" placeholder="" type="text"
                  v-model="dashboardElement.method"
                  v-validate="'required'">
            <option :value="'avg'">Średnia</option>
            <option :value="'min'">Wartość minimalna</option>
            <option :value="'max'">Wartość maksymalna</option>
          </select>

          <span class="validation-error">{{ errors.first('type') }}</span>
        </div>


        <div class="uk-form-controls uk-margin" v-if="dashboardElement.type === 4 || dashboardElement.type === 5">
          <label>
            Wartość
          </label>
          <input
              type="text"
              class="uk-input"
              data-vv-as="Wartość"
              name="days"
              placeholder="Proszę podać wartość przypisaną do przycisku"
              v-model="dashboardElement.value"
              v-validate="'required'"
          />
          <span class="validation-error">{{ errors.first('days') }}</span>
        </div>

        <div class="uk-form-controls uk-margin" v-if="dashboardElement.type === 4 || dashboardElement.type === 5">
          <label>Kolor</label>
          <select class="uk-select" data-vv-as="kolor" name="variant" placeholder="" type="text"
                  v-model="dashboardElement.variant"
                  v-validate="'required'">
            <option :value="'success'">Zielony</option>
            <option :value="'danger'">Czerwony</option>
            <option :value="'primary'">Niebieski</option>
            <option :value="'warning'">Żółty</option>
            <option :value="'secondary'">Szary</option>
          </select>

          <span class="validation-error">{{ errors.first('type') }}</span>
        </div>

        <div class="uk-form-controls uk-margin" v-if="dashboardElement.type === 2">
          <label>
            Interwał
          </label>
          <input
              type="text"
              class="uk-input"
              data-vv-as="interwał"
              name="interval"
              placeholder="Proszę podać interwał (w minutach)"
              v-model="dashboardElement.interval"
              v-validate="'required'"
          />
          <span class="validation-error">{{ errors.first('days') }}</span>
        </div>

        <div class="uk-form-controls uk-margin" v-if="dashboardElement.type === 2">
          <label>
            Liczba dni
          </label>
          <input
              type="text"
              class="uk-input"
              data-vv-as="liczba dni"
              name="days"
              placeholder="Proszę podać liczbę dni dla wykresu"
              v-model="dashboardElement.days"
              v-validate="'required'"
          />
          <span class="validation-error">{{ errors.first('days') }}</span>
        </div>

        <template v-if="dashboardElement.type === 0 || dashboardElement.type ===3">
          <label>Rejestr</label>
          <v-select :filterable="true" :options="registers"
                    label="name"
                    data-vv-as="kontrahent"
                    name="contractor" ref="multiSelectContractor"
                    :class="'wpip-select'" v-model="selectedRegisters[0]" v-validate="'required'">

            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{ option.name }}
              </div>
            </template>

            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.name }}
              </div>
            </template>

            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                Nie znaleziono <em>{{ search }}</em>.
              </template>
              <em style="opacity: 0.5;" v-else>Wyszukaj kontrahenta</em>
            </template>
          </v-select>
        </template>
        <template v-else>
          <b-row class="mb-1">
            <b-col cols="6">
              <template v-if="filterRegisters().length > onPage">
                <b-button variant="outline-wpip" v-if="availablePage > 1" @click="previousPage('available')">Poprzednia
                  strona
                </b-button>
                <b-button variant="outline-wpip" v-if="availablePage < Math.ceil(filterRegisters().length/onPage)"
                          @click="nextPage('available')" class="ml-1">Następna strona
                </b-button>
              </template>
            </b-col>
            <b-col cols="6" class="text-right" v-if="selectedRegisters.length > onPage">
              <b-button variant="outline-wpip" v-if="selectedPage > 1" @click="previousPage('selected')">Poprzednia
                strona
              </b-button>
              <b-button variant="outline-wpip" v-if="selectedPage < Math.ceil(selectedRegisters.length/onPage)"
                        @click="nextPage('selected')" class="ml-1">Następna strona
              </b-button>
            </b-col>
          </b-row>

          <div class="uk-form-controls  uk-margin">
            <label>Rejestry</label>
            <vue-list-picker
                :left-items="getPage(filterRegisters(), availablePage)"
                :right-items="getPage(selectedRegisters, selectedPage)"
                @move-right="moveRight"
                @move-all-right="moveAllRight"
                @move-all-left="moveAllLeft"
                @move-left="moveLeft"
                button-class="uk-button user-selection-buttons"
                content-attr="name"
                content-class="item-selected"
                content-key="@id"
                title-class="section-title"
                title-left="Dostępne rejestry"
                title-right="Wybrane rejestry"
                :content-substr=128
            />
          </div>
        </template>
      </div>
      <div class="uk-modal-footer uk-text-right">
        <button class="uk-button uk-button-default uk-modal-close" type="button">Anuluj</button>
        <button @click="removeElement" class="uk-button wpip-button" type="button" v-if="dashboardElement.id">
          Usuń
        </button>
        <button @click="submit" class="uk-button wpip-button" type="button">{{ modal.button }}</button>
      </div>
    </div>
  </div>
</template>

<script>

import mixin from "../mixin";
import apiConfig from "../../apiConfig";
import UIkit from "uikit";
import axiosInstance from "../../axiosInstance";

export default {
  name: 'dashboardElementModal',
  data() {
    return {
      maxWidth: 6,
      dashboardElement: {
        id: null,
        title: "",
        horizontalPosition: 0,
        verticalPosition: 0,
        height: 1,
        width: 1,
        minValue: "",
        maxValue: "",
        days: "",
        dashboard: apiConfig.dashboardsApiUrl + '/' + this.$route.params.dashboardId
      },
      selectedPage: 1,
      availablePage: 1,
      onPage: 10,
      selectedRegisters: [],
      registers: [],
      modal:
          {
            title: "",
            button: ""
          },

    }
  },
  mixins: [mixin],
  methods: {
    previousPage(list) {
      if (list === 'selected') {
        if (this.selectedPage > 1) {
          this.selectedPage--
        }
      } else if (list === 'available') {
        if (this.availablePage > 1) {
          this.availablePage--
        }
      }
    },
    nextPage(list) {
      if (list === 'selected') {
        if (this.selectedPage < Math.ceil(this.selectedRegisters.length / this.onPage)) {
          this.selectedPage++
        }
      } else if (list === 'available') {
        if (this.availablePage < Math.ceil(this.filterRegisters().length / this.onPage)) {
          this.availablePage++
        }
      }
    },
    getPage(list, page) {
      return list.slice((page - 1) * this.onPage, page * this.onPage)
    },
    moveAllRight() {
      let vm = this
      this.getPage(this.filterRegisters(), this.availablePage).forEach(register => {
        vm.moveRight(register)
      })
    },
    moveAllLeft() {
      let vm = this
      this.getPage(this.selectedRegisters, this.selectedPage).forEach(register => {
        vm.moveLeft(register)
      })
    },
    moveRight(register) {
      this.selectedRegisters.push(register)
      if (this.availablePage > Math.ceil(this.filterRegisters().length / this.onPage) && this.availablePage > 1) {
        this.availablePage--
      }
    },
    moveLeft(register) {
      this.selectedRegisters.forEach((item, key) => {
        let vm = this
        if (item.id === register.id) {
          vm.registers.push(register)
          vm.selectedRegisters.splice(key, 1)
        }
      })
      if (this.selectedPage > Math.ceil(this.selectedRegisters.length / this.onPage) && this.selectedPage > 1) {
        this.selectedPage--
      }
    },
    changeType(){
      if(this.dashboardElement.type === 0 || this.dashboardElement.type ===3){
        if(this.selectedRegisters.length > 1){
          for(let i = this.selectedRegisters.length - 1; i>0; i--){
            this.moveLeft(this.selectedRegisters[i])
          }
        }
      }
    },
    showModal: function (dashboardElement, i = 0, j = 0, maxWidth = 6) {
      this.dashboardElement = dashboardElement;
      this.maxWidth = maxWidth;
      this.availablePage = 1
      this.selectedPage = 1
      let vm = this;
      if (!this.dashboardElement) {

        this.selectedRegisters = [];
        this.dashboardElement = {
          id: null,
          horizontalPosition: j,
          verticalPosition: i,
          height: 1,
          width: 1,
          days: "",
          minValue: "",
          maxValue: "",
          dashboard: apiConfig.dashboardsApiUrl + '/' + this.$route.params.dashboardId
        }
        this.modal =
            {
              title: "Dodaj element na dashboard",
              button: "Dodaj",
              blocked: false
            }
      } else {
        this.modal =
            {
              title: "Zmień element na dashboardzie",
              button: "Zmień",
              blocked: false
            }

        this.selectedRegisters = [];
        dashboardElement.register.forEach(function (register) {
          vm.selectedRegisters.push({"@id": register['@id'], id: register.id, name: register.name});
        })

      }
      this.getRegisters();
      this.$validator.reset();
      this.modalDom.show();
    },
    removeElement: function () {
      let vm = this;
      UIkit.modal.confirm("Czy chcesz usunąć element?", {
        labels: {ok: "Usuń", cancel: "Anuluj"}
      }).then(function () {
        axiosInstance
            .delete(apiConfig.dashboardElementsApiUrl + '/' + vm.dashboardElement.id, {
              headers: vm.$data.headers
            }).then(function () {
          vm.$emit('refresh');
        })
      });
    },
    filterRegisters: function () {
      let result = this.registers;
      let vm = this;
      if (vm.selectedRegisters.length > 0) {
        vm.selectedRegisters.forEach(function (selectedRegister) {
          result.forEach(function (register, key) {
            if (register.id === selectedRegister.id) {
              result = result.splice(key, 1);
            }
          })
        });
      }
      return result;

    },
    getRegisters: function (e, page = 1) {
      let vm = this;
      if (page === 1) {
        vm.registers = [];
      }
      let params = {};
      params['name'] = e;
      params.page = page;
      axiosInstance
          .get(apiConfig.registersApiUrl, {
            params,
            headers: this.$data.headers
          })
          .then(function (response) {
            console.log(response.data)
            vm.registers = vm.registers.concat(response.data.member);

            if (response.data.view && response.data.view.next) {
              vm.getRegisters(e, page + 1);
            }
          });
    },
    submit: function () {
      let postData = {...this.dashboardElement};
      let vm = this;
      postData.register = [];
      vm.selectedRegisters.forEach(function (register) {
        postData.register.push(register['@id']);
      });
      if (typeof postData.minValue == 'string') {
        postData.minValue = postData.minValue.replace(",", ".");
        postData.minValue = parseFloat(postData.minValue);
      }
      if (typeof postData.maxValue == 'string') {
        postData.maxValue = postData.maxValue.replace(",", ".");
        postData.maxValue = parseFloat(postData.maxValue);
      }
      if (typeof postData.interval == 'string') {
        postData.interval = parseInt(postData.interval);
      }
      if (typeof postData.days == 'string') {
        postData.days = parseInt(postData.days);
      }
      vm.$validator.validate().then((result) => {
        if (result) {
          this.modal.blocked = true;
          if (this.dashboardElement.id == null) {
            axiosInstance
                .post(apiConfig.dashboardElementsApiUrl, JSON.stringify(postData), {
                  headers: this.$data.headers
                })
                .then(async function (response) {
                  if (response.status === 201) {
                    vm.$emit('refresh');
                    vm.modalDom.hide();
                  }
                  vm.modal.blocked = false;
                }).catch();
          } else {
            axiosInstance
                .put(apiConfig.dashboardElementsApiUrl + '/' + vm.dashboardElement.id, JSON.stringify(postData), {
                  headers: this.$data.headers
                })
                .then(async function (response) {
                  if (response.status === 200) {
                    vm.$emit('refresh');
                    vm.modalDom.hide();
                  }
                  vm.modal.blocked = false;
                }).catch();
          }
        }
      });
    }
  },
  mounted() {

    this.getRegisters();
    if (this.modalDom == null) {
      this.modalDom = UIkit.modal('#dashboard-element-modal');
    }
  }
}
</script>
