<template>
    <div>
      <add-person-report-modal @refresh="getReports()" ref="reportModal"/>
      <vk-grid class="uk-margin-bottom">
            <h3 class="uk-width-expand">Ocena pracowników </h3>
            <button
                @click="addReport"
                class="uk-button wpip-button uk-width-auto"
            ><vk-icon icon="plus-circle"></vk-icon> Dodaj Ocenę
            </button>
        </vk-grid>
        <vk-card>
        <b-table
                :columns="fields"
                :fields="fields"
                :items="data"
                :per-page="this.$data.perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                borderedless
                no-sort-reset
                :responsive="true"
                class="uk-table vk-table-justify uk-table-divider"
                hover
                ref="table">

            <template v-slot:cell(actions)="data">
                <person-rates-actions :datatable=data v-on:refresh="refresh" v-on:showRates="showRates"/>
            </template>
            <template v-slot:cell(name)="data">
                {{data.item.person.firstName + " " + data.item.person.lastName}}
            </template>
            <template v-slot:cell(fromDate)="data">
              {{ new Date(data.item.fromDate).toLocaleDateString() }}
            </template>
            <template v-slot:cell(toDate)="data">
              {{ new Date(data.item.toDate).toLocaleDateString() }}
            </template>
            <template v-slot:cell(goals)="data">
                <span class="uk-label wpip-label">{{ getGoalsCount(data.item) }}</span>
            </template>
            <template v-slot:cell(operationalGoals)="data">
                <span class="uk-label wpip-label">{{ data.item.goals.length - getGoalsCount(data.item) }}</span>
            </template>
        </b-table>
        <person-ratings-modal ref="personRatingsModal" v-on:refresh="refresh"/>
        <b-pagination :per-page="perPage" :total-rows="totalItems" align="center"
                      v-model="currentPage" v-if="totalItems>perPage" size="sm" page-class="wpip-page" class="wpip-page" @change="getReports"/></vk-card>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import axiosInstance from "../../axiosInstance";
    import PersonRatesActions from "./personRatesActions";
    import PersonRatingsModal from "./personRatingsModal";
    import AddPersonReportModal from "@/components/zpc/addPersonReport";

    export default {
        name: 'peopleRatesList',
        components: {
          AddPersonReportModal,
            PersonRatingsModal,
            PersonRatesActions,
        },
        data() {
            return {
                sortBy: 'name',
                sortDesc: false,
                data: [],
                selected: null,
                currentPage: 1,
                totalItems: '',
                fields: [
                    {key: 'name', label: 'Nazwa', class: 'uk-width-1-5', sortable: true},
                    {key: 'fromDate', label: 'Od', sortable: true},
                    {key: 'toDate', label: 'Do', sortable: true},
                    {key: 'goals', label: 'Cele strategiczne', sortable: true},
                    {key: 'operationalGoals', label: 'Cele operacyjne'},
                    {key: 'actions', label: '', class: 'text-right'},
                ]
            }
        },
        mixins: [mixin],
        methods: {
          getGoalsCount(report){
            let count = 0
            report.goals.forEach(function(goal){
              if(!goal.parent){
                count++
              }
            })
            return count
          },
          async getReports(e=null) {
            if(e!==null){
              this.currentPage = e
            }
            let vm = this;
            let desc = 'desc';
            if (vm.sortDesc !== true) {
              desc = 'asc';
            }

            var params = {
              page: this.currentPage
            };

            if (this.sortBy !== '') {
              var key = "order[" + this.sortBy + "]";
              params[key] = desc;
            }
            let res = await axiosInstance
                .get(apiConfig.peopleReportsApiUrl,
                    {
                      params,
                      headers: this.$data.headers
                    });
            vm.data = res.data['member'];
            vm.$refs.table.refresh()
            vm.totalItems = res.data['totalItems'];

            return vm.data;
          },
            showRates(e) {
                this.selected = e;
                this.$refs.personRatingsModal.showModal(e);
            },
            addReport(){
              this.$refs.reportModal.showModal()
            },
            newGoal: function () {
                this.$refs.goalModal.showModal(null);
            },
            async refresh() {
                await this.$refs.table.refresh();
                this.$refs.personRatingsModal.showModal(this.selected);
            }
        },
      mounted() {
          this.getReports()
      }
    }
</script>
<style scoped>
    .wpip-label {
        background-color: rgb(195, 22, 50);
        width: 2em;
        height: 2em;
        padding-top: 0.3em;
        text-align: center;
        border-radius: 50%;
        color: white;
    }

</style>
