<template>
    <div class="text-right">
        <!--<a
                v-if="datatable.item.status==0"
                @click.prevent="showList"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Historia"
        >
            <vk-icon class="hover" icon="history"/>
        </a>
        <a
                v-else
                @click.prevent="showList"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Przejdź do listy obiektów"
        >
            <vk-icon class="hover" icon="credit-card"/>
        </a>
        <a
                @click.prevent="showList"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Zobacz szczegóły"
        >
        </a>
        -->
        <a
                @click.prevent="edit(datatable.item)"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Edytuj przestrzeń"
        >
            <vk-icon class="hover" icon="pencil"/>
        </a>

    </div>
</template>

<script>

    import mixin from "../mixin";

    export default {
        components: {
        },
        name: 'rentalObjectSpacesActions',
        mixins: [mixin],
        props: {
            datatable: Object
        },
        methods: {
            edit: function(item){
                this.$emit('editSpace', item)
            }
        }
    }
</script>
<style scoped>
    div {
        white-space: nowrap;
    }

    .middleware-red {
        background-color: #bf0e01;
        color: white;
    }

    .middleware-orange {
        background-color: #e38703;
        color: white;
    }
    .middleware-green {
        background-color: #09a913;
        color: white;
    }
</style>
