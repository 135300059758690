<template>
    <div class="uk-flex-top uk-modal-container" container=".admin" id="wpip-parameter-add" uk-modal>
        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close/>
            <div class="uk-modal-body">
                <h3>{{ modal.title }}</h3>
                <b-row class="mb-1">
                  <b-col cols="6">
                    <template v-if="filterUsers().length > onPage">
                      <b-button variant="outline-wpip" v-if="availablePage > 1" @click="previousPage('available')">Poprzednia strona</b-button>
                      <b-button variant="outline-wpip" v-if="availablePage < Math.ceil(filterUsers().length/onPage)" @click="nextPage('available')" class="ml-1">Następna strona</b-button>
                    </template>
                  </b-col>
                  <b-col cols="6" class="text-right" v-if="selectedUsers.length > onPage">
                    <b-button variant="outline-wpip" v-if="selectedPage > 1" @click="previousPage('selected')">Poprzednia strona</b-button>
                    <b-button variant="outline-wpip" v-if="selectedPage < Math.ceil(selectedUsers.length/onPage)" @click="nextPage('selected')" class="ml-1">Następna strona</b-button>
                  </b-col>
                </b-row>
                <vue-list-picker
                        :left-items="getPage(filterUsers(), availablePage)"
                        :right-items="getPage(selectedUsers, selectedPage)"
                        @move-right="moveRight"
                        @move-all-right="moveAllRight"
                        @move-all-left="moveAllLeft"
                        @move-left="moveLeft"
                        button-class="uk-button user-selection-buttons"
                        content-attr="label"
                        content-class="item-selected"
                        content-key="@id"
                        title-class="section-title"
                        title-left="Dostępni użytkownicy"
                        title-right="Wybrani użytkownicy"
                        :content-substr=128
                />
            </div>


            <div class="uk-modal-footer uk-text-right">
                <button class="uk-button uk-button-default uk-modal-close" type="button">Anuluj</button>
                <button @click="addUser" class="uk-button wpip-button" type="button">{{modal.button}}
                </button>
            </div>
        </div>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import UIkit from "uikit";
    import axiosInstance from "../../axiosInstance";

    export default {
        name: 'userModal',
        components: {},
        data() {
            return {
                filterValue: "",
                building: null,
                modalDom: null,
                user: null,
                selectedPage: 1,
                availablePage: 1,
                users: [],
                onPage: 10,
                selectedUsers: [],
                modal:
                    {
                        title: "Przypisanie użytkowników do budynku",
                        button: "Dodaj"
                    },

            }
        },
        mixins: [mixin],
        methods: {
            previousPage(list){
              if(list==='selected'){
                if(this.selectedPage > 1){
                  this.selectedPage--
                }
              }
              else if(list==='available'){
                if(this.availablePage > 1){
                  this.availablePage--
                }
              }
            },
            nextPage(list){
              if(list==='selected'){
                if(this.selectedPage < Math.ceil(this.selectedUsers.length/this.onPage)){
                  this.selectedPage++
                }
              }
              else if(list==='available'){
                if(this.availablePage < Math.ceil(this.filterUsers().length/this.onPage)){
                  this.availablePage++
                }
              }
            },
            getPage(list, page){
                return list.slice((page-1)*this.onPage, page*this.onPage)
            },
            moveAllRight(){
              let vm = this
              this.getPage(this.filterUsers(),this.availablePage).forEach(user => {
                vm.moveRight(user)
              })
            },
            moveAllLeft(){
              let vm = this
              this.getPage(this.selectedUsers,this.selectedPage).forEach(user => {
                vm.moveLeft(user)
              })
            },
            moveRight(user){
               this.selectedUsers.push(user)
               if(this.availablePage > Math.ceil(this.filterUsers().length/this.onPage) && this.availablePage>1){
                 this.availablePage--
               }
            },
            moveLeft(user){
              this.selectedUsers.forEach((item,key)=>{
                let vm = this
                if(item.id === user.id){
                  vm.users.push(user)
                  vm.selectedUsers.splice(key, 1)
                }
              })
              if(this.selectedPage > Math.ceil(this.selectedUsers.length/this.onPage) && this.selectedPage>1){
                this.selectedPage--
              }
            },
            showModal: function (buildingId) {
                this.user = null
                this.building = buildingId
                this.selectedUsers = []
                this.availablePage = 1
                this.selectedPage = 1
                this.getUsers('')
                this.$validator.reset()
                this.modalDom.show()
            },
            getUsers: function (e, page=1) {
                let vm = this;
                if(page===1){
                    vm.users = [];
                }
                var params = {};
                params['username'] = e;
                params.page = page;
                axiosInstance
                    .get(apiConfig.usersApiUrl, {
                        params,
                        headers: this.$data.headers
                    })
                    .then(function (response) {
                        vm.users = vm.users.concat(response.data['member']);
                        vm.users.forEach(function (user) {
                            user.buildings.forEach(function (building) {
                                if (building === apiConfig.buildingsApiUrl + '/' + vm.building) {
                                    vm.selectedUsers.push(user);
                                }
                            })
                        })
                        if(response.data['view'] && response.data['view']['next']){
                            vm.getUsers(e,page+1);
                        }
                    });
            },
            filterUsers: function () {
                let result = this.users;
                let vm = this;
                vm.selectedUsers.forEach(function (selectedUser) {
                    result.forEach(function (user, key) {
                        if (user.id === selectedUser.id) {
                            result = result.splice(key, 1);
                        }
                    })
                });
                return result;

            },
            addUser: function () {
                let vm = this;
                let selected = [];
                vm.selectedUsers.forEach(function(user){
                    selected.push(user['@id']);
                });
                let postData = {
                    users: selected
                };

                        axiosInstance
                            .put(apiConfig.buildingsApiUrl + "/" + this.building, JSON.stringify(postData), {
                                headers: vm.$data.headers
                            })
                            .then(function (response) {
                                if (response.status === 200) {
                                    vm.modalDom.hide();
                                    vm.$emit('refresh');
                                }
                });
            }
        },
        mounted() {
            this.modalDom = UIkit.modal('#wpip-parameter-add');
        }

    }
</script>
<style>
    .section-title {
        color: white !important;
        background-color: #bf0e01 !important;
    }

    .item-selected.list-picker-selected {
        background-color: #bf0e01 !important;
    }

    .item-selected.list-picker-selected::selection {
        background-color: #bf0e01 !important;
        color: white!important;
    }

    .item-selected::selection {
        color: #666 !important;
    }

    .user-selection-buttons {
        margin-top: 2px !important;
        color: white !important;
    }

    .btn-outline-wpip{
      color: #bf0e01!important;
      background-color: transparent!important;
      background-image: none!important;
      border-color: #bf0e01!important;
    }

    .btn-outline-wpip:hover {
      color: #fff!important;
      background-color: #bf0e01!important;
      border-color: #bf0e01!important;
    }
</style>

