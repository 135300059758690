<template>
    <admin>
        <div class="uk-container-expand uk-padding" id="ias-content">
            <vk-grid>
                <menuAdmin/>
                <div class="uk-width-expand">
                    <div class="uk-grid uk-margin-bottom">
                        <h3 class="uk-width-expand">Fazy IAS</h3>
                        <button @click="addIasPhase"
                                class="uk-button wpip-button uk-width-auto"
                        ><vk-icon icon="plus-circle"></vk-icon> Dodaj nową fazę IAS
                        </button>
                    </div>
                    <vk-card>
                        
                    <ias-phases-list v-on:edit="editIasPhase" ref="table"></ias-phases-list>
                    </vk-card>
                </div>
            </vk-grid>
        </div>
        <ias-phase-modal @refresh="refresh" ref="modal"/>
    </admin>
</template>

<script>

    import mixin from "../../components/mixin";
    import iasPhasesList from "../../components/iasPhases/iasPhasesList";
    import iasPhaseModal from "../../components/iasPhases/iasPhaseModal";

    export default {
        name: 'iasPhases',
        components: {
            iasPhaseModal,
            iasPhasesList
        },
        mixins: [mixin],
        data() {
            return {
                sortBy: 'name',
                sortDesc: false,
                data: [],
                currentPage: 1,
                totalItems: ''
            }
        },
        methods: {
            addIasPhase: function () {
                this.$refs.modal.showModal({id: null, name: ''});
            },
            editIasPhase: function (fmea) {
                this.$refs.modal.showModal(fmea);
            },
            refresh: function () {
                this.$refs.table.refresh();
            }

        },
        async mounted() {
            this.ability = await this.$ability.getAbility();
            if (!this.ability.can('manage', 'ROLE_SUPER_ADMIN')) {
                await this.$router.push({name: 'not-allowed'});
            }
        }
    }
</script>
