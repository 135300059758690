<template>
    <div class="uk-flex-top uk-modal-container" container=".admin" id="wpip-goal-add" uk-modal>
        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-modal-body">
                <h3>{{ modal.title }}</h3>

                <div class="uk-margin">
                    <label class="uk-form-label">Określ cel szczegółowy</label>
                    <div class="uk-form-controls">
                <textarea
                        class="uk-textarea"
                        rows="5"
                        v-model="goal.name"/>
                    </div>
                </div>


                <div class="uk-grid-small uk-margin" uk-grid>
                    <div class="uk-width-1-1@s">
                        <label class="uk-form-label">Perspektywa</label>
                        <div class="uk-form-controls">
                            <select class="uk-select" data-vv-as="perspektywa" name="perspective"
                                    v-model="goal.perspective"
                                    v-validate="'required'">
                                <option hidden></option>
                                <option
                                        :key="'r'+perspective.id"
                                        :value="perspective.id"
                                        v-for="perspective in perspectives"
                                >{{ perspective.perspective }}
                                </option>
                            </select>
                            <span class="validation-error">{{ errors.first('perspective') }}</span>
                        </div>
                    </div>
                </div>

            </div>


            <div class="uk-modal-footer uk-text-right">
                <button @click="cancel" class="uk-button uk-button-default uk-modal-close" type="button">Anuluj</button>
                <button @click="submitDetailedGoal" class="uk-button wpip-button" type="button">{{modal.button}}
                </button>
            </div>
        </div>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import UIkit from "uikit";
    import axiosInstance from "../../axiosInstance";
    import {pl} from "vuejs-datepicker/src/locale";
    import Perspectives from "../../constants/goalPerspectives"

    export default {
        name: 'goalModal',
        data() {
            return {
                pl: pl,
                filterValue: "",
                responsible: [],
                perspectives: Perspectives.PERSPECTIVES,
                goal: {
                    id: null,
                    name: "",
                    perspective: null
                },
                modal:
                    {
                        title: "Dodaj nowy cel",
                        button: "Dodaj"
                    },

            }
        },
        mixins: [mixin],
        methods: {
            showModal: function (goal) {
                if (goal == null) {
                    this.goal.id = null;
                    this.goal.name = "";
                    this.goal.perspective = null;

                    this.modal.button = "Dodaj";
                    this.modal.title = "Dodaj nowy cel";
                } else {
                    this.goal.id = goal.id;
                    this.goal.name = goal.name;
                    this.goal.perspective = goal.perspective;

                    this.modal.button = "Zmień";
                    this.modal.title = "Zmień cel";
                }
                this.$validator.reset();

                UIkit.modal("#wpip-goal-add").show();
            },
            submitDetailedGoal: async function () {
                let vm = this;
                vm.$validator.validate().then((result) => {
                    if (result) {
                        let postData = {
                            name: this.goal.name,
                            perspective: this.goal.perspective,
                            parent: '/api/goals/' + this.$route.params.goalId,
                            type: false
                            //bonus: this.goal.bonus
                        };
                        if (this.goal.id == null) {
                            axiosInstance
                                .post(apiConfig.goalsApiUrl, JSON.stringify(postData), {
                                    headers: this.$data.headers
                                })
                                .then(async function (response) {
                                    if (response.status === 201) {
                                        UIkit.modal("#wpip-goal-add").hide();
                                        vm.$emit('refresh');
                                    }
                                }).catch();
                        } else {
                            axiosInstance
                                .put(apiConfig.goalsApiUrl + "/" + vm.goal.id, JSON.stringify(postData), {
                                    headers: this.$data.headers
                                })
                                .then(async function (response) {
                                    if (response.status === 200) {
                                        UIkit.modal("#wpip-goal-add").hide();
                                        vm.$emit('refresh');
                                    }
                                }).catch();
                        }
                    }
                }).catch(() => {
                    return false;
                });
            },
            cancel: function () {
                this.$emit('refresh');
            }
        },
        mounted: function () {
            let vm = this;
            axiosInstance
                .get(apiConfig.peopleApiUrl, {
                    headers: this.$data.headers
                })
                .then(function (response) {
                    vm.responsible = response.data['member'];
                });
        }

    }
</script>
<style scoped>
    .correlation-header {
        font-size: 1.3em;
    }
</style>
