<template>
  <div class="text-right">
    <import-devices-modal ref="importModal" :buildingId="datatable.item.id"></import-devices-modal>
    <a
        class="uk-icon-button"
        href="#"
        @click.prevent="showImportModal()"
        uk-tooltip="Importuj Urządzenia"
    >
      <vk-icon class="hover" icon="push"/>
    </a>
    <a
        class="uk-icon-button"
        :href="'/buildings/'+datatable.item.id+'/resources'"
        uk-tooltip="Pokaż zasoby"
    >
      <vk-icon class="hover" icon="copy"/>
    </a>
    <a
        @click.prevent="addPerson(datatable.item)"
        class="uk-icon-button"
        href="#"
        uk-tooltip="Pokaż osoby"
    >
      <vk-icon class="hover" icon="user"/>
    </a>
    <a
        @click.prevent="editBuilding()"
        class="uk-icon-button"
        href="#"
        uk-tooltip="Edytuj bydynek"
    >
      <vk-icon class="hover" icon="pencil"/>
    </a>
    <a
        @click.prevent="deleteBuilding()"
        class="uk-icon-button"
        href="#"
        uk-tooltip="Usuń budynek"
    >
      <vk-icon class="Usuń" icon="trash"/>
    </a>
    <remove ref="remove" v-on:refresh="$emit('refresh')"/>
  </div>
</template>

<script>

import mixin from "../../components/mixin";

import apiConfig from "../../apiConfig";
import axiosInstance from "../../axiosInstance";
import UIkit from "uikit"
import remove from "../ui/remove";
import ImportDevicesModal from "./importDevicesModal";

export default {
  name: 'buildingsActions',
  components: {ImportDevicesModal, remove},
  mixins: [mixin],
  props: {
    datatable: Object
  },
  methods: {
    showImportModal(){
      this.$refs.importModal.showModal()
    },
    showPeople: function (building) {
      this.$emit('showPeople', building)
    },
    addPerson: function (building) {
      this.$emit('addPerson', building)
    },
    editBuilding: function () {
      this.$emit('edit', this.datatable.item);
      //router.push({name: 'buildingEdit', params: {buildingId: this.datatable.item.id}})
    },
    deleteBuilding: function () {
      if (this.$route.name === 'contractors') {
        let vm = this;
        UIkit.modal.confirm("Czy chcesz usunąć budynek z kontrahenta?", {
          labels: {ok: "Usuń", cancel: "Anuluj"}
        }).then(function () {
          let postData = {
            contractor: null
          };
          axiosInstance
              .put(apiConfig.buildingsApiUrl + '/' + vm.datatable.item.id, postData, {
                headers: vm.$data.headers
              }).then((response) => {
            if (response.status === 200) {
              vm.$parent.$el.remove();
            } else {
              // handle if status code != 204
            }
          });
        });
      } else {
        if (this.datatable.item.devices.length > 0) {
          UIkit.modal.alert('Nie można usunąć budynku posiadającego przypisane urządzenia');
        } else if (this.datatable.item.improvements.length > 0) {
          UIkit.modal.alert('Nie można usunąć budynku posiadającego przypisane usprawnienia')
        } else if (this.datatable.item.fmeaAnalyses.length > 0) {
          UIkit.modal.alert('Nie można usunąć budynku posiadającego przypisane analizy FMEA');
        } else if (this.datatable.item.swotAnalyses.length > 0) {
          UIkit.modal.alert('Nie można usunąć budynku posiadającego przypisane analizy SWOT');
        } else {
          this.$refs.remove.remove(apiConfig.buildingsApiUrl + '/' + this.datatable.item.id, 'budynek');
        }
      }
    }
  }
}
</script>
