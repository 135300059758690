<template>
    <span>
                <a
                        @click.prevent="changeCorrelation"
                        class="uk-icon-button negative-2"
                        href="#"
                        uk-tooltip="Silna korelacja wykluczająca"
                        v-bind:class="{selected: correlationValue===-2}"
                        value="-2"
                >
                    <vk-icon icon="minus"/>
                </a>
                <a
                        @click.prevent="changeCorrelation"
                        class="uk-icon-button negative-1"
                        href="#"
                        uk-tooltip="Korelacja negatywna"
                        v-bind:class="{selected: correlationValue===-1}"
                        value="-1"
                >
                    <vk-icon icon="minus"/>
                </a>
                <a
                        @click.prevent="changeCorrelation"
                        class="uk-icon-button positive-1"
                        href="#"
                        uk-tooltip="Korelacja pozytywna"
                        v-bind:class="{selected: correlationValue===1}"
                        value="1"
                >
                    <vk-icon icon="plus"/>
                </a>
                <a
                        @click.prevent="changeCorrelation"
                        class="uk-icon-button positive-2"
                        href="#"
                        uk-tooltip="Korelacja bardzo pozytywna"
                        v-bind:class="{selected: correlationValue===2}"
                        value="2"
                >
                    <vk-icon icon="plus"/>
                </a>
                    </span>
</template>

<script>

    import mixin from "../../components/mixin";

    export default {
        name: 'qfdSelectCorrelation',
        mixins: [mixin],
        props: {
            parameterId: null,
            initialValue: null
        },
        data() {
            return {
                correlationValue: 0
            }
        },
        methods: {
            changeCorrelation(parameter) {
                let clicked = parameter.target;
                while (clicked.nodeName !== "A") {
                    clicked = clicked.parentElement
                }
                let clickedValue = parseInt(clicked.attributes.getNamedItem('value').value)
                if (clickedValue === this.correlationValue) {
                    this.correlationValue = 0;
                } else {
                    this.correlationValue = clickedValue;
                }
                this.$emit('setCorrelation', {id: this.parameterId, value: this.correlationValue});
            },
        },
        watch: {
            initialValue: function () {
                this.correlationValue = this.initialValue;
            }
        },
        mounted: function () {
            this.correlationValue = this.initialValue;
        }
    }
</script>
<style scoped>
    a {
        margin-right: 3px;
    }

    .negative-2.selected {
        background-color: rgb(240, 80, 110);
        color: white;
    }

    .negative-1.selected {
        background-color: rgb(240, 140, 61);
        color: white;
    }

    .positive-1.selected {
        background-color: rgb(30, 135, 240);
        color: white;
    }

    .positive-2.selected {
        background-color: rgb(50, 210, 150);
        color: white;
    }
</style>
