<template>
    <div class="uk-flex-top uk-modal-container" container=".admin" id="cost" uk-modal @hide="hide">
        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-modal-body">
                <h3>{{ modal.title }}</h3>

                <label class="uk-form-label">Nazwa</label>
                <div class="uk-margin">
                    <input class="uk-input" data-vv-as="nazwa" name="name" placeholder="" type="text"
                           v-model="invoice.name"
                           v-validate="'max:255'">
                    <span class="validation-error">{{ errors.first('name') }}</span>

                </div>

                <label class="uk-form-label">Obiekt IAS</label>
                <div class="uk-margin">
                    <select class="uk-select" data-vv-as="obiekt IAS" name="building" placeholder="" type="text"
                            v-model="selectedBuilding"
                            v-validate="'required'" @change="changeBuilding">
                        <option v-bind:key="'b_'+building.id" v-for="building in event.buildings"
                                :value="building['@id']">{{
                            building.name }}
                        </option>
                    </select>
                    <span class="validation-error">{{ errors.first('building') }} {{ budgetMessage }}</span>
                </div>

                <label class="uk-form-label">Kategoria</label>
                <div class="uk-margin">
                    <select class="uk-select" data-vv-as="kategoria" name="category" placeholder="" type="text"
                            v-model="invoice.budgetPosition"
                            v-validate="'required'" :disabled="positions.length==0">
                        <option v-bind:key="'p_'+position.id" v-for="position in positions" :value="position['@id']">
                            <span v-if="position.budgetCategory">{{
                            position.budgetCategory.name }}</span>
                            <span v-else>{{
                            position.name }}</span>

                        </option>
                    </select>
                    <span class="validation-error">{{ errors.first('category') }}</span>
                </div>
                <div v-if="event.hasContractor">
                    <label class="uk-form-label">Kontrahent</label>
                    <div class="uk-margin">
                        <select class="uk-select" data-vv-as="kontrahent" name="contractor" placeholder="" type="text"
                                v-model="invoice.contractor"
                                v-validate="''">
                            <option v-bind:key="contractor.id" v-for="contractor in event.contractors"
                                    :value="contractor['@id']">{{ contractor.name }}
                            </option>
                        </select>
                        <span class="validation-error">{{ errors.first('contractor') }}</span>
                    </div>
                </div>
                <div v-if="event.hasDevice">
                    <label class="uk-form-label">Urządzenie</label>
                    <div class="uk-margin">
                        <select class="uk-select" data-vv-as="urządzenie" name="device" placeholder="" type="text"
                                v-model="invoice.device">
                            <option v-bind:key="device.id" v-for="device in event.devices"
                                    :value="device['@id']">{{ device.name }}
                            </option>
                        </select>
                        <span class="validation-error">{{ errors.first('device') }}</span>
                    </div>
                </div>

                <label class="uk-form-label">Wartość netto (PLN)</label>
                <div class="uk-margin">
                    <input class="uk-input" data-vv-as="wartość netto (PLN)" name="value" placeholder="" type="text"
                           v-model="invoice.value"
                           v-validate="'required|cash'">
                    <span class="validation-error">{{ errors.first('value') }}</span>

                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-default uk-modal-close" type="button">Anuluj</button>
                    <button @click="addCost" class="uk-button wpip-button" type="button">{{modal.button}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import mixin from "../mixin";
    import UIkit from "uikit";
    import 'vue-datetime/dist/vue-datetime.css'
    import axiosInstance from "../../axiosInstance";
    import apiConfig from "../../apiConfig";

    export default {
        name: 'addCostModal',
        props: {},
        data() {
            return {
                filterValue: "",
                modalDom: null,
                parameter: null,
                contractors: [],
                showed: false,
                positions: [],
                buildings: [],
                key: 0,
                type: 'datetime',
                building: {},
                budgetMessage: "",
                selectedBuilding: {},
                event: {},
                invoice: {
                    building: "",
                    contractor: "",
                    device: "",
                    budgetPosition: "",
                    value: 0
                },
                modal:
                    {
                        title: "Dodaj koszt",
                        button: "Dodaj",
                        mode: 0
                    },

            }
        },
        mixins: [mixin],
        methods: {
            hide: function () {
                if (this.showed) {
                    this.showed = false;
                    this.$emit('showEventDetails', this.event);
                    this.event.contractors.shift()
                    this.event.devices.shift()
                }
            },
            showModal: async function (cost, event = null) {
                this.invoice = {...cost};
                this.positions = [];
                this.modal.mode = 0;
                if (cost.id) {
                    this.event = {...event};
                    this.modal.title = "Zmień koszt";
                    this.modal.button = "Zmień";
                    this.modal.mode = 1;
                    this.selectedBuilding = this.invoice.budgetPosition.budget.building['@id'];
                    await this.changeBuilding({
                        data: {budgetPosition: this.invoice.budgetPosition['@id']},
                        target: {value: this.selectedBuilding}
                    })
                } else {
                    this.event = {...event};
                    this.invoice = {
                        id: null,
                        building: "",
                        contractor: null,
                        device: "",
                        budgetPosition: "",
                        value: 0
                    };
                    this.selectedBuilding = null;
                    this.modal.title = "Dodaj koszt";
                    this.modal.button = "Dodaj";
                }
                await this.$validator.reset();
                this.showed = true;
                this.event.contractors.unshift({
                    '@id': null,
                    'id': null,
                    'name': 'Brak'
                })
                this.event.devices.unshift({
                    '@id': null,
                    'id': null,
                    'name': 'Brak'
                })
                UIkit.modal('#cost').show();
            },
            getBuildings: async function () {
                let vm = this;
                vm.buildings = vm.event.buildings
                return vm.buildings;
            },
            changeBuilding: async function (e) {
                if (e.target.value !== "") {
                    let params = {};
                    let fromDateFilter = new Date(new Date(this.event.end).getFullYear(), new Date(this.event.end).getMonth(), 1);
                    let toDateFilter = new Date(new Date(this.event.end).getFullYear(), new Date(this.event.end).getMonth(), 1);
                    fromDateFilter.setDate(fromDateFilter.getDate()-1)
                    toDateFilter.setDate(toDateFilter.getDate()+1)
                    params['fromDate[after]'] = fromDateFilter.toISOString()
                    params['fromDate[before]'] = toDateFilter.toISOString()
                    params['building'] = e.target.value;
                    let budgets = await axiosInstance
                        .get(apiConfig.budgetsApiUrl,
                            {
                                params,
                                headers: this.$data.headers
                            });
                    this.building = e.target.value;
                    if (e.data && e.data.budgetPosition) {
                        this.invoice.budgetPosition = e.data.budgetPosition;
                    }
                    this.getPositions(budgets.data['member']);
                }
            },
            getPositions: function (budgets) {
                let vm = this;
                vm.positions = [];
                budgets.forEach(function (budget) {
                        budget.budgetPositions.forEach(function (position) {
                            vm.positions.push(position);
                        })
                })
                if(vm.positions.length==0){
                  this.budgetMessage = "Brak budżetu w terminie zakończenia zadania"
                } else {
                  this.budgetMessage = ""
                }
            },
            getContractors: async function (page = 1) {
                if (page === 1) {
                    this.contactors = [];
                }
                let vm = this;
                var params = {};
                params['page'] = page;

                let res = await axiosInstance
                    .get(apiConfig.contractorsApiUrl,
                        {
                            params,
                            headers: this.$data.headers
                        });
                vm.contractors = vm.contractors.concat(res.data['member']);
                if (res.data['view'] && res.data['view']['next']) {
                    await vm.getContractors(page + 1);
                }
                return vm.contractors;
            },
            addCost: function () {
                let postData = {...this.invoice};
                delete postData.event;
                if (typeof postData.value === 'string') {
                    postData.value = postData.value.replace(",", ".");
                    postData.value = parseFloat(postData.value);
                }
                if (this.invoice.id == null) {
                    postData.event = "/api/events/" + this.event.id;
                }
                if (this.invoice.device === "") {
                    delete postData.device;
                }
                let vm = this;
                vm.$validator.validate().then((result) => {


                    if (result) {
                        if (this.modal.mode === 0) {
                            axiosInstance
                                .post(apiConfig.invoicesApiUrl, JSON.stringify(postData), {
                                    headers: this.$data.headers
                                })
                                .then(async function (response) {
                                    if (response.status === 201) {
                                        UIkit.modal('#cost').hide();
                                    }
                                }).catch();
                        } else {
                            axiosInstance
                                .put(apiConfig.invoicesApiUrl + '/' + vm.invoice.id, JSON.stringify(postData), {
                                    headers: this.$data.headers
                                })
                                .then(async function (response) {
                                    if (response.status === 200) {
                                        vm.$emit('editEvent', vm.event);
                                        UIkit.modal('#cost').hide();
                                    }
                                }).catch();
                        }
                    }
                });
            }
        },
        watch: {
            selectedBuilding: function (val) {
                this.invoice.building = val;
                this.invoice.budgetPosition = "";
            }
        },
        async created() {
            this.modalDom = UIkit.modal('#cost');
        }
    }
</script>
<style>
    .correlation-header {
        font-size: 1.3em;
    }

    .wpip-theme .vdatetime-popup__header,
    .wpip-theme .vdatetime-calendar__month__day--selected > span > span,
    .wpip-theme .vdatetime-calendar__month__day--selected:hover > span > span {
        background: #bf0e01 !important;
    }

    .wpip-theme .vdatetime-year-picker__item--selected,
    .wpip-theme .vdatetime-time-picker__item--selected,
    .wpip-theme .vdatetime-popup__actions__button {
        color: #bf0e01;
    }
</style>
