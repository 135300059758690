import {Ability} from "@casl/ability";
import axiosInstance from "./axiosInstance";
import apiConfig from "./apiConfig";

export default {
    abilities: null,
    init: async function () {
        let abilitiesList = [];
        let vm = this;
        let res = await axiosInstance.get(apiConfig.usersApiUrl + "/" + localStorage.getItem('user'), {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                'Authorization': "Bearer " + localStorage.getItem('token'),
                blocker: {
                    requestKey: 'customRequestKeyName',
                    blockAllowed: false
                }
            }
        });
        res.data.roles.forEach(function (role) {
            abilitiesList.push({
                subject: role, actions: 'manage'
            })
        });
        vm.abilities = new Ability(abilitiesList);
        return vm.abilities;


    },

    test: function () {
    },

    getAbility: async function () {
        if (this.abilities == null) {
            let ability = await this.init()
            return ability;
        } else {
            return this.abilities;
        }
    },

    checkManageIasAbility: function (role) {
        let result = false;
        result = result || this.abilities.can('manage', 'ROLE_SUPER_ADMIN');
        result = result || this.abilities.can('manage', 'ROLE_IAS');
        result = result || this.abilities.can('manage', 'ROLE_' + role);
        return result;
    }
}
