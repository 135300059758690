<template>
<admin>
  <div id="wpip-project">
    <vk-grid class="uk-margin-bottom">
      <h3 class="uk-width-expand">Metody wspomagające spełnianie funkcji zarządzania IAS</h3>
    </vk-grid>
    <vk-card>
      <p><span class="method-name">ZARZĄDZANIE PRZEZ CELE</span> – umożliwia określenie celu/celów głównych, ich dekompozycję na cele cząstkowe i określenie odpowiedzialności za ich realizację.</p>

      <p><span class="method-name">SWOT–TOWS</span> – Kompleksowa metoda analizy strategicznej. Na podstawie wskazanych silnych i słabych stron oraz szans i zagrożeń, umożliwia opracowanie strategii postępowania.</p>

      <p><span class="method-name">QFD</span> – Macierz rozwinięcia funkcji jakościowych. Metoda umożliwiająca skonfrontowanie oczekiwań klienta z proponowanym przez projektanta rozwiązaniem i porównanie z tym co oferują konkurenci. Na tej podstawie
        można udoskonalić proponowane rozwiązanie.</p>

      <p><span class="method-name">FMEA</span> – Metoda analizy przyczyn i skutków błędów. Umożliwia zapobieganie skutkom błędów popełnianych w trakcie projektowania lub wytwarzania.</p>

      <p><span class="method-name">KAIZEN</span> – Ciągłe doskonalenie. Metoda pozwalająca wykorzystywać pomysły doskonalące istniejące rozwiązania, zgłaszane przez pracowników.</p>

      <p><span class="method-name">ZARZĄDZANIE EKSPLOATACJĄ</span> – moduł wspomagający zarządzanie eksploatacją, wielowariantowe projektowanie budynków i wielokryterialną ocenę nakładów inwestycyjnych i kosztów eksploatacji.</p>
    </vk-card>
    <div uk-grid>
      <div class="uk-width-1-2@m">
        <button class="uk-button uk-button-default uk-width-auto mt-3" @click="$router.push('infos')"><vk-icon icon="arrow-left"></vk-icon>  wstecz</button>
      </div>
    </div>
  </div>
</admin>
</template>

<style scoped>
.method-name {
  font-weight: bold;
  font-size: 1.2em;
}
</style>

<script>
export default {
  name: "iasMethods",
  components: {},
  data() {
    return {
      buildingId: null
    };
  },
  methods: {
    getBuildingId: () => {
      let buildingId = null;
      if ("undefined" !== localStorage.getItem('currentBuilding')) {
          const buildingData = JSON.parse(localStorage.getItem('currentBuilding'));
          buildingId = buildingData.id;
      }

      return buildingId;
    }
  }
};
</script>
