<template>
    <admin>
        <div class="uk-container uk-padding">
            <vk-grid>
                <menuAdmin></menuAdmin>
                <div class="uk-width-expand@m">
                    <buildingForm ref="form" v-on:submit="sendData"></buildingForm>
                </div>
            </vk-grid>
        </div>
    </admin>
</template>

<script>

    import {Grid} from 'vuikit/lib/grid';
    import mixin from "../../components/mixin";
    import apiConfig from "../../apiConfig";
    import BuildingForm from "../../components/building/buildingForm";
    import axiosInstance from "../../axiosInstance";

    export default {
        name: 'buildingAdd',
        components: {
            VkGrid: Grid,
            buildingForm: BuildingForm
        },
        data() {
            return {
                name: null,
                contractor: null,
                contractors: [],
                description: null,
                isIAS: false,
                isBMS: false,
                isDevice: false,
                isExploatation: false,
                isRental: false,
                isAnalysis: false,
                beginDate: false,
                endDate: false,
                image: "",
                imageFile: null
            }
        },
        mixins: [mixin],
        methods: {
            sendData: function (postData) {
                let vm = this;
                axiosInstance
                    .post(apiConfig.buildingsApiUrl, JSON.stringify(postData), {
                        headers: this.$data.headers,

                    })
                    .then(async function (response) {
                        if (response.status === 201) {

                                let res = await axiosInstance
                                    .get(apiConfig.userBuildingsApiUrl+'/'+localStorage.getItem('user'), {
                                        headers: vm.$data.headers,
                                    });
                            if (res.status === 200) {
                                localStorage.setItem('buildings', JSON.stringify(res.data));
                            }
                            vm.$router.push('/buildings');
                        }
                    }).catch();
            }

        }
    }
</script>
