<template>
    <span>
        <a
                @click.prevent="editIndustry(datatable.item)"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Edytuj branżę"
        >
            <vk-icon class="hover" icon="pencil"/>
        </a>
        <a
                @click.prevent="deleteIndustry(datatable.item.id)"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Usuń branżę"
        >
            <vk-icon class="hover" icon="trash"/>
        </a>
        <remove ref="remove" v-on:refresh="$emit('refresh')"/>
    </span>
</template>

<script>

    import mixin from "../../components/mixin";
    import apiConfig from "../../apiConfig";
    import remove from "../ui/remove";

    export default {
        name: 'industriesActions',
        components: {
            remove
        },
        mixins: [mixin],
        props: {
            datatable: Object
        },
        methods: {
            editIndustry(industry) {
                this.$emit('edit', industry)
            },
            deleteIndustry(industryId) {
                this.$refs.remove.remove(apiConfig.industriesApiUrl + "/" + industryId, "branżę");
            }
        }
    }
</script>
