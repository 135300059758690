<template>
<admin>
  <div class="uk-container-expand uk-padding">
    <vk-grid>
      <h3 class="uk-width-expand">Analiza {{ swotType[this.data.type]}}</h3>
      <button @click="showList" class="uk-button wpip-button uk-width-auto">Powrót do listy analiz
      </button>
    </vk-grid>
    <div v-if="data.type === SwotAnalysisTypes.TYPE_TOWS">
      <div v-if="result.strategy === 'so'">
        <h2>Strategia agresywna (maxi-maxi)</h2>
        <p>Polega na silnej ekspansji i rozwoju, przy maksymalnym wykorzystaniu szans dzięki silnym stronom.</p>
        <p>Strategia wskazuje sposób osiągania celów istotnych dla istnienia i rozwoju przedsiębiorstwa lub realizowanego przedsięwzięcia. Cele te powinny być wyraźnie określone. Mogą one być wykorzystane w metodzie „zarządzanie przez cele”.</p>
        <p>Strategia agresywna obejmuje między innymi takie działania jak: wykorzystanie okazji, wzmacnianie pozycji na rynku, przejmowanie organizacji o tym samym profilu, koncentrowanie zasobów na produktach, które mają zdolność konkurencyjną. </p>
        <p>Opracuj strategię umożliwiającą wykorzystanie następujących szans przez wykorzystanie silnych stron firmy.</p>

        <vk-grid>
          <div class="uk-width-1-2@m">
            <h4>Szansa (waga)</h4>
            <div v-bind:key="element.name" v-for="element in result.lists[0]">
              {{element.name}} ({{element.weight}})
            </div>
          </div>
          <div class="uk-width-1-2@m">
            <h4>Silna strona (waga)</h4>
            <div v-bind:key="element.name" v-for="element in result.lists[1]">
              {{element.name}} ({{element.weight}})
            </div>
          </div>
        </vk-grid>

      </div>
      <div v-if="result.strategy === 'st'">
        <h2>Strategia konserwatywna (maxi-mini)</h2>
        <p>Występuje w organizacji w sytuacji, gdy przy dużym potencjale wewnętrznym, jest ona poddana niekorzystnemu układowi warunków zewnętrznych. Przy użyciu mocnych stron należy przezwyciężać zagrożenia.</p>
        <p>Strategia konserwatywna obejmuje między innymi takie działania jak: zmniejszenie asortymentu produktów, segmentacja rynku, redukcja kosztów, ulepszanie produktów, które mają zdolność konkurencyjną, rozwijanie nowych produktów, poszukiwanie nowych rynków.</p>
        <p>Opracuj strategię umożliwiającą eliminację lub ograniczenie zagrożeń dzięki wykorzystaniu silnych stron firmy.</p>
        <vk-grid>
          <div class="uk-width-1-2@m">
            <h4>Zagrożenie (waga)</h4>
            <div v-bind:key="element.name" v-for="element in result.lists[0]">
              {{element.name}} ({{element.weight}})
            </div>
          </div>
          <div class="uk-width-1-2@m">
            <h4>Silna strona (waga)</h4>
            <div v-bind:key="element.name" v-for="element in result.lists[1]">
              {{element.name}} ({{element.weight}})
            </div>
          </div>
        </vk-grid>
      </div>
      <div v-if="result.strategy === 'wo'">
        <h2>Strategia konkurencyjna (mini-maxi)</h2>
        <p>Występuje w organizacji, w której słabe strony przeważają nad mocnymi, natomiast w otoczeniu przeważają szanse. Polega na wykorzystaniu pojawiających się szans, przy jednoczesnej redukcji słabych stron.</p>
        <p>Strategia konkurencyjna obejmuje takie działania jak: powiększanie zasobów finansowych, powiększanie zasobów handlowych, ulepszanie linii produktów, zwiększanie produktywności, redukcja kosztów.</p>
        <p>Opracuj strategię umożliwiającą wykorzystanie następujących szans przez eliminację bądź ograniczenie słabych stron.</p>
        <vk-grid>
          <div class="uk-width-1-2@m">
            <h4>Szansa (waga)</h4>
            <div v-bind:key="element.name" v-for="element in result.lists[0]">
              {{element.name}} ({{element.weight}})
            </div>
          </div>
          <div class="uk-width-1-2@m">
            <h4>Słaba strona (waga)</h4>
            <div v-bind:key="element.name" v-for="element in result.lists[1]">
              {{element.name}} ({{element.weight}})
            </div>
          </div>
        </vk-grid>
      </div>
      <div v-if="result.strategy === 'wt'">
        <h2>Strategia defensywna (mini-mini)</h2>
        <p>Strategia ta umożliwia przetrwanie w sytuacji, gdy organizacja działa w nieprzychylnym otoczeniu i pozbawiona jest istotnych mocnych stron.</p>
        <p>Strategia defensywna obejmuje takie działania jak: stopniowe wycofywanie się z niektórych działalności, redukcję kosztów, zmniejszenie zdolności produkcyjnej, zatrzymanie procesu inwestowania, sprzedaż niektórych działalności, sprzedaż niektórych zasobów.</p>
        <p>Opracuj strategię umożliwiającą eliminację lub złagodzenie zagrożeń dzięki usunięciu lub ograniczeniu słabych stron firmy.</p>
        <vk-grid>
          <div class="uk-width-1-2@m">
            <h4>Zagrożenie (waga)</h4>
            <div v-bind:key="element.name" v-for="element in result.lists[0]">
              {{element.name}} ({{element.weight}})
            </div>
          </div>
          <div class="uk-width-1-2@m">
            <h4>Słaba strona (waga)</h4>
            <div v-bind:key="element.name" v-for="element in result.lists[1]">
              {{element.name}} ({{element.weight}})
            </div>
          </div>
        </vk-grid>
      </div>
    </div>
    <div v-else>
      <h2>Strategia {{result.strategy}}</h2>
      <p v-if="result.strategy==='przygotowawcza'">Strategia ta polega na zwiększeniu liczby i mocy silnych stron oraz wyeliminowaniu słabych stron lub ograniczeniu ich mocy.</p>
      <p v-if="result.strategy==='ofensywna'">Strategia polega na wykorzystaniu jednej lub kilku szans poprzez użycie silnych stron i eliminację/ograniczenie słabych stron.</p>
      <p v-if="result.strategy==='defensywna'">Strategia polega na przeciwdziałaniu zagrożeniom poprzez likwidację/zmniejszenie słabych stron i wzmocnienie silnych stron.</p>
      <div v-if="result.strategy==='przygotowawcza'" class="uk-grid uk-child-width-1-2@m" uk-grid>
        <div v-bind:key="list.title" v-for="list in result.lists">
          <h3>{{ list.title}}</h3>
          <ul class="uk-list uk-list-striped">
            <li :key="element" v-for="element in list.elements">
              {{ element.name }} ({{element.count}})
            </li>
          </ul>
        </div>
      </div>
    </div>
    <vk-grid class="uk-margin-large-top">
      <div class="uk-width-1-2@m uk-text-left">
        <router-link v-bind:to="prevStep">
          <button class="uk-button wpip-button">Ustal korelacje</button>
        </router-link>
      </div>
      <div class="uk-width-1-2@m uk-text-right">
        <button class="uk-button wpip-button download" v-on:click="downloadPdf">Raport z analizy {{ swotType[this.data.type]}} (.pdf)</button>
        <file-download ref="download" />
      </div>
    </vk-grid>
  </div>
</admin>
</template>

<script>
import mixin from "../../components/mixin";
import apiConfig from "../../apiConfig";
import SwotAnalysisTypes from "../../constants/swotAnalysisTypes";
import axiosInstance from "../../axiosInstance";
import FileDownload from "../../components/ui/fileDownload"

export default {
  name: 'swotResult',
  components: {
    fileDownload: FileDownload
  },
  mixins: [mixin],
  data() {
    return {
      SwotAnalysisTypes: SwotAnalysisTypes,
      sortBy: 'name',
      sortDesc: false,
      data: [],
      result: {
        title: '',
        lists: []
      },
      prevStep: "/buildings/" + this.$route.params.buildingId + "/swots/" + this.$route.params.swotId + "/correlation",
      currentPage: 1,
      totalItems: '',
      showDetails: false,
    }
  },
  methods: {
    async get() {

      let vm = this;
      axiosInstance
        .get(apiConfig.swotApiUrl + '/' + this.$route.params.swotId, {
          headers: vm.$data.headers
        }).then(function(res) {

          vm.data = res.data;
          if (vm.data.type === SwotAnalysisTypes.TYPE_TOWS) {
            vm.prevStep = "/buildings/" + vm.$route.params.buildingId + "/swots/" + vm.$route.params.swotId + "/correlation/tows";
          }
        });
      axiosInstance
        .get(apiConfig.swotApiUrl + '/analyse/' + this.$route.params.swotId, {
          headers: vm.$data.headers
        }).then(function(res) {
          vm.result = res.data;
          if (vm.data.type === SwotAnalysisTypes.TYPE_TOWS) {
            vm.result.lists[0] = vm.result.lists[0].sort((a, b) => (a.weight > b.weight) ? -1 : 1);
            vm.result.lists[1] = vm.result.lists[1].sort((a, b) => (a.weight > b.weight) ? -1 : 1);
          }
        });
    },
    showList() {
      this.$router.push({
        name: 'swots'
      })
    },
    downloadPdf: function() {
      this.$refs.download.download("/swot/" + this.data.id, "SWOT.pdf");
    }

  },
  async mounted() {
    await this.$ability.getAbility();
    if (!this.$ability.checkManageIasAbility('SWOT')) {
      await this.$router.push({
        name: 'not-allowed'
      });
    }
    this.get();
  }

}
</script>
<style scoped>
.download {
  cursor: pointer;
}
</style>
