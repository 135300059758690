<template>
    <div class="uk-flex-top uk-modal-container" container=".admin" id="wpip-rate-person" uk-modal>
        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-modal-body">
                <h3>{{ modal.title }}</h3>

                <div class="uk-grid-small uk-margin" uk-grid>
                    <div class="uk-width-1-1@s">
                        <label class="uk-form-label">Osoba oceniana</label>
                        <div class="uk-form-controls">
                            <v-select :filterable="false" :options="responsible" @search="search"
                                      label="@id" :class="'wpip-select'" v-model="report.person">

                                <template slot="option" slot-scope="option">
                                    <div class="d-center">
                                        {{ option.firstName }} {{ option.lastName }}
                                    </div>
                                </template>

                                <template slot="selected-option" slot-scope="option">
                                    <div class="selected d-center">
                                        {{ option.firstName }} {{ option.lastName }}
                                    </div>
                                </template>

                                <template v-slot:no-options="{ search, searching }">
                                    <template v-if="searching">
                                        Nie znaleziono <em>{{ search }}</em>.
                                    </template>
                                    <em style="opacity: 0.5;" v-else>Wyszukaj osobę</em>
                                </template>
                            </v-select>
                            <span class="validation-error">{{ errors.first('responsible') }}</span>
                        </div>
                    </div>
                    <div class="uk-width-1-2@s">
                        <label class="uk-form-label">Od</label>
                        <div class="uk-inline uk-width-1-1">
                            <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>
                            <datepicker
                                    :format="'dd.MM.yyyy'" :input-class="'uk-input'" :language="pl"
                                        data-vv-as="Od"
                                        name="fromDate" type="date" v-model="report.fromDate"
                                        v-validate="'required'"/>
                            <span class="validation-error">{{ errors.first('fromDate') }}</span>
                        </div>
                    </div>
                    <div class="uk-width-1-2@s">
                        <label class="uk-form-label">Do</label>
                        <div class="uk-inline uk-width-1-1">
                            <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>
                            <datepicker
                                    :format="'dd.MM.yyyy'" :input-class="'uk-input'" :language="pl"
                                        data-vv-as="Do"
                                        name="toDate" type="date" v-model="report.toDate"
                                        v-validate="'required'"/>
                            <span class="validation-error">{{ errors.first('toDate') }}</span>
                        </div>
                    </div>
                </div>

            </div>


            <div class="uk-modal-footer uk-text-right">
                <button @click="cancel" class="uk-button uk-button-default uk-modal-close" type="button">Anuluj</button>
                <button @click="createReport" class="uk-button wpip-button" type="button">{{modal.button}}
                </button>
            </div>
        </div>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import UIkit from "uikit";
    import axiosInstance from "../../axiosInstance";
    import {pl} from "vuejs-datepicker/src/locale";
    import Datepicker from "vuejs-datepicker";

    export default {
        name: 'addPersonReportModal',
        data() {
            return {
                pl: pl,
                filterValue: "",
                responsible: [],
                benefits: [],
                disabledDates: {
                    to: null
                },
                report: {
                    fromDate: null,
                    toDate: null,
                    person: null,
                },
                modal:
                    {
                        title: "Dodaj nowy goal",
                        button: "Dodaj"
                    },

            }
        },
        components: {
            datepicker: Datepicker,
        },
        mixins: [mixin],
        methods: {
            async getPeople(e) {
                let vm = this;
                let params = {};
                params['firstName'] = e.split(' ');
                params['lastName'] = e.split(' ');
                let res = await axiosInstance
                    .get(apiConfig.peopleApiUrl,
                        {
                            params,
                            headers: this.$data.headers
                        });
                vm.responsible = res.data['member'];
                return vm.data;
            },
            search: function(e){
                let vm = this;
                clearTimeout(this.searchTimeout)
                this.searchTimeout = setTimeout(function () {
                    vm.getPeople(e)
                }, 500);
            },
            showModal: function (report) {
                if (report == null) {
                    this.report.id = null
                    this.report.person = ""
                    this.report.fromDate = null
                    this.report.toDate = null

                    this.modal.button = "Dodaj"
                    this.modal.title = "Dodaj nową ocenę"
                }
                this.$validator.reset();

                UIkit.modal("#wpip-rate-person").show();
            },
            createReport: async function () {
                let vm = this;
                vm.$validator.validate().then((result) => {
                    if (result) {
                        let postData = {
                            person: vm.report.person['@id'],
                            fromDate: vm.report.fromDate,
                            toDate: vm.report.toDate,
                            ratedBy: '/api/users/'+localStorage.getItem('user')

                        };
                        if (vm.report.id == null) {
                            axiosInstance
                                .post(apiConfig.peopleReportsApiUrl, JSON.stringify(postData), {
                                    headers: vm.$data.headers
                                })
                                .then(function (response) {
                                    if (response.status === 201) {
                                      axiosInstance.get(apiConfig.zpcRelations + "/" + response.data.id,
                                          {
                                            headers: vm.$data.headers
                                          }).then(function (){
                                        UIkit.modal("#wpip-rate-person").hide();
                                        vm.$emit('refresh');
                                      });


                                    }
                                }).catch();
                        }
                    }
                }).catch(() => {
                    return false;
                });
            },
            cancel: function () {
                this.$emit('refresh');
            }
        },
        mounted: function () {
            this.getPeople("")
        }

    }
</script>
<style scoped>
    .correlation-header {
        font-size: 1.3em;
    }
</style>
