<template>
  <admin>
  <div id="wpip-project">
    <div uk-grid>
      <h3 class="uk-width-expand">Analiza QFD</h3>
      <button class="uk-button wpip-button uk-width-auto" @click="$router.push('tools')">Lista metod</button>
    </div>
    <div id="wpip-tools" class="uk-child-width-1-2@m uk-grid-small uk-grid-match" uk-grid>
      <div v-for="tool in tools" :key="tool.name">
        <div class="uk-card uk-card-default uk-card-body">
          <h3 class="uk-card-title">
            <vk-icon v-bind:icon="tool.icon"/>
            {{ tool.title }}
          </h3>
          <p>{{ tool.desc }}</p>
          <button class="uk-button wpip-button" @click="getTool(tool.name)">Wybierz</button>
        </div>
      </div>
    </div>
  </div>
  </admin>
</template>

<script>
export default {
  name: "qfdOptions",
  data() {
    return {
      tools: [
        {
          name: "qfdParameters",
          icon: "question",
          title: "Baza parametrów technicznych obiektów IAS",
          desc: "Ustal parametry obiektów IAS oraz określ ich współzależność"
        },
        {
          name: "qfdAnalyses",
          icon: "info",
          title: "Analizy projektu ",
          desc: "Przeprowadź analizę QFD"
        }
      ]
    };
  },
    created() {
        this.pid = this.$route.params.Pid;
    },
    methods: {
        projects() {
            this.$router.push({name: "home"});
        },
        getTool(tool) {
            this.$router.push({name: tool, params: {buildingId: this.$route.params.buildingId}});
        }
    },
    async mounted() {
        await this.$ability.getAbility();
        if (!this.$ability.checkManageIasAbility('QFD')) {
            await this.$router.push({name: 'not-allowed'});
        }
    }
};
</script>
