<template>
    <div class="">
        <vk-grid class="uk-margin-bottom">
            <h3 class="uk-width-expand">Lista zgłoszonych pomysłów usprawnień </h3>
            <button
                    @click="newImprovement"
                    class="uk-button wpip-button uk-width-auto"
            ><vk-icon icon="plus-circle"></vk-icon> Nowe zgłoszenie
            </button>
        </vk-grid>
        <vk-card>
        <empty-list v-if="totalItems===0"/>
        <b-table
                v-else
                :columns="fields"
                :current-page="currentPage"
                :fields="fields"
                :items="get"
                :per-page="this.$data.perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                borderedless
no-sort-reset
:responsive="true"
                class="uk-table vk-table-justify uk-table-divider"
                hover
                ref="table">

            <template v-slot:cell(author)="data">
                <span v-if="data.item.author != null">
                    {{ data.item.author.firstName }} {{ data.item.author.lastName }}
                </span>
            </template>
            <template v-slot:cell(unit.name)="data">
                <span :uk-tooltip="data.item.unit.name">{{ limit(data.item.unit,50) }}</span>
            </template>
            <template v-slot:cell(name)="data">
                <span :uk-tooltip="data.item.name">{{ limit(data.item,50) }}</span>
            </template>
            <template v-slot:cell(areaScope)="data">
                <span v-if="data.item.iasPhase!==null">{{ data.item.iasPhase.name }}</span>/<span v-if="data.item.industry!==null">{{ data.item.industry.name }}</span>
            </template>

            <template v-slot:cell(actions)="data">
                <improvements-actions :datatable=data v-on:edit="edit" v-on:rate="rate" v-on:refresh="refresh()"/>
            </template>
        </b-table>
        <b-pagination :per-page="perPage" :total-rows="totalItems" align="center"
                      v-model="currentPage" v-if="totalItems>perPage" size="sm" page-class="wpip-page" class="wpip-page"/>

        </vk-card>
        <improvements-modal ref="improvementModal" v-on:refresh="refresh"/>
        <ratings-modal ref="ratingsModal" v-on:refresh="refresh"/>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import axiosInstance from "../../axiosInstance";
    import ImprovementsActions from "./keizensActions";
    import ImprovementsModal from "./improvementsModal";
    import RatingsModal from "./ratingsModal";
    import improvementsTypes from "../../constants/improvementsTypes";
    import EmptyList from "../ui/emptyList";
    import charLimiter from "../charLimiter";

    export default {
        name: 'kaizensList',
        components: {
            EmptyList,
            improvementsModal: ImprovementsModal,
            ratingsModal: RatingsModal,
            improvementsActions: ImprovementsActions
        },
        data() {
            return {
                IMPROVEMENT_AREA: improvementsTypes.IMPROVEMENT_AREA,
                IMPROVEMENT_SCOPE: improvementsTypes.IMPROVEMENT_SCOPE,
                sortBy: 'name',
                sortDesc: false,
                data: [],
                currentPage: 1,
                totalItems: '',
                fields: [
                    {key: 'date', label: 'Data', sortable: true},
                    {key: 'unit.name', label: 'Jednostka organizacyjna', sortable: true},
                    {key: 'areaScope', label: 'Obszar Usprawnienia/Branża', sortable: true},
                    {key: 'name', label: 'Opis', sortable: true},
                    {key: 'author', label: 'Autor', sortable: true},
                    {key: 'actions', label: '', class: 'text-right'},
                ]
            }
        },
        mixins: [mixin, charLimiter],
        methods: {
            async get() {
                let vm = this;
                let desc = 'desc';
                if (vm.sortDesc !== true) {
                    desc = 'asc';
                }

                var params = {};

                if (this.sortBy != '') {
                    var key = "order[" + this.sortBy + "]";
                    params[key] = desc;
                }
                params['page'] = vm.currentPage;
                params['building'] = vm.$route.params.buildingId;

                let res = await axiosInstance
                    .get(apiConfig.keizensApiUrl,
                        {
                            params,
                            headers: this.$data.headers
                        });
                vm.data = res.data['member'];
                vm.totalItems = res.data['totalItems'];

                return vm.data;
            },
            edit(e) {
                this.$refs.improvementModal.showModal(e);
            },
            rate(e) {
                this.$refs.ratingsModal.showModal(e);
            },
            newImprovement: function () {
                this.$refs.improvementModal.showModal(null);
            },
            refresh() {
                this.get()
                if(this.$refs.table) {
                    this.$refs.table.refresh();
                }
            }
        }
    }
</script>
