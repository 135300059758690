import Vue from 'vue';
import Vuikit from 'vuikit';
import VuikitIcons from '@vuikit/icons';
import '@vuikit/theme';
import App from './App.vue'
import router from './router'
import Admin from './components/ui/admin';
import Devices from './pages/devices';
import Logout from './pages/logout';
import Register from './pages/registers';
import Menu from './components/ui/menu';
import MenuBudgets from './components/ui/subMenu';
import VeeValidate, {Validator} from 'vee-validate';
import VeeValidateMessagesPl from "vee-validate/dist/locale/pl";
import BootstrapVue from 'bootstrap-vue'
import Loading from 'vue-loading-overlay';
import VueListPicker from 'vue-list-picker'
import abilities from "./abilities";
import vSelect from 'vue-select';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import 'vue-search-select/dist/VueSearchSelect.css';
import './assets/css/select.css';
import 'vue-select/dist/vue-select.css';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

require('./assets/scss/main.scss');
require('./assets/css/vuikit.min.css');


Vue.use(BootstrapVue);
Vue.use(Vuikit);
Vue.use(require('vue-chartist'))
Vue.use(VuikitIcons);
Vue.use(Loading);
Vue.use(VueListPicker);
Validator.localize({pl: VeeValidateMessagesPl});

//Custom Validators
require("./validators/swot.js");
require("./validators/contractor.js");
require("./validators/decimal.js");

Vue.use(VeeValidate, {locale: 'pl', fieldsBagName: 'formFields'});

Vue.prototype.$ability = abilities;

Vue.component('admin', Admin);
Vue.component('v-select', vSelect);
Vue.component('menuAdmin', Menu);
Vue.component('menuBudgets', MenuBudgets);
Vue.component('devices', Devices);
Vue.component('logout', Logout);
Vue.component('registers', Register);

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {

    let token = localStorage.getItem('token');
    if (to['name'] != 'login') {
        if (!token || typeof token === "undefined" || token === "undefined") {
            next({path: '/login'});
        }
    }

    next();
});


new Vue({
    router,
    render: h => h(App)
}).$mount('#app');
