<template>
    <div class="card">
        <div class="card-title text-center">
        </div>
        <div class="card-body">
            <div class="row" v-if="$props.element.register && $props.element.register.length===0" >
                <div class="col-5">
                    <div class="icon-big text-center">
                        <div class="icon-danger"><i class="nc-icon nc-simple-remove text-danger uk-text-bold"></i></div>
                    </div>
                </div>
                <div class="col-7">
                    <div class="numbers">
                        <div><p class="card-category">Nie wybrano rejestrów</p> <h4 class="card-title"></h4></div>
                    </div>
                </div>
            </div>
            <div v-else :key="register.id" class="row" v-for="register in $props.element.register">
                <div class="col-5">
                    <div class="icon-big text-center">
                        <div v-if="!register.valueExpected" class="icon-warning"><i class="nc-icon nc-simple-remove text-warning uk-text-bold"></i></div>
                        <div v-else class="icon-success"><i class="nc-icon nc-check-2 text-success uk-text-bold"></i></div>
                    </div>
                </div>
                <div class="col-7">
                    <div class="numbers">
                        <div><p class="card-category">{{ register.name }}</p> <h4 class="card-title">{{ register.lastValue }} {{register.unitOfMeasure}}</h4></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import charLimiter from "../charLimiter";

    export default {
        name: 'dashboardElementMultipleValues',
        components: {},
        data() {
            return {
            }
        },
        props: {
            elementId: null,
            element: {}
        },
        mixins: [mixin, charLimiter]
    }
</script>
<style scoped>
    .icon-big {
        font-size: 3em;
    }
</style>
