<template>
    <div class="text-right">
        <a
                @click.prevent="showSpaces(datatable.item.id)"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Przestrzenie"
        >
            <vk-icon class="hover" icon="table"/>
        </a>
        <a
                @click.prevent="showRenters(datatable.item)"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Najemcy"
        >
            <vk-icon class="hover" icon="users"/>
        </a>
        <a
                @click.prevent="showDetails(datatable.item)"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Dane szczegółowe"
        >
            <vk-icon class="hover" icon="search"/>
        </a>
        <a
                @click.prevent="showRentals(datatable.item)"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Należności"
        >
            <vk-icon class="hover" icon="credit-card"/>
        </a>
    </div>
</template>

<script>

    import mixin from "../mixin";

    export default {
        components: {},
        name: 'renterListActions',
        mixins: [mixin],
        props: {
            datatable: Object
        },
        methods: {
            showSpaces: function (objectId) {
                this.$emit('showSpaces', objectId)
            },
            showRenters: function (building) {
                localStorage.setItem('buildingFilter', JSON.stringify(building))
                this.$router.push({name: 'renters'})
            },
            showDetails: function (building) {
                this.$emit('showDetails', building)
            },
            showRentals: function(building){
                localStorage.setItem('buildingFilter', JSON.stringify(building))
                this.$router.push({name: 'rentalDashboard'})
            }
        }
    }
</script>
<style scoped>
    div {
        white-space: nowrap;
    }

    .middleware-red {
        background-color: #bf0e01;
        color: white;
    }

    .middleware-orange {
        background-color: #e38703;
        color: white;
    }

    .middleware-green {
        background-color: #09a913;
        color: white;
    }
</style>
