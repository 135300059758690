<template>
    <div class="uk-container-fluid uk-padding">
        <vk-grid>
            <menuAdmin/>
            <div class="uk-width-expand@m">
                <vk-grid class="uk-margin-bottom">
                    <h3 class="uk-width-expand">Kontrahenci </h3>
                    <button
                            @click="$refs.modal.showModal({})"
                            class="uk-button wpip-button uk-width-auto"
                    ><vk-icon icon="plus-circle"></vk-icon> Dodaj Kontrahenta
                    </button>
                </vk-grid>
                <vk-card>
                <div>
                    <empty-list v-if="totalItems===0"/>
                    <b-table v-else
                             :columns="fields" :current-page="currentPage" :fields="fields"
                             :items="get"
                             :per-page="this.$data.perPage"
                             :sort-by.sync="sortBy"
                             :sort-desc.sync="sortDesc"
                             borderedless
                             hover
                             no-sort-reset
                             :responsive="true"
                             ref="table">
                        <template v-slot:cell(name)="data">
                            <a @click="clickedRow(data.item.id)"  :uk-tooltip="data.item.name">{{ limit(data.item,50) }} </a>
                        </template>
                        <template v-slot:cell(email)="data">
                            <span :uk-tooltip="data.item.name">{{ limit(data.item,50, 'email') }}</span>
                        </template>
                        <template v-slot:cell(actions)="data">
                            <contractorsActions :datatable=data v-on:delete="refresh()" @edit="$refs.modal.showModal"
                                                v-on:refresh="refresh"/>
                        </template>
                    </b-table>
                    <b-pagination :per-page="perPage" :total-rows="totalItems" align="center"
                                  v-model="currentPage" v-if="totalItems>perPage" size="sm" page-class="wpip-page"
                                  class="wpip-page"/>

                    <contractor-modal ref="modal" @refresh="refresh"></contractor-modal>
                </div>
                    
                </vk-card>
            </div>
        </vk-grid>
    </div>
</template>

<script>

    import {Grid} from 'vuikit/lib/grid';
    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import ContractorsActions from "./contractorsActions"
    import ContractorModal from "./contractorModal"
    import axiosInstance from "../../axiosInstance";
    import EmptyList from "../ui/emptyList";
    import charLimiter from "../charLimiter";

    export default {
        name: 'contractorsList',
        components: {
            EmptyList,
            contractorsActions: ContractorsActions,
            VkGrid: Grid,
            ContractorModal
        },
        data() {
            return {
                sortBy: 'name',
                sortDesc: false,
                data: [],
                currentPage: 1,
                totalItems: '',
                fields: [
                    {key: 'name', label: 'Nazwa', sortable: true},
                    {key: 'email', label: 'E-mail', sortable: true},
                    {key: 'taxNo', label: 'NIP', class: 'text-center'},
                    {key: 'actions', label: ''},
                ]
            }
        },
        mixins: [mixin, charLimiter],
        methods: {
            clickedRow: function (contractorId) {
                this.$emit('details', contractorId);
            },
            async get() {
                let vm = this;
                let desc = 'desc';
                if (vm.sortDesc !== true) {
                    desc = 'asc';
                }


                var key = "order[" + this.sortBy + "]";
                var params = {};
                params[key] = desc;
                params['page'] = vm.currentPage;

                let res = await axiosInstance
                    .get(apiConfig.contractorsApiUrl,
                        {
                            params,
                            headers: this.$data.headers
                        });
                vm.data = res.data['member'];
                vm.totalItems = res.data['totalItems'];

                return vm.data;
            },
            refresh: async function () {
                await this.get();
                if (this.$refs.table) {
                    this.$refs.table.refresh();
                }
            }
        }
    }
</script>
