<template>
    <td
            :class="{'wpip-swot-last-vertical': lastVertical, 'wpip-swot-last-horizontal': lastHorizontal}"
            :id="getCorrelationId"
            :uk-tooltip="e1.swotElementType.toUpperCase() + ' - '   + e1.name + '<br/>' +e2.swotElementType.toUpperCase() + ' - '  +e2.name"
            @click="correlateSwotElements"
            @mouseleave="highlightSwotElements(false, $event)"
            @mouseover="highlightSwotElements(true, $event)"
            class="uk-text-center"
            :data-type="correlationType"
    >
                                <span v-if="checkCorrelation(2, correlationType[1]) === 'strong' && analysisType === SwotAnalysisTypes.TYPE_SWOT">
                                    <vk-icon icon="arrow-up"></vk-icon>
                                    <vk-icon icon="arrow-up"></vk-icon>
                                </span><span
            v-else-if="checkCorrelation(2, correlationType[1]) === 'strong' && analysisType === SwotAnalysisTypes.TYPE_TOWS">
                                    <vk-icon icon="check"></vk-icon>
                                </span>
        <span v-else-if="checkCorrelation(1, correlationType[1]) === 'weak'">

                                    <vk-icon icon="arrow-up"></vk-icon>
                                </span>
        <span v-else>
                                    -
                                </span>
    </td>
</template>

<script>

    import mixin from "../../components/mixin";
    import SwotAnalysisTypes from "../../constants/swotAnalysisTypes";
    import apiConfig from "../../apiConfig";
    import UIkit from 'uikit'
    import axiosInstance from "../../axiosInstance";

    export default {
        name: 'swotElementsCorrelationCell',
        components: {},
        mixins: [mixin],
        data() {
            return {
                SwotAnalysisTypes: SwotAnalysisTypes,
            }
        },
        props: {
            e1: null,
            e2: null,
            modal: null,
            correlationType: null,
            analysisType: null,
            lastHorizontal: null,
            lastVertical: null
        },
        methods: {
            getCorrelationId() {
                return this.correlationType + "_" + this.e1.id + "_" + this.e2.id;
            },
            correlateSwotElements(event) {
                if (this.analysisType === SwotAnalysisTypes.TYPE_SWOT) {
                    let self = event.currentTarget;
                    let swotType = self.getAttribute("data-type");

                    this.modal.el1 = this.e1;
                    this.modal.el2 = this.e2;
                    this.modal.a = this.modal.correlationLabels[swotType].a;
                    this.modal.b = this.modal.correlationLabels[swotType].b;
                    this.modal.type = [
                        this.modal.correlationLabels[swotType].typeFrom,
                        this.modal.correlationLabels[swotType].typeTo
                    ];

                    let correlations = this.e1.secondaryElementCorrelations;
                    this.modal.value = 0;

                    if (correlations.length !== 0) {
                        for (let cor of correlations) {
                            if (
                                this.e2.id === cor.primaryElement.id &&
                                cor.primaryElement.swotElementType === this.modal.correlationLabels[swotType].typeTo
                            ) {
                                this.modal.value = cor.value;
                            }
                        }
                    }


                    UIkit.modal("#wpip-swot-correlation").show();
                } else {
                    this.toggleCorrelation(this.e1, this.e2);
                    let vm = this;

                    axiosInstance
                        .get(apiConfig.swotCorrelationsApiUrl + "/primaryElement=" + vm.e2.id + ";secondaryElement=" + vm.e1.id,
                            {
                                headers: vm.$data.headers
                            })
                        .then(function () {
                            axiosInstance
                                .delete(apiConfig.swotCorrelationsApiUrl + "/primaryElement=" + vm.e2.id + ";secondaryElement=" + vm.e1.id,
                                    {
                                        headers: vm.$data.headers
                                    }).then(function () {
                            })
                        })
                        .catch(function (res) {
                            if (res.response.status === 404) {
                                let postData = {
                                    primaryElement: vm.e2["@id"],
                                    secondaryElement: vm.e1["@id"],
                                    value: 2
                                };
                                axiosInstance
                                    .post(apiConfig.swotCorrelationsApiUrl, postData,
                                        {
                                            headers: vm.$data.headers
                                        }).then(function () {
                                })
                            }
                        });
                }

            },
            highlightSwotElements(flag, event) {
                let self = event.currentTarget;
                let parent = self.parentElement;
                let siblings = parent.children;

                for (let e of siblings) {
                    if (flag) {
                        if (!e.getAttribute("rowspan") && e !== self) {
                            e.classList.add("wpip-high");
                        }
                    } else {
                        e.classList.remove("wpip-high");
                    }
                }
            },
            checkCorrelation(level, swotMatrixType) {
                let correlation = false;

                if (this.e1.secondaryElementCorrelations.length !== 0) {
                    correlation = true;
                }

                let correlations = this.e1.secondaryElementCorrelations;
                let correlationStrength = "none";
                let loopExit = false;

                for (let el of correlations) {
                    if (!loopExit) {
                        if (this.e2.id === el.primaryElement.id && el.primaryElement.swotElementType === swotMatrixType) {
                            correlation = true;
                            correlationStrength = el.value;
                            loopExit = true;
                        } else {
                            correlation = false;
                        }
                    }
                }

                let correlationMarker = "none";


                if (correlation && level === correlationStrength) {
                    if (level === 2) {
                        correlationMarker = "strong";
                    } else if (level === 1) {
                        correlationMarker = "weak";
                    }
                }
                return correlationMarker;
            },
            toggleCorrelation() {
                let correlations = this.e1.secondaryElementCorrelations;
                let loopExit = false;

                for (let el of correlations) {
                    if (!loopExit) {
                        if (this.e2.id === el.primaryElement.id) {
                            if (el.value == 2) {
                                el.value = 0
                            } else {
                                el.value = 2
                            }
                            loopExit = true;
                        }
                    }
                }
                if (!loopExit) {
                    this.e1.secondaryElementCorrelations.push({
                        primaryElement: this.e2,
                        secondaryElement: this.e1,
                        value: 2
                    })
                }
            }
        }
    }
</script>
