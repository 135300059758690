<template>
  <div class="text-right">
    <a
        @click.prevent="getInvoice(datatable.item.id)"
        class="uk-icon-button"
        href="#"
        uk-tooltip="Pobierz fakturę"
    >
      <vk-icon class="hover" icon="download"/>
    </a>
    <a
        @click.prevent="changeStatus(datatable.item)"
        class="uk-icon-button"
        href="#"
        uk-tooltip="Zmień status płatności"
    >
      <vk-icon v-if="!datatable.item.status" class="hover" icon="credit-card"/>
      <vk-icon v-else class="hover text-success" icon="check"></vk-icon>
    </a>
    <a
        @click.prevent="editInvoice(datatable.item)"
        class="uk-icon-button"
        href="#"
        uk-tooltip="Edytuj fakturę"
    >
      <vk-icon class="hover" icon="pencil"></vk-icon>
    </a>

    <a
        @click.prevent="removeInvoice()"
        class="uk-icon-button"
        href="#"
        uk-tooltip="Usuń fakturę"
    >
      <vk-icon class="hover" icon="trash"/>
    </a>
    <remove ref="remove" v-on:refresh="$emit('refresh')"/>
    <file-download ref="download"/>
  </div>
</template>

<script>

import mixin from "../mixin";
import FileDownload from "../ui/fileDownload";
import apiConfig from "@/apiConfig";
import Remove from "@/components/ui/remove";

export default {
  title: 'delete',
  components: {
    Remove,
    FileDownload
  },
  name: 'invoiceActions',
  mixins: [mixin],
  props: {

    datatable: Object
  },
  methods: {
    changeStatus: function (item) {
      this.$emit('toggleStatus', item)
    },
    editInvoice: function (item) {
      this.$emit('edit', item)
    },
    getInvoice: function (id) {
      this.downloadFile({id: id, filename: 'faktura.pdf'})
    },

    downloadFile: function (file) {
      this.$refs.download.download('/invoice/' + file.id, file.filename);
    },
    removeInvoice: function () {
      this.$refs.remove.remove(apiConfig.invoicesApiUrl + "/" + this.datatable.item.id, "fakturę");
    }
  }
}
</script>
<style scoped>
div {
  white-space: nowrap;
}

.middleware-red {
  background-color: #bf0e01;
  color: white;
}

.middleware-orange {
  background-color: #e38703;
  color: white;
}

.middleware-green {
  background-color: #09a913;
  color: white;
}
</style>
