<template>
  <admin>
    <div class="uk-container-expand uk-padding">
      <vk-grid>
        <menuAdmin></menuAdmin>
        <div class="uk-width-expand@m">
          <div>
            <ias-file-list></ias-file-list>
          </div>
        </div>
      </vk-grid>
    </div>
  </admin>
</template>

<script>

import mixin from "../../components/mixin";
import IasFileList from "../../components/iasFile/iasFileList";

export default {
  name: 'iasFiles',
  components: {
    IasFileList
  },
  mixins: [mixin],
  data() {
    return {
    }
  },
  async mounted() {
    this.ability = await this.$ability.getAbility();
    if (!this.ability.can('manage', 'ROLE_SUPER_ADMIN')) {
      await this.$router.push({name: 'not-allowed'});
    }
  }
}
</script>
