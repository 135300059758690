<template>
    <span>
        <a
                @click.prevent="editImprovement(datatable.item)"
                class="uk-icon-button"
                href="#"
                v-if="edit==true"
                uk-tooltip="Edytuj usprawnienie"
        >
            <vk-icon class="hover" icon="pencil"/>
        </a>
        <!-- <a
                @click.prevent="rateImprovement(datatable.item)"
                class="uk-icon-button"
                href="#"
                v-if="rate==true"
                uk-tooltip="Oceń usprawnienie"
        >
            <vk-icon class="hover" icon="search"/>
        </a> -->
        <a
                @click.prevent="downloadPdf(datatable.item)"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Pobierz raport (.pdf)"
        >
            <vk-icon class="hover" icon="download"/>
        </a>
        <a
                @click.prevent="removeImprovement(datatable.item)"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Usuń usprawnienie"
                v-if="edit==true"
        >
            <vk-icon class="hover" icon="trash"/>
        </a>


            <remove ref="remove" v-on:refresh="$emit('refresh')"/>
        <file-download ref="download"/>
    </span>
</template>

<script>

    import mixin from "../../components/mixin";
    import FileDownload from "../ui/fileDownload"
    import Remove from "../ui/remove";
    import apiConfig from "../../apiConfig";

    export default {
        name: 'improvementsActions',
        components: {
            Remove,
            fileDownload: FileDownload
        },
        mixins: [mixin],
        props: {
            datatable: Object
        },
        data: function () {
            return {
                edit: false,
                rate: false
            }
        },
        methods: {
            editImprovement(improvement) {
                this.$emit('edit', improvement);
            },
            rateImprovement(improvement) {
                this.$emit('rate', improvement);
            },
            downloadPdf(improvement) {
                this.$refs.download.download("/keizen/" + improvement.id, "Kaizen.pdf");
            },
            removeImprovement(improvement) {
                this.$refs.remove.remove(apiConfig.keizensApiUrl + '/' + improvement.id, 'usprawnienie');
            },
            async canEdit() {
                let result = false;
                this.ability = await this.$ability.getAbility();
                result = result || this.ability.can('manage', 'ROLE_SUPER_ADMIN');
                result = result || (this.ability.can('manage', 'ROLE_MANAGEMENT') && this.datatable.item.rates.length === 0);

                if (this.datatable.item.author != null) {
                    let author = parseInt(localStorage.getItem('person')) === this.datatable.item.author.id;
                    author = author && this.datatable.item.rates.length === 0;
                    result = result || author;
                }
                return result;
            },
          canRate: async function () {
            let result = false;
            this.ability = await this.$ability.getAbility();
            result = result || this.ability.can('manage', 'ROLE_SUPER_ADMIN');
            result = result || this.ability.can('manage', 'ROLE_MANAGEMENT');
            if (this.datatable.item.author != null) {
              let author = parseInt(localStorage.getItem('person')) === this.datatable.item.author.id;
              result = result || (this.ability.can('manage', 'ROLE_KEIZENTEAM') && !author);
            }
            return result;
            }

        },
        async mounted() {
            this.ability = await this.$ability.getAbility();
            this.rate = await this.canRate()
            this.edit = await this.canEdit()
        }
    }
</script>
