<template>
    <div>
      <vk-card class="uk-margin-bottom">
        <h4>Filtry</h4>
        <b-row>
          <b-col>
            <label>Budynek</label>
            <v-select multiple :filterable="false" :options="buildings" @search="searchBuildings"
                      label="@id" :class="'wpip-select'" v-model="filter.buildings"
                      data-vv-as="budynki" ref="multiSelectBuilding"
                      name="buildings" v-validate="'required'" @input="get()">

              <template slot="option" slot-scope="option">
                <div class="d-center">
                  {{ option.name }}
                </div>
              </template>

              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  {{ option.name }}
                </div>
              </template>

              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  Nie znaleziono <em>{{ search }}</em>.
                </template>
                <em style="opacity: 0.5;" v-else>Wyszukaj budynek</em>
              </template>
            </v-select>
          </b-col>
          <b-col>
            <label>Nazwa</label>
            <b-input class="uk-input" v-model="filter.name" debounce="500" @update="get"/>
          </b-col>
          <b-col>
            <label>Rodzaj</label>
            <b-input class="uk-input" v-model="filter.model" debounce="500" @update="get"/>
          </b-col>
        </b-row>
      </vk-card>
      <vk-card>
        <empty-list v-if="totalItems===0"/>
        <b-table v-else
                 :columns="fields" :current-page="currentPage" :fields="fields"
                 :items="data"
                 :per-page="this.$data.perPage"
                 :sort-by.sync="sortBy"
                 :sort-desc.sync="sortDesc"
                 borderedless
                 hover
                 no-sort-reset
                 :responsive="true"
                 ref="table">

            <template v-slot:cell(name)="data">
                <!-- <span @click="toggle(data)" class="table-toggle">
                    <vk-icon :icon="data.detailsShowing ? 'chevron-up' : 'chevron-down'"></vk-icon>
                    <span :uk-tooltip="data.item.name">{{limit(data.item, 100)}}</span>
                </span> -->
                <span :uk-tooltip="data.item.name">{{limit(data.item, 100)}}</span>
            </template>

            <template v-slot:row-details="row">
                <b-card>
                    <registers-list :device="row.item" :deviceId="row.item.id" :deviceRegisterTypes="row.item.registerTypeFields"/>
                </b-card>
            </template>

            <template v-slot:cell(actions)="data">
                <devices-actions :datatable=data v-on:refresh="refresh" @editDevice="editDevice"/>
            </template>
        </b-table>
        <device-modal ref="modal" @refresh="refresh"/>
        <b-pagination :per-page="perPage" :total-rows="totalItems" align="center" v-model="currentPage"
                      v-if="totalItems>perPage" size="sm" page-class="wpip-page" class="wpip-page"/>
      </vk-card>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import DevicesActions from "./devicesActions";
    import axiosInstance from "../../axiosInstance";
    import RegistersList from "../register/RegistersList";
    import DeviceModal from "./deviceModal";
    import EmptyList from "../ui/emptyList";
    import charLimiter from "../charLimiter";

    export default {
        name: 'devicesList',
        components: {
            EmptyList,
            DeviceModal,
            RegistersList,

            devicesActions: DevicesActions
        },
      props: {
          filters: Object
      },
      mounted(){
          this.get()
          this.getBuildings()
      },
        data() {
            return {
                sortBy: 'name',
                sortDesc: false,
                data: [],
                buildings: [],
                filter: {
                  buildings: [],
                  model: '',
                  name: ''
                },
                details: [],
                currentPage: 1,
                totalItems: '',
                fields: [
                    {key: 'name', label: 'Nazwa', sortable: true},
                    {key: 'building.name', label: 'Budynek', sortable: true},
                    {key: 'model', label: 'Rodzaj', sortable: true},
                    {key: 'actions', label: ''},
                ],
                buildingId: null
            }
        },
        mixins: [mixin, charLimiter],
        methods: {
            toggle: function (data) {
                let vm = this;

                if (!data.detailsShowing) {
                    this.details.push(data.item.id);
                } else {
                    this.details.forEach(function (device, key) {
                        if (device === data.item.id) {
                            vm.details.splice(key, 1);
                        }
                    });
                }
                data.toggleDetails();
                this.$refs.table.refresh();
            },
            addDevice: function () {
                this.$refs.modal.showModal(null);
            },
            editDevice: function (device) {
                this.$refs.modal.showModal(device);
            },
            async get() {
                let vm = this;
                let desc = 'desc';
                if (vm.sortDesc !== true) {
                    desc = 'asc';
                }

                var params = {};

                if (this.sortBy !== '') {
                    var key = "order[" + this.sortBy + "]";
                    params[key] = desc;
                }
                params['page'] = vm.currentPage;
                params['contractor.id'] = vm.contractorId;
                params['building.id'] = [];
                vm.filter.buildings.forEach((building=>{
                  params['building.id'].push(building.id)
                }))
              if(this.filter.name){
                params['name'] = this.filter.name
              }
              if(this.filter.model){
                params['model'] = this.filter.model
              }
              this.$emit('update:filters', this.filter)
                let res = await axiosInstance
                    .get(apiConfig.devicesApiUrl,
                        {
                            params,
                            headers: this.$data.headers
                        });
                vm.data = res.data['member'];
                vm.totalItems = res.data['totalItems'];
                vm.data.forEach(function (element) {
                    vm.details.forEach(function (device) {
                        if (device === element.id) {
                            element['_showDetails'] = true;
                        }
                    });
                });
                return vm.data;
            },
            refresh: async function () {
                await this.get();
                if (this.$refs.table) {
                    this.$refs.table.refresh();
                }
            },
          searchBuildings: function (e) {
            let vm = this;
            clearTimeout(this.searchTimeout)
            this.searchTimeout = setTimeout(function () {
              vm.getBuildings(e)
            }, 500);
          },
          getBuildings: function (e) {
            let vm = this;
            var params = {};
            params['name'] = e;
            axiosInstance
                .get(apiConfig.buildingsApiUrl, {
                  params,
                  headers: this.$data.headers
                })
                .then(function (response) {
                  vm.buildings = response.data['member'];
                });
          },
        }
    }
</script>
