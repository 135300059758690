<template>
    <div id="w-filters" uk-grid>
        <form class="uk-form-horizontal uk-width-1-1">
            <fieldset class="uk-fieldset">
                <legend class="uk-legend">Filtruj</legend>
                <div class="uk-margin">
                    <vk-grid>
                        <label class="uk-form-label w-label">Właściciel</label>

                        <div class="uk-inline uk-width-expand">
                            <span class="uk-form-icon uk-form-icon-flip" uk-icon="" ></span>
                            <v-select multiple :filterable="false" :options="contractors" @search="searchContractors"
                                      label="@id" :class="'wpip-select'" v-model="filter.contractors"
                                      data-vv-as="kontrahenci" ref="multiSelectContractor"
                                      name="contractors" v-validate="'required'">

                                <template slot="option" slot-scope="option">
                                    <div class="d-center">
                                        {{ option.name }}
                                    </div>
                                </template>

                                <template slot="selected-option" slot-scope="option">
                                    <div class="selected d-center">
                                        {{ option.name }}
                                    </div>
                                </template>

                                <template v-slot:no-options="{ search, searching }">
                                    <template v-if="searching">
                                        Nie znaleziono <em>{{ search }}</em>.
                                    </template>
                                    <em style="opacity: 0.5;" v-else>Wyszukaj kontahentów</em>
                                </template>
                            </v-select>
                        </div>
                    </vk-grid>
                </div>
                <div class="uk-margin">
                    <vk-grid>
                        <label class="uk-form-label w-label">Suma należności</label>
                        <div class="uk-inline uk-width-expand">
                            <span class="uk-form-icon uk-form-icon-flip" uk-icon="">PLN</span>
                            <input class="uk-input" type="text" v-model="filter.minValue" placeholder="od" :disabled="filter.status[2]">
                        </div>
                        <div class="uk-inline uk-width-expand">
                            <span class="uk-form-icon uk-form-icon-flip" uk-icon="">PLN</span>
                            <input class="uk-input" type="text" v-model="filter.maxValue" placeholder="do" :disabled="filter.status[2]">
                        </div>
                    </vk-grid>
                </div>
                <div class="uk-margin">
                    <vk-grid>
                        <label class="uk-form-label w-label">Dostępna powierzchnia</label>
                        <div class="uk-inline uk-width-expand">
                            <span class="uk-form-icon uk-form-icon-flip" uk-icon="">m2</span>
                            <input class="uk-input" type="text" v-model="filter.minArea" placeholder="od">
                        </div>
                        <div class="uk-inline uk-width-expand">
                            <span class="uk-form-icon uk-form-icon-flip" uk-icon="">m2</span>
                            <input class="uk-input" type="text" v-model="filter.maxArea" placeholder="do">
                        </div>
                    </vk-grid>
                </div>
                <div class="uk-margin">
                    <vk-grid>
                        <label class="uk-form-label w-label">Data wymagalności</label>
                        <datepicker :format="'dd.MM.yyyy'" :input-class="'uk-input'" class="uk-width-expand"
                                    :language="pl" data-uk-datepicker="{format:'dd.MM.YYYY'}"
                                    name="fromDate" type="text" v-model="filter.beginDate"  placeholder="od" :disabled="filter.status[2]">
                          <span slot="afterDateInput" class="clear-icon" @click.prevent="filter.beginDate=''"><vk-icon icon="close"></vk-icon></span>
                        </datepicker>

                        <datepicker :format="'dd.MM.yyyy'" :input-class="'uk-input'" class="uk-width-expand"
                                    :language="pl" data-uk-datepicker="{format:'dd.MM.YYYY'}"
                                    name="fromDate" type="text" v-model="filter.endDate"  placeholder="do" :disabled="filter.status[2]">
                          <span slot="afterDateInput" class="clear-icon" @click.prevent="filter.endDate=''"><vk-icon icon="close"></vk-icon></span>
                        </datepicker>
                    </vk-grid>
                </div>
                <div class="uk-margin">
                    <label class="uk-form-label w-label">Status należności</label>
                    <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                        <label><input class="uk-checkbox" v-model="filter.status[0]" type="checkbox" @input="changeStatus(0)"> wymagalne</label>
                        <label><input class="uk-checkbox" v-model="filter.status[1]" type="checkbox" @input="changeStatus(1)"> zaległe</label>
                        <label><input class="uk-checkbox" v-model="filter.status[2]" type="checkbox" @input="changeStatus(2)"> zapłacone</label>
                    </div>
                </div>
                <div class="uk-margin uk-align-right">
                    <button class="uk-button wpip-button uk-width-auto" @click.prevent="$emit('filter', filter)">Filtruj</button>
                </div>
            </fieldset>
        </form>
    </div>
</template>

<script>
    import mixin from "../../components/mixin";
    import charLimiter from "../charLimiter";
    import axiosInstance from "../../axiosInstance";
    import apiConfig from "../../apiConfig";
    import {pl} from "vuejs-datepicker/dist/locale";
    import Datepicker from 'vuejs-datepicker';

    export default {
        name: "rentalObjectsFilters",
        components: {
            Datepicker
        },
        data() {
            return {
                pl: pl,
                contractors: [],
                filter: {
                    contractors: [],
                    beginDate: "",
                    endDate: "",
                    status: [],
                    minValue: null,
                    maxValue: null,
                    minArea: null,
                    maxArea: null,
                }
            };
        },
        methods: {
            changeStatus: function(index){
                if(!this.filter.status[index]){
                    for(let i=0;i<3;i++){
                        if(i!==index){
                            this.filter.status[i] = false
                        }
                    }
                }
            },
                searchContractors: function (e) {
                    let vm = this;
                    clearTimeout(this.searchTimeout)
                    this.searchTimeout = setTimeout(function () {
                        vm.getContractors(e)
                    }, 500);
                },
                setFilters: function (filter) {
                    this.filter = filter
                },
                getContractors: function (e) {
                    let vm = this;
                    var params = {};
                    params['name'] = e;
                    axiosInstance
                        .get(apiConfig.contractorsApiUrl, {
                            params,
                            headers: this.$data.headers
                        })
                        .then(function (response) {
                            vm.contractors = response.data['member'];
                        });
                }
        },
        mounted() {
            this.searchContractors("")
            this.filter = this.$attrs.filters
        },
        mixins: [mixin, charLimiter]
    };
</script>

<style scoped>
    .uk-accordion li {
        list-style-type: none;
    }

    .uk-accordion-title {
        color: black;
        font-size: 1.3em;
    }

    .uk-accordion {
        padding-left: 0;
    }
</style>
