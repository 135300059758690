<template>
  <div class="uk-flex-top uk-modal-container" container=".admin" id="wpip-self-edit" uk-modal>
    <div class="uk-modal-dialog uk-margin-auto-vertical">
      <button class="uk-modal-close-default" type="button" uk-close/>
      <div class="uk-modal-body">
        <h3>{{ modal.title }}</h3>

        <div class="uk-margin">
          <label class="uk-form-label">Hasło</label>
          <div class="uk-form-controls">
            <input
                class="uk-input"
                data-vv-as="hasło"
                name="password"
                type="password"
                v-model="user.password"
                v-validate="'required'"
            >
            <span class="validation-error">{{ errors.first('password') }}</span>
          </div>
        </div>


        <div class="uk-margin">
          <label class="uk-form-label">Powtórz Hasło</label>
          <div class="uk-form-controls">
            <input
                class="uk-input"
                data-vv-as="powtórz hasło"
                name="passwordConfirmation"
                type="password"
                v-model="user.passwordConfirmation"
                v-validate="'required'"
            >
            <span class="validation-error">{{ errors.first('passwordConfirmation') }}</span>
            <span class="validation-error" v-if="user.password.length>0 && user.passwordConfirmation.length>0 && user.password!==user.passwordConfirmation"> Podane hasła nie są identyczne. </span>
          </div>
        </div>

        <div class="uk-modal-footer uk-text-right">
          <button @click="cancel" class="uk-button uk-button-default uk-modal-close" type="button">Anuluj
          </button>
          <button @click="submit" class="uk-button wpip-button" type="button">{{ modal.button }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import mixin from "../mixin";
import apiConfig from "../../apiConfig";
import UIkit from "uikit";
import axiosInstance from "../../axiosInstance";

export default {
  name: 'selfEditModal',
  data() {
    return {
      filterValue: "",
      user: {
        id: null,
        email: "",
        password: "",
        passwordConfirmation: ""
      },
      modal:
          {
            title: "Edytuj dane użytkownika",
            button: "Zmień"
          },

    }
  },
  mixins: [mixin],
  methods: {
    showModal: function (userId) {
      let vm = this
      axiosInstance.get(
          apiConfig.usersApiUrl + '/' + userId
      ).then((result) => {
        vm.user = result.data

        this.user.password = '';
        this.user.passwordConfirm = '';
        this.$validator.reset();
      })
      this.modal.button = "Zmień";
      this.modal.title = "Zmień dane użytkownika";

      UIkit.modal("#wpip-self-edit").show();
    },
    submit: async function () {
      let vm = this;
      vm.$validator.validate().then((result) => {
        if (result && vm.user.password==vm.user.passwordConfirmation) {
          this.submitPassword()
        }
      }).catch(() => {
        return false;
      });
    },
    submitPassword: function () {
      let postData = {...this.user};
      if(!postData.person){
        delete postData.person
      }
      if(typeof postData.person !== 'string'){
        postData.person = postData.person['@id']
      }
      axiosInstance
          .put(apiConfig.selfEditApiUrl, JSON.stringify(postData), {
            headers: this.$data.headers
          })
          .then(async function (response) {
            if (response.status === 200) {
              UIkit.modal("#wpip-self-edit").hide();
            }
          }).catch();
    },
    cancel: function () {
      this.$emit('refresh');
    }
  }

}
</script>
