<template>

    <div class="uk-margin">
        <renter-spaces-list-modal ref="spacesModal" @edit="editRental" @addRental="addRental" @refresh="refresh"/>
        <rental-modal :contractor="selectedContractor" ref="rentalModal" @refresh="refresh"/>
        <renter-contacts-list-modal ref="contactsModal"/>
        <rental-details-modal ref="rentalDetailsModal" @addInvoice="addInvoice"/>
        <contractor-details-modal ref="contractorDetails"/>
        <invoice-modal ref="invoiceModal" @refresh="refreshDetails"/>
        <contractor-modal ref="contractorEdit" :renter-mode="true" v-on:refresh="refresh"/>
        <empty-list v-if="totalItems===0"/>
        <b-table v-else ref="table" hover borderedless
                 :fields="fields"
                 :columns="fields"
                 :items="data"
                 :per-page="this.$data.perPage"
                 :current-page="currentPage"
                 :sort-by.sync="sortBy"
                 :sort-desc.sync="sortDesc"
                 no-sort-reset
                 :responsive="true">
            <template v-slot:head(actions)="data">
                <span class="uk-icon-link" uk-tooltip="Filtruj"><vk-icon class="hover" icon="settings" @click="$emit('toggleFilters')"/></span><span class="uk-badge uk-margin-small-left">{{$attrs.filterNum}}</span>
            </template>
            <template v-slot:cell(name)="data">
              <span :uk-tooltip="'Istnieje niezakończony wynajem z przekroczonym teminem zakończenia'" v-if="data.item.rentalWarning"><vk-icon class="hover text-warning" icon="warning"/></span> {{ data.item.name }}
            </template>
          <template v-slot:cell(area)="data">
            {{ parseFloat(data.item.rentedArea).toFixed(2) }}
          </template>
          <template v-slot:cell(value)="data">
            {{ parseFloat(data.item.value).toFixed(2) }}
          </template>
            <template v-slot:cell(actions)="data">
                <renter-list-actions :datatable=data v-on:showObjects="showObjects" v-on:showContacts="showContacts"
                                     v-on:contractorDetails="contractorDetails" v-on:contractorEdit="contractorEdit" v-on:showDetails="showDetails" v-on:refresh="refreshSuspend"/>
            </template>
            <template v-slot:cell(status)="data">
                <span v-if="data.item.isSuspended" class="small badge badge-danger badge-pill">zawieszony</span>
                <span v-else :class="'badge ' + renterStatus[data.item.status+'-class'] ">{{ renterStatus[data.item.status] }}</span>
            </template>

        </b-table>
        <b-pagination :total-rows="totalItems" :per-page="perPage" v-model="currentPage"
                      align="center" v-if="totalItems>perPage" size="sm" page-class="wpip-page" class="wpip-page"/>
    </div>
</template>

<script>
    import mixin from "../../components/mixin"
    import charLimiter from "../charLimiter"
    import RenterListActions from "./renterListActions"
    import RenterSpacesListModal from "./renterSpacesListModal"
    import RenterContactsListModal from "./renterContactsListModal"
    import ContractorDetailsModal from "../contractor/contractorDetailsModal"
    import renterStatus from '../../constants/renterStatus'
    import ContractorModal from "../contractor/contractorModal";
    import axiosInstance from "../../axiosInstance";
    import apiConfig from "../../apiConfig";
    import EmptyList from "../ui/emptyList";
    import RentalModal from "./rentalModal";
    import RentalDetailsModal from "../rentalDashboard/rentalDetailsListModal";
    import InvoiceModal from "@/components/rentalDashboard/invoiceModal";

    export default {
        name: "rentalList",
        components: {
          InvoiceModal,
            RentalDetailsModal,
            RentalModal,
            EmptyList,
            ContractorModal,
            RenterListActions,
            RenterContactsListModal,
            RenterSpacesListModal,
            ContractorDetailsModal
        },
        data() {
            return {
                renterStatus: renterStatus,
                sortBy: 'name',
                sortDesc: false,
                data: [],
                currentPage: 1,
                totalItems: '',
                fields: [
                    {key: 'name', label: 'Najemca', sortable: true},
                    {key: 'value', label: 'Należności (PLN)'},
                    {key: 'area', label: 'Powierzchnia (M2)'},
                    {key: 'status', label: 'Status'},
                    {key: 'actions', label: '', class: 'text-right'},
                ],
              lastRenter: null,
                selectedContractor: null
            };
        },
        mixins: [mixin, charLimiter],
        created() {
        },
        methods: {
            addInvoice(data){
              this.$refs.invoiceModal.showModal(data)
              this.lastRenter = data.rental.contractor
            },
            refresh: function(){
              this.get(this.$props.filters)
                if(this.$refs.table) {
                    this.$refs.table.refresh()
                }
            },
            refreshDetails: function(){
              this.get(this.$props.filters)
                if(this.$refs.table) {
                    this.$refs.table.refresh()
                }
                this.showDetails(this.lastRenter)
            },
            refreshSuspend: function(){
                this.get(this.$props.filters)

              if(this.$refs.table) {

                this.$refs.table.refresh()
              }
            },
            addContractor: function(){
                this.$refs.contractorEdit.showModal({})
            },
            async get(filters={}) {
                let vm = this;
                let desc = 'desc';
                if (vm.sortDesc !== true) {
                    desc = 'asc';
                }

                var key = "order[" + this.sortBy + "]";
                var params = filters;
                params[key] = desc;
                params['page'] = vm.currentPage;
                params['isRenter'] = true;

                let res = await axiosInstance
                    .get(apiConfig.contractorsApiUrl,
                        {
                            params,
                            headers: this.$data.headers
                        });
                vm.data = res.data['member'];
                vm.data.forEach(function (renter) {
                    renter.value = renter.paymentData.value;
                    if(renter.paymentData.datePayment!==null && new Date(renter.paymentData.datePayment)<new Date()) {
                        renter.status = 0
                    }
                    else{
                        renter.status = 1
                    }
                });
                vm.totalItems = res.data['totalItems'];



                return vm.data;
            },
            addRental: function(){
                this.$refs.rentalModal.showModal(null);
            },
            editRental: function(data){
                this.$refs.rentalModal.showModal(data);
            },
            showObjects: function (e) {
                this.selectedContractor = e
                this.$refs.spacesModal.showModal(e);
            },
            showContacts: function (e) {
                this.$refs.contactsModal.showModal(e);
            },
            contractorDetails: function (e) {
                this.$refs.contractorDetails.clickedRow(e);
            },
            contractorEdit: function (e) {
                this.$refs.contractorEdit.showModal({...e});
            },
            showDetails: function (e) {
                this.$refs.rentalDetailsModal.showModal(e);
            }
        },
        mounted() {
            this.get();
        },
        props:{
          filters: Object
        }
    };
</script>

<style scoped>
    .uk-accordion li {
        list-style-type: none;
    }

    .uk-accordion-title {
        color: black;
        font-size: 1.3em;
    }

    .uk-accordion {
        padding-left: 0;
    }

    .badge {
        font-size: 1em
    }

    .small{
        font-size:12px;
    }
</style>
