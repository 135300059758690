<template>
  <div class="uk-flex-top uk-modal-container" container=".admin" id="wpip-info" uk-modal>
    <div class="uk-modal-dialog uk-margin-auto-vertical">
      <button class="uk-modal-close-default" type="button" uk-close/>
      <div class="uk-modal-body">
        <h3>{{ cols[column].name }}</h3>
        <!-- <div>{{cols[column].description}}</div> -->
        <div v-html="cols[column].description"></div>
      </div>
    </div>
  </div>
</template>

<script>

import mixin from "../mixin";
import UIkit from "uikit";

export default {
  name: 'infoModal',
  data() {
    return {
      filterValue: "",
      cols:
          [
            {
              name: "Funkcja",
              description: "Plan funkcji ma strukturę drzewiastą. Odwzorowują ją numery funkcji, które wskazują, która funkcja jest wyższego rzędu i które funkcje są jej rozwinięciem (funkcje niższego rzędu). Nazwa funkcji określa potrzebne działanie w zakresie zarządzania IAS"
            },
            {
              name: "Rodzaj",
              description: "S – strategiczna,<br>T – taktyczna,<br>O – operacyjna"
            },
            {
              name: "Odpowiedzialny za wynik",
              description: "Symbol jednostki organizacyjnej, która ponosi odpowiedzialność za rezultat spełniania funkcji, w związku z tym ma prawo do zmiany sposobu spełniania funkcji przez głównego realizatora oraz jednostek z nim współpracujących. W szczególności odpowiada za wynik w przypadku, gdy funkcja jest spełniana w outsourcingu."
            },
            {
              name: "Główny realizator",
              description: "Symbol jednostki organizacyjnej, która bezpośrednio spełnia funkcję i sekwencję procesu, która tę funkcję obejmuje."
            },
            {
              name: "Nadzór",
              description: "Stanowisko kierownicze, które najczęściej jest przełożonym głównego realizatora, lub jest przełożonym wyższego rzędu. Często jest również odpowiedzialnym za wyniki."
            },
            {
              name: "Współpraca",
              description: "Symbol jednostki organizacyjnej, która albo realizuje cząstkowe procesy (operacje) objęte daną funkcją lub uczestniczy w spełnianiu funkcji powiązanych z funkcją głównego realizatora."
            },
          ],
      column: 0
    }
  },
  mixins: [mixin],
  methods: {
    showModal: function (column) {
      this.column = column

      UIkit.modal("#wpip-info").show();
    }
  }

}
</script>
