<template>
  <admin>
    <div class="wpip-qfd">
      <div class="uk-container-expand" id="ias-content">
        <div class="uk-grid-divider uk-child-width-expand@s qfd-analysis" uk-grid>
          <div class="uk-width-1-6@m">
            <steps :step="step" v-on:selectStep="selectStep"></steps>
          </div>
          <div class="uk-width-5-6@m main-panel">
            <div v-if="step===1">
              <client-requirements v-bind:properties="properties" ref="table" v-on:propertiesChange="changeProperties"
                                   v-on:remove="remove" v-on:nextStep="nextStep"></client-requirements>
            </div>
            <div v-if="step===2">
              <technical-parameters v-bind:properties="properties" ref="technical" v-on:refresh="get"
                                    v-bind:parameters="parameters" v-on:previousStep="previousStep"
                                    v-on:nextStep="nextStep"></technical-parameters>
            </div>
            <div v-if="step===3">
              <correlations-preview v-bind:properties="properties" v-bind:parameters="parameters"
                                    v-on:previousStep="previousStep" v-on:nextStep="nextStep"></correlations-preview>
            </div>
            <div v-if="step===4">
              <parameters-rating v-bind:properties="properties" v-bind:parameters="parameters"
                                 v-on:previousStep="previousStep" v-on:nextStep="nextStep"></parameters-rating>
            </div>
            <div v-if="step===5">
              <competition-rating v-bind:properties="properties" v-bind:parameters="parameters"
                                  v-on:propertiesChange="changeProperties" v-on:previousStep="previousStep"
                                  v-on:nextStep="nextStep"></competition-rating>
            </div>
            <div v-if="step===6">
              <technical-difficulty v-bind:analysis="properties"
                                    v-on:previousStep="previousStep"></technical-difficulty>
            </div>
          </div>
        </div>
      </div>
    </div>
  </admin>
</template>

<script>
import mixin from "../../components/mixin";
import ClientRequirements from "../../components/qfd/clientRequirements";
import TechnicalParameters from "../../components/qfd/technicalParameters";
import CompetitionRating from "../../components/qfd/competitionRating";
import ParametersRating from "../../components/qfd/parametersRating";
import TechnicalDifficulty from "../../components/qfd/technicalDifficulty";
import CorrelationsPreview from "../../components/qfd/correlationsPreview";
import Steps from "../../components/qfd/steps";
import axiosInstance from "../../axiosInstance";
import apiConfig from "../../apiConfig";

    export default {
        name: 'qfdAnalysis',
        components: {
            'steps': Steps,
            'clientRequirements': ClientRequirements,
            'technicalParameters': TechnicalParameters,
            'competitionRating': CompetitionRating,
            'parametersRating': ParametersRating,
            'technicalDifficulty': TechnicalDifficulty,
            'correlationsPreview': CorrelationsPreview
        },
        mixins: [mixin],
        data() {
            return {
                step: 1,
                sortBy: 'name',
                sortDesc: false,
                data: [],
                properties: [
                ],
                parameters: [
                ],
                currentPage: 1,
                totalItems: '',
                showDetails: false,
                buildingId: null
            }
        },
        methods: {
          async getParameters(page=1) {
            let vm = this;
            let params = {page: page};
            let res = await axiosInstance
                .get(apiConfig.qfdParametersApiUrl,
                    {
                      params,
                      headers: this.$data.headers
                    });
            vm.data = res.data['member'];
            vm.totalItems = res.data['totalItems'];
            if (page == 1){
              vm.parameters = vm.data;
            }
            else {
              vm.parameters = vm.parameters.concat(vm.data)
            }
            if (res.data['view'] && res.data['view']['next']){
              await this.getParameters(page + 1)
            }
          } ,
          async get() {
                let vm = this;
                await this.getParameters()
                let params = {};
                params['qfdAnalysisProperties.qfdAnalysis'] = vm.$route.params.analysisId;
                let res = await axiosInstance
                    .get(apiConfig.qfdPropertiesApiUrl,
                        {
                            params,
                            headers: this.$data.headers
                        });
                vm.data = res.data['member'];
                vm.totalItems = res.data['totalItems'];
                vm.properties = null;
                vm.properties = [];
                vm.data.forEach(function (property) {
                    let newProperty = {
                        name: property.name,
                        weight: property.qfdAnalysisProperties[0].weight,
                        competition: property.qfdAnalysisProperties[0].competition,
                        propertyId: property.id,
                        parameters: []
                    };
                    property.qfdPropertyParameters.forEach(function (parameter) {
                        newProperty.parameters.push({
                            id: parameter.qfdParameter.id,
                            weight: parameter.weight,
                            relationId: parameter.id
                        })
                    });
                    vm.properties.push(newProperty);
                });
                if(vm.step === 1) {
                    vm.$refs.table.data = vm.properties;
                    vm.$refs.table.refresh();
                }
                return vm.data;
            },
            selectStep: function(step){
                this.step = step;
            },
            changeProperties: function(props){
                this.properties = props;
            },
            refresh: function(){
                this.$refs.table.refresh();
                this.step = 1;
                this.get();
                this.step = 2;
            },
            previousStep: function(){
                this.step--;
            },
            nextStep: function(){
                this.step++;
            },
            remove: function(e){
                this.properties.splice(e, 1);
            }

  },
  async mounted() {
    await this.$ability.getAbility();
    if (!this.$ability.checkManageIasAbility('QFD')) {
      await this.$router.push({name: 'not-allowed'});
    }
    this.selectStep(parseInt(this.$route.params.step));
    this.get();
  }
}
</script>
<style scoped>
.qfd-analysis {
}

.main-panel {
  background: transparent;
  max-height: 100%;
}
</style>
