<template>
    <div class="text-right">
        <a
                @click.prevent="addFile(datatable.item.category)"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Dodaj plik"
        >
            <vk-icon class="hover" icon="plus"/>
        </a>
    </div>
</template>

<script>

import mixin from "../mixin";

export default {
        name: 'iasFileActions',
        mixins: [mixin],
        props: {
            datatable: Object
        },
        methods: {
            addFile: function (category) {
                this.$emit('add', category);
            }
        }
    }
</script>
