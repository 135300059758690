<template>
    <div>
        <vk-grid>
            <h3 class="uk-width-expand">Analiza QFD - Parametry techniczne </h3>
            <button
                    @click="back()"
                    class="uk-button wpip-button uk-width-auto"
            >lista analiz QFD
            </button>
        </vk-grid>
        <b-table
                :columns="fields"
                :current-page="currentPage"
                :fields="fields"
                :items="get"
                :per-page="this.$data.perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                no-sort-reset
                :responsive="true"
                borderedless
                class="uk-table vk-table-justify uk-table-divider"
                hover
                ref="table">

            <template v-slot:cell(name)="data">
              <span :uk-tooltip="data.item.name">{{ limit(data.item,50) }}</span>
            </template>
            <template v-slot:cell(weight)="data">
                <span class="badge-cell"><span class="wpip-badge">{{ data.item.weight }}</span></span>
            </template>
            <template v-slot:cell(parameters)="data">
                <span class="badge">{{ data.item.parameters.length }}</span>
            </template>
            <template v-slot:cell(actions)="data">
                <technical-parameters-actions :datatable="data" v-on:addParameter="addParameterForm"
                                              v-on:showParameters="showParameters"/>
            </template>
            <template v-slot:cell(key)="data">
                {{ data.index+1 }}
            </template>
        </b-table>
        <b-pagination :per-page="perPage" :total-rows="totalItems" align="center"
                      v-model="currentPage" v-if="totalItems>perPage" size="sm" page-class="wpip-page"
                      class="wpip-page"/>
        <div class="uk-grid uk-child-width-1-2@m uk-margin-top" uk-grid>
            <div class="uk-text-left">

                <button class="uk-button wpip-button" v-on:click="previousStep">Wymagania klienta</button>
            </div>
            <div class="uk-text-right">
                <button class="uk-button wpip-button" v-on:click="nextStep">Istotność i zależność parametrów</button>
            </div>
        </div>
        <technical-parameter-modal ref="modal" :property-name="propertyName" :parameters="$attrs.parameters" v-bind:properties="$attrs.properties"
                                   v-on:addParameter="addParameter"/>
        <technical-parameter-list-modal :key="key" ref="listModal" v-bind:parameters="$attrs.parameters"
                                        v-on:refreshRelations="refresh"/>
    </div>
</template>

<script>

    import mixin from "../../components/mixin";
    import TechnicalParametersActions from "./technicalParametersActions";
    import TechnicalParameterModal from "./technicalParameterModal";
    import TechnicalParameterListModal from "./technicalParameterListModal";
    import charLimiter from "@/components/charLimiter";

    export default {
        name: 'qfdAnalysisTechnicalParameters',
        components: {TechnicalParameterModal, TechnicalParameterListModal, TechnicalParametersActions},
        mixins: [mixin, charLimiter],
        data() {
            return {
                key: 0,
                sortBy: 'name',
                sortDesc: false,
                data: [],
                currentPage: 1,
                totalItems: '',
                propertyName: null,
                fields: [
                    {key: 'key', label: "LP", class: "wi"},
                    {key: 'name', label: 'Wymaganie klienta', sortable: false},
                    {key: 'weight', label: 'Istotność dla klienta', sortable: false},
                    {key: 'parameters', label: 'Liczba powiązanych parametrów', sortable: false},
                    {key: 'actions', label: '', class: 'text-right'},
                ]
            }
        },
        props: {},
        methods: {
            async get() {
                this.data = this.$attrs.properties;
                return this.data;
            },
            refresh: function (e) {
                this.data[e.property].parameters.splice(e.parameter, 1);
                this.$emit('refresh');
            },
            addParameterForm: function (e) {
                this.propertyName = this.data[e.index].name
                this.$refs.modal.showModal(e.index, null, e.id);
            },
            addParameter: function (e) {
                this.data[e.property].parameters.push(e.parameter);
                this.$emit('refresh');
            },
            showParameters: function (e) {
                this.$refs.listModal.showModal(this.data[e], e)
            },
            previousStep: function () {
                this.$emit('previousStep');
            },
            nextStep: function () {
                this.$emit('nextStep');
            },
            back: function () {
                this.$router.push({name: 'qfdAnalyses'})
            }

        }
    }
</script>
<style scoped>
    .badge {
        background-color: rgb(30, 135, 240);
        color: white;
    }

    .wpip-badge {
        margin-left: 4px;
        font-size: 0.8em;
        width: 2em;
        padding-top: 0;
        height: 2em;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        border: 1px solid #bf0e01;
        border-radius: 3px;
        background-color: #bf0e01;
        color: white;
    }

    .badge-cell {
        height: 1.8em;
        width: 2em;
        text-align: center;
        display: table-cell;
        vertical-align: middle;
    }

</style>
