<template>
  <div class="uk-flex-top uk-modal-container" container=".admin" id="importDevices" uk-modal>
    <div class="uk-modal-dialog uk-margin-auto-vertical">
      <button class="uk-modal-close-default" type="button" uk-close></button>
      <div class="uk-modal-body">
        <h3>{{ modal.title }}</h3>
        <div class="uk-margin">
          <b-form-file v-model="resource.file" placeholder="Nie wybrano pliku..."/>
          <span class="validation-error">{{ errorMessage  }}</span>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button class="uk-button uk-button-default uk-modal-close" type="button">Anuluj</button>
          <button :disabled="modal.blocked" @click="submit" class="uk-button wpip-button" type="button">
            {{ modal.button }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "../mixin";
import UIkit from "uikit";
import 'vue-datetime/dist/vue-datetime.css'
import {pl} from "vuejs-datepicker/src/locale";
import axiosInstance from "../../axiosInstance";
import apiConfig from "../../apiConfig";

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result.split('base64,')[1]);
  reader.onerror = error => reject(error);
});

export default {
  name: 'importDevicesModal',
  components: {},
  data() {
    return {
      pl: pl,
      modalDom: null,
      phases: [],
      errorMessage: "",
      resource: {
        file: null
      },
      modal:
          {
            title: "Dodaj zasób",
            button: "Dodaj",
            blocked: false
          },

    }
  },
  mixins: [mixin],
  methods: {
    showModal: async function () {
      let vm = this
      this.resource.file = null
      this.errorMessage = ""
        vm.modal =
            {
              title: "Importuj urządzenia",
              button: "Importuj",
              blocked: false
            }

      await this.$validator.reset()
      this.modalDom.show()
    },
    submit: async function () {
      let vm = this;


      let postData = {
        buildingId: vm.$attrs.buildingId
      };
      if(vm.resource.file){
        postData.fileContent = await toBase64(this.resource.file)
      }
      if(vm.resource.file==null){
        vm.errorMessage = "Nie wybrano pliku..."
      }
      vm.$validator.validate().then(async (result) => {
        if (result && vm.resource.file!==null) {
            axiosInstance
                .post(apiConfig.importDevicesApi, JSON.stringify(postData), {
                  headers: this.$data.headers
                })
                .then(async function (response) {
                  if (response.status === 201) {
                    vm.modalDom.hide();
                  }
                  vm.modal.blocked = false;
                }).catch(function (errors){
                  vm.errorMessage = errors.response.data.message
            });
        }
      });
    }
  },
  mounted() {
    if (this.modalDom == null) {
      this.modalDom = UIkit.modal('#importDevices');
    }
  }

}
</script>
