<template>

    <div class="uk-margin">
        <rental-object-spaces-modal ref="spacesModal" v-on:addSpace="addSpace" />
        <building-details-modal ref="detailsModal"/>
        <spaces-modal ref="addSpaceModal" @refresh="refresh"/>
        <empty-list v-if="totalItems===0"/>
        <b-table v-else ref="table" hover borderedless
                 :fields="fields"
                 :columns="fields"
                 :items="data"
                 :per-page="this.$data.perPage"
                 :current-page="currentPage"
                 :sort-by.sync="sortBy"
                 :sort-desc.sync="sortDesc"
                 no-sort-reset
                 :responsive="true">
            <template v-slot:head(actions)="data">
                <span class="uk-icon-link" uk-tooltip="Filtruj"><vk-icon class="hover" icon="settings" @click="$emit('toggleFilters')"/></span><span class="uk-badge uk-margin-small-left">{{$attrs.filterNum}}</span>
            </template>
            <template v-slot:cell(name)="data">
                {{ data.item.name }}
            </template>
            <template v-slot:cell(actions)="data">
                <rental-objects-actions :datatable=data v-on:showSpaces="showSpaces" @showDetails="showBuildingDetails" v-on:refresh="refresh"/>
            </template>
            <template v-slot:cell(avalaibleArea)="data">
                {{ (data.item.availableArea).toFixed(2) }}
            </template>
            <template v-slot:cell(rentedArea)="data">
              <span v-if="data.item.rentedArea">
                {{ (data.item.rentedArea).toFixed(2) }}
              </span>
              <span v-else>0.00</span>
            </template>
            <template v-slot:cell(status)="data">
                <span v-if="data.item.isSuspended" class="small badge badge-danger badge-pill">zawieszony</span>
                <span v-else :class="'badge ' + renterStatus[data.item.status+'-class'] ">{{ renterStatus[data.item.status] }}</span>
            </template>

        </b-table>
        <b-pagination :total-rows="totalItems" :per-page="perPage" v-model="currentPage"
                      align="center" v-if="totalItems>perPage" size="sm" page-class="wpip-page" class="wpip-page"/>
    </div>
</template>

<script>
    import mixin from "../../components/mixin"
    import charLimiter from "../charLimiter"
    import RentalObjectSpacesModal from "./rentalObjectSpacesListModal"
    import renterStatus from '../../constants/renterStatus'
    import axiosInstance from "../../axiosInstance"
    import apiConfig from "../../apiConfig"
    import EmptyList from "../ui/emptyList"
    import RentalObjectsActions from "./rentalObjectsActions"
    import SpacesModal from "./spacesModal";
    import BuildingDetailsModal from "./buildingDetailsModal";

    export default {
        name: "rentalObjectsList",
        components: {
            BuildingDetailsModal,
            SpacesModal,
            RentalObjectSpacesModal,
            RentalObjectsActions,
            EmptyList,

        },
        data() {
            return {
                renterStatus: renterStatus,
                sortBy: 'name',
                sortDesc: false,
                data: [],
                currentPage: 1,
                totalItems: '',
                fields: [
                    {key: 'name', label: 'Budynek', sortable: true},
                    {key: 'contractor.name', label: 'Właściciel', sortable: true},
                    {key: 'avalaibleArea', label: 'Dostępna pow.'},
                    {key: 'rentedArea', label: 'Wynajęta pow.'},
                    {key: 'actions', label: '', class: 'text-right'},
                ],
            };
        },
        mixins: [mixin, charLimiter],
        created() {

        },
        methods: {
            setFilters: function(filters){
                this.filters = filters
            },
            refresh: function (buildingId) {
                if(buildingId){
                    this.showSpaces(buildingId)
                }
                this.get()
                if (this.$refs.table) {
                    this.$refs.table.refresh()
                }
            },
            addSpace: function(data){
                this.$refs.addSpaceModal.showModal(data)
            },
            showSpaces: function (objectId) {
                this.$refs.spacesModal.showModal(objectId)
            },
            showBuildingDetails: function(building){
                this.$refs.detailsModal.showModal(building)
            },
            async get(filters={}) {
                let vm = this;
                let desc = 'desc'
                if (vm.sortDesc !== true) {
                    desc = 'asc'
                }


                var key = "order[" + this.sortBy + "]"
                var params = filters
                params[key] = desc
                params['page'] = vm.currentPage
                params['isRental'] = true

                 let res = await axiosInstance
                    .get(apiConfig.buildingsApiUrl,
                        {
                            params,
                            headers: this.$data.headers
                        });
                vm.data = res.data['member']
                let rentedArea = 0
                let totalArea = 0
                vm.data.forEach(function (object) {
                    totalArea += object.areas.total
                    rentedArea += object.areas.rented

                });
                vm.totalItems = res.data['totalItems']


                this.$emit('dashboardData', {objects: vm.totalItems, rentedArea: rentedArea, percent: 100 * (rentedArea/totalArea)})

                return vm.data;
            },
        },
        mounted() {
            this.get();
        }
    };
</script>

<style scoped>
    .uk-accordion li {
        list-style-type: none;
    }

    .uk-accordion-title {
        color: black;
        font-size: 1.3em;
    }

    .uk-accordion {
        padding-left: 0;
    }

    .badge {
        font-size: 1em
    }

    .small {
        font-size: 12px;
    }
</style>
