<template>
    <div class="uk-flex-top uk-modal-container uk-modal" container=".admin" id="wpip-swot-parameters"
         v-on:show="clearErrors">
        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-modal-body">
                <h3>{{ modal.title }}</h3>
                <div class="uk-form-controls">
            <textarea
                    class="uk-textarea"
                    data-vv-as="opis"
                    name="description"
                    placeholder="Proszę podać krótki opis nowej analizy"
                    rows="5" v-model="swot.description"
                    v-validate="'required'"
            ></textarea>
                    <span class="validation-error">{{ errors.first('description') }}</span>
                </div>
                <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                    <label>
                        <input
                                checked
                                class="uk-radio"
                                data-vv-as="metoda"
                                name="type"
                                type="radio"
                                v-model="swot.type" v-validate="'required'"
                                value="0"
                        /> SWOT (metoda refleksji strategicznej)
                    </label>
                    <label>
                        <input
                                class="uk-radio"
                                name="type"
                                type="radio"
                                v-model="swot.type"
                                value="1"
                        /> SWOT / TOWS
                    </label>

                </div>
                <span class="validation-error">{{ errors.first('type') }}</span>
                <div v-if="parseInt(swot.type) === 0">
                    <h4>Ustal granice dla strategii SWOT-mrs</h4>
                    <table class="uk-table uk-margin">
                        <tr>
                            <td class="uk-width-expand">Odsetek silnych stron wspomagających wykorzystanie szansy, poniżej którego nie będzie rozpatrywana strategia ofensywna</td>
                            <td class="uk-width-auto">
                                <div>
                                    <input @blur="soaOut" @focus="soaIn" class="uk-input" name="soa" type="text"
                                           v-model="swot.formattedSoa"
                                           v-validate="'border'"/><br/>
                                    <span class="validation-error">{{ errors.first('soa') }}</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="uk-width-expand">Odsetek słabych stron, które potęgują zagrożenie, od którego nie będzie rozpatrywana strategia ofensywna.</td>
                            <td class="uk-width-auto">
                                <input @blur="wtcOut" @focus="wtcIn" class="uk-input" name="wtc" type="text"
                                       v-model="swot.formattedWtc"
                                       v-validate="'border'"/>
                                <div class="validation-error">{{ errors.first('wtc') }}</div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="uk-modal-footer uk-text-right">
                <button class="uk-button uk-button-default uk-modal-close" type="button">Anuluj</button>
                <button @click="submit" class="uk-button wpip-button" type="button">{{ modal.button }}</button>
            </div>
        </div>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import UIkit from "uikit";
    import axiosInstance from "../../axiosInstance";

    export default {
        name: 'swotParametersModal',
        data() {
            return {
                swot: {
                    description: "",
                    type: null,
                    formattedSoa: "",
                    formattedWtc: "",
                    soa: 0.3,
                    wtc: 0.5
                },
                modal:
                    {
                        title: "Analiza typu SWOT",
                        button: "Dodaj"
                    },

            }
        },
        mixins: [mixin],
        methods: {
            clearErrors() {
                this.$validator.reset();
            },
            submit: function () {
                let vm = this;
                let postData = {
                    description: this.swot.description,
                    type: parseInt(this.swot.type),
                    soa: this.swot.soa,
                    wtc: this.swot.wtc,
                    buildings: "/api/buildings/" + this.$route.params.buildingId
                };
                vm.$validator.validate().then((result) => {
                    if (result) {
                        if (vm.swot.id) {
                            axiosInstance
                                .put(apiConfig.swotApiUrl + '/' + vm.swot.id, JSON.stringify(postData), {
                                    headers: this.$data.headers
                                })
                                .then(function (response) {
                                    if (response.status === 200) {
                                        vm.$emit('modified');
                                        UIkit.modal("#wpip-swot-parameters").hide();
                                    }
                                });
                        } else {
                            postData.author = '/api/users/' + localStorage.getItem('user');
                            axiosInstance
                                .post(apiConfig.swotApiUrl, JSON.stringify(postData), {
                                    headers: this.$data.headers
                                })
                                .then(function (response) {
                                    if (response.status === 201) {
                                        vm.$emit('modified');
                                        UIkit.modal("#wpip-swot-parameters").hide();
                                    }
                                });
                        }
                    }
                });

            },
            soaOut: function () {
                this.swot.formattedSoa = this.swot.formattedSoa.replace(/,/gi, '.');
                let value = parseFloat(this.swot.formattedSoa);
                if (!isNaN(value)) {
                    this.swot.soa = value / 100;
                } else {
                    value = 0;
                }

                this.swot.formattedSoa = value.toFixed(2) + "%";
            },
            wtcOut: function () {
                this.swot.formattedWtc = this.swot.formattedWtc.replace(/,/gi, '.');
                let value = parseFloat(this.swot.formattedWtc);
                if (!isNaN(value)) {
                    this.swot.wtc = value / 100;
                } else {
                    value = 0;
                }
                this.swot.formattedWtc = value.toFixed(2) + "%";
            },
            soaIn: function () {
                this.swot.formattedSoa = parseFloat(this.swot.formattedSoa);
            },
            wtcIn: function () {
                this.swot.formattedWtc = parseFloat(this.swot.formattedWtc);
            }
        }

    }
</script>
