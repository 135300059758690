<template>
    <div class="text-right">
        <a
                @click.prevent="showDetails(datatable.item)"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Należności"
        >
            <vk-icon class="hover" icon="credit-card"/>
        </a>
        <a
                @click.prevent="showObjects(datatable.item.id)"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Obiekty"
        >
            <vk-icon class="hover" icon="location"/>
        </a>
        <a
                @click.prevent="showContacts(datatable.item.id)"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Kontakty"
        >
            <vk-icon class="hover" icon="users"/>
        </a>
        <a
                @click.prevent="contractorDetails(datatable.item.id)"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Dane szczegółowe"
        >
            <vk-icon class="hover" icon="search"/>
        </a>
        <a
                @click.prevent="contractorEdit(datatable.item)"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Edytuj dane"
        >
            <vk-icon class="hover" icon="pencil"/>
        </a>
        <a
                @click.prevent="toggleSuspend(datatable.item)"
                v-if="!datatable.item.isSuspended"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Zawieś najemcę"
        >
            <vk-icon class="hover" icon="ban"/>
        </a>
        <a
                @click.prevent="toggleSuspend(datatable.item)"
                v-else
                class="uk-icon-button"
                href="#"
                uk-tooltip="Cofnij zawieszenie"
        >
            <vk-icon class="hover" icon="refresh"/>
        </a>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import UIkit from "uikit";
    import axiosInstance from "../../axiosInstance";

    export default {
        components: {},
        name: 'renterListActions',
        mixins: [mixin],
        props: {
            datatable: Object
        },
        methods: {
            showDetails: function (contractor) {
                this.$emit('showDetails', contractor)
            },
            showContacts: function (contractorId) {
                this.$emit('showContacts', contractorId)
            },
            showObjects: function (contractorId) {
                this.$emit('showObjects', contractorId)
            },
            contractorDetails: function (contractorId) {
                this.$emit('contractorDetails', contractorId)
            },
            contractorEdit: function (contractor) {
                this.$emit('contractorEdit', contractor)
            },
            toggleSuspend: function (contractor) {
                let vm = this;
                let confirmLabel = "Czy chcesz zawiesić najemcę?"
                if(contractor.isSuspended){
                  confirmLabel = "Czy chcesz cofnąć zawieszenie najemcy?"
                }
                UIkit.modal.confirm(confirmLabel, {
                    labels: {ok: "Tak", cancel: "Nie"}
                }).then(function () {
                    let postData = {
                        isSuspended: !contractor.isSuspended
                    }
                    axiosInstance
                        .put('/api/contractors/' + contractor.id, JSON.stringify(postData), {
                            headers: vm.$data.headers
                        }).then(function (response) {
                        if (response.status === 200) {
                            vm.$emit('refresh');
                        }
                    }).catch(function () {
                        UIkit.modal.alert("Wystąpił błąd")
                    });
                });

            }
        }
    }
</script>
<style scoped>
    div {
        white-space: nowrap;
    }

    .middleware-red {
        background-color: #bf0e01;
        color: white;
    }

    .middleware-orange {
        background-color: #e38703;
        color: white;
    }

    .middleware-green {
        background-color: #09a913;
        color: white;
    }
</style>
