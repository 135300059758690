<template>
    <div class="container h-100">
        <div class="row align-items-center h-100">
            <div class="col-sm-10 offset-2 mx-auto access-panel">
                <div class="form-signin">
                    <div class="row h-100">
                        <div class="col-sm-4 login h-100">
                            <div class="form-signin-logo row mb-5">
                                <img alt="" src="../assets/images/logoWPIP.svg">
                            </div>
                            <form @submit="checkFormLogin" id="login" method="post">
                                <div class="row">
                                    <div class="col-sm-8 offset-2">
                                        <div class="alert alert-danger" role="alert" v-if="error">
                                            Nieprawidłowe dane logowania
                                        </div>
                                        <div class="login-input-box">
                                            <div class="uk-inline">

                                            <span class="uk-form-icon uk-icon" uk-icon="icon: user"><svg height="20"
                                                                                                         ratio="1"
                                                                                                         viewBox="0 0 20 20"
                                                                                                         width="20"
                                                                                                         xmlns="http://www.w3.org/2000/svg"> <circle
                                                    cx="9.9" cy="6.4" fill="none" r="4.4" stroke="#000"
                                                    stroke-width="1.1"></circle> <path
                                                    d="M1.5,19 C2.3,14.5 5.8,11.2 10,11.2 C14.2,11.2 17.7,14.6 18.5,19.2"
                                                    fill="none" stroke="#000"
                                                    stroke-width="1.1"></path></svg></span>
                                                <input class="form-control uk-input" id="username" name="email"
                                                       placeholder="e-mail" data-vv-as="e-mail"
                                                       type="text" v-model="email" v-validate="'required|email'"/>
                                            </div>
                                            <div>
                                                <span class="login-validation-error">{{ errors.first('email') }}</span>
                                            </div>
                                        </div>
                                        <div class="login-input-box">
                                            <div class="uk-inline">

                                                <span class="uk-form-icon uk-icon" uk-icon="icon: lock"><svg height="20"
                                                                                                             ratio="1"
                                                                                                             viewBox="0 0 20 20"
                                                                                                             width="20"
                                                                                                             xmlns="http://www.w3.org/2000/svg"> <rect
                                                        fill="none" height="10" stroke="#000" width="13" x="3.5"
                                                        y="8.5"></rect> <path
                                                        d="M6.5,8 L6.5,4.88 C6.5,3.01 8.07,1.5 10,1.5 C11.93,1.5 13.5,3.01 13.5,4.88 L13.5,8"
                                                        fill="none"
                                                        stroke="#000"></path></svg></span>
                                                <input class="form-control uk-input" id="password" name="password"
                                                       placeholder="hasło" data-vv-as="hasło"
                                                       type="password" v-model="password" v-validate="'required'" />
                                            </div>
                                            <div>
                                                <span class="login-validation-error">{{ errors.first('password') }}</span>
                                            </div>
                                        </div>
                                        <button class="btn btn-primary btn-block btn-lg submit uk-button-large"
                                                type="submit">
                                            <b>zaloguj</b> się
                                        </button>
                                    </div>
                                </div>
                            </form>

                        </div>
                        <div class="col-sm-8 h-100 content login-background">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img alt class="logo-ue uk-position-bottom-center uk-margin-bottom" src="../assets/images/poir_logos.jpg">        
    </div>    
</template>

<script>

    import apiConfig from '../apiConfig'
    import axiosInstance from "../axiosInstance";
    import mixin from "../components/mixin";
    import router from "../router";

    export default {
        name: 'login',
        data() {
            return {
                error: false,
                email: null,
                password: null
            }
        },
        mixins: [mixin],
        methods: {
            checkFormLogin: function (e) {
                e.preventDefault();
                let vm = this;
                let postData = {
                    email: vm.email,
                    password: vm.password
                };

                vm.$validator.validate().then((result) => {
                    if (result) {
                        axiosInstance
                            .post(apiConfig.loginApiUrl, JSON.stringify(postData), {
                                headers: {
                                    'Content-Type': 'application/json;charset=UTF-8',
                                    'Access-Control-Allow-Origin': '*'
                                }
                            })
                            .then(async function (response) {
                                if (response.status === 200) {
                                    vm.error = false;
                                    localStorage.setItem('token', response['data']['token']);
                                    localStorage.setItem('refreshToken', response['data']['refreshToken']);
                                    localStorage.setItem('user', response['data']['user']);
                                    localStorage.setItem('person', response['data']['person']);
                                    localStorage.setItem('roles', response['data']['roles']);
                                    localStorage.setItem('currentBuilding', JSON.stringify(response['data']['building']));
                                    localStorage.setItem('buildings', JSON.stringify(response['data']['buildings']));
                                    let headers = {
                                        'Content-Type': 'application/json;charset=UTF-8',
                                        "Access-Control-Allow-Origin": "*",
                                        'Authorization': "Bearer " + response['data']['token'],
                                        blocker: {
                                            requestKey: 'customRequestKeyName',
                                            blockAllowed: false
                                        }
                                    };
                                    let params = {};
                                    if (response['data']['person'] != null) {
                                        await axiosInstance
                                            .get(apiConfig.peopleApiUrl + '/' + response['data']['person'],
                                                {
                                                    params,
                                                    headers: headers
                                                }).then(function (res) {
                                                localStorage.setItem('fullName', res.data.firstName + " " + res.data.lastName);
                                            });
                                    } else {
                                            await axiosInstance
                                                .get(apiConfig.usersApiUrl + '/' + response['data']['user'],
                                                    {
                                                        params,
                                                        headers: headers
                                                    }).then(function (res) {

                                                    localStorage.setItem('fullName', res.data.username);
                                                });

                                    }
                                    router.push({name: 'dashboard'});
                                }
                            }).catch(() => vm.error = true);
                    }
                }).catch(() => {
                    return false;
                });
            }
        }
    }
</script>

<style scoped>
    .form-signin .login {
        padding: 0;
    }

    .form-signin-logo {
        margin: 5px !important;
    }

    .access-panel {
        padding: 0;
        width: 898px!important;
        box-shadow: 0 0 100px #888888;
    }

    .access-panel [type="submit"] {
        font-size: 110%;
    }

    .uk-form-icon:not(a):not(button):not(input) {
        pointer-events: none;
        color: white !important;
    }

    .uk-form-icon:not(.uk-form-icon-flip) + .uk-input {
        padding-left: 40px;
        color: white;
    }

    .uk-form-icon:not(.uk-form-icon-flip) + .uk-input:focus {
        background-color: transparent;
        color: white;
    }

    .uk-form-icon:not(.uk-form-icon-flip) + .uk-input::placeholder {
        background-color: transparent;
        color: whitesmoke;
    }

    .login-input-box{
        margin-bottom: 20px;
        margin-top: 30px;
    }

    .login-validation-error{
        color: white;
        font-size: 80%;
    }
</style>
