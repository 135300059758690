<template>
<div class="uk-flex-top uk-modal-container" container=".admin" id="wpip-fmea-rate-element" uk-modal>
  <div class="uk-modal-dialog uk-margin-auto-vertical">
    <button class="uk-modal-close-default" type="button" uk-close></button>
    <div class="uk-modal-body">
      <div class="uk-grid">
        <div class="uk-width-1-2@m">
          <legend class="uk-legend uk-text-bold">Informacje podstawowe</legend>
          <div class="uk-margin-top">
            <label class="uk-form-label uk-text-bold">Faza w cyklu życia IAS</label>
            <div class="uk-form-controls">
              {{getPhaseName(fmeaElementForm.iasPhase)}}
            </div>
          </div>

          <div class="uk-margin-top">
            <label class="uk-form-label uk-text-bold">Obiekt / część obiektu - funkcja obiektu</label>
            <div class="uk-form-controls">
              {{getSubphaseName(fmeaElementForm.object)}}
            </div>
          </div>

          <label class="uk-form-label uk-margin-top uk-text-bold">Opis potencjalnej wady</label>
          <div class="uk-form-controls">
            {{ fmeaElementForm.failureDescription }}
          </div>

          <label class="uk-form-label uk-margin-top uk-text-bold">Przyczyny potencjalnej wady</label>
          <div class="uk-form-controls">
            <span v-if="fmeaElementForm.failureCause != ''">{{ fmeaElementForm.failureCause }}</span>
            <span v-else class="uk-label wpip-label">Brak</span>
          </div>

          <label class="uk-form-label uk-margin-top uk-text-bold">Skutki potencjalnej wady</label>
          <div class="uk-form-controls">
            <span v-if="fmeaElementForm.failureEffect != ''">{{ fmeaElementForm.failureEffect }}</span>
            <span v-else class="uk-label wpip-label">Brak</span>
          </div>

          <label class="uk-form-label uk-margin-top uk-text-bold">Aktualny sposób kontroli</label>
          <div class="uk-form-controls">
            <span v-if="fmeaElementForm.failureWayOfControl != ''">{{ fmeaElementForm.failureWayOfControl }}</span>
            <span v-else class="uk-label wpip-label">Brak</span>
          </div>
        </div>

        <div class="uk-width-1-2@m">
          <legend class="uk-legend uk-text-bold">Działania zapobiegawcze</legend>
          <div class="uk-margin">
            <label class="uk-form-label uk-text-bold">Osoba odpowiedzialna</label>
            <div class="uk-form-controls">
              {{ fmeaElementForm.responsible.firstName }} {{ fmeaElementForm.responsible.lastName }}
            </div>
          </div>

          <div class="uk-margin-top">
            <label class="uk-form-label uk-text-bold">Termin realizacji</label>
            <div class="uk-form-controls">
              {{ fmeaElementForm.dueDate }}
            </div>
          </div>
          <div class="">
            <label class="uk-form-label uk-margin-top uk-text-bold">
              Znaczenie wady dla klienta <span class="uk-margin-small-left uk-badge wpip-badge">{{ fmeaElementForm.clientValue }}</span>
            </label>
          </div>
          <div class="">
            <label class="uk-form-label uk-margin-top uk-text-bold">
              Ryzyko wystąpienia wady <span class="uk-margin-small-left uk-badge wpip-badge">{{ fmeaElementForm.risk }}</span>
            </label>
          </div>
          <div class="">
            <label class="uk-form-label uk-margin-top uk-text-bold">
              Wykrywalność wady <span class="uk-margin-small-left uk-badge wpip-badge">{{ fmeaElementForm.detection }}</span>
            </label>
          </div>

          <div class="uk-margin-top uk-text-large uk-text-bold">
            Liczba Priorytetowa Ryzyka (LPR) =
            <span class="uk-label wpip-label uk-text-large">{{ calcLPR() }}</span>
          </div>

          <label class="uk-form-label uk-margin-top uk-text-bold">Sugerowane działania zapobiegawcze</label>
          <div class="uk-form-controls">
            {{ fmeaElementForm.suggestedActions }}
            <span v-if="fmeaElementForm.suggestedActions != ''">{{ fmeaElementForm.suggestedActions }}</span>
            <span v-else class="uk-label wpip-label">Brak</span>
          </div>

        </div>
      </div>

      <form class="uk-form-stacked">
        <span>
          <legend class="uk-legend uk-margin-top uk-text-bold">Działania zapobiegawcze</legend>
          <label class="uk-form-label uk-margin">Podjęte działania zapobiegawcze</label>
          <div class="uk-form-controls">
            <textarea class="uk-textarea" rows="3" v-model="fmeaElementForm.takenActions" :readonly="mode==0 && !isSuperAdmin() "></textarea>
          </div>
        </span>
        
        <legend class="uk-legend uk-margin-top uk-text-bold" v-if="mode==0">Nowa ocena ryzyka</legend>
        <div class="uk-grid uk-margin uk-child-width-1-3@s" uk-grid v-if="mode==0">
          <div class>
            <label class="uk-form-label">
              Znaczenie wady dla klienta
              <span class="uk-margin-small-left uk-badge wpip-badge">{{ fmeaElementForm.newClientValue }}</span>
            </label>
            <input class="uk-range" data-vv-as="znaczenie wady dla klienta" max="10" min="0" name="clientValue" step="1" type="range" v-model="fmeaElementForm.newClientValue" v-validate="'required|min_value:1'" value="2" />
            <span class="validation-error">{{ errors.first('clientValue') }}</span>
            <small>Jak istotne znaczenie dla klienta ma potencjalna wada</small>            
          </div>
          <div class>
            <label class="uk-form-label">
              Ryzyko wystąpienia wady
              <span class="uk-margin-small-left uk-badge wpip-badge">{{ fmeaElementForm.newRisk }}</span>
            </label>
            <input class="uk-range" data-vv-as="ryzyko wystąpienia wady" max="10" min="0" name="risk" step="1" type="range" v-model="fmeaElementForm.newRisk" v-validate="'required|min_value:1'" value="2" />
            <span class="validation-error">{{ errors.first('risk') }}</span>
            <small>Jaka jest potencjalna częstotliwość wystąpienia wady</small>
          </div>
          <div class>
            <label class="uk-form-label">
              Wykrywalność wady
              <span class="uk-margin-small-left uk-badge wpip-badge">{{ fmeaElementForm.newDetection }}</span>
            </label>
            <input class="uk-range" data-vv-as="wykrywalność wady" max="10" min="0" name="detection" step="1" type="range" v-model="fmeaElementForm.newDetection" v-validate="'required|min_value:1'" value="2" />
            <span class="validation-error">{{ errors.first('detection') }}</span>
            <small>Jakie jest prawdopodobieństwo że potencjalna wada nie zostanie wykryta</small>
          </div>
        </div>

        <div class="uk-margin uk-text-large" v-if="mode==0">
          Liczba Priorytetowa Ryzyka (LPR) =
          <span class="uk-label wpip-label uk-text-large">{{ calcNewLPR() }}</span>
        </div>
      </form>
    </div>
    <div class="uk-modal-footer uk-text-right">
      <button class="uk-button uk-button-default uk-modal-close" type="button">Anuluj</button>
      <button @click="editFmeaElement" class="uk-button wpip-button" type="button">Zapisz</button>
    </div>
  </div>
</div>
</template>

<script>
import mixin from "../mixin";
import apiConfig from "../../apiConfig";
import UIkit from "uikit";
import axiosInstance from "../../axiosInstance";
import {
  pl
} from "vuejs-datepicker/src/locale";


export default {
  name: 'fmeaElementRate',
  data() {
    return {
      mode: 0,
      pl: pl,
      iasPhase: [{
          id: 1,
          name: "Projektowanie"
        },
        {
          id: 2,
          name: "Budowa"
        },
        {
          id: 3,
          name: "Eksploatacja"
        },
        {
          id: 4,
          name: "Rozwój"
        }
      ],
      iasSubphase: [{
          id: 1,
          name: "Magazyn"
        },
        {
          id: 2,
          name: "Biuro"
        },
        {
          id: 3,
          name: "Hala produkcyjna"
        },
        {
          id: 4,
          name: "Parking"
        }
      ],
      searchTimeout: null,
      responsible: [],
      fmeaElementForm: {
        id: 0,
        clientValue: 0,
        risk: 0,
        detection: 0,
        newClientValue: 0,
        newRisk: 0,
        newDetection: 0,
        responsible: "",
        dueDate: "",
        suggestedActions: "",
        failureWayOfControl: "",
        failureDescription: "",
        failureCause: "",
        failureEffect: "",
        iasPhase: "",
        object: ""
      }

    }
  },
  mixins: [mixin],
  methods: {
    isSuperAdmin() {
      return localStorage.getItem('roles').includes('SUPER_ADMIN')
    },
    fullName: function(person) {
      return person.firstName + " " + person.lastName;
    },
    async get() {
      this.getPeople("");
    },
    search: function(e) {
      let vm = this;
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(function() {
        vm.getPeople(e)
      }, 500);
    },
    getPhaseName: function(id) {
      let name = "";
      this.iasPhase.forEach(function(phase) {
        if (phase.id === id) {
          name = phase.name;
        }
      })
      return name;
    },
    getSubphaseName: function(id) {
      let name = "";
      this.iasSubphase.forEach(function(subphase) {
        if (subphase.id === id) {
          name = subphase.name;
        }
      });
      return name;
    },
    async getPeople(e) {
      let vm = this;
      let params = {};
      params['firstName'] = e.split(' ');
      params['lastName'] = e.split(' ');
      let res = await axiosInstance
        .get(apiConfig.peopleApiUrl, {
          params,
          headers: this.$data.headers
        });
      vm.responsible = res.data['member'];
      return vm.responsible;
    },
    showModal: function(analysis, mode = 1) {
      this.mode = mode;
      if (analysis == null) {
        this.fmeaElementForm = {
          id: null,
          clientValue: 0,
          risk: 0,
          detection: 0,
          responsible: "",
          dueDate: "",
          suggestedActions: "",
          failureWayOfControl: "",
          failureDescription: "",
          failureCause: "",
          failureEffect: "",
          iasPhase: "",
          object: ""
        }
      } else {
        this.fmeaElementForm = analysis;
        if (!this.fmeaElementForm.newClientValue && this.fmeaElementForm.takenActions == null) {
          this.fmeaElementForm.newClientValue = null;
          this.fmeaElementForm.newRisk = null;
          this.fmeaElementForm.newDetection = null;
        } else if (!this.fmeaElementForm.newClientValue && this.fmeaElementForm.takenActions !== null) {
          this.fmeaElementForm.newClientValue = 0;
          this.fmeaElementForm.newRisk = 0;
          this.fmeaElementForm.newDetection = 0;
        }
        this.fmeaElementForm.responsible = analysis.person;
      }
      this.$validator.reset();
      UIkit.modal("#wpip-fmea-rate-element").show();
      this.get();
    },

    calcLPR(element) {
      let f = this.fmeaElementForm;
      if (element) {
        f = element;
      }

      let lpr = f.clientValue * f.risk * f.detection;

      if (lpr === 0) {
        return "proszę ocenić wadę";
      } else {
        return lpr;
      }
    },

    calcNewLPR(element) {
      let f = this.fmeaElementForm;
      if (element) {
        f = element;
      }

      let lpr = f.newClientValue * f.newRisk * f.newDetection;

      if (lpr === 0) {
        return "proszę ocenić ryzyko";
      } else {
        return lpr;
      }
    },
    editFmeaElement: function() {
      let vm = this;
      vm.$validator.validate().then((result) => {
        if (result) {
          let postData = this.fmeaElementForm;
          //let id = postData.id;
          //delete postData.id;
          postData.newClientValue = parseInt(postData.newClientValue);
          postData.newRisk = parseInt(postData.newRisk);
          postData.newDetection = parseInt(postData.newDetection);
          postData.person = "/api/people/" + postData.person['id'];
          postData.fmeaAnalysis = "/api/fmea_analyses/" + this.$route.params.analysisId;
          postData.dueDate = new Date(postData.dueDate);
          axiosInstance
            .put(apiConfig.fmeaElementsApiUrl + "/" + postData.id, JSON.stringify(postData), {
              headers: this.$data.headers
            })
            .then(function(response) {
              if (response.status === 200) {
                UIkit.modal("#wpip-fmea-rate-element").hide();
                vm.$emit('modified');
              }
            }).catch();
        }
      }).catch(() => {
        return false;
      });
    }
  },
  mounted() {

  }

}
</script>
<style scoped>
.uk-close {
  color: #bf0e01 !important;
}

.wpip-label {
  border: 1px solid #bf0e01;
  background-color: #fff;
  color: #bf0e01;
}

.wpip-badge {

  background-color: #bf0e01;
}

.wpip-text-large {
  font-size: 1.3em;
}

.wpip-fmea-danger td {
  background-color: #bf0e011e;
  font-weight: bold;
}

.wpip-fmea-danger-lpr {
  color: #bf0e01;
  font-size: 1.3em;
}

.uk-table-justify td:first-child {
  padding-left: 8px;
}

.uk-table-justify td:last-child {
  padding-right: 8px;
}
</style>
<style>

</style>
