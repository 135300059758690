<template>
    <admin>
        <div class="uk-container uk-padding">
            <vk-grid>
                <menuAdmin></menuAdmin>
                <div class="uk-width-expand@m">
                    <contractorForm ref="form" v-on:submit="sendData"></contractorForm>
                </div>
            </vk-grid>
        </div>
    </admin>
</template>

<script>

    import {Grid} from 'vuikit/lib/grid';
    import mixin from "../../components/mixin";
    import apiConfig from "../../apiConfig";
    import contractorForm from "../../components/contractor/contractorForm";
    import axiosInstance from "../../axiosInstance";

    export default {
        name: 'contractorAdd',
        components: {
            VkGrid: Grid,
            contractorForm: contractorForm
        },
        data() {
            return {
                name: null,
                improvements_limit: null,
                people: []
            }
        },
        mixins: [mixin],
        methods: {
            sendData: function (postData) {
                let vm = this;
                axiosInstance
                    .post(apiConfig.contractorsApiUrl, JSON.stringify(postData), {
                        headers: this.$data.headers
                    })
                    .then(function (response) {
                        if (response.status === 201) {
                            vm.$router.push('/contractors');
                        }
                    }).catch();
            }
        },

    }
</script>
