<template>
    <div class="uk-flex-top uk-modal-container uk-modal" container=".admin" id="position-modal">
        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-modal-body">
                <h3>{{ modal.title }}</h3>
                <div class="uk-form-controls">
                    <label class="uk-form-label">
                        Nazwa
                    </label>
                    <input
                            type="text"
                            class="uk-input"
                            data-vv-as="nazwa"
                            name="name"
                            placeholder="Proszę podać nazwę kategorii"
                            v-model="position.name"
                            v-validate="'required|max:255'"
                    />
                    <span class="validation-error">{{ errors.first('name') }}</span>
                </div>
                <div class="uk-form-controls">
                    <label>
                        Kategoria główna
                    </label>
                    <select
                            class="uk-input"
                            data-vv-as="kategoria główna"
                            name="mainCategory"
                            v-model="position.budgetMainCategory"
                            v-validate="'required'"
                    >
                        <option v-bind:key="category['id']" v-for="category in categories" :value="category['id']">{{
                            category.name }}
                        </option>
                    </select>
                    <span class="validation-error">{{ errors.first('mainCategory') }}</span>
                </div>


                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-default uk-modal-close" type="button">Anuluj</button>
                    <button @click="submit" class="uk-button wpip-button" type="button">{{ modal.button }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import UIkit from "uikit";
    import axiosInstance from "../../axiosInstance";

    export default {
        name: 'categoryModal',
        components: {},
        data() {
            return {
                categories: [],
                budget: {},
                position: {
                    name: "",
                    budgetMainCategory: null
                },
                modal:
                    {
                        title: "Dodaj kategorię do istniejącego budżetu",
                        button: "Dodaj"
                    },

            }
        },
        mixins: [mixin],
        methods: {
            showModal: function (budget, categories) {
                this.budget = budget
                this.categories = []
                let vm = this
                let categoriesTest = []

                this.position = {
                    name: "",
                    budgetMainCategory: null
                }

                categories.forEach(function (category) {
                    if(category.budgetMainCategory && !categoriesTest[category.budgetMainCategory.id]){
                        categoriesTest[category.budgetMainCategory.id] = 1;
                        vm.categories.push(category.budgetMainCategory)
                    } else if(category.budgetCategory && !categoriesTest[category.budgetCategory.budgetMainCategory.id]){
                        categoriesTest[category.budgetCategory.budgetMainCategory.id] = 1;
                        vm.categories.push(category.budgetCategory.budgetMainCategory)
                    }
                })

                vm.categories.push({
                    id: 0,
                    name: "Inne"
                })

                this.$validator.reset()
                this.modalDom.show()
            },
            submit: function () {
                let postData = {...this.position};
                if(postData.budgetMainCategory) {
                    postData.budgetMainCategory = '/api/budget_main_categories/' + postData.budgetMainCategory
                }
                else {
                    postData.budgetMainCategory = null
                }
                postData.value = "0"
                postData.budget = this.budget['@id']
                let vm = this;
                vm.$validator.validate().then((result) => {
                    if (result) {
                        this.modal.blocked = true;
                        if (this.position.id == null) {
                            axiosInstance
                                .post(apiConfig.budgetPositionsApiUrl, JSON.stringify(postData), {
                                    headers: this.$data.headers
                                })
                                .then(async function (response) {
                                    if (response.status === 201) {
                                        vm.modalDom.hide()
                                        vm.$emit('refresh')
                                    }
                                    vm.modal.blocked = false;
                                }).catch();

                        }
                    }
                });
            }
        },
        mounted() {
            if (this.modalDom == null) {
                this.modalDom = UIkit.modal('#position-modal');
            }
        }
    }
</script>
