<template>
    <div class="text-right">
        <a
                @click.prevent="middlewares(datatable.item.id)"
                :class="'uk-icon-button ' + middlewareStatus()"
                href="#"
                uk-tooltip="Zarządzaj middleware"
        >
            <vk-icon class="hover" icon="server"/>
        </a>
        <a
                @click.prevent="$router.push({name: 'registerValues', params:{registerId: datatable.item.id}})"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Historia odczytów"
        >
            <vk-icon class="hover" icon="history"/>
        </a>
        <a      v-if="datatable.item.type === type.read || datatable.item.type === type.readwrite"
                @click.prevent="changeValue()"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Zmień wartość rejestru"
        >
            <vk-icon class="hover" icon="link"/>
        </a>
        <a
                @click.prevent="editRegister()"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Edytuj rejestr"
        >
            <vk-icon class="hover" icon="pencil"/>
        </a>
        <a
                @click.prevent="deleteRegister()"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Usuń rejestr"
        >
            <vk-icon class="hover" icon="trash"/>
        </a>
        <remove ref="remove" v-on:refresh="$emit('refresh')"/>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import axiosInstance from "../../axiosInstance";
    import UIkit from "uikit";
    import remove from "../ui/remove";

    export default {
        title: 'delete',
        components: {
            remove
        },
        name: 'registerDelete',
        mixins: [mixin],
        props: {
            datatable: Object
        },
        methods: {
            middlewareStatus: function(){
                if(this.datatable.item.middlewares.length==0){
                    return 'middleware-red'
                }
                else{
                    let inactive = false;
                    this.datatable.item.middlewares.forEach(function(middleware){
                        if(!middleware.active){
                            inactive = true;
                            return 0;
                        }

                    });
                    if(inactive){
                        return 'middleware-orange';
                    }
                }
                return 'middleware-green'
            },
            middlewares: function (register) {
                this.$emit('middlewares', register)
            },
            editRegister: function () {
                this.$emit('editRegister' ,{...this.datatable.item})
            },
            changeValue: function () {
                this.$emit('changeValue' ,{...this.datatable.item})
            },
            deleteRegister: function () {
                let vm = this;
                UIkit.modal.confirm("Czy chcesz usunąć rejestr?", {
                    labels: {ok: "Usuń", cancel: "Anuluj"}
                }).then(function () {
                    axiosInstance
                        .delete(apiConfig.registersApiUrl + '/' + vm.datatable.item.id, {
                            headers: vm.$data.headers
                        }).then((response) => {
                        if (response.status === 204) {
                            vm.$parent.$el.remove();
                        } else {
                            // handle if status code != 204
                        }
                    });
                })
            }
        }
    }
</script>
<style scoped>
    div {
        white-space: nowrap;
    }

    .middleware-red {
        background-color: #bf0e01;
        color: white;
    }

    .middleware-orange {
        background-color: #e38703;
        color: white;
    }
    .middleware-green {
        background-color: #09a913;
        color: white;
    }
</style>
