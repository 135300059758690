<template>
    <admin>
        <contractors-list v-on:details="clickedRow"></contractors-list>
        <contractor-details-modal ref="details"></contractor-details-modal>

    </admin>
</template>

<script>
    import mixin from "../../components/mixin";
    import ContractorsList from '../../components/contractor/contractorsList';
    import ContractorDetailsModal from "../../components/contractor/contractorDetailsModal";

    export default {
        name: 'units',
        components: {
            'contractorsList': ContractorsList,
            'contractorDetailsModal': ContractorDetailsModal,
        },
        mixins: [mixin],
        data() {
            return {
                sortBy: 'name',
                sortDesc: false,
                data: [],
                currentPage: 1,
                totalItems: '',
                showDetails: false,
            }
        },
        methods: {
            clickedRow: async function (contractorId) {
                this.$refs.details.clickedRow(contractorId);
            },
            refresh: async function () {
                await this.get();
                this.$refs.table.refresh();
            }
        },
        async mounted() {
            this.ability = await this.$ability.getAbility();
            if (!this.ability.can('manage', 'ROLE_SUPER_ADMIN')) {
                await this.$router.push({name: 'not-allowed'});
            }
        }
    }
</script>
