<template>
    <div class="text-right">
        <a
                @click.prevent="templateDetails()"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Szczegóły szablonu"
        >
            <vk-icon class="hover" icon="plus"/>
        </a>
        <a
                @click.prevent="editTemplate()"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Edytuj szablon"
        >
            <vk-icon class="hover" icon="pencil"/>
        </a>
        <a
                @click.prevent="deleteTemplate()"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Usuń szablon"
        >
            <vk-icon class="hover" icon="trash"/>
        </a>
        <remove ref="remove" v-on:refresh="$emit('refresh')"/>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import remove from "../ui/remove";

    export default {
        name: 'devicesActions',
        components: {
            remove
        },
        mixins: [mixin],
        props: {
            datatable: Object
        },
        methods: {
            editTemplate: function () {
                this.$emit('edit', this.datatable.item);
            },
            templateDetails: function(){
                this.$router.push({name:"budgetTemplateCategories", params: {templateId: this.datatable.item.id}});
            },
            deleteTemplate: function () {
                this.$refs.remove.remove(apiConfig.budgetTemplatesApiUrl + '/' + this.datatable.item.id, "szablon budżetu");
            }
        }
    }
</script>
