<template>
    <span>
            <a
                    @click.prevent="removeRelation(datatable)"
                    class="uk-icon-button"
                    href="#"
                    uk-tooltip="Usuń powiązanie"
            >
                <vk-icon class="hover" icon="trash"/>
            </a>
        <remove ref="remove" v-on:refresh="remove"/>
    </span>
</template>

<script>

    import mixin from "../../components/mixin";
    import apiConfig from "../../apiConfig";
    import remove from "../ui/remove";

    export default {
        name: 'qfdTechnicalParametersListActions',
        components: {
            remove
        },
        mixins: [mixin],
        props: {
            datatable: Object,
            index: null
        },
        methods: {
            removeRelation(relation) {
                this.$refs.remove.remove(apiConfig.qfdPropertyParametersApiUrl + "/" + relation.relationId, "powiązanie")
            },
            remove() {
                this.$parent.$el.remove();
                this.$emit('refresh', this.index);
            }
        }
    }
</script>
