<template>
  <admin>
  <div id="wpip-project">
    <div class="uk-container-expand uk-padding">

    <details-modal ref="details" @addCost="addCost" @editCost="editCost"  @editEvent="edit"  @removeEvent="removeEvent"/>
    <add-event-modal ref="modal" @addEvent="addEvent" @addCost="addCost" @editEvent="editEvent" @showEventDetails="showEventDetails" @removeEvent="removeEvent"/>
    <add-cost-modal ref="cost" @showEventDetails="showEventDetails"/>
    <div uk-grid>
      <menu-budgets/>
      <div class="uk-width-expand@m">
        <vk-grid class="uk-margin-bottom">
          <h3 class="uk-width-expand">Kalendarz - {{ title }}</h3>
        </vk-grid>
        <vk-card>
        <full-calendar defaultView="dayGridMonth" :selectable="true" :plugins="calendarPlugins" :header="calendarHeader" ref="calendar"
                   @select="select" @eventResize="resize" @eventDrop="drop" @eventClick="clicked" @viewSkeletonRender="changeTitle" :events="events" :locale="locale" :minTime="minTime" :maxTime="maxTime" :editable="true" :slot-duration="'00:15:00'"
                   :aspect-ratio="2.5" :slot-label-format="labelFormat" :button-text="buttonText" :custom-buttons="customButtons"
                   @datesRender="handleChange"/></vk-card>
      </div> </div>
  </div>
  </div>
  </admin>
</template>

<script>


  import FullCalendar from '@fullcalendar/vue'
  import dayGridPlugin from '@fullcalendar/daygrid'
  import timeGridPlugin from '@fullcalendar/timegrid'
  import list from '@fullcalendar/list'
  import interaction from '@fullcalendar/interaction'
  import pl from '@fullcalendar/core/locales/pl'
  import AddEventModal from "../../components/calendar/addEventModal";
  import axiosInstance from "../../axiosInstance";
  import apiConfig from "../../apiConfig";
  import mixin from "../../components/mixin";
  import AddCostModal from "../../components/calendar/addCostModal";
  import DetailsModal from "../../components/calendar/detailsModal";
  let vm;
  export default {
    components: {
      AddCostModal,
      AddEventModal,
      DetailsModal,
      FullCalendar // make the <FullCalendar> tag available
    },
    mixins: [mixin],
    data() {
      return {
        ability: null,
        currentStart: new Date(),
        customButtons: {
          addEventButton: {
            text: 'Dodaj zadanie',
            click: function () {
              vm.add()
            }
          }
        },
        calendarPlugins: [ dayGridPlugin, timeGridPlugin, list, interaction ],
        calendarHeader: {
          left: 'prev,next today',
          center: 'title',
          right: 'addEventButton dayGridMonth,timeGridWeek,timeGridDay listMonth,listWeek,listDay'
        },
        buttonText:{
          listMonth: "Miesiąc",
          listWeek: "Tydzień",
          listDay: "Dzień"
        },
        minTime: "07:00:00",
        maxTime: "18:00:00",
        labelFormat: {
          hour: 'numeric',
          minute: '2-digit',
          omitZeroMinute: false,
          meridiem: 'false'
        },
        events: [
        ],
        locale: pl,
        title: "Widok kalendarza - Miesiąc",
      }
    },
    methods:{
      changeTitle: function(){
        let calendarApi = this.$refs.calendar.getApi()
        if(calendarApi.view.type==="dayGridMonth"){
          this.title = "Widok kalendarza - Miesiąc";
        } else if(calendarApi.view.type==="timeGridWeek"){
          this.title = "Widok kalendarza - Tydzień";
        } else if(calendarApi.view.type==="timeGridDay"){
          this.title = "Widok kalendarza - Dzień";
        } else if(calendarApi.view.type==="listMonth"){
          this.title = "Lista zadań - Miesiąc";
        } else if(calendarApi.view.type==="listWeek"){
          this.title = "Lista zadań - Tydzień";
        } else if(calendarApi.view.type==="listDay"){
          this.title = "Lista zadań - Dzień";
        }
      },
      select: function(info) {
        this.$refs.modal.showModal({
          title: '',
          start: info.start,
          end: info.end,
          allDay: info.allDay,
          textColor: 'white'
        })
      },
      add: function() {
        this.$refs.modal.showModal({
          title: '',
          start: new Date().toISOString(),
          end: null,
          allDay: false,
          textColor: 'white'
        })
      },
      addEvent: function(event){
        event.textColor = 'white'
        event.end = event.endDate
        this.events.push(event);
      },
      addCost: function(event){
        this.$refs.cost.showModal({id:null, budgetPosition: "", contractor: "", value: ""}, event);
      },
      editCost: function(cost, event){
        this.$refs.cost.showModal(cost,event);
      },
      removeEvent: function(event){
        let vm = this;
        this.events.forEach(function (ev,k) {
          if(ev.id === parseInt(event.id)){
            vm.events.splice(k,1);
            return 0;
          }
        });
      },
      editEvent: async function (event) {
        let key = 0;

        this.events.forEach(function (ev, k) {
          if (ev.id === parseInt(event.id)) {
            key = k;
            return 0;
          }
        });
        this.events.splice(key, 1);
        this.events.push(event);
        this.events = [];
        await this.getEvents(apiConfig.eventsApiUrl);
        this.showEventDetails(event);
      },
      resize: function(info){
        let event = {};
        this.events.forEach(function (ev) {
          if(ev.id === parseInt(info.event.id)){
            event = ev;
          }
        });
        let postData = {
          endDate: info.event.end,
          allDay: info.event.allDay
        };
        axiosInstance
                .put(apiConfig.eventsApiUrl+'/' + event.id, JSON.stringify(postData), {
                  headers: this.$data.headers
                })
                .then(async function (response) {
                  if (response.status === 200) {
                    event.end = info.event.end;
                    event.start = info.event.start;
                  }
                }).catch();
      },
      drop: function(info){
        let event = {};
        this.events.forEach(function (ev) {
          if(ev.id === parseInt(info.event.id)){
            event = ev;
          }
        });
        let postData = {
          start: info.event.start,
          endDate: info.event.end,
          allDay: info.event.allDay
        };
        axiosInstance
                .put(apiConfig.eventsApiUrl+'/' + event.id, JSON.stringify(postData), {
                  headers: this.$data.headers
                })
                .then(async function (response) {
                  if (response.status === 200) {
                    event.start = info.event.start;
                    event.end = info.event.end;
                    event.allDay = info.event.allDay;
                  }
                }).catch();
      },
      clicked: function (info) {
        this.$refs.details.showModal({
          id: info.event.id,
          name: info.event.title,
          start: info.event.start,
          end: info.event.end,
          description: info.event.extendedProps.description,
          allDay: info.event.allDay,
          textColor: 'white',
          eventType: info.event.extendedProps.eventType['@id'],
          hasContractor: info.event.extendedProps.hasContractor,
          hasDevice: info.event.extendedProps.hasDevice,
          responsibles: info.event.extendedProps.responsibles,
          devices: info.event.extendedProps.devices,
          buildings: info.event.extendedProps.buildings,
          contractors: info.event.extendedProps.contractors
        })

      },
      showEventDetails: function (event) {
        if(!event.title){
          event.title = event.name;
          event.eventType = {'@id': event.eventType}
        }
        this.$refs.details.showModal({
          id: event.id,
          name: event.title,
          start: event.start,
          end: event.end,
          description: event.description,
          allDay: event.allDay,
          textColor: 'white',
          eventType: event.eventType['@id'],
          hasContractor: event.hasContractor,
          hasDevice: event.hasDevice,
          responsibles: event.responsibles,
          devices: event.devices,
          buildings: event.buildings,
          contractors: event.contractors
        });

      },
      edit: function (info) {
        this.$refs.modal.showModal({
          id: info.id,
          name: info.name,
          start: info.start,
          end: info.end,
          description: info.description,
          allDay: info.allDay,
          textColor: 'white',
          eventType: info.eventType,
          hasContractor: info.hasContractor,
          hasDevice: info.hasDevice,
          responsibles: info.responsibles,
          devices: info.devices,
          buildings: info.buildings,
          contractors: info.contractors
        })

      },
      getEvents: function(url){
        let vm = this;
        let params = {}

        let nextMonth = new Date(this.currentStart)
        nextMonth.setMonth(nextMonth.getMonth()+1)
        nextMonth.setDate(15)

        let prevMonth = new Date(this.currentStart)
        prevMonth.setDate(-15)

        params['start[before]'] = nextMonth
        params['start[after]'] = prevMonth

        axiosInstance
                .get(url, {
                  params,
                  headers: this.$data.headers
                })
                .then(function (response) {
                  response.data['member'].forEach(function (event) {
                    event.title = event.name;
                    event.end = event.endDate;
                    event.color = event.eventType.color;
                    event.textColor = 'white';
                    vm.events.push(event);
                  });
                  if(response.data['view'] && response.data['view']['next']){
                    vm.getEvents(response.data['view']['next']);
                  }
                });
      },
      handleChange(e){
        this.currentStart = e.view.currentStart
        this.events = [];
        this.getEvents(apiConfig.eventsApiUrl)
      }
    },
    mounted: async function () {
      this.ability = await this.$ability.getAbility();
      if (!this.ability.can('manage', 'ROLE_EXPLOATATION') && !this.ability.can('manage', 'ROLE_SUPER_ADMIN')) {
        await this.$router.push({name: 'not-allowed'});
      }
      vm = this;
    }
  }
</script>

<style lang='scss'>

  @import '~@fullcalendar/core/main.css';
  @import '~@fullcalendar/daygrid/main.css';
  @import '~@fullcalendar/timegrid/main.css';
  @import '~@fullcalendar/list/main.css';

  .fc{
    border-top: 1px solid #dee2e6!important;
    margin-top:10px;
    padding-top: 5px;
  }

  .fc-button-primary{
    background-color: #bf0e01;
    border-color: #940e01;
  }

  .fc-button-primary:not(:disabled).fc-button-active{
    background-color: #940e01;
    border-color: #940e01;
  }

  .fc-button-primary:hover{
    background-color: #940e01;
    border-color: #940e01;
  }

  .fc-button-primary:disabled{
    background-color: rgba(191, 14, 1, 0.8);
    border-color: #940e01;
  }
</style>
