<template>
    <div class="text-right">
        <a
                @click.prevent="editDevice()"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Edytuj urządzenie"
        >
            <vk-icon class="hover" icon="pencil"/>
        </a>
        <a
                @click.prevent="deleteDevice()"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Usuń urządzenie"
        >
            <vk-icon class="hover" icon="trash"/>
        </a>
        <remove ref="remove" v-on:refresh="$emit('refresh')"/>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import remove from "../ui/remove";

    export default {
        name: 'devicesActions',
        components: {
            remove
        },
        mixins: [mixin],
        props: {
            datatable: Object
        },
        methods: {
            editDevice: function () {
                this.$emit('editDevice',{...this.datatable.item});
            },
            deleteDevice: function () {
                this.$refs.remove.remove(apiConfig.devicesApiUrl + '/' + this.datatable.item.id, "urządzenie");
            }
        }
    }
</script>
