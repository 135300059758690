<template>
  <admin>
  <div id="wpip-project">
    <div uk-grid>
      <h3 class="uk-width-expand">System organizacyjny IAS</h3>
    </div>
    <div id="wpip-tools" class="uk-child-width-1-3@m uk-grid-small uk-grid-match" uk-grid>
      <div :key="info.name" v-for="info in infos">
          <div class="uk-card uk-card-default uk-card-body">
              <h3 class="uk-card-title">
                  <span class="uk-margin-small-right"><vk-icon v-bind:icon="info.icon"/></span>
                  {{ info.title }}
              </h3>
              <p>{{ info.desc }}</p>
              <button class="uk-button wpip-button" @click="getInfo(info.name)">Wybierz</button>
          </div>
      </div>
    </div>
  </div>
  </admin>
</template>

<script>
export default {
  name: "infos",
  data() {
    return {
        activeTools: [],
        infos: [
            {
                name: "functionTree",
                icon: "social",
                title: "Plan funkcji modułu IAS",
                desc: "Plan funkcji określa funkcje (działania), które system zarządzania IAS powinien spełniać. Funkcje przyporządkowane są do jednostek organizacyjnych i iw ten sposób określają role (obowiązki) tych jednostek w zakresie zarządzania IAS."
            },
            {
                name: "schema",
                icon: "git-fork",
                title: "Struktura organizacyjna systemu",
                desc: "Na strukturę organizacyjną składa się schemat organizacyjny oraz wykazy funkcji dla jednostek organizacyjnych ukazanych na schemacie."
            },
            {
                name: "processTree",
                icon: "future",
                title: "Procesy systemu IAS",
                desc: "Procesy obejmują powiązane ze sobą pod względem informacyjnym i materiałowym funkcje. Odpowiednio do tego, czy powiązane są funkcje wyższego czy niższego rzędu rozróżnia się procesy główne i podprocesy."
            },
            {
                name: "iasMethods",
                icon: "grid",
                title: "Metody wspomagające",
                desc: "W module tym znajduje się pakiet metod, które wspomagają spełnianie szeregu funkcji i procesów zarządzania w sposób efektywny i profesjonalny."
            }
      ]
    };
  },
  created() {
    this.pid = this.$route.params.Pid;
  },
  methods: {
      getInfo(info) {
          this.$router.push({name: info});
      }
  }
};
</script>
