<template>
    <span>
            <a
                    @click.prevent="editBenefit(datatable.item)"
                    class="uk-icon-button"
                    href="#"
                    uk-tooltip="Zmień benefit"
            >
                <vk-icon class="hover" icon="file-edit"/>
            </a>
            <a
                    @click.prevent="removeBenefit(datatable.item)"
                    class="uk-icon-button"
                    href="#"
                    uk-tooltip="Usuń benefit"
            >
                <vk-icon class="hover" icon="trash"/>
            </a>
        <remove ref="remove" v-on:refresh="$emit('refresh')"/>
    </span>
</template>

<script>

    import mixin from "../../components/mixin";
    import apiConfig from "../../apiConfig";
    import Remove from "../ui/remove";

    export default {
        name: 'benefitsActions',
        components: {Remove},
        mixins: [mixin],
        props: {
            datatable: Object
        },
        methods: {
            editBenefit(benefit) {
                this.$emit('edit', benefit);
            },
            removeBenefit(benefit) {
                this.$refs.remove.remove(apiConfig.benefitsApiUrl + "/" + benefit.id, "benefit");
            }

        }
    }
</script>
