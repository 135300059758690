<template>
    <div>
        <vk-grid class="uk-margin-bottom">
            <h3 class="uk-width-expand">Cele strategiczne </h3>
            <button
                    @click="newGoal"
                    class="uk-button wpip-button uk-width-auto"
            ><vk-icon icon="plus-circle"></vk-icon> Dodaj cel
            </button>
        </vk-grid>
        <vk-card>

            <empty-list v-if="totalItems===0"/>
        <b-table
                v-else
                :columns="fields"
                :current-page="currentPage"
                :fields="fields"
                :items="get"
                :per-page="this.$data.perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                borderedless
no-sort-reset
:responsive="true"
                class="uk-table vk-table-justify uk-table-divider"
                hover
                ref="table">

            <template v-slot:cell(actions)="data">
                <goals-actions :datatable=data v-on:edit="editGoal" v-on:refresh="refresh"/>
            </template>
            <template v-slot:cell(detailedGoals)="data">
                <span class="uk-label wpip-label">{{ data.item.goals.length }}</span>
            </template>
            <template v-slot:cell(responsible)="data">
                <span v-if="data.item.person!=null">
                {{ data.item.person.firstName }} {{ data.item.person.lastName }}
                    </span>
            </template>
        </b-table>
        <goals-modal ref="goalModal" v-on:refresh="refresh"/>
        <b-pagination :per-page="perPage" :total-rows="totalItems" align="center"
                      v-model="currentPage"  v-if="totalItems>perPage" size="sm" page-class="wpip-page" class="wpip-page"/>
        </vk-card>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import axiosInstance from "../../axiosInstance";
    import GoalsActions from "./goalsActions";
    import GoalsModal from "./goalsModal";
    import EmptyList from "../ui/emptyList";

    export default {
        name: 'goalsList',
        components: {
            EmptyList,
            goalsActions: GoalsActions,
            goalsModal: GoalsModal,
        },
        data() {
            return {
                sortBy: 'name',
                sortDesc: false,
                data: [],
                currentPage: 1,
                totalItems: '',
                fields: [
                    {key: 'name', label: 'Nazwa', class: 'uk-width-1-5', sortable: true},
                    {key: 'responsible', label: 'Odpowiedzialny', sortable: true},
                    {key: 'detailedGoals', label: 'Cele szczegółowe'},
                    {key: 'realization', label: 'Termin realizacji'},
                    {key: 'kpis', label: 'Mierniki'},
                    {key: 'actions', label: '', class: 'text-right'},
                ]
            }
        },
        mixins: [mixin],
        methods: {
            async get() {
                let vm = this;
                let desc = 'desc';
                if (vm.sortDesc !== true) {
                    desc = 'asc';
                }

                var params = {};

                if (this.sortBy !== '') {
                    var key = "order[" + this.sortBy + "]";
                    params[key] = desc;
                }
                params['page'] = vm.currentPage;
                params['building'] = vm.$route.params.buildingId;
                params['parent[exists]'] = false;
                let res = await axiosInstance
                    .get(apiConfig.goalsApiUrl,
                        {
                            params,
                            headers: this.$data.headers
                        });
                vm.data = res.data['member'];
                vm.totalItems = res.data['totalItems'];

                return vm.data;
            },
            editGoal(e) {
                this.$refs.goalModal.showModal(e);
            },
            newGoal: function () {
                this.$refs.goalModal.showModal(null);
            },
            refresh() {
                if(this.$refs.table) {
                    this.$refs.table.refresh();
                } else {
                    this.get();
                }
            }
        }
    }
</script>
<style scoped>
    .wpip-label{
        background-color: rgb(240, 80, 110);
        color: white;
    }

</style>
