<template>
    <admin>
        <div v-if="isBuildingSelected()" class="uk-container-expand uk-padding">
            <vk-grid>
                <sub-menu/>
                <div  class="uk-width-expand@m">
                    <div class="uk-grid uk-margin-bottom">
                        <h3 class="uk-width-expand">Lista Urządzeń</h3>
                        <button    @click="$refs.list.addDevice(null)"
                                class="uk-button wpip-button uk-width-auto"
                        ><vk-icon icon="plus-circle"></vk-icon> Dodaj urządzenie
                        </button>
                        <button @click="downloadXlsx()"
                                class="ml-1 uk-button uk-button-default uk-width-auto"
                        ><vk-icon icon="download"></vk-icon> Eksportuj dane
                        </button>
                    </div>
                    <devices-list ref="list" :filters.sync="filters"/>
                </div>

            </vk-grid>
        </div>
        <div v-else>
            <div class="uk-alert">
                Wybierz obiekt aby zarządzać urządzeniami.
            </div>
        </div>
      <file-download ref="download"/>
    </admin>
</template>

<script>

    import mixin from "../../components/mixin";
    import DevicesList from "../../components/device/DevicesList";
    import apiConfig from "../../apiConfig";
    import axiosInstance from "../../axiosInstance";
    import SubMenu from "@/components/ui/subMenu";
    import FileDownload from "@/components/ui/fileDownload";

    export default {
        name: 'devices',
        components: {
          FileDownload,
          SubMenu,
            'devicesList': DevicesList
        },
        mixins: [mixin],
        data() {
            return {
                filters: {},
                sortBy: 'name',
                sortDesc: false,
                data: [],
                ability: null,
                currentPage: 1,
                totalItems: '',
                fields: [
                    {key: 'name', label: 'Nazwa', sortable: true},
                    {key: 'delete', label: ''},
                ]
            }
        },
        methods: {
            isBuildingSelected: function(){
                if(localStorage.getItem('currentBuilding')!==null  && localStorage.getItem('currentBuilding')!=='null'){
                    return true;
                }
                return false;
            },
            async get() {
                let vm = this;
                let desc = 'desc';
                if (vm.sortDesc != true) {
                    desc = 'asc';
                }

                var key = "order[" + this.sortBy + "]";
                var params = {};
                params[key] = desc;
                params['page'] = vm.currentPage;

                let res = await axiosInstance
                    .get(apiConfig.devicesApiUrl,
                        {
                            params,
                            headers: this.$data.headers
                        });
                vm.data = res.data['member'];
                vm.totalItems = res.data['totalItems'];

                return vm.data;
            },
      downloadXlsx: function() {
        let vm = this
        let params = {}

        if(this.filters.name){
          params['name'] = this.filters.name
        }
        if(this.filters.model){
          params['model'] = this.filters.model
        }

        if(vm.filters.buildings.length>0){
          params.buildingId = []
          vm.filters.buildings.forEach((building)=>{
            params.buildingId.push(building.id)
          })
        }
        this.$refs.download.getApi(apiConfig.devicesApiUrl + "/export", "urzadzenia_"+new Date().toLocaleDateString()+".xlsx", params);
      }
        },
        async mounted() {
            this.ability = await this.$ability.getAbility();
            if (!this.ability.can('manage', 'ROLE_DEVICES') && !this.ability.can('manage', 'ROLE_SUPER_ADMIN')) {
                await this.$router.push({name: 'not-allowed'});
            }
        }
    }
</script>
