<template>
    <div>
        <div class="uk-grid uk-margin-bottom">
            <h3 class="uk-width-expand">Rejestry</h3>
        </div>

        <empty-list v-if="data.length===0"/>
        <b-table v-else :columns="fields" :current-page="currentPage" :fields="fields"
                 :items="get"
                 :sort-by.sync="sortBy"
                 :sort-desc.sync="sortDesc"
                 hover
                 borderedless
                 ref="registers"
no-sort-reset
:responsive="true">
            <template v-slot:cell(type)="data">
                {{ registerTypeName[data.item.type] }}
            </template>
            <template v-slot:cell(name)="data">
                <span :uk-tooltip="data.item.name">{{ limit(data.item,50) }}</span>
            </template>
            <template v-slot:cell(lastValue)="data">
                <span v-if="data.item.valueType !== valueType.string && data.item.valueType !== valueType.coil">{{data.item.lastValue}}</span>
                <span v-if="data.item.valueType === valueType.string || data.item.valueType === valueType.coil">{{data.item.lastValueString}}</span>
            </template>
        </b-table>
        <b-pagination :per-page="perPage" :total-rows="totalItems" align="center" v-model="currentPage"
                      v-if="totalItems>perPage" size="sm" page-class="wpip-page" class="wpip-page"/>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import EmptyList from "../ui/emptyList";
    import charLimiter from "../charLimiter";
    import axiosInstance from "../../axiosInstance";
    import apiConfig from "../../apiConfig";

    export default {
        name: 'middlewareRegistersList',
        components: {EmptyList},
        data() {
            return {
                filter: null,
                sortBy: 'name',
                sortDesc: false,
                data: [],
                currentPage: 1,
                totalItems: '',
                fields: [
                    {key: 'name', label: 'Nazwa', sortable: true},
                    {key: 'type', label: 'Typ', sortable: true},
                    {key: 'number', label: 'Numer', sortable: true},
                    {key: 'lastValue', label: 'Ostatnia wartość', class: 'text-right'},
                    {key: 'last', label: '', class: "hidden"}
                ]
            }
        },
        mixins: [mixin, charLimiter],
        methods: {
            async get() {
                let vm = this;
                let desc = 'desc';
                if (vm.sortDesc !== true) {
                    desc = 'asc';
                }
                let params = {
                    'page': vm.currentPage,
                    'middlewares.id': this.$attrs.middlewareId
                };
                if(this.sortBy!==null && this.sortBy!=="") {
                    params['order[' + this.sortBy + ']'] = desc;
                }
                let res = await axiosInstance
                    .get(apiConfig.registersApiUrl,
                        {
                            params, headers: this.$data.headers
                        });
                vm.data = res.data.member;
                vm.totalItems = res.data.totalItems;

                return vm.data;
            },
            refresh: function () {
                this.get();
                if (this.$refs.table) {
                    this.$refs.table.refresh();
                }
            }
        }, mounted() {
            this.get();
        }
    }
</script>