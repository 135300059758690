<template>
    <div class="uk-flex-top uk-modal-container" container=".admin" id="wpip-fmea-add" uk-modal>
        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-modal-body">
                <h3>{{ modal.title }}</h3>
                <div class="uk-form-controls">
            <textarea
                    class="uk-textarea"
                    data-vv-as="opis"
                    name="description"
                    placeholder="Proszę podać krótki opis nowej analizy FMEA"
                    rows="5"
                    v-model="modal.description"
                    v-validate="'required'"
            ></textarea>
                    <span class="validation-error">{{ errors.first('description') }}</span>
                </div>
                <div class="uk-margin">
                    <div class="uk-form-controls">
                        <input
                                class="uk-input"
                                data-vv-as="LPR"
                                name="lpr"
                                placeholder="Podaj wartość krytyczną ryzyka"
                                type="text"
                                v-model="modal.lpr"
                                v-validate="'required|integer'"
                        />
                    </div>
                    <span class="validation-error">{{ errors.first('lpr') }}</span>
                </div>
                <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                    <label>
                        <input
                                class="uk-radio"
                                name="radio2"
                                type="radio"
                                v-model="modal.type"
                                value="1"
                        /> Analiza obiektu IAS
                    </label>
                    <label>
                        <input
                                class="uk-radio"
                                name="radio2"
                                type="radio"
                                v-model="modal.type"
                                value="2"
                        /> Analiza procesu
                    </label>
                </div>
            </div>
            <div class="uk-modal-footer uk-text-right">
                <button class="uk-button uk-button-default uk-modal-close" type="button">Anuluj</button>
                <button @click="addFmea" class="uk-button wpip-button" type="button" v-if="modal.id==null">Dodaj
                </button>
                <button @click="editFmea" class="uk-button wpip-button" type="button" v-else>Zmień</button>
            </div>
        </div>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import UIkit from "uikit";
    import axiosInstance from "../../axiosInstance";

    export default {
        name: 'swotParametersModal',
        data() {
            return {
                swot: {},
                modal:
                    {
                        title: "Analiza FMEA",
                        description: null,
                        lpr: null,
                        type: null,
                        button: "Dodaj"
                    },

            }
        },
        mixins: [mixin],
        methods: {
            showModal: function (analysis) {
                if (analysis == null) {
                    this.modal.id = null;
                    this.modal.description = "";
                    this.modal.lpr = null;
                    this.modal.type = 1;
                    this.modal.button = "Dodaj";
                    this.modal.title = "Dodaj analizę FMEA";
                } else {
                    this.modal = analysis;
                    this.modal.button = "Zmień";
                    this.modal.title = "Edytuj analizę FMEA";
                }
                this.$validator.reset();
                UIkit.modal("#wpip-fmea-add").show();
            },
            addFmea: function () {
                let vm = this;
                vm.$validator.validate().then((result) => {
                    if (result) {
                        let postData = {
                            description: this.modal.description,
                            lpr: parseInt(this.modal.lpr),
                            type: parseInt(this.modal.type),
                            building: "/api/buildings/" + this.$route.params.buildingId,
                            author: "/api/users/" + localStorage.getItem('user')
                        };
                        axiosInstance
                            .post(apiConfig.fmeasApiUrl, JSON.stringify(postData), {
                                headers: this.$data.headers
                            })
                            .then(function (response) {
                                if (response.status === 201) {
                                    UIkit.modal("#wpip-fmea-add").hide();
                                    vm.$emit("modified");
                                }
                            }).catch();
                    }
                }).catch(() => {
                    return false;
                });
            },
            editFmea: function () {
                let vm = this;
                let postData = {
                    description: this.modal.description,
                    lpr: parseInt(this.modal.lpr),
                    type: parseInt(this.modal.type),
                };

                axiosInstance
                    .put(apiConfig.fmeasApiUrl + "/" + vm.modal.id, JSON.stringify(postData), {
                        headers: this.$data.headers
                    })
                    .then(function (response) {
                        if (response.status === 200) {
                            UIkit.modal("#wpip-fmea-add").hide();
                            vm.$emit("modified");
                        }
                    }).catch();
            }
        }

    }
</script>
