export default Object.freeze({
    IMPROVEMENT_AREA: [
        "Obszar #1",
        "Obszar #2",
        "Obszar #3"
    ],
    IMPROVEMENT_SCOPE: [
        "Zakres #1",
        "Zakres #2",
        "Zakres #3"
    ]
})
