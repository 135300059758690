<template>
  <admin ref="admin">
    <div class="uk-container-expand uk-padding">
      <vk-grid>
        <sub-menu></sub-menu>
        <building-modal ref="buildingModal" @refresh="refresh" />
        <div class="uk-width-expand@m">
          <vk-grid class="uk-margin-bottom">
            <h3 class="uk-width-expand">Wynajem - Obiekty </h3>
            <button
                @click="addObject()"
                class="uk-button wpip-button uk-width-auto"
            ><vk-icon icon="plus-circle"></vk-icon> Dodaj Obiekt
            </button>
          </vk-grid>
          <vk-grid class="uk-width-auto uk-margin-bottom">

            <div class="uk-width-1-3@m">
              <div class="card">
                <div class="card-title text-center">
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-5">
                      <div class="icon-big text-center">
                        <div class="icon-danger"><i
                            class="nc-icon nc-chart-pie-36 text-success uk-text-bold"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-7">
                      <div class="numbers">
                        <div><p class="card-category">Zajętość powierzchni</p> <h4
                            class="card-title">{{ percent }}%</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <hr>
                  <div class="stats">
                    <div><i class="nc-icon nc-refresh-02"/> {{ today.toLocaleDateString() }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="uk-width-1-3@m">
              <div class="card">
                <div class="card-title text-center">
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-5">
                      <div class="icon-big text-center">
                        <div class="icon-danger"><i
                            class="nc-icon nc-layers-3 text-warning uk-text-bold"></i></div>
                      </div>
                    </div>
                    <div class="col-7">
                      <div class="numbers">
                        <div><p class="card-category">Wynajęta powierzchnia</p> <h4
                            class="card-title">{{ rentedArea }} m2</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <hr>
                  <div class="stats">
                    <div><i class="nc-icon nc-refresh-02"/> {{ today.toLocaleDateString() }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="uk-width-1-3@m">
              <div class="card">
                <div class="card-title text-center">
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-5">
                      <div class="icon-big text-center">
                        <div class="icon-success"><i
                            class="nc-icon  nc-square-pin text-primary uk-text-bold"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-7">
                      <div class="numbers">
                        <div><p class="card-category">Liczba obiektów</p> <h4
                            class="card-title">{{ objectsNumber }}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <hr>
                  <div class="stats">
                    <div><i class="nc-icon nc-refresh-02"/> {{ today.toLocaleDateString() }}</div>
                  </div>
                </div>
              </div>
            </div>
          </vk-grid>
          <vk-card class="uk-margin-bottom">
            <rental-objects-filters v-if="showFilters" @filter="filter" :filters="filters"></rental-objects-filters>
          </vk-card>
          <vk-card>
            <rental-objects-list ref="list" @toggleFilters="toggleFilters"
                               :filterNum="filterNum"></rental-objects-list>
          </vk-card>
        </div>
      </vk-grid>

    </div>
  </admin>
</template>

<script>
import mixin from "../../components/mixin";
import RentalObjectsList from "../../components/rentalObject/rentalObjectsList";
import RentalObjectsFilters from "../../components/rentalObject/rentalObjectFilters";
import BuildingModal from "../../components/building/buildingModal";
import SubMenu from "@/components/ui/subMenu";
import axiosInstance from "@/axiosInstance";
import apiConfig from "@/apiConfig";

export default {
  name: "rentalDashboard",
  components: {SubMenu, BuildingModal, RentalObjectsFilters, RentalObjectsList},
  data() {
    return {
      objectsNumber: 0,
      percent: 0,
      rentedArea: 0,
      lastFilters: [],
      showFilters: false,
      filterNum: 0,
      filters: {
        contractors: [],
        beginDate: "",
        endDate: "",
        status: [],
        minValue: null,
        maxValue: null,
        minArea: null,
        maxArea: null,
      },
      today: new Date()
    };
  },
  mixins: [mixin],
  async mounted() {
    this.getDashboardData({})
  },
  methods: {
    async getDashboardData(filters={}) {
      let vm = this
      let params = { filters: filters }
      await axiosInstance
          .get(apiConfig.rentalsApiUrl + '/dashboards/objects',
              {
                params,
                headers: this.$data.headers
              }).then(function (result) {
                if(result.data.totalArea>0) {
                  vm.percent = (100 * (result.data.rentedArea / result.data.totalArea)).toFixed(1)
                }
                else {
                  vm.percent = '0.0'
                }
            vm.objectsNumber = result.data.objects
            vm.rentedArea = result.data.rentedArea.toFixed(2)
          });
    },
    addObject: function () {
      this.$refs.buildingModal.showModal({});
    },
    toggleFilters: function () {
      this.showFilters = !this.showFilters
    },
    setDashboardData: function (data) {
      this.objectsNumber = data.objects
      this.percent = data.percent.toFixed(1)
      this.rentedArea = data.rentedArea.toFixed(1)
    },
    refresh: async function () {

      let vm = this
      let res = await axiosInstance
          .get(apiConfig.userBuildingsApiUrl + '/' + localStorage.getItem('user'), {
            headers: vm.$data.headers,
          });
      if (res.status === 200) {
        localStorage.setItem('buildings', JSON.stringify(res.data))
      }
      this.filter(this.filters)
      this.$refs.admin.getBuildings()
    },
    filter: function (filters) {
      this.filters = filters
      filters = {...filters}
      let contractors = [];
      let contractorsIds = [];
      let filterNum = 0
      if (filters.contractors.length > 0) {
        filterNum++
      }
      filters.contractors.forEach(function (contractor) {
        contractors.push(contractor['@id'])
        contractorsIds.push(contractor['id'])
      })
      if (contractors.length > 0) {
        filters['contractor'] = contractors
        filters['contractorId'] = contractorsIds
      }
      delete filters.contractors
      if (filters.endDate && filters.endDate.toLocaleString() !== "") {
        filters['paymentDate[before]'] = filters.endDate.toLocaleString()
        filterNum++
      }
      if (filters.beginDate && filters.beginDate.toLocaleString() !== "") {
        filters['paymentDate[after]'] = filters.beginDate.toLocaleString()
        filterNum++
      }
      if (filters.minValue) {
        filters['toPay[gte]'] = filters.minValue
        filterNum++
      }
      if (filters.maxValue) {
        filters['toPay[lte]'] = filters.maxValue
        filterNum++
      }
      if (filters.minArea) {
        filters['availableArea[gte]'] = filters.minArea
        filterNum++
      }
      if (filters.maxArea) {
        filters['availableArea[lte]'] = filters.maxArea
        filterNum++
      }
      if (filters.status[2]) {
        filters['toPay[lte]'] = 0
      }
      if (filters.status[1]) {
        filters['paymentDate[before]'] = new Date().toLocaleString()
      }
      if (filters.status[0]) {
        filters['paymentDate[after]'] = new Date().toLocaleString()
      }

      delete filters.beginDate
      delete filters.endDate
      delete filters.minValue
      delete filters.maxValue
      delete filters.status
      this.filterNum = filterNum
      this.getDashboardData(filters)
      this.$refs.list.get(filters)
    }
  }
};
</script>

<style scoped>
.uk-accordion li {
  list-style-type: none;
}

.uk-accordion-title {
  color: black;
  font-size: 1.3em;
}

.uk-accordion {
  padding-left: 0;
}

.icon-big {
  font-size: 3em
}
</style>
