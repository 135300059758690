<template>
  <div class="w-100">
    <div class="row">
      <b-col>
        <b-button class="w-100" :variant="element.variant" size="lg" @click="setValue">{{element.title}}</b-button>
      </b-col>
    </div>
  </div>
</template>

<script>

import mixin from "../mixin";
import charLimiter from "../charLimiter";
import axiosInstance from "@/axiosInstance";
import apiConfig from "@/apiConfig";

export default {
  name: 'dashboardElementButton',
  components: {},
  data() {
    return {}
  },
  props: {
    elementId: null,
    element: {}
  },
  mixins: [mixin, charLimiter],
  methods: {
    setValue(){
      let vm = this
      let postData = {
        values: []
      }
      this.element.register.forEach(function (register){
        postData.values.push({register: register.id, value: vm.element.value})
      })
      axiosInstance
          .post(apiConfig.registerValuesApiUrl + "/add", JSON.stringify(postData), {
            headers: vm.$data.headers
          })
    }
  },
  mounted: function () {

  }
}
</script>
<style scoped>
.icon-big {
  font-size: 3em;
}
</style>
