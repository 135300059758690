<template>
    <div class="no-data-alert">
        <div uk-alert>
            <p>Brak danych do wyświetlenia</p>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'emptyList'
    }
</script>
<style scoped>
    .no-data-alert {
        text-align: center;
    }
    .uk-alert {
        display: inline-block;
        margin: 15px;
    }
    .uk-alert p {
        padding-right: 10px;
    }
</style>
