import {Validator} from 'vee-validate';

Validator.extend('nip', {
    validate: (value) => {
        value = value.replace(/[ -]/gi, '');
        if (value.length !== 10)
            return false;

        let weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];
        let sum = 0;
        let control = parseInt(value.substring(9, 10));
        for (let i = 0; i < weights.length; i++) {
            sum += (parseInt(value.substring(i, i + 1)) * weights[i]);
        }

        return sum % 11 === control;
    }
});

Validator.extend('phone', {
    validate: (value) => {
        if (value.length == 0)
            return false;
        value = value.replace(/[ -]/gi, '');

        let regex = /^[0-9+]{8,13}$/;
        return regex.test(value);
    }
});

const dictionary = {
    pl: {
        messages:{
            phone: () => 'Pole nie zawiera poprawnego numeru telefonu.',
            nip: () => 'Pole nie zawiera poprawnego numeru NIP.'
        }
    }
};

Validator.localize(dictionary);
