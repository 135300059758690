<template>
    <admin>
        <units-list v-on:details="clickedRow"></units-list>
        <units-details-modal ref="details"></units-details-modal>
    </admin>
</template>

<script>

    import mixin from "../../components/mixin";
    import UnitDetailsModal from '../../components/unit/unitDetailsModal';
    import UnitsList from '../../components/unit/unitsList';
    import apiConfig from "../../apiConfig";
    import axiosInstance from "../../axiosInstance";

    export default {
        name: 'units',
        components: {
            'unitsList': UnitsList,
            'unitsDetailsModal': UnitDetailsModal
        },
        mixins: [mixin],
        data() {
            return {
                sortBy: 'name',
                sortDesc: false,
                data: [],
                currentPage: 1,
                totalItems: '',
                showDetails: false,

                modal:
                    {
                        title: "Jednostka Organizacyjna",
                        unit: [],
                        fields: [
                            {key: 'name', label: 'Nazwa'},
                            {key: 'improvementsLimit', label: 'Liczba usprawnień w miesiącu ', class: 'text-right'}
                        ]
                    }
            }
        },
        methods: {
            clickedRow: async function (unitId) {
                this.$refs.details.clickedRow(unitId);
            },
            async get() {
                let vm = this;
                let desc = 'desc';
                if (vm.sortDesc !== true) {
                    desc = 'asc';
                }


                var key = "order[" + this.sortBy + "]";
                var params = {};
                params[key] = desc;
                params['page'] = vm.currentPage;

                let res = await axiosInstance
                    .get(apiConfig.unitsApiUrl,
                        {
                            params,
                            headers: this.$data.headers
                        });
                vm.data = res.data['member'];
                vm.totalItems = res.data['totalItems'];

                return vm.data;
            },
            refresh: async function () {
                await this.get();
                this.$refs.table.refresh();
            },
            async mounted() {
                this.ability = await this.$ability.getAbility();
                if (!this.ability.can('manage', 'ROLE_SUPER_ADMIN')) {
                    await this.$router.push({name: 'not-allowed'});
                }
            }
        }
    }
</script>
