<template>
    <div class="uk-container-expand uk-padding">
        <vk-grid>
            <menuAdmin/>
            <div class="uk-width-expand@m">
                <vk-grid class="uk-margin-bottom">
                    <h3 class="uk-width-expand">Jednostki Organizacyjne </h3>
                    <button
                            @click="$refs.modal.showModal({})"
                            class="uk-button wpip-button uk-width-auto"
                    ><vk-icon icon="plus-circle"></vk-icon> Dodaj jednostkę
                    </button>
                </vk-grid>
                <vk-card>
                <div>
                    <empty-list v-if="totalItems===0"/>
                    <b-table v-else ref="table" hover borderedless
no-sort-reset
:responsive="true"
                             :fields="fields"
                             :columns="fields"
                             :items="get"
                             :per-page="this.$data.perPage"
                             :current-page="currentPage"
                             :sort-by.sync="sortBy"
                             :sort-desc.sync="sortDesc">
                        <template v-slot:cell(name)="data">
                            <a @click="clickedRow(data.item.id)" :uk-tooltip="data.item.name">{{ limit(data.item, 80) }} </a>
                        </template>

                        <template v-slot:cell(status)="data">
                            <unitsStatus :datatable="data"/>
                        </template>
                        <template v-slot:cell(actions)="data">
                            <unitsActions :datatable="data" v-on:refresh="refresh" @edit="$refs.modal.showModal"/>
                        </template>
                    </b-table>
                    <b-pagination :total-rows="totalItems" :per-page="perPage" v-model="currentPage"
                                  align="center" v-if="totalItems>perPage" size="sm" page-class="wpip-page" class="wpip-page"/>
                    <unit-modal ref="modal" @refresh="refresh"/>
                </div>
                    
                </vk-card>
            </div>
        </vk-grid>
    </div>
</template>

<script>

    import {Grid} from 'vuikit/lib/grid';
    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import UnitsActions from "./unitsActions"
    import UnitsStatus from "./unitsStatus"
    import axiosInstance from "../../axiosInstance";
    import UnitModal from "./unitModal";
    import EmptyList from "../ui/emptyList";
    import charLimiter from "../charLimiter";

    export default {
        name: 'unitsList',
        components: {
            EmptyList,
            UnitModal,
            unitsStatus: UnitsStatus,
            unitsActions: UnitsActions,
            VkGrid: Grid
        },
        data() {
            return {
                sortBy: 'name',
                sortDesc: false,
                data: [],
                currentPage: 1,
                totalItems: '',
                fields: [
                    {key: 'name', label: 'Nazwa', sortable: true},
                    {key: 'improvementsLimit', label: 'Usprawnienia / miesiąc', sortable: true},
                    {key: 'status', label: 'Aktywna', class: 'text-center'},
                    {key: 'actions', label: ''},
                ]
            }
        },
        mixins: [mixin, charLimiter],
        methods: {
            refresh: function () {
                this.get();
                if(this.$refs.table) {
                    this.$refs.table.refresh();
                }
            },
            clickedRow: function (unitId) {
                this.$emit('details', unitId);
            },
            async get() {
                let vm = this;
                let desc = 'desc';
                if (vm.sortDesc !== true) {
                    desc = 'asc';
                }


                let key = "order[" + this.sortBy + "]";
                let params = {};
                if(this.sortBy!==null && this.sortBy!=="") {
                    params[key] = desc;
                }
                params['page'] = vm.currentPage;

                let res = await axiosInstance
                    .get(apiConfig.unitsApiUrl,
                        {
                            params,
                            headers: this.$data.headers
                        });
                vm.data = res.data['member'];
                vm.totalItems = res.data['totalItems'];

                return vm.data;
            }
        }
    }
</script>
