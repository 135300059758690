<template>
    <div class="uk-container uk-padding">
        <vk-grid>
            <h3 class="uk-width-expand">Baza parametrów obiektów IAS </h3>
            <button
                    @click="newParameter"
                    class="uk-button wpip-button uk-width-auto"
            >Dodaj parametr
            </button>
        </vk-grid>
        <div>
            <h4>Legenda:</h4>
            <div>
                <span class="uk-label negative-2">Silna korelacja wykluczająca</span>
                <span class="uk-label uk-margin-small-left negative-1">Korelacja negatywna</span>
                <span class="uk-label uk-margin-small-left positive-1">Korelacja Pozytywna</span>
                <span class="uk-label uk-margin-small-left positive-2">Korelacja bardzo pozytywna</span>
            </div>
        </div>
        <empty-list v-if="totalItems===0"/>
        <b-table
                v-else
                :columns="fields"
                :current-page="currentPage"
                :fields="fields"
                :items="get"
                :per-page="128"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                borderedless
                no-sort-reset
                :responsive="true"
                class="uk-table vk-table-justify uk-table-divider"
                hover
                ref="table">
            <template v-slot:cell(name)="data">
                <span :uk-tooltip="data.item.name">{{ limit(data.item,50) }}</span>
            </template>
            <template v-slot:cell(description)="data">
                <span>{{ limit(data.item,150,'description') }}</span>
            </template>
            <template v-slot:cell(correlations)="data">
                <span class="uk-label uk-margin-small-left"
                      v-bind:class="{'negative-2': correlation.correlation===-2, 'negative-1': correlation.correlation===-1, 'neutral': correlation.correlation===0,  'positive-1': correlation.correlation===1, 'positive-2': correlation.correlation===2}"
                      :uk-tooltip="correlation.qfdParameter1.name + '<br>' + getTooltip(correlation.correlation)"
                      v-bind:key="correlation.id"
                      v-for="correlation in data.item.qfdParameter2Correlations">{{ limit(correlation.qfdParameter1, 50) }}</span>
                <span class="uk-label uk-margin-small-left" v-bind:key="correlation.id"
                      v-bind:class="{'negative-2': correlation.correlation===-2, 'negative-1': correlation.correlation===-1, 'neutral': correlation.correlation===0, 'positive-1': correlation.correlation===1, 'positive-2': correlation.correlation===2}"
                      :uk-tooltip=" correlation.qfdParameter2.name + '<br>' + getTooltip(correlation.correlation)"
                      v-for="correlation in data.item.qfdParameterCorrelations1">{{ correlation.qfdParameter2.name }}</span>
            </template>
            <template v-slot:cell(actions)="data">
                <parameters-actions :datatable=data v-on:edit="editParameter" v-on:refresh="refresh"/>
            </template>
        </b-table>
        <b-pagination :per-page="128" :total-rows="totalItems" align="center"
                      v-model="currentPage" v-if="totalItems>128" size="sm" page-class="wpip-page"
                      class="wpip-page"/>
        <parameters-modal ref="parametersModal" v-on:refresh="refresh"/>
    </div>
</template>

<script>

import mixin from "../mixin";
import apiConfig from "../../apiConfig";
import axiosInstance from "../../axiosInstance";
import ParametersModal from "./parametersModal";
import ParametersActions from "./parametersActions";
import EmptyList from "../ui/emptyList";
import charLimiter from "../charLimiter";

export default {
  name: 'qfdParametersList',
  components: {
    EmptyList,
    parametersModal: ParametersModal,
    parametersActions: ParametersActions,
  },
  data() {
    return {
      sortBy: 'name',
      sortDesc: false,
      data: [],
      currentPage: 1,
      totalItems: '',
      fields: [
        {key: 'name', label: 'Parametr', class: 'uk-width-1-5', sortable: true},
        {key: 'description', label: 'Opis', sortable: true},
        {key: 'correlations', label: 'Korelacje', class: 'uk-width-2-5'},
        {key: 'actions', label: '', class: 'text-right'},
      ],
      correlationLevels: {
        "-2": "Silna korelacja wykluczająca",
        "-1": "Korelacja negatywna",
        "1": "Korelacja pozytywna",
        "2": "Korelacja bardzo pozytywna"
      }
    }
  },
  mixins: [mixin, charLimiter],
  methods: {
    async get() {
      let vm = this;
      let desc = 'desc';
      if (vm.sortDesc !== true) {
        desc = 'asc';
      }

      var params = {};

      if (this.sortBy !== '') {
        var key = "order[" + this.sortBy + "]";
        params[key] = desc;
      }
      params['page'] = vm.currentPage;
      params['building'] = vm.$route.params.buildingId;

      let res = await axiosInstance
          .get(apiConfig.qfdParametersApiUrl,
              {
                params,
                headers: this.$data.headers
              });
      vm.data = res.data['member'];
      vm.totalItems = res.data['totalItems'];

      return vm.data;
    },
    editParameter(e) {
      this.$refs.parametersModal.showModal(e);
    },
    newParameter: function () {
      this.$refs.parametersModal.showModal(null);
    },
    refresh() {
      this.get();
      if (this.$refs.table) {
        this.$refs.table.refresh();
      }
    },
    getTooltip(level) {
      return this.correlationLevels[level];
    }
  }
}
</script>
<style scoped>
h4 {
  margin-bottom: 2px;
  margin-top: 8px
}

.negative-2 {
  background-color: rgb(240, 80, 110);
  color: white;
}

.negative-1 {
  background-color: rgb(240, 140, 61);
  color: white;
}

.neutral {
  display: none;
}

.positive-1 {
  background-color: rgb(30, 135, 240);
  color: white;
}

.positive-2 {
  background-color: rgb(50, 210, 150);
  color: white;
}
</style>
