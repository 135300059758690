import {Validator} from 'vee-validate';

Validator.extend('border', {
    validate: (value) => {
        let floatValue = parseFloat(value);
        let regex = /^[0-9]{0,3}[.,]{0,1}[0-9]*%{0,1}$/;
        if (floatValue < 0 || floatValue > 100 || !regex.test(value)) {
            return false;
        }
        else {
            return true;
        }
    }
});

const dictionary = {
    pl: {
        messages:{
            border: () => 'Pole nie jest wartością zawierającą się pomiędzy 0 a 100%'
        }
    }
};

Validator.localize(dictionary);
