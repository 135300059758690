<template>
  <div class="w-100">
    <div class="row">
      <b-col>
        <b-button class="w-100" :variant="element.variant" size="lg" @click="setValue">
          <b-input class="button-input" v-model="value" @click.prevent="e => e.stopPropagation()"></b-input>
          {{element.title}}
        </b-button>
      </b-col>
    </div>
  </div>
</template>

<script>

import mixin from "../mixin";
import charLimiter from "../charLimiter";
import axiosInstance from "@/axiosInstance";
import apiConfig from "@/apiConfig";

export default {
  name: 'dashboardElementInputButton',
  components: {},
  data() {
    return {
      value: ""
    }
  },
  props: {
    elementId: null,
    element: {}
  },
  mixins: [mixin, charLimiter],
  methods: {
    setValue(){
      let vm = this
      let postData = {
        values: []
      }
      let value = vm.element.value
      if(this.value){
        value = this.value
      }
      this.element.register.forEach(function (register){
        postData.values.push({register: register.id, value: value})
      })
      axiosInstance
          .post(apiConfig.registerValuesApiUrl + "/add", JSON.stringify(postData), {
            headers: vm.$data.headers
          })
    }
  },
  mounted: function () {
    this.value = this.element.value
  }
}
</script>
<style scoped>
.icon-big {
  font-size: 3em;
}

.button-input{
  background: rgba(255,255,255,0.4);
}
</style>
