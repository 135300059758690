<template>
  <div>
    <vk-grid class="uk-margin-small-bottom">
      <h3 class="uk-width-expand">Zasoby </h3>
      <button
          @click="$refs.modal.showModal({})"
          class="uk-button wpip-button uk-width-auto"
      >Dodaj
      </button>
    </vk-grid>
    <empty-list v-if="totalItems===0"/>
    <b-table v-else
             ref="table" hover borderedless
             no-sort-reset
             :responsive="true"
             :fields="fields"
             :columns="fields"
             :items="get"
             :per-page="this.$data.perPage"
             :current-page="currentPage"
             :sort-by.sync="sortBy"
             :sort-desc.sync="sortDesc">

      <template v-slot:cell(name)="data">
                <span :uk-tooltip="data.item.name">
                {{ limit(data.item, 100) }}
                </span>
      </template>

      <template v-slot:cell(actions)="data">
        <resources-actions :datatable=data v-on:edit="edit" v-on:refresh="refresh()"
                           v-on:addPerson="addPerson" v-on:showPeople="showModal"/>
      </template>


    </b-table>
    <resource-modal ref="modal" @refresh="refresh"/>
    <b-pagination :total-rows="totalItems" :per-page="perPage" v-model="currentPage"
                  align="center" v-if="totalItems>perPage" size="sm" page-class="wpip-page" class="wpip-page"/>
  </div>
</template>

<script>

import mixin from "../mixin";
import apiConfig from "../../apiConfig";
import axiosInstance from "../../axiosInstance";
import 'vue-loading-overlay/dist/vue-loading.css';
import EmptyList from "../ui/emptyList";
import charLimiter from "../charLimiter";
import ResourceModal from "./resourceModal";
import ResourcesActions from "./resourcesActions";

export default {
  name: 'resourcesList',
  components: {
    ResourcesActions,
    ResourceModal,
    EmptyList
  },
  data() {
    return {
      sortBy: 'name',
      sortDesc: false,
      data: [],
      isLoading: true,
      currentPage: 1,
      totalItems: '',
      fields: [
        {key: 'description', label: 'Nazwa', sortable: true},
        {key: 'actions', label: ''},
      ],
      contractorId: null
    }
  },
  mixins: [mixin, charLimiter],
  methods: {
    showModal: function (e) {
      this.$refs.usersModal.showModal(e, e.id)
    },
    addPerson: function (e) {
      this.$refs.addUserModal.showModal(e.id)
    },
    async get() {
      let loader = this.$loading.show({
        // Optional parameters
        container: null,
        canCancel: false
      });
      let vm = this;
      let desc = 'desc';
      if (vm.sortDesc !== true) {
        desc = 'asc';
      }

      var params = {
        buildings: [vm.$route.params.id]
      };

      if (this.sortBy !== '') {
        var key = "order[" + this.sortBy + "]";
        params[key] = desc;
      }
      params['page'] = vm.currentPage;
      params['contractor.id'] = vm.contractorId;

      let res = await axiosInstance
          .get(apiConfig.iasResourcesApiUrl,
              {
                params,
                headers: this.$data.headers
              });
      if (res.status === 200) {
        vm.data = res.data;
      }
      loader.hide();
      return vm.data;
    },
    showParameters: function (e) {
      this.$refs.listModal.showModal(this.data[e], e)
    },
    refresh: async function () {
      let vm = this;

      let params = {
        buildings: [vm.$route.params.id]
      }

      let res = await axiosInstance
          .get(apiConfig.iasResourcesApiUrl, {
            params,
            headers: vm.$data.headers,
          });
      if (res.status === 200) {
        localStorage.setItem('resources', JSON.stringify(res.data));
      }
      await this.get();
      this.$emit('refreshBuildings');
      if (this.$refs.table) {
        this.$refs.table.refresh();
      }
    },
    showTools: function (id, e) {
      e.preventDefault();
      this.$router.push({name: 'tools', params: {buildingId: id}})
    },
    edit: function (e) {
      this.$refs.modal.showModal(e)
      //this.$emit('edit');
    }
  }
}
</script>
