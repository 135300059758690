<template>
    <div class="text-right">
        <a
                @click.prevent="getInvoices(datatable.item)"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Pobierz faktury"
        >
            <vk-icon class="hover" icon="download"/>
        </a>
        <a
                @click.prevent="addInvoice(datatable.item)"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Dodaj fakturę"
        >
            <vk-icon class="hover" icon="cloud-upload"/>
        </a>
        <a
                v-if="datatable.detailsShowing"
                @click.prevent="toggleInvoices(datatable)"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Ukryj faktury"
        >
            <vk-icon class="hover" icon="minus"/>
        </a>
        <a
                v-else
                @click.prevent="toggleInvoices(datatable)"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Pokaż faktury"
        >
            <vk-icon class="hover" icon="plus"/>
        </a>
        <file-download ref="download"/>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import FileDownload from "../ui/fileDownload";

    export default {
        title: 'delete',
        components: {
            FileDownload
        },
        name: 'rentalDetailsActions',
        mixins: [mixin],
        props: {
            datatable: Object
        },
        methods: {
            remind: function(){

            },
            getInvoices: function (data) {
                let invoices = []
                data.invoices.forEach(function (invoice) {
                    invoices.push(invoice.id)
                })
                this.$refs.download.download('/invoices/' + invoices, "Faktury.zip");
            },
            addInvoice: function(item){
                  this.$emit('addInvoice', {rental: item, month: item.month})
            },
            toggleInvoices: function(key){
                this.$emit('toggleInvoices', key);
            }
        }
    }
</script>
<style scoped>
    div {
        white-space: nowrap;
    }

    .middleware-red {
        background-color: #bf0e01;
        color: white;
    }

    .middleware-orange {
        background-color: #e38703;
        color: white;
    }
    .middleware-green {
        background-color: #09a913;
        color: white;
    }
</style>
