<template>
    <div class="uk-flex-top uk-modal-container" container=".admin" id="people" uk-modal>
        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-modal-body">
                <h3>{{ modal.title }}</h3>
                <div class="uk-margin">
                    <label class="uk-form-label">Imię</label>
                    <input class="uk-input" name="first-name" v-model="person.firstName" type="text" placeholder="Imię" data-vv-as="imię"
                           v-validate="'required|max:255'">
                    <span class="validation-error">{{ errors.first('first-name') }}</span>
                </div>
                <div class="uk-margin">
                    <label class="uk-form-label">Nazwisko</label>
                    <input class="uk-input" name="last-name" v-model="person.lastName" type="text" placeholder="Nazwisko" data-vv-as="nazwisko"
                           v-validate="'required|max:255'">
                    <span class="validation-error">{{ errors.first('last-name') }}</span>
                </div>
                <div class="uk-margin">
                    <label class="uk-form-label">E-mail</label>
                    <input class="uk-input" name="email" v-model="person.email" type="text" placeholder="E-mail" data-vv-as="e-mail"
                           v-validate="'required|email'">
                    <span class="validation-error">{{ errors.first('email') }}</span>
                </div>
                <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                    <label><input class="uk-checkbox" type="checkbox" name="is-manager" v-model="person.isManager">
                        Manager</label>
                </div>
                <div class="uk-margin">
                    <label class="uk-form-label">Jednostka organizacyjna</label>

                    <v-select :filterable="false" :options="units" @search="search" name="unit"
                              label="@id" :class="'wpip-select'" v-model="person.unit" v-validate="'required'" data-vv-as="jednostka organizacyjna">

                        <template slot="option" slot-scope="option">
                            <div class="d-center">
                                {{ option.name }}
                            </div>
                        </template>

                        <template slot="selected-option" slot-scope="option">
                            <div class="selected d-center">
                                {{ option.name }}
                            </div>
                        </template>

                        <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                                Nie znaleziono <em>{{ search }}</em>.
                            </template>
                            <em style="opacity: 0.5;" v-else>Wyszukaj jednostkę organizacyjną</em>
                        </template>
                    </v-select>
                    <span class="validation-error">{{ errors.first('unit') }}</span>
                </div>

                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-default uk-modal-close" type="button">Anuluj</button>
                    <button :disabled="modal.blocked" @click="submit" class="uk-button wpip-button" type="button">
                        {{modal.button}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import mixin from "../mixin";
    import UIkit from "uikit";
    import 'vue-datetime/dist/vue-datetime.css'
    import axiosInstance from "../../axiosInstance";
    import apiConfig from "../../apiConfig";

    export default {
        name: 'personModal',
        components: {
        },
        data() {
            return {
                filterValue: "",
                modalDom: null,
                parameter: null,
                key: 0,
                type: 'datetime',
                buildingIndex: 0,
                person: {},
                units:[],
                modal:
                    {
                        title: "Dodaj osobę",
                        button: "Dodaj",
                        blocked: false
                    },

            }
        },
        created: function () {
            let vm = this;
            var params = {};

            params['status'] = 1;
            axiosInstance
                .get(apiConfig.unitsApiUrl, {
                    params,
                    headers: this.$data.headers
                })
                .then(function (response) {
                    vm.units = response.data['member'];
                });
            axiosInstance
                .get(apiConfig.usersApiUrl, {
                    headers: this.$data.headers
                })
                .then(function (response) {
                    vm.users = response.data['member'];
                });
        },
        mixins: [mixin],
        methods: {
            showModal: function (person) {
                let vm = this;
                this.person = {...person};
                if (person.id == null) {
                    vm.modal =
                        {
                            title: "Dodaj osobę",
                            button: "Dodaj",
                            blocked: false
                        }
                        this.person.unit="";
                } else {
                    this.modal =
                        {
                            title: "Zmień osobę",
                            button: "Zmień",
                            blocked: false
                        }
                }

                this.modalDom.show();
            },
            search: function(e){
                let vm = this;
                clearTimeout(this.searchTimeout)
                this.searchTimeout = setTimeout(function () {
                    vm.getUnits(e)
                }, 500);
            },
            async getUnits(e) {
                let vm = this;
                let params = {};
                params['name'] = e.split(' ');
                let res = await axiosInstance
                    .get(apiConfig.unitsApiUrl,
                        {
                            params,
                            headers: this.$data.headers
                        });
                vm.units = res.data['member'];
                return vm.units;
            },
            submit: function () {
                let postData = {
                    firstName: this.person.firstName,
                    lastName: this.person.lastName,
                    email: this.person.email,
                    isManager: this.person.isManager,
                    unit: this.person.unit['@id'],
                };

                let vm = this;
                vm.$validator.validate().then((result) => {
                    if (result) {
                        this.modal.blocked = true;
                        if (!this.person.id) {
                            axiosInstance
                                .post(apiConfig.peopleApiUrl, JSON.stringify(postData), {
                                    headers: this.$data.headers
                                })
                                .then(async function (response) {
                                    if (response.status === 201) {
                                        vm.$emit('refresh');
                                        vm.modalDom.hide();
                                    }
                                    vm.modal.blocked = false;
                                }).catch();
                        } else {
                            axiosInstance
                                .put(apiConfig.peopleApiUrl + '/' + vm.person.id, JSON.stringify(postData), {
                                    headers: this.$data.headers
                                })
                                .then(async function (response) {
                                    if (response.status === 200) {
                                        vm.$emit('refresh');
                                        vm.modalDom.hide();
                                    }
                                    vm.modal.blocked = false;
                                }).catch();
                        }
                    }
                });
            }
        },
        mounted() {
            if (this.modalDom == null) {
                this.modalDom = UIkit.modal('#people');
            }
        }

    }
</script>
