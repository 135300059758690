<template>
<div>
  <vk-grid class="uk-width-auto">

    <div class="uk-width-1-3@m uk-margin-bottom">
      <div class="card">
        <div class="card-title text-center">
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-5">
              <div class="icon-big text-center">
                <div class="icon-danger"><i class="nc-icon nc-money-coins text-danger uk-text-bold"></i>
                </div>
              </div>
            </div>
            <div class="col-7">
              <div class="numbers">
                <div>
                  <p class="card-category">Koszty</p>
                  <h4 class="card-title">{{total.costs.toFixed(1)}} PLN</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <hr>
          <div class="stats">
            <div><i class="nc-icon nc-refresh-02" /> {{new Date().toLocaleDateString()}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-width-1-3@m">
      <div class="card">
        <div class="card-title text-center">
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-5">
              <div class="icon-big text-center">
                <div class="icon-danger"><i class="nc-icon nc-notes text-primary uk-text-bold" :class="{ 'text-danger': total.costs>total.value}"></i></div>
              </div>
            </div>
            <div class="col-7">
              <div class="numbers">
                <div>
                  <p class="card-category">Wykorzystanie budżetu</p>
                  <h4 class="card-title">{{(total.costs*100/total.value).toFixed(1)}} %</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <hr>
          <div class="stats">
            <div><i class="nc-icon nc-refresh-02" /> {{new Date().toLocaleDateString()}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-width-1-3@m">
      <div class="card">
        <div class="card-title text-center">
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-5">
              <div class="icon-big text-center">
                <div class="icon-success"><i class="nc-icon  nc-chart-pie-36 text-success uk-text-bold"></i>
                </div>
              </div>
            </div>
            <div class="col-7">
              <div class="numbers">
                <div>
                  <p class="card-category">Plan</p>
                  <h4 class="card-title">{{total.value.toFixed(1)}} PLN</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <hr>
          <div class="stats">
            <div><i class="nc-icon nc-refresh-02" /> {{new Date().toLocaleDateString()}}</div>
          </div>
        </div>
      </div>
    </div>
  </vk-grid>
  <empty-list v-if="budgetCategories.length===0" />
  <b-table v-else :columns="categoriesFields" :current-page="currentPage" :fields="categoriesFields" :items="getBudgetCategories()" :per-page="this.$data.perPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :class="'no-header'" borderedless
    no-sort-reset :responsive="true" hover ref="table">

    <template v-slot:cell(name)="data">
      <span @click="toggle(data)" class="row-click" :uk-tooltip="data.item.name">
        {{ data.detailsShowing ? '-' : '+'}}
        <span :uk-tooltip="'Przekroczono plan w przynajmniej jednej z kategorii'" v-if="data.item.overBudget">
          <vk-icon class="hover text-warning" icon="warning" /></span>
        <span class="uk-label label-margin-left uk-label-danger" v-if="data.item.sum>data.item.value">PRZEKROCZONO PLAN</span>
        {{ limit(data.item,50) }}
      </span>
    </template>

    <template v-slot:cell(actions)="data">
      <span class="uk-label uk-label-success label-margin-left" uk-tooltip="Plan">{{ data.item.value.toFixed(1) }}</span>
      <span class="uk-label uk-label-danger label-margin-left" uk-tooltip="Koszty">{{ data.item.sum.toFixed(1) }}</span>
      <span class="uk-badge wpip-badge label-margin-left" v-if="data.item.value>0" uk-tooltip="% realizacji planu">{{(data.item.sum*100/data.item.value).toFixed(1) }}%</span>
    </template>

    <template v-slot:row-details="row">
      <b-card>
        <b-table :columns="categoriesFields" :current-page="currentPage" :fields="categoriesFields" :items="getBudgetSubCategories(row.item)" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :class="'no-header'" borderedless no-sort-reset
          :responsive="true" hover ref="table">

          <template v-slot:cell(name)="data">
            <span class="row-click" v-if="data.item.budgetCategory" @click="setFilter(data.item)" :uk-tooltip="data.item.budgetCategory.name"><span class="uk-label label-margin-left uk-label-danger" v-if="data.item.sum>data.item.value">PRZEKROCZONO
                PLAN</span> {{ limit(data.item.budgetCategory,40) }}</span>
            <span v-else class="row-click" @click="setFilter(data.item)" :uk-tooltip="data.item.name"><span class="uk-label label-margin-left uk-label-danger" v-if="data.item.sum>data.item.value">PRZEKROCZONO PLAN</span>
              {{ limit(data.item,40) }}</span>
          </template>

          <template v-slot:cell(actions)="data">
            <span class="uk-label uk-label-success label-margin-left" uk-tooltip="Plan">{{ parseFloat(data.item.value).toFixed(2) }}</span>
            <span class="uk-label uk-label-danger label-margin-left" uk-tooltip="Koszty">{{ data.item.sum.toFixed(2) }}</span>
            <span class="uk-badge wpip-badge label-margin-left" v-if="parseFloat(data.item.value)>0" uk-tooltip="% realizacji planu">{{(data.item.sum*100/parseFloat(data.item.value)).toFixed(2) }}%</span>

          </template>

        </b-table>
      </b-card>
    </template>

  </b-table>


  <b-table :columns="fields" :current-page="currentPage" :fields="fields" :items="data" :per-page="this.$data.perPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" borderedless no-sort-reset :responsive="true" hover ref="table">
    <template v-slot:cell(event.name)="data">
      <span v-if="data.item.event.name" :uk-tooltip="data.item.event.name">
        {{ limit(data.item.event,40) }}
      </span>
    </template>
    <template v-slot:cell(category)="data">
      <span v-if="data.item.budgetPosition.budgetCategory && data.item.budgetPosition.budgetCategory.name" :uk-tooltip="data.item.budgetPosition.budgetCategory.name">
        {{ limit(data.item.budgetPosition.budgetCategory,40) }}
      </span>
      <span v-else-if="data.item.budgetPosition.name" :uk-tooltip="data.item.budgetPosition.name">
        {{ limit(data.item.budgetPosition,40) }}
      </span>
    </template>

    <template v-slot:cell(name)="data">
      <span v-if="data.name!==null" :uk-tooltip="data.item.name"> {{ limit(data.item,50) }} </span>
      <span v-else> brak </span>
    </template>
  </b-table>
  <b-pagination :per-page="perPage" :total-rows="totalItems" align="center" v-model="currentPage" v-if="totalItems>perPage" size="sm" page-class="wpip-page" class="wpip-page" />
</div>
</template>

<script>
import mixin from "../mixin";
import apiConfig from "../../apiConfig";
import axiosInstance from "../../axiosInstance";
import charLimiter from "../charLimiter";
import emptyList from "../ui/emptyList";

export default {
  name: 'invoicesList',
  components: {
    emptyList
  },
  data() {
    return {
      sortBy: 'name',
      sortDesc: false,
      data: [],
      category: "",
      filters: [
        [],
        []
      ],
      budgetCategories: [],
      categoriesParents: [],
      currentPage: 1,
      totalItems: '',
      budget: {},
      total: {
        costs: 0,
        value: 0
      },
      fields: [{
          key: 'name',
          label: 'Nazwa kosztu',
          sortable: true
        },
        {
          key: 'category',
          label: 'Nazwa kategorii',
          sortable: true
        },
        {
          key: 'event.name',
          label: 'Nazwa zadania',
          sortable: true
        },
        {
          key: 'value',
          label: 'Wartość netto (PLN)',
          sortable: true
        },
        {
          key: 'actions',
          label: ''
        },
      ],
      categoriesFields: [{
          key: 'name',
          label: 'Nazwa ',
          sortable: true,
          class: 'uk-width-auto'
        },
        {
          key: 'actions',
          label: '',
          class: 'uk-width-expand'
        }
      ],
      buildingId: null
    }
  },
  mixins: [mixin, charLimiter],
  methods: {
    setFilter: function(category) {


      let removed = false
      let vm = this
      this.filters[1].forEach(function(item, key) {
        if (item === category['id']) {
          vm.filters[1].splice(key, 1);
          removed = true
          category._rowVariant = '';
          return
        }
      })
      if (!removed) {
        vm.filters[1].push(category['id'])
        category._rowVariant = 'success';
      }
      this.category = category['id'];
      this.getInvoices();
    },
    getTotal: function() {
      let vm = this
      vm.total.value = 0;
      vm.total.costs = 0;
      vm.budgetCategories.forEach(function(item) {
        vm.total.value += item.value;
        vm.total.costs += item.sum;
      })
    },
    async getInvoices() {
      let vm = this;
      let desc = 'desc';
      if (vm.sortDesc !== true) {
        desc = 'asc';
      }

      var params = {};

      if (this.sortBy !== '') {
        var key = "order[" + this.sortBy + "]";
        params[key] = desc;
      }
      params['page'] = vm.currentPage;
      params['budgetPosition.budget.id'] = this.$route.params.budgetId;
      if (this.filters[1].length !== 0) {
        params['budgetPosition.id'] = this.filters[1];
      }
      if (this.filters[1].length === 0 && this.filters[0].length > 0) {
        let filter = []
        this.filters[0].forEach(function(mainCategory) {
          mainCategory.categories.forEach(function(category) {
            filter.push(category.id)
          })
        });
        params['budgetPosition.id'] = filter
      }

      let res = await axiosInstance
        .get(apiConfig.invoicesApiUrl, {
          params,
          headers: this.$data.headers
        });
      vm.data = res.data['member'];
      vm.totalItems = res.data['totalItems'];
    },
    async get() {
      let vm = this;
      let desc = 'desc';
      if (vm.sortDesc !== true) {
        desc = 'asc';
      }

      var params = {};

      if (this.sortBy !== '') {
        var key = "order[" + this.sortBy + "]";
        params[key] = desc;
      }
      params['page'] = vm.currentPage;
      params['budgetPosition.budget.id'] = this.$route.params.budgetId;
      if (this.category !== '') {
        params['budgetPosition.id'] = this.category;
      }

      let res = await axiosInstance
        .get(apiConfig.invoicesApiUrl, {
          params,
          headers: this.$data.headers
        });
      vm.data = res.data['member'];
      vm.totalItems = res.data['totalItems'];
      res = await axiosInstance
        .get(apiConfig.budgetsApiUrl + "/" + this.$route.params.budgetId, {
          headers: this.$data.headers
        });

      this.budget = res.data;

      this.budgetCategories = [];
      vm.budget.budgetPositions.forEach(function(position) {

        if (position.budgetCategory) {
          if (!vm.budgetCategories[position.budgetCategory.budgetMainCategory.id]) {
            vm.budgetCategories[position.budgetCategory.budgetMainCategory.id] = {
              name: position.budgetCategory.budgetMainCategory.name,
              id: position.budgetCategory.budgetMainCategory.id,
              sum: 0,
              value: 0,
              overBudget: false,
              categories: []
            }
          }
          position.sum = 0;
          vm.budgetCategories[position.budgetCategory.budgetMainCategory.id].categories.push(position);
          vm.budgetCategories[position.budgetCategory.budgetMainCategory.id].value += parseFloat(position.value);
          vm.categoriesParents[position.id] = position.budgetCategory.budgetMainCategory.id;
        } else if (position.budgetMainCategory) {
          if (!vm.budgetCategories[position.budgetMainCategory.id]) {
            vm.budgetCategories[position.budgetMainCategory.id] = {
              name: position.budgetMainCategory.name,
              id: position.budgetMainCategory.id,
              sum: 0,
              value: 0,
              overBudget: false,
              categories: []
            }
          }
          position.sum = 0;
          vm.budgetCategories[position.budgetMainCategory.id].categories.push(position);
          vm.budgetCategories[position.budgetMainCategory.id].value += parseFloat(position.value);
          vm.categoriesParents[position.id] = position.budgetMainCategory.id;
        } else {
          if (!vm.budgetCategories[0]) {
            vm.budgetCategories[0] = {
              name: 'Inne',
              id: null,
              sum: 0,
              value: 0,
              overBudget: false,
              categories: []
            }
          }
          position.sum = 0;
          vm.budgetCategories[0].categories.push(position);
          vm.budgetCategories[0].value += parseFloat(position.value);
          vm.categoriesParents[position.id] = 0;
        }
      })
      vm.data.forEach(function(invoice) {

        vm.budgetCategories[vm.categoriesParents[invoice.budgetPosition.id]].sum += invoice.value;
        vm.budgetCategories[vm.categoriesParents[invoice.budgetPosition.id]].categories.forEach(function(category) {
          if (category.id === invoice.budgetPosition.id) {
            category.sum += invoice.value;
            if (category.sum > category.value) {
              vm.budgetCategories[vm.categoriesParents[invoice.budgetPosition.id]].overBudget = true
            }
          }
        });
      })
      this.getTotal()
      return vm.data;
    },
    getBudgetSubCategories: function(data) {
      let result = [];
      data.categories.forEach(function(category) {
        if (category) {
          result.push(category);
        }
      })
      return result;
    },
    getBudgetCategories: function() {
      let result = [];
      this.budgetCategories.forEach(function(category) {
        if (category) {
          result.push(category);
        }
      });
      return result;
    },
    refresh: async function() {
      await this.get();
      this.$refs.table.refresh();
    },
    toggle: function(data) {
      let vm = this
      if (data.detailsShowing) {
        this.filters[0].forEach(function(item, key) {
          if (item.id === data.item['id']) {
            vm.filters[0].splice(key, 1);
            item.categories.forEach(function(category) {
              vm.filters[1].forEach(function(filter, filterKey) {
                if (filter === category.id) {
                  category._rowVariant = ''
                  vm.filters[1].splice(filterKey, 1);
                }
              })
            })
          }
        })
      } else {
        vm.filters[0].push(data.item)
      }
      data.toggleDetails();
      this.getInvoices()
    },
  },
  mounted: function() {
    this.get();
  }
}
</script>
<style>
.uk-width-expand {
  white-space: nowrap;
}

.row-click {
  cursor: pointer;
  width: 100%;
  display: block;
}

.label-margin-left {
  margin-left: 0.5em;
}

.icon-big {
  font-size: 3em
}
</style>
