<template>
    <div>
        <vk-grid class="uk-margin-bottom">
            <h3 class="uk-width-expand">Budżety
            </h3>
            <button @click="add"
                    class="uk-button wpip-button uk-width-auto"
            ><vk-icon icon="plus-circle"></vk-icon> Dodaj budżet
            </button>
        </vk-grid>
        <budget-modal ref="modal" v-on:refresh="refresh"/>
        <copy-modal ref="copyModal" v-on:refresh="refresh"/>
        <budgets-filters ref="filters" v-if="showFilters" @filter="filter" :filters="filters"></budgets-filters>
        <vk-card>
            <empty-list v-if="totalItems===0"/>
            <b-table v-else
                    :columns="fields" :current-page="currentPage" :fields="fields"
                    :items="data"
                    :per-page="this.$data.perPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    borderedless
                    no-sort-reset
                    :responsive="true"
                    hover
                    ref="table">

                <template v-slot:head(actions)="data">
                    <span class="uk-icon-link" uk-tooltip="Filtruj"><vk-icon class="hover" icon="settings" @click="toggleFilters()"/></span><span class="uk-badge uk-margin-small-left">{{filterNum}}</span>
                </template>
                <template v-slot:cell(name)="data">
                    <span :uk-tooltip="data.item.name">{{ limit(data.item,100) }}</span>
                </template>
                <template v-slot:cell(fromDate)="data">
                    {{new Date(data.item.fromDate).getMonth()+1}}.{{new Date(data.item.fromDate).getFullYear()}}
                </template>
                <template v-slot:cell(status)="data">
                    <span class="uk-label label-margin-left uk-label-danger" v-if="data.item.status">PRZEKROCZONO PLAN</span>
                    <span class="uk-label label-margin-left uk-label-success" v-else>OK</span>
                </template>
                <template v-slot:cell(actions)="data">
                    <budgets-actions v-on:edit="edit" @copy="copy" :datatable=data v-on:refresh="refresh"/>
                </template>
            </b-table>
            <b-pagination v-if="totalItems>perPage" size="sm" page-class="wpip-page" class="wpip-page" :per-page="perPage"
                        :total-rows="totalItems" align="center" v-model="currentPage"/>
            <p>
                <file-download ref="download"/>
                Dane budżetów (
                <span class="download" v-on:click="downloadXlsx">.xlsx</span>)
            </p>
        </vk-card>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import BudgetsActions from "./budgetsActions";
    import axiosInstance from "../../axiosInstance";
    import BudgetModal from "./budgetModal";
    import EmptyList from "../ui/emptyList";
    import charLimiter from "../charLimiter";
    import CopyModal from "./copyModal";
    import BudgetsFilters from "./budgetsFilters";
    import FileDownload from "../ui/fileDownload";

    export default {
        name: 'budgetsList',
        components: {
            FileDownload,
            BudgetsFilters,
            CopyModal,
            EmptyList,
            BudgetModal,
            budgetsActions: BudgetsActions
        },
        data() {
            return {
                sortBy: 'fromDate',
                sortDesc: true,
                data: [],
                filters: {
                    buildings: [],
                    beginDate: "",
                    endDate: "",
                    status: [false, false],
                    minValue: null,
                    maxValue: null,
                    minCosts: null,
                    maxCosts: null,
                },
                showFilters: false,
                filterNum: 0,
                currentPage: 1,
                totalItems: '',
                fields: [
                    {key: 'name', label: 'Nazwa', sortable: true},
                    {key: 'building.name', label: 'Obiekt'},
                    {key: 'fromDate', label: 'Okres', sortable: true},
                    {key: 'value', label: 'Plan Netto (PLN)'},
                    {key: 'costs', label: 'Koszty Netto (PLN)'},
                    {key: 'status', label: 'Status'},
                    {key: 'actions', label: ''},
                ],
                buildingId: null
            }
        },
        mixins: [mixin, charLimiter],
        methods: {
            downloadXlsx: function () {
                this.$refs.download.get("/budgets/xlsx", "budżety.xlsx", this.filters);
            },
            filter(filters){
                this.filters = filters
                filters = {...filters}
                let buildings=[];
                let filterNum=0
                if(filters.buildings.length>0){
                    filterNum++
                }
                filters.buildings.forEach(function (building) {
                    buildings.push(building['@id'])
                })
                filters.building = buildings
                delete filters.buildings
                if(filters.endDate.toLocaleString()!==""){
                    filters['fromDate[before]']=filters.endDate.toLocaleString()
                    filterNum++
                }
                if(filters.beginDate.toLocaleString()!==""){
                    filters['fromDate[after]']=filters.beginDate.toLocaleString()
                    filterNum++
                }
                if(filters.minValue){
                    filters['value[gte]'] = filters.minValue
                    filterNum++
                }
                if(filters.maxValue){
                    filters['value[lte]'] = filters.maxValue
                    filterNum++
                }
                if(filters.minCosts){
                    filters['costs[gte]'] = filters.minCosts
                    filterNum++
                }
                if(filters.maxCosts){
                    filters['costs[lte]'] = filters.maxCosts
                    filterNum++
                }
                if(filters.status[0]!==filters.status[1]){
                    filterNum++
                    if(filters.status[0]){
                        filters.status = false
                    }
                    else if(filters.status[1]){
                        filters.status = true
                    }
                }

                delete filters.beginDate
                delete filters.endDate
                delete filters.minValue
                delete filters.maxValue
                delete filters.minCosts
                delete filters.maxCosts
                this.filterNum = filterNum
                this.get(filters)
            },
            async get(filters={}) {
                let vm = this;
                let desc = 'desc';
                if (vm.sortDesc !== true) {
                    desc = 'asc';
                }

                let params = filters;

                if (this.sortBy !== '') {
                    var key = "order[" + this.sortBy + "]";
                    params[key] = desc;
                }
                params['page'] = vm.currentPage;
                params['building.id'] = this.$route.params.buildingId;

                let res = await axiosInstance
                    .get(apiConfig.budgetsApiUrl,
                        {
                          params,
                            headers: this.$data.headers
                        });
                vm.data = res.data['member'];
                vm.totalItems = res.data['totalItems'];
                return vm.data;
            },
            refresh: async function (budget) {
                await this.get();
                if (this.$refs.table) {
                    this.$refs.table.refresh();
                }
                if(budget) {
                    let vm = this
                    await axiosInstance
                        .get(apiConfig.budgetsApiUrl + "/" + budget.id,
                            {
                                headers: vm.$data.headers
                            }).then(function (result) {
                            vm.$refs.modal.showModal(result.data)
                        });
                }
            },
            add: function () {
                this.$refs.modal.showModal(null);
            },
            edit: function (budget) {
                this.$refs.modal.showModal({...budget});
            },
            copy: function (budget) {
                this.$refs.copyModal.showModal({...budget});
            },
            toggleFilters: function(){
                this.showFilters=!this.showFilters
            }
        },
        mounted: function () {
            this.get();
        }
    }
</script>
<style scoped>
    .download:hover{
        cursor: pointer;
        text-decoration: underline;
    }
</style>
