<template>
    <div class="text-right">
        <a
                @click.prevent="editContractor()"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Edytuj kontrahenta"
        >
            <vk-icon class="hover" icon="pencil"/>
        </a>
        <a
                @click.prevent="$router.push({'name':'contractorContacts', 'params':{'contractorId': datatable.item.id}})"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Kontakty"
        >
            <vk-icon class="hover" icon="user"/>
        </a>
        <a
                @click.prevent="deleteContractor()"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Usuń kontrahenta"
        >
            <vk-icon class="hover" icon="trash"/>
        </a>
        <remove ref="remove" v-on:refresh="$emit('refresh')"/>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import UIkit from 'uikit';
    import remove from "../ui/remove";

    export default {
        name: 'contractorsActions',
        components: {
            remove
        },
        mixins: [mixin],
        props: {
            datatable: Object
        },
        methods: {
            editContractor: function () {
                this.$emit("edit", this.datatable.item);
            },
            deleteContractor: function () {
                if (this.datatable.item.buildings.length > 0) {
                    UIkit.modal.alert('Nie można usunąć kontrahenta posiadającego przypisane budynki');
                } else {
                    this.$refs.remove.remove(apiConfig.contractorsApiUrl + "/" + this.datatable.item.id, "kontrahenta");
                }
            }
        }
    }
</script>
