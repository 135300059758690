<template>
    <admin ref="admin">
        <div class="uk-container-expand uk-padding">
            <vk-grid>
                <sub-menu/>
                <div class="uk-width-expand@m">
                    <div>
                        <dashboards-list ref="list"/>
                    </div>
                </div>
            </vk-grid>
        </div>

    </admin>

</template>

<script>

    import mixin from "../../components/mixin";
    import DashboardsList from "../../components/dashboard/dashboardsList"
    import SubMenu from "@/components/ui/subMenu";

    export default {
        name: 'dashboards',
        components: {
          SubMenu,
            DashboardsList
        },
        mixins: [mixin],
        data() {
            return {
                sortBy: 'name',
                sortDesc: false,
                data: [],
                currentPage: 1,
                ability: null,
                totalItems: '',
                fields: [
                    {key: 'name', label: 'Imię', sortable: true},
                    {key: 'actions', label: ''},
                ]
            }
        },
        async mounted() {
            this.ability = await this.$ability.getAbility();
            if (!this.ability.can('manage', 'ROLE_BMS') && !this.ability.can('manage', 'ROLE_SUPER_ADMIN')) {
                await this.$router.push({name: 'not-allowed'});
            }
        }
    }
</script>
