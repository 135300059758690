<template>
    <span>
            <a
                    @click.prevent="editActivity(datatable.item)"
                    class="uk-icon-button"
                    href="#"
                    uk-tooltip="Zmień działanie"
            >
                <vk-icon class="hover" icon="file-edit"/>
            </a>
            <a
                    @click.prevent="removeActivity(datatable.item)"
                    class="uk-icon-button"
                    href="#"
                    uk-tooltip="Usuń działanie"
            >
                <vk-icon class="hover" icon="trash"/>
            </a>
            <remove ref="remove" v-on:refresh="$emit('refresh')"/>
    </span>
</template>

<script>

    import mixin from "../../components/mixin";
    import apiConfig from "../../apiConfig";
    import Remove from "../ui/remove";

    export default {
        name: 'goalsActions',
        components: {Remove},
        mixins: [mixin],
        props: {
            datatable: Object
        },
        methods: {
            editActivity(activity) {
                this.$emit('edit', activity);
            },
            removeActivity(activity) {
                this.$refs.remove.remove(apiConfig.zpcActivitiesApiUrl + "/" + activity.id, "działanie");
            }

        }
    }
</script>
