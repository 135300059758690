<template>
    <span class="rating" ref="rating">
        <span class="wpip-star" v-bind:key="star" v-for="star in maxRate" v-on:click="clickedStar"
              v-on:mouseleave="normalStars" v-on:mouseover="highlightStars"  :uk-tooltip="star"><span class="star-cell">{{star}}</span></span>
    </span>
</template>

<script>

    import mixin from "../mixin";

    export default {
        name: 'starRating',
        mixins: [mixin],
        props: {
            maxRate: null,
            value: null,
            blocked: Boolean,
        },
        methods: {
            setInitialStars: function (el, stars = 0) {
                let star = this.$el.firstChild;
                for (let i = 0; i < stars; i++) {
                    star.className += ' highlighted-star clicked-star';
                    star = star.nextSibling;
                }

            },
            highlightStars: function (e) {
                if (!this.blocked) {
                    e.currentTarget.className += ' highlighted-star';
                    let previousSibling = e.currentTarget.previousSibling;
                    while (previousSibling != null) {
                        previousSibling.className += ' highlighted-star';
                        previousSibling = previousSibling.previousSibling;
                    }
                }
            },
            normalStars: function (e) {
                if (!this.blocked) {
                    let current = e.currentTarget;
                    while (current != null && current.className.search('clicked-star') === -1) {
                        current.className = 'wpip-star';
                        current = current.previousSibling;
                    }
                }
            },
            clickedStar: function (e) {
                if (!this.blocked) {
                    let rate = 0;
                    let current = e.currentTarget.parentElement.lastChild;
                    while (current != null) {
                        current.className = 'wpip-star';
                        current = current.previousSibling;
                    }
                    current = e.currentTarget;
                    while (current != null) {
                        current.className += ' highlighted-star clicked-star';
                        rate++;
                        current = current.previousSibling;
                    }
                    this.unclickedStars(e);
                    this.$emit('rated', {key: this.$attrs.id, rate: rate});
                }
            },
            unclickedStars: function (e) {
                let current = e.currentTarget.parentElement.lastChild;
                while (current != null && current.className.search('clicked-star') === -1) {
                    current.className = 'wpip-star';
                    current = current.previousSibling;
                }
            }
        },
        mounted() {
            if (this.blocked !== true) {
                this.blocked = false;
            }
            this.setInitialStars(null, this.value);
        },
        watch: {
            value: function (value) {
                let star = this.$el.firstChild;
                for (let i = 0; i < this.$props.maxRate; i++) {
                    star.className = 'wpip-star';
                    star = star.nextSibling;
                }
                this.setInitialStars(null,value);
            }
        }
    }
</script>
<style scoped>
    .wpip-star {
        margin-left: 4px;
        font-size: 0.8em;
        width:2em;
        padding-top: 0;
        height: 2em;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        border: 1px solid #bf0e01;
        background-color: transparent;
        color:  #bf0e01;
        border-radius: 3px;
        cursor: pointer;
    }

    .highlighted-star{
        background-color: #bf0e01;
        color: white;
    }

    .star-cell{
        height: 1.8em;
        width:2em;
        text-align: center;
        display: table-cell;
        vertical-align: middle;
    }

    .highlighted-star:hover{
        background-color: #ec281b;
    }

</style>
