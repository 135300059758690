<template>
    <div id="w-filters" uk-grid>
        <form class="uk-form-horizontal uk-width-1-1">
            <fieldset class="uk-fieldset">
                <legend class="uk-legend">Filtruj</legend>
                <div class="uk-margin">
                    <vk-grid>
                        <label class="uk-form-label w-label">Budynek</label>

                        <div class="uk-inline uk-width-expand">
                            <span class="uk-form-icon uk-form-icon-flip" uk-icon="" ></span>
                            <v-select multiple :filterable="false" :options="buildings" @search="searchBuildings"
                                      label="@id" :class="'wpip-select'" v-model="filter.buildings"
                                      data-vv-as="budynki" ref="multiSelectBuilding"
                                      name="buildings" v-validate="'required'">

                                <template slot="option" slot-scope="option">
                                    <div class="d-center">
                                        {{ option.name }}
                                    </div>
                                </template>

                                <template slot="selected-option" slot-scope="option">
                                    <div class="selected d-center">
                                        {{ option.name }}
                                    </div>
                                </template>

                                <template v-slot:no-options="{ search, searching }">
                                    <template v-if="searching">
                                        Nie znaleziono <em>{{ search }}</em>.
                                    </template>
                                    <em style="opacity: 0.5;" v-else>Wyszukaj budynek</em>
                                </template>
                            </v-select>
                        </div>
                    </vk-grid>
                </div>
                <div class="uk-margin">
                    <vk-grid>
                        <label class="uk-form-label w-label">Suma należności</label>
                        <div class="uk-inline uk-width-expand">
                            <span class="uk-form-icon uk-form-icon-flip" uk-icon="">PLN</span>
                            <input class="uk-input" type="text" v-model="filter.minValue" placeholder="od">
                        </div>
                        <div class="uk-inline uk-width-expand">
                            <span class="uk-form-icon uk-form-icon-flip" uk-icon="">PLN</span>
                            <input class="uk-input" type="text" v-model="filter.maxValue" placeholder="do">
                        </div>
                    </vk-grid>
                </div>
                <div class="uk-margin">
                    <vk-grid>
                        <label class="uk-form-label w-label">Powierzchnia</label>
                        <div class="uk-inline uk-width-expand">
                            <span class="uk-form-icon uk-form-icon-flip" uk-icon="">m2</span>
                            <input class="uk-input" type="text" v-model="filter.minArea" placeholder="od">
                        </div>
                        <div class="uk-inline uk-width-expand">
                            <span class="uk-form-icon uk-form-icon-flip" uk-icon="">m2</span>
                            <input class="uk-input" type="text" v-model="filter.maxArea" placeholder="do">
                        </div>
                    </vk-grid>
                </div>
                <div class="uk-margin">
                    <vk-grid>
                        <label class="uk-form-label w-label">Data wymagalności</label>
                        <datepicker :format="'dd.MM.yyyy'" :input-class="'uk-input'" class="uk-width-expand"
                                    :language="pl" data-uk-datepicker="{format:'dd.MM.YYYY'}"
                                    name="fromDate" type="text" v-model="filter.beginDate"  placeholder="od">
                          <span slot="afterDateInput" class="clear-icon" @click.prevent="filter.beginDate=''"><vk-icon icon="close"></vk-icon></span>
                        </datepicker>

                        <datepicker :format="'dd.MM.yyyy'" :input-class="'uk-input'" class="uk-width-expand"
                                    :language="pl" data-uk-datepicker="{format:'dd.MM.YYYY'}"
                                    name="fromDate" type="text" v-model="filter.endDate"  placeholder="do">
                          <span slot="afterDateInput" class="clear-icon" @click.prevent="filter.endDate=''"><vk-icon icon="close"></vk-icon></span>
                        </datepicker>
                    </vk-grid>
                </div>
                <div class="uk-margin">
                    <label class="uk-form-label w-label">Status należności</label>
                    <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                        <label><input class="uk-checkbox" type="checkbox" v-model="filter.status[0]"> OK</label>
                        <label><input class="uk-checkbox" type="checkbox" v-model="filter.status[1]"> zaległe</label>
                        <label><input class="uk-checkbox" type="checkbox"  v-model="filter.status[2]"> zawieszony</label>
                    </div>
                </div>
                <div class="uk-margin uk-align-right">
                    <button class="uk-button wpip-button uk-width-auto" @click.prevent="$emit('filter', filter)">Filtruj</button>
                </div>
            </fieldset>
        </form>
    </div>
</template>

<script>
    import mixin from "../../components/mixin";
    import charLimiter from "../charLimiter";
    import {pl} from "vuejs-datepicker/dist/locale";
    import axiosInstance from "../../axiosInstance";
    import apiConfig from "../../apiConfig";
    import Datepicker from 'vuejs-datepicker';

    export default {
        name: "rentersFilters",
        components: {
            Datepicker
        },
        data() {
            return {
                pl: pl,
                buildings: [],
                filter: {
                    buildings: [],
                    beginDate: "",
                    endDate: "",
                    status: [],
                    minValue: null,
                    maxValue: null,
                    minArea: null,
                    maxArea: null,
                }
            };
        },
        methods: {
            searchBuildings: function (e) {
                let vm = this;
                clearTimeout(this.searchTimeout)
                this.searchTimeout = setTimeout(function () {
                    vm.getBuildings(e)
                }, 500);
            },
            setFilters: function(filter){
                this.filter = filter
            },
            getBuildings: function (e) {
                let vm = this;
                var params = {};
                params['name'] = e;
                axiosInstance
                    .get(apiConfig.buildingsApiUrl, {
                        params,
                        headers: this.$data.headers
                    })
                    .then(function (response) {
                        vm.buildings = response.data['member'];
                    });
            }
        },
        mounted: function () {
            this.searchBuildings("")
            this.filters = this.$attrs.filters
        },
        mixins: [mixin, charLimiter]
    };
</script>

<style scoped>
    .uk-accordion li {
        list-style-type: none;
    }

    .uk-accordion-title {
        color: black;
        font-size: 1.3em;
    }

    .uk-accordion {
        padding-left: 0;
    }
</style>
