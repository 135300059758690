<template>
    <span>
        <a
                @click.prevent="editFmea(datatable.item)"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Edytuj analizę FMEA"
        >
            <vk-icon class="hover" icon="pencil"/>
        </a>
        <a
                @click.prevent="analysis(datatable.item.id)"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Przejdź do analizy FMEA"
        >
            <vk-icon class="hover" icon="search"/>
        </a>
        <a
                @click.prevent="deleteFmea(datatable.item.id)"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Usuń analizę FMEA"
        >
            <vk-icon class="hover" icon="trash"/>
        </a>
        <remove ref="remove" v-on:refresh="$emit('refresh')"/>
    </span>
</template>

<script>

    import mixin from "../../components/mixin";
    import UIkit from 'uikit';
    import apiConfig from "../../apiConfig";
    import remove from "../ui/remove";

    export default {
        name: 'fmeasActions',
        components: {
            remove
        },
        mixins: [mixin],
        props: {
            datatable: Object
        },
        methods: {
            editFmea(fmea) {
                this.$emit('edit', fmea)
            },
            deleteFmea(fmeaId) {
                if (this.datatable.item.fmeaElements.length > 0) {
                    UIkit.modal.alert('Nie można usunąć analizy posiadającej elementy');
                } else {
                    this.$refs.remove.remove(apiConfig.fmeasApiUrl + "/" + fmeaId, "analizę FMEA");
                }
            },

            analysis(fmeaId) {
                this.$router.push('/buildings/' + this.$route.params.buildingId + '/fmeas/' + fmeaId + '/analysis')
            }

        }
    }
</script>
