<template>
  <div class="uk-flex-top uk-modal-container" container=".admin" id="wpip-goal-preview" uk-modal>
    <div class="uk-modal-dialog uk-margin-auto-vertical">
      <button class="uk-modal-close-default" type="button" uk-close></button>
      <div class="uk-modal-body">
        <h3>{{ modal.title }}</h3>
        <table class="table">
          <tr>
            <th>Cel</th>
            <td>{{ goal.name }}</td>
          </tr>
          <tr>
            <th>Termin realizacji</th>
            <td>{{ goal.realization }}</td>
          </tr>
          <tr>
            <th>Mierniki realizacji</th>
            <td>{{ goal.kpis }}</td>
          </tr>
          <tr>
            <th>Benefit - cel akceptowalny</th>
            <td>{{ getBenefit(1) }}</td>
          </tr>
          <tr>
            <th>Benefit - cel oczekiwany</th>
            <td>{{ getBenefit(2)  }}</td>
          </tr>
        </table>




      </div>


      <div class="uk-modal-footer uk-text-right">
        <button @click="cancel" class="uk-button uk-button-default uk-modal-close" type="button">Zamknij</button>
      </div>
    </div>
  </div>
</template>

<script>

import mixin from "../mixin";
import UIkit from "uikit";
import {pl} from "vuejs-datepicker/src/locale";
import Perspectives from "../../constants/goalPerspectives"

export default {
  name: 'goalPreviewModal',
  data() {
    return {
      pl: pl,
      filterValue: "",
      responsible: [],
      perspectives: Perspectives.PERSPECTIVES,
      goal: {
        id: null,
        name: "",
        perspective: null
      },
      modal:
          {
            title: "Opis celu"
          },

    }
  },
  mixins: [mixin],
  methods: {
    showModal: function (goal) {
      this.goal = goal
      this.$validator.reset();

      UIkit.modal("#wpip-goal-preview").show();
    },
    getBenefit(level) {
      let label = ""
      if(this.goal.goalBenefits) {
        this.goal.goalBenefits.forEach(function (item) {
          if (item.realizationLevel === level) {
            let cash;
            let bonus = item.benefit.bonus;
            if (item.benefit.cash === "" || item.benefit.cash == null) {
              cash = "brak";
            } else {
              cash = item.benefit.cash + "%";
            }
            if (item.benefit.bonus === "" || item.benefit.bonus == null) {
              bonus = "brak";
            }
            label = cash + " | " + bonus;
          }
        });
        if (label === "") {
          label = "brak";
        }
      }
      return label;
    },
    cancel: function () {
      this.$emit('refresh');
    }
  }
}
</script>
<style scoped>
.correlation-header {
  font-size: 1.3em;
}
</style>
