<template>
    <div class="uk-flex-top uk-modal-container" container=".admin" id="wpip-parameters" uk-modal>
        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-modal-body">
                <h3>{{ modal.title }}</h3>
                <empty-list v-if="!property.parameters || property.parameters.length===0"/>
                <table v-else class="uk-table" ref="table" :key="key">
                    <tr>
                        <th></th>
                        <th>Poziom korelacji wymogu i parametru</th>
                    </tr>
                    <tr v-bind:key="parameter.id" v-for="(parameter,index) in property.parameters">
                        <td>{{ getParameter(parameter.id).name}}</td>
                        <td>
                            <star-rating @rated="changeRate" :id="parameter.relationId" :blocked="false" :maxRate="10" :value="parameter.weight"/>
                        </td>
                        <td>
                            <technical-parameters-list-actions :datatable="parameter" :index="index"
                                                               v-on:refresh="deletedRelation"/>
                        </td>
                    </tr>
                </table>

            </div>
        </div>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import UIkit from "uikit";
    import StarRating from "../ui/starRating";
    import TechnicalParametersListActions from './technicalParametersListActions'
    import EmptyList from "../ui/emptyList";
    import axiosInstance from "../../axiosInstance";
    import apiConfig from "../../apiConfig";

    export default {
        name: 'technicalParameterListModal',
        components: {EmptyList, StarRating, TechnicalParametersListActions},
        props:{
        },
        data() {
            return {
                filterValue: "",
                modalDom: null,
                parameter: null,
                key: 0,
                propertyIndex: 0,
                property: {},
                modal:
                    {
                        title: "Lista parametrów"
                    },

            }
        },
        mixins: [mixin],
        methods: {
            showModal: function (property, index=null) {
                this.propertyIndex = index;
                this.property = property;
                this.modalDom.show();
            },
            changeRate: function(data){
                let vm = this
                // eslint-disable-next-line no-console
                let postData = {
                    weight: data.rate,
                };
                axiosInstance
                    .put(apiConfig.qfdPropertyParametersApiUrl + "/" + data.key, JSON.stringify(postData), {
                        headers: vm.$data.headers
                    });
            },
            getParameter: function(id){
                let result = null;
                this.$attrs.parameters.forEach(function (parameter) {
                    if(parameter.id === id){
                        result = parameter;
                        return false;
                    }
                });
                return result;
            },
            deletedRelation: function(e){
                //delete this.property.parameters[e];
                this.key++;
                this.$emit('refreshRelations',{property: this.propertyIndex, parameter:e});
            }
        },
        mounted() {
            this.modalDom = UIkit.modal('#wpip-parameters');
        }

    }
</script>
<style scoped>
    .correlation-header {
        font-size: 1.3em;
    }
</style>
