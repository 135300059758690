<template>
  <div class="uk-width-1-6@m">

    <vk-nav v-if="building.isExploatation && (can('manage', 'ROLE_SUPER_ADMIN') || can('manage', 'ROLE_EXPLOATATION'))">
      <li class="uk-nav-header">Eksploatacja</li>
      <li>
        <router-link :to="{ name: 'budgets' }" :class="{current: $route.name==='budgets'}"><vk-icon icon="credit-card"></vk-icon> Budżety</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'calendar' }" :class="{current: $route.name==='calendar'}"><vk-icon icon="calendar"></vk-icon> Kalendarz</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'budgetTemplates' }" :class="{current: $route.name==='budgetemplates'}"><vk-icon icon="copy"></vk-icon> Szablony Budżetów</router-link>
      </li>
      <vk-nav-item-divider title></vk-nav-item-divider>
    </vk-nav>
    <vk-nav v-if="building.isDevice && (can('manage', 'ROLE_SUPER_ADMIN') || can('manage', 'ROLE_DEVICES') || can('manage', 'ROLE_BMS'))">
      <li class="uk-nav-header">Urządzenia</li>
      <li>
        <router-link :to="{ name: 'dashboards'}" :class="{current: $route.name==='dashboards'}"><vk-icon icon="grid"></vk-icon> Dashboardy</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'middlewares', params: {'buildingId': buildingId} }" :class="{current: $route.name==='middlewares'}"><vk-icon icon="server"></vk-icon> Middleware</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'devices', params: {'buildingId': buildingId} }" :class="{current: $route.name==='devices'}"><vk-icon icon="play-circle"></vk-icon> Urządzenia</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'registers' }" :class="{current: $route.name==='registers'}"><vk-icon icon="list"></vk-icon> Rejestry</router-link>
      </li>
      <vk-nav-item-divider title></vk-nav-item-divider>
    </vk-nav>
    <vk-nav v-if="building.isRental && (can('manage', 'ROLE_SUPER_ADMIN') || can('manage', 'ROLE_RENTAL'))">
      <li class="uk-nav-header">Wynajem</li>

      <li>
        <router-link :to="{'name':'rentalDashboard'}" :class="{current: $route.name==='rentalDashboard'}"><vk-icon icon="check"></vk-icon> Rozliczenia</router-link>
      </li>
      <li>
        <router-link :to="{'name':'renters'}" :class="{current: $route.name==='renters'}"><vk-icon icon="users"></vk-icon> Najemcy</router-link>
      </li>
      <li>
        <router-link :to="{'name':'rentalObjects'}" :class="{current: $route.name==='rentalObjects'}"><vk-icon icon="home"></vk-icon> Obiekty</router-link>
      </li>
    </vk-nav>
  </div>
</template>

<script>

export default {
  name: 'sub-menu',
  data: function () {
    return {
      ability: null,
      buildingId: JSON.parse(localStorage.getItem('currentBuilding')).id,
      building: JSON.parse(localStorage.getItem('currentBuilding'))
    }
  },
  methods: {
    async can(right, role) {
      if (!this.ability) {
        this.ability = await this.$ability.getAbility();
      }
      return this.ability.can(right, role)
    },
  }
}

</script>
<style scoped>
  .current{
    color: #666;
  }
</style>