<template>
    <admin>
        <div class="wpip-qfd">
            <div class="uk-container-expand" id="ias-content">
                <qfd-analyses-list ref="table" v-on:edit="editFmea"/>
                <fmea-details-modal ref="modal" v-on:modified="refresh"/>
            </div>

            <div class="uk-container uk-padding">
                <router-link :to="{ name: 'qfds', params: { buildingId: $route.params.buildingId } }">
                    <button
                            class="uk-button wpip-button uk-width-auto uk-margin-small-right"
                    >Wstecz
                    </button>
                </router-link>
                <router-link :to="{ name: 'tools', params: { buildingId: $route.params.buildingId } }">
                    <button class="uk-button wpip-button">Lista metod</button>
                </router-link>
            </div>
        </div>
    </admin>
</template>

<script>
    import mixin from "../../components/mixin";
    import FmeaDetailsModal from "../../components/fmea/fmeaDetailsModal";
    import QfdAnalysesList from "../../components/qfd/qfdAnalysesList";

    export default {
        name: 'qfds',
        components: {
            'qfdAnalysesList': QfdAnalysesList,
            'fmeaDetailsModal': FmeaDetailsModal
        },
        mixins: [mixin],
        data() {
            return {
                sortBy: 'name',
                sortDesc: false,
                data: [],
                currentPage: 1,
                totalItems: '',
                showDetails: false,
                buildingId: null
            }
        },
        methods: {
            addNewFmea: function () {
                this.$refs.modal.showModal(null);
            },
            editFmea: function (fmea) {
                this.$refs.modal.showModal(fmea);
            },
            refresh: function () {
                this.$refs.table.refresh();
            }

        },
        watch: {
            currentPage: {
                handler: function () {
                    this.get().catch(() => {
                    })
                }
            }
        },
        async mounted() {
            await this.$ability.getAbility();
            if (!this.$ability.checkManageIasAbility('QFD')) {
                await this.$router.push({name: 'not-allowed'});
            }
        }
    }
</script>
