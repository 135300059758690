<!--suppress HtmlFormInputWithoutLabel -->
<template>
    <div class="uk-flex-top uk-modal-container" container=".admin" id="wpip-rating-add" uk-modal>
        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close/>
            <div class="uk-modal-body">
                <h3>{{ modal.title }}</h3>
                <legend class="uk-legend">Informacje podstawowe</legend>

                <div class="uk-margin">
                    <label class="uk-form-label" for="name">Nazwa</label>
                    <div class="uk-form-controls">
                        <input
                                disabled=disabled
                                class="uk-input"
                                id="name"
                                type="text"
                                v-model="improvement.name"
                        >
                    </div>
                </div>

                <div class="uk-margin">
                    <label class="uk-form-label" for="area">Obszar usprawnienia</label>
                    <div class="uk-form-controls">
                        <select class="uk-select" disabled="true" id="area" v-model="improvement.iasPhase">
                            <option hidden/>
                            <option v-bind:key="'phase_'+iasPhase['id']" :value="iasPhase['@id']" v-for="iasPhase in iasPhases">{{iasPhase.name}}</option>
                        </select>
                    </div>
                </div>

                <div class="uk-margin">
                    <label class="uk-form-label" for="scope">Branża usprawnienia</label>
                    <div class="uk-form-controls">
                        <select class="uk-select" disabled="true" id="scope" v-model="improvement.industry">
                            <option hidden/>
                            <option v-bind:key="'industry_'+industry['id']" :value="industry['@id']" v-for="industry in industries">{{industry.name}}</option>
                        </select>
                    </div>
                </div>

                <div class="uk-margin">
                    <label class="uk-form-label" for="problemDescription">Identyfikacja i opis problemu</label>
                    <div class="uk-form-controls">
                <textarea
                        class="uk-textarea"
                        disabled=disabled
                        id="problemDescription"
                        rows="3"
                        v-model="improvement.problemDescription"
                />
                    </div>
                </div>

                <div class="uk-margin">
                    <label class="uk-form-label" for="description">Opis propozycji rozwiązania problemu i oczekiwane rezultaty</label>
                    <div class="uk-form-controls">
                <textarea
                        class="uk-textarea"
                        disabled=disabled
                        id="description"
                        rows="5"
                        v-model="improvement.description"
                />
                    </div>
                </div>

                <div class="uk-margin">
                      <label class="uk-form-label">Weryfikacja osiągniętych rezultatów po wdrożeniu</label>
                      <div class="uk-form-controls">
                  <textarea
                          class="uk-textarea"
                          disabled=disabled
                          id="anticipatedResults"
                          rows="5"
                          v-model="improvement.anticipatedResults"
                  />
                      </div>
                  </div>

                <div v-if="improvement.improvementFiles.length>0">
                    <label class="uk-form-label" >Pliki:</label>
                    <ul>
                        <li v-bind:key="file.id" v-for="file in improvement.improvementFiles">
                            <span class="open-file" v-on:click="downloadFile(file)">{{ file.filename }}</span>
                        </li>
                    </ul>
                    <file-download ref="download"/>
                </div>

                <legend class="uk-legend">Ocena proponowanego usprawnienia - ocenia zespół powołany przez Lidera
                    Kaizen
                </legend>
                <div
                        :key="'rg'+index"
                        class="uk-grid uk-margin-top"
                        uk-grid
                        v-for="(group, index) in categories"
                >
                    <div class="uk-width-1-1">
                        <h4 style="border-bottom: 1px solid grey;">{{ group.name }}</h4>
                    </div>
                    <div
                            :key="'rc'+cIndex"
                            uk-grid
                            v-bind:category-id="category.id"
                            v-bind:class="'uk-width-1-1 rating-category rating-category-' + category.id"
                            v-for="(category, cIndex) in group.ratingCategories"
                    >
                        <div class="uk-width-1-1">{{ category.name }} <span
                                class="uk-margin-small-left uk-badge wpip-badge"
                        >{{ improvement.rates[category.id] ? improvement.rates[category.id].rate : 0 }}</span></div>
                        <!--
                        <div class="uk-width-auto stars-number"
                             v-bind:category-id="category.id">
                            <star-rating class="uk-width-expand" :id="category.id" :maxRate="5" :value="improvement.rates[category.id].rate" v-on:rated="setRate"/>

                        </div>
                        -->
                        <div class="uk-width-1-3 stars-number"
                             v-bind:category-id="category.id" v-if="improvement.rates[category.id]">
                            <input
                                    class="uk-range"
                                    data-vv-as="znaczenie wady dla klienta"
                                    max="5"
                                    min="0"
                                    name="clientValue"
                                    step="1"
                                    type="range" v-model="improvement.rates[category.id].rate" v-validate="'required|min_value:1'"
                                    value="2"
                            />
                        </div>
                        <div class="uk-width-1-1"  v-if="category.numericField===true && improvement.rates[category.id]">
                            <label v-bind:for="'value-'+category.id">Wartość</label><br/>
                            <input class="uk-input" type="text" :name="'value['+category.id+']'" v-model="improvement.rates[category.id].value" v-bind:id="'value-'+category.id" data-vv-as="wartość"
                                   v-validate="'min_value:0|cash'" @input="replace(category.id)">

                            <span class="validation-error">{{ errors.first('value['+category.id+']') }}</span>
                        </div>
                        <div class="uk-width-1-1" v-if="improvement.rates[category.id]">
                            <label v-bind:for="'comment-'+category.id">Uwagi</label>
                            <div class="uk-form-controls">
                                <textarea @change="changeNotes" class="uk-textarea rate-comment" placeholder="Uwagi ..."
                                          v-bind:category-id="category.id"
                                          v-model="improvement.rates[category.id].notes"
                                          rows="3"
                                          v-bind:id="'comment-'+category.id"/>
                            </div>
                        </div>
                    </div>
                </div>
                <h4 style="border-bottom: 1px solid grey;">Decyzja o wdrożeniu
                </h4>
                <label><input class="uk-radio" name="decision" :value="1" type="radio" v-model="improvement.decision"> Tak</label><br/>
                <label><input class="uk-radio" name="decision" :value="2"  type="radio" v-model="improvement.decision"> Nie</label><br/>
                <label><input class="uk-radio" name="decision" :value="3" type="radio" v-model="improvement.decision"> Odłóż w czasie</label><br/>
                <div class="uk-margin" v-if="improvement.decision===3">
                    <datepicker :format="'dd.MM.yyyy'" :input-class="'uk-input'"
                                :language="pl"
                                data-vv-as="data" name="decisionDate"
                                ref="decisionDate" type="date" v-model="improvement.decisionDate"/>
                    <span class="validation-error">{{ errors.first('decisionDate') }}</span>
                </div>
                <div class="uk-width-1-1">
                    <label v-bind:for="'comment'">Uwagi</label>
                    <div class="uk-form-controls">
                                <textarea class="uk-textarea" placeholder="Uwagi ..."
                                          v-model="improvement.decisionComment"
                                          rows="5"/>
                    </div>
                </div>
              <div v-if="improvement.decision===1">
                <div class="uk-margin">
                  <label class="uk-form-label">Dodaj pliki</label>
                  <div class="uk-form-controls">
                    <input accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx,application/pdf," id="file" multiple
                           data-vv-as="kaizen" name="files" ref="files" type="file"
                           v-validate="'ext:doc,docx,xls,xlsx,ppt,pptx,pdf'">
                  </div>
                  <span class="validation-error">{{ errors.first('files') }}</span>
                </div>
              </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-default uk-modal-close" type="button">Anuluj</button>
                    <button @click="submitRating" class="uk-button wpip-button" type="button">{{modal.button}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import UIkit from "uikit";
    import axiosInstance from "../../axiosInstance";
    import FileDownload from "../ui/fileDownload";
    import Datepicker from "vuejs-datepicker";
    import {pl} from "vuejs-datepicker/src/locale";

    export default {
        name: 'ratingsModal',
        components: {
            fileDownload: FileDownload,
            Datepicker
        },
        data() {
            return {
                pl: pl,
                categories: [],
                iasPhases: [],
                industries: [],
                improvement: {
                    id: null,
                    decision: 0,
                    decisionComment: "",
                    decisionDate: null,
                    name: "",
                    description: "",
                    anticipatedResults: "",
                    improvementFiles: [],
                    problemDescription: "",
                    scope: null,
                    rates: [],
                    area: null,
                    ratings: []
                },
                modal:
                    {
                        title: "Zgłoszenie usprawnienia",
                        button: "Dodaj"
                    },

            }
        },
        mixins: [mixin],
        methods: {
            replace: function(category){
                this.improvement.rates[category].value = this.improvement.rates[category].value.replace(',','.');
            },
            downloadFile: function (file) {
                this.$refs.download.download('/keizen/file/' + file.id, file.filename);
            },
            showModal: function (improvement) {
                let vm = this;
                this.improvement.id = improvement.id;
                this.improvement.name = improvement.name;
                this.improvement.description = improvement.description;
                this.improvement.problemDescription = improvement.problemDescription;
                this.improvement.anticipatedResults = improvement.anticipatedResults;
                this.improvement.improvementFiles = improvement.improvementFiles;
                if(improvement.industry!==null) {
                    this.improvement.industry = improvement.industry['@id'];
                } else {
                    this.improvement.industry = null;
                }
                if(improvement.iasPhase!==null) {
                    this.improvement.iasPhase = improvement.iasPhase['@id'];
                } else {
                    this.improvement.iasPhase = null;
                }
                this.improvement.decision = improvement.decision;
                this.improvement.decisionDate = improvement.decisionDate;
                this.improvement.decisionComment = improvement.decisionComment;
                this.improvement.rates=[];
                this.modal.button = "Zapisz";
                this.modal.title = "Ocena zgłoszonego pomysłu";
                this.improvement.ratings = [];

                this.categories.forEach(function (group) {

                    group.ratingCategories.forEach(function (cat) {
                        vm.improvement.rates[cat.id] = {rate:0, notes:""}
                        //vm.clearStars(vm.$el.querySelector('.rating-category-' + cat.id + ' .stars-number').firstChild);
                        vm.setComment(vm.$el.querySelector('.rating-category-' + cat.id + ' .rate-comment'));
                    });
                });
                //this.improvement.rates = improvement.rates;
                improvement.rates.forEach((rate) => {
                    //this.setInitialStars(this.$el.querySelector('.rating-category-' + rate.ratingCategory.id + ' .stars-number'), rate.rate);
                    vm.improvement.rates[rate.ratingCategory.id] = {rate: rate.rate};
                    this.setComment(this.$el.querySelector('.rating-category-' + rate.ratingCategory.id + ' .rate-comment'), rate.comment);
                    this.improvement.rates[rate.ratingCategory.id].notes = rate.comment;
                    this.improvement.rates[rate.ratingCategory.id].value = rate.value;
                });


                this.$validator.reset();
                UIkit.modal("#wpip-rating-add").show();
            },

            setComment: function () {
                //el.value = comment;
            },
            submitRating: function () {
                let vm = this;

                let postData = {
                    rates: this.improvement.rates,
                    improvementId: this.improvement.id,
                    decision: this.improvement.decision,
                    decisionDate: this.improvement.decisionDate,
                    decisionComment: this.improvement.decisionComment,
                };

                axiosInstance
                    .post(apiConfig.ratesApiUrl, JSON.stringify(postData), {
                        headers: this.$data.headers
                    })
                    .then(function (response) {
                        if (response.status === 201) {
                            UIkit.modal("#wpip-rating-add").hide();
                            vm.$emit('refresh')
                          for (let i = 0; i < vm.$refs.files.files.length; i++) {
                            vm.addFiles(vm.$refs.files.files[i], vm.improvement.id);
                          }
                            vm.$refs.files.value = null;
                        }
                    }).catch();
            },

            addFiles: function (file, improvement) {
              let vm = this;
              let formData = new FormData();
              formData.append('file', file);
              formData.append('improvement', improvement);
              axiosInstance.post("/api/uploadFile/keizen", formData, {
                headers: this.$data.multipartHeaders
              })
                  .then(function () {
                    vm.$emit('refresh');
                  });
            },
            changeNotes: function (e) {
                let categoryId = parseInt(e.target.attributes['category-id'].value);
                if (!this.improvement.ratings[categoryId]) {
                    this.improvement.ratings[categoryId] = {'rate': 0, 'notes': e.target.value};
                } else {
                    this.improvement.ratings[categoryId].notes = e.target.value;
                }
            },
            setRate: function (e) {
                this.improvement.rates[e.key].rate = e.rate;
            }
        },
        mounted() {
            let vm = this;
            axiosInstance
                .get(apiConfig.ratingsApiUrl,
                    {
                        headers: this.$data.headers
                    }).then(function (result) {

                vm.categories = result.data['member'];
            });
            axiosInstance
                .get(apiConfig.industriesApiUrl,
                    {
                        headers: this.$data.headers
                    }).then(function (result) {

                vm.industries = result.data['member'];
            });
            axiosInstance
                .get(apiConfig.iasPhasesApiUrl,
                    {
                        headers: this.$data.headers
                    }).then(function (result) {

                vm.iasPhases = result.data['member'];
            });
        }

    }
</script>

<style scoped>
    .wpip-star {
        font-size: 24px;
        color: #bf0e01;
        cursor: pointer;
    }


    .open-file {
        color: #777;
        cursor: pointer;
    }

    .uk-close {
        color: #bf0e01 !important;
    }

    .wpip-label {
        border: 1px solid #bf0e01;
        background-color: #fff;
        color: #bf0e01;
    }

    .wpip-badge {

        background-color: #bf0e01;
    }

    .wpip-text-large {
        font-size: 1.3em;
    }

    .wpip-fmea-danger td {
        background-color: #bf0e011e;
        font-weight: bold;
    }

    .uk-table-justify td:first-child {
        padding-left: 8px;
    }

    .uk-table-justify td:last-child {
        padding-right: 8px;
    }
</style>
