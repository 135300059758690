<template>
    <div class="h-100 admin" id="admin">
      <self-edit-modal ref="selfEdit"/>
        <vk-sticky v-if="!isDashboard() || !isFullScreen">
            <vk-navbar>
                <div class="uk-container uk-container-expand">
                    <vk-navbar-nav>
                        <vk-navbar-logo>
                            <router-link :to="{'name': 'dashboard'}" class="uk-float-left logo">
                                <img alt class="logo-svg" src="../../assets/images/logoWPIP.svg">
                            </router-link>
                        </vk-navbar-logo>
                        <vk-navbar-nav>
                            <vk-navbar-nav-dropdown subtitle="Struktura systemu IAS"  title="System organizacyjny" class="main-item" v-bind:class="{'uk-active': isInformation()}">
                                <vk-navbar-nav-dropdown-nav>
                                    <vk-nav-item href="/function-tree" title="Plan funkcji" icon="social"></vk-nav-item>
                                    <vk-nav-item href="/schema" title="Struktura organizacyjna" icon="git-fork"></vk-nav-item>
                                    <vk-nav-item href="/process-tree" title="Procesy systemu" icon="future"></vk-nav-item>
                                    <vk-nav-item href="/methods" title="Metody wspomagające" icon="grid"></vk-nav-item>
                                </vk-navbar-nav-dropdown-nav>
                            </vk-navbar-nav-dropdown>
                            <vk-navbar-nav-dropdown title="Narzędzia IAS" subtitle="Zbiór narzędzi" class="main-item" v-bind:class="{'uk-active': testIasm() || isExploatation() || isRental()}" v-if="this.ias || this.bms || this.rental || this.admin || this.exploatation">
                                <vk-navbar-nav-dropdown-nav>
                                    <vk-nav-item class="link" :key="tool.name" v-for="tool in activeTools" @click="getTool(tool.name)" v-bind:title="tool.title" v-bind:icon="tool.icon"></vk-nav-item>
                                </vk-navbar-nav-dropdown-nav>
                            </vk-navbar-nav-dropdown>
                            <vk-navbar-nav-dropdown title="MetaBMS" subtitle="Budynek" class="main-item" v-bind:class="{'uk-active': isCBMS()}" v-if="this.bms">
                                <vk-navbar-nav-dropdown-nav v-if="buildingStorage.isExploatation && (can('manage', 'ROLE_SUPER_ADMIN') || can('manage', 'ROLE_EXPLOATATION'))">
                                    <li class="uk-nav-header">Eksploatacja</li>
                                    <li>
                                        <router-link :to="{ name: 'budgets' }" :class="{current: $route.name==='budgets'}"><vk-icon icon="credit-card"></vk-icon>Budżety</router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{ name: 'calendar' }" :class="{current: $route.name==='calendar'}"><vk-icon icon="calendar"></vk-icon>Kalendarz</router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{ name: 'budgetTemplates' }" :class="{current: $route.name==='budgetemplates'}"><vk-icon icon="copy"></vk-icon>Szablony</router-link>
                                    </li>
                                </vk-navbar-nav-dropdown-nav>
                                <vk-navbar-nav-dropdown-nav v-if="buildingStorage.isDevice && (can('manage', 'ROLE_SUPER_ADMIN') || can('manage', 'ROLE_DEVICES') || can('manage', 'ROLE_BMS'))">
                                    <li class="uk-nav-header">Urządzenia</li>
                                    <li>
                                        <router-link :to="{ name: 'dashboards'}" :class="{current: $route.name==='dashboards'}"><vk-icon icon="grid"></vk-icon>Dashboardy</router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{ name: 'middlewares', params: {'buildingId': buildingId} }" :class="{current: $route.name==='middlewares'}"><vk-icon icon="server"></vk-icon>Middleware</router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{ name: 'devices', params: {'buildingId': buildingId} }" :class="{current: $route.name==='devices'}"><vk-icon icon="play-circle"></vk-icon>Urządzenia</router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{ name: 'registers' }" :class="{current: $route.name==='registers'}"><vk-icon icon="list"></vk-icon>Rejestry</router-link>
                                    </li>
                                </vk-navbar-nav-dropdown-nav>
                                <vk-navbar-nav-dropdown-nav v-if="buildingStorage.isRental && (can('manage', 'ROLE_SUPER_ADMIN') || can('manage', 'ROLE_RENTAL'))">
                                    <li class="uk-nav-header">Wynajem</li>
                                    <li>
                                        <router-link :to="{'name':'rentalDashboard'}" :class="{current: $route.name==='rentalDashboard'}"><vk-icon icon="check"></vk-icon>Rozliczenia</router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{'name':'renters'}" :class="{current: $route.name==='renters'}"><vk-icon icon="users"></vk-icon>Najemcy</router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{'name':'rentalObjects'}" :class="{current: $route.name==='rentalObjects'}"><vk-icon icon="home"></vk-icon>Obiekty</router-link>
                                    </li>
                                </vk-navbar-nav-dropdown-nav>
                            </vk-navbar-nav-dropdown>
                            <vk-navbar-nav-item href="/buildings" title="ADMINISTRACJA" subtitle="Zarządzanie systemem" class="main-item" v-bind:class="{'uk-active': !isRental() && !testIasm() && !testDevices() && $route.name!=='dashboard' && !isExploatation() && !isInformation() && !isCBMS()}"></vk-navbar-nav-item>
                        </vk-navbar-nav>
                        <div class="uk-navbar-right">
                            <ul class="uk-navbar-nav" uk-navbar="mode: click">
                                <li v-vk-tooltip="this.currentUser" @click="showSelfEdit()"><a><vk-icon icon="user"></vk-icon></a></li>
                                <li v-vk-tooltip="'Wyloguj'"><router-link v-bind:to="{ name: 'logout' }"><vk-icon icon="unlock"></vk-icon></router-link></li>
                                <li>
                                    <a class="building-selector" href="#"><span class="object-nav"><img
                                            :src="imageSrc(currentBuilding)" class="building-pill uk-border-ciecle"></span></a>
                                    <div class="uk-navbar-dropdown uk-width-large">
                                        <ul class="uk-nav uk-navbar-dropdown-nav">
                                            <li :key="'b_'+building.id" v-for="building in buildings">
                                                <div class="building-selector-item" v-on:click="changeBuilding(building)">
                                                    <img :src="imageSrc(building)" v-bind:class="{'current': building.name== currentBuilding.name }"
                                                        class="small-building-pill uk-border-circle">{{building.name}}
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </vk-navbar-nav>
                </div>
            </vk-navbar>
        </vk-sticky>

        <div id="my-id" uk-offcanvas>
            <div class="uk-offcanvas-bar">

            <button class="uk-offcanvas-close" type="button" uk-close></button>
                <router-link :to="{'name': 'dashboard'}" class="uk-float-left logo">
                    <img alt class="logo-svg" src="../../assets/images/logoWPIP.svg">
                </router-link>
                <br/>
                <div class="uk-navbar small-navbar">
                    <ul class="uk-navbar-nav" uk-navbar="mode: click">
                        <li>
                            <a class="building-selector-small" href="#"><span class=""> <img
                                    :src="imageSrc(currentBuilding)" class="building-pill uk-border-ciecle uk-margin-right"> {{ currentBuilding.name }}</span></a>
                            <div class="uk-navbar-dropdown uk-width-large">
                                <ul class="uk-nav uk-navbar-dropdown-nav uk-width-small">
                                    <li :key="'b_'+building.id" v-for="building in buildings">
                                        <div class="building-selector-item" v-on:click="changeBuilding(building)">
                                            <img :src="imageSrc(building)"
                                                 class="small-building-pill uk-border-circle">{{building.name}}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
                <ul class="uk-list list-nav">

                    <li v-bind:class="{'uk-active': isInformation()}">
                        <router-link :to="{ name: 'infos' }">System organizacyjny IAS</router-link>
                    </li>
                    <li v-bind:class="{'uk-active': testIasm() || isExploatation() || testDevices() || isRental()}" v-if="this.ias || this.bms || this.rental || this.admin || this.exploatation">
                        <router-link :to="{ name: 'home' }" v-if="currentBuilding.id===-1">IAS Narzędzia
                        </router-link>
                        <router-link :to="{ name: 'tools', params: {'buildingId': currentBuilding.id} }" v-else>
                            IAS Narzędzia
                        </router-link>
                    </li>
                    <li v-bind:class="{'uk-active': isCBMS()}" v-if="this.bms"><router-link :to="{name: 'dashboards'}">metaBMS</router-link></li>
                    <li v-bind:class="{'uk-active': !isRental() && !testIasm() && !testDevices() && $route.name!=='dashboard' && !isExploatation() && !isInformation() && !isCBMS()}"
                        v-if="this.admin">
                        <router-link :to="{ name: 'buildings' }">Panel administracyjny</router-link>
                    </li>

                </ul>
                <div>
                    <span class="self-edit" @click="showSelfEdit()">{{this.currentUser}}</span><span
                        class="uk-float-right"> <router-link class="logout-button"
                                                             v-bind:to="{ name: 'logout' }">Wyloguj</router-link></span>
                </div>
                <span class="uk-float-right version">Wersja: {{version}}</span>
        </div>
        </div>
        <div class="uk-container uk-container-expand uk-margin-bottom" id="wpip-main">
            <slot></slot>
        </div>
        
        <div v-if="!isDashboard() || !isFullScreen"  class="footer">
            <div class="uk-container">
                <img alt class="logo-ue uk-align-center" src="../../assets/images/poir_logos.jpg">
            </div>
        </div>
    </div>
</template>

<script>

    import apiConfig from "../../apiConfig";
    import version from "../../../config/version.json"
    import axiosInstance from "../../axiosInstance";
    import mixin from "../mixin";
    import UIkit from "uikit";
    import SelfEditModal from "@/components/user/selfEditModal";

    export default {
        name: 'admin',
        components: {SelfEditModal},
        data: function () {
            return {
                isFullScreen: false,
                isIasm: false,
                isDevice: false,
                ability: null,
                version: version.version,
                ias: false,
                bms: false,
                admin: false,
                exploatation: false,
                rental: false,
                currentBuilding: {
                    name: "Wybierz budynek",
                    id: -1
                },
                currentUser: "",
                buildings: [],
                activeTools: [],
                building: {},
                tools: [
                    {
                    name: "swots",
                    role: "SWOT",
                    icon: "thumbnails",
                    title: "Analiza SWOT",
                    },
                    {
                    name: "qfds",
                    role: "QFD",
                    icon: "info",
                    title: "Analiza QFD",
                    },
                    {
                    name: "fmeas",
                    role: "FMEA",
                    icon: "warning",
                    title: "Analiza FMEA",
                    },
                    {
                    name: "zpcs",
                    role: "ZPC",
                    icon: "check",
                    title: "Zarządzanie przez cele",
                    },
                    {
                    name: "kaizens",
                    role: "KEIZEN",
                    icon: "bolt",
                    title: "Kaizen",
                    },
                    {
                    name: "exploatation",
                    role: "EXPLOATATION",
                    icon: "settings",
                    title: "Zarządzenie eksploatacją",
                    }
                ],
                buildingId: JSON.parse(localStorage.getItem('currentBuilding')).id,
                buildingStorage: JSON.parse(localStorage.getItem('currentBuilding'))
            }
        },
        mixins: [mixin],
        methods: {
            isDashboard(){
              return this.$route.name === 'dashboardView'
            },
            showSelfEdit(){
                this.$refs.selfEdit.showModal(this.userId)
            },
            checkAdminAbilty: async function () {
                let result = false;
                this.ability = await this.$ability.getAbility();
                result = result || this.ability.can('manage', 'ROLE_SUPER_ADMIN');
                return result;
            },
            checkIasAbilty: async function () {
                let result = false;
                this.ability = await this.$ability.getAbility();
                result = result || this.ability.can('manage', 'ROLE_SUPER_ADMIN');
                result = result || this.ability.can('manage', 'ROLE_IAS');
                result = result || this.ability.can('manage', 'ROLE_SWOT');
                result = result || this.ability.can('manage', 'ROLE_FMEA');
                result = result || this.ability.can('manage', 'ROLE_QFD');
                result = result || this.ability.can('manage', 'ROLE_KEIZEN');
                result = result || this.ability.can('manage', 'ROLE_ZPC');
                return result;
            },
            checkBmsAbilty: async function () {
                let result = false;
                this.ability = await this.$ability.getAbility();
                result = result || this.ability.can('manage', 'ROLE_SUPER_ADMIN');
                result = result || this.ability.can('manage', 'ROLE_BMS');
                result = result || this.ability.can('manage', 'ROLE_DEVICES');
                return result;
            },
            testIasm: function () {
                this.isIasm = this.$route.name === 'home' || this.$route.path.includes('buildings/');
                return this.isIasm;
            },
            isInformation: function () {
                return this.$route.name === 'functionTree' || this.$route.name === 'infos' || this.$route.name === 'schema' || this.$route.name === 'iasMethods';
            },
            isExploatation: function () {
                return this.$route.name === 'calendar' || this.$route.path.includes('budget');
            },
            isCBMS: function () {
                return this.$route.name === 'dashboards' || this.$route.path.includes('middlewares') || this.$route.path.includes('devices') || this.$route.path.includes('registers');
            },
            isRental: function () {
                return ['rentalDashboard', 'rentalObjects', 'renters'].includes(this.$route.name);
            },
            checkExploatationAbility: async function () {
                let result = false;
                this.ability = await this.$ability.getAbility();
                result = result || this.ability.can('manage', 'ROLE_EXPLOATATION');
                return result;
            },

            testDevices: function () {
                this.isDevice = this.$route.path.includes('devices/') || this.$route.path.includes('dashboards');
                return this.isDevice;
            },
            imageSrc: function (building) {
                if (building.image) {
                    return apiConfig.apiUrl + "/uploads/images/building/" + building.image;
                } else {
                    return apiConfig.apiUrl + "/logoWPIP.svg";
                }
            },
            changeBuilding: function (building) {
                localStorage.setItem('currentBuilding', JSON.stringify(building));
                this.currentBuilding = building;
                let postData = {
                    currentBuilding: building['@id']
                };
                axiosInstance
                    .put(apiConfig.usersApiUrl + '/' + localStorage.getItem('user'), JSON.stringify(postData), {
                        headers: this.$data.headers
                    });
                this.$router.push({name: this.$route.params.name, params: {'buildingId': building.id}});
                this.$router.go();

            },
            getBuildings: function () {
                this.buildings = JSON.parse(localStorage.getItem('buildings'));
            },
            checkAbilty: function (role) {
                let result = false;
                result = result || this.ability.can('manage', 'ROLE_SUPER_ADMIN');
                result = result || this.ability.can('manage', 'ROLE_IAS');
                result = result || this.ability.can('manage', 'ROLE_' + role);
                return result;
            },
            async can(right, role) {
                if (!this.ability) {
                    this.ability = await this.$ability.getAbility();
                }
                return this.ability.can(right, role)
            },
            getTool(tool) {
                if (tool === 'exploatation') {
                    if(this.ability.can('manage', 'ROLE_EXPLOATATION') && this.building.isExploatation || this.ability.can('manage', 'ROLE_SUPER_ADMIN')){
                    this.$router.push({name: 'calendar', params: {buildingId: this.$route.params.buildingId}});
                    }
                    else if(this.ability.can('manage', 'ROLE_DEVICES') && this.building.isDevices){
                    this.$router.push({name: 'devices', params: {buildingId: this.$route.params.buildingId}});
                    }
                    else if(this.building.isRental){
                    this.$router.push({name: 'rentalDashboard', params: {buildingId: this.$route.params.buildingId}});
                    }
                    else {
                    this.$bvModal.msgBoxOk('Brak dostępnych narzędzi dla wybranego obiektu')
                    }
                } else {
                    this.$router.push({name: tool, params: {buildingId: this.$route.params.buildingId}});
                }
            }
        },
        async mounted() {
          let vm = this;
          window.onresize = function () {
            let maxHeight = window.screen.height,
                curHeight = window.innerHeight
            if (maxHeight === curHeight) {
              vm.isFullScreen = true
            }
            else {
              vm.isFullScreen = false
            }
          }
          let maxHeight = window.screen.height,
              curHeight = window.innerHeight
          if (maxHeight === curHeight) {
            vm.isFullScreen = true
          }
          else {
            vm.isFullScreen = false
          }
            this.ability = await this.$ability.getAbility();
            this.ias = await this.checkIasAbilty();
            this.bms = await this.checkBmsAbilty();
            this.admin = await this.checkAdminAbilty();
            this.rental = true;
            this.exploatation = await this.checkExploatationAbility();
            this.buildings = JSON.parse(localStorage.getItem('buildings'));
            if (JSON.parse(localStorage.getItem('currentBuilding')) != null) {
                this.currentBuilding = JSON.parse(localStorage.getItem('currentBuilding'))
            }
            this.currentUser = localStorage.getItem('fullName');
            this.userId = localStorage.getItem('user');
            if (this.$route.params.buildingId != null) {
                await axiosInstance
                    .get(apiConfig.testBuildingApiUrl + '/' + this.$route.params.buildingId + '/' + localStorage.getItem('user'), {
                        headers: this.$data.headers
                    }).then(function (result) {
                        if (!result.data.allowed) {
                            UIkit.modal.alert('Nie masz uprawnień do przeglądania wybranego budynku').then(function () {
                                vm.$router.push({name: "dashboard"});
                            });
                        }
                    });
            }

            this.building = JSON.parse(localStorage.getItem('currentBuilding'))
            this.tools.forEach(function (tool) {
                if(tool.name==='exploatation'){
                    if((vm.ability.can('manage', 'ROLE_SUPER_ADMIN') || vm.ability.can('manage', 'ROLE_EXPLOATATION') || vm.ability.can('manage', 'ROLE_DEVICES') || vm.ability.can('manage', 'ROLE_RENTAL')) && (vm.building.isExploatation || vm.building.isBMS || vm.building.isRental|| vm.building.isDevice )){
                    vm.activeTools.push(tool);
                    }
                }
                else if(tool.name==='zpcs'){
                    vm.activeTools.push(tool);
                }
                else if (vm.checkAbilty(tool.role) && vm.building.isIAS) {
                    vm.activeTools.push(tool);
                }
            })
        }
    }

</script>

<style>
    html,
    body {
        height: 100%;
        margin: 0;
    }
    
    img.logo-ue {
      max-height: 80px;
    }

    .self-edit:hover{
      cursor: pointer;
    }

    .uk-active a {
        border-color: #bf0e01 !important;
    }

    .list-nav li a{
        color: #999;
    }

    .list-nav li.uk-active{
        border-color: #bf0e01 !important;
    }

    .list-nav li{

        border-bottom: 1px solid #e5e5e5;
    }

    #app {
        font-family: "Nunito", sans-serif;
        font-size: 16px;
        font-weight: normal;
        line-height: 1.5;
        -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
        background: #fff;
        color: #666;
        height: 100%;
    }

    #wpip-main {
        min-height: calc(100% - 233px);
    }

    .logo {
        max-width: calc(100% - 100px);
        padding-right: 40px;
    }

    .logo-svg {
        width: 260px;
        padding-top: 20px;
    }

    .main-menu {
        background-color: #fff !important;
        box-shadow: 0 -5px 15px #000;
        margin-bottom: 20px;
    }

    .validation-error {
        color: #e91e1e;
        font-size: 12px;
    }

    .building-icon {
        width: 50px;
        text-align: center;
        height: 50px;
        object-fit: fill;
        margin-left: 5px !important;
        vertical-align: middle;
        padding-top: 5px !important;
        border: 1px solid #bf0e01 !important;
    }

    .small-building-pill {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 1px solid #bf0e01 !important;
        margin-right: 10px;
    }

    .building-pill {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 1px solid #bf0e01 !important;
        margin-left: 10px;
    }

    .building-selector-item {
        margin-top: 4px;
        cursor: pointer;
    }

    .building-selector {
        width: 400px;
        justify-content: right !important;
    }

    .building-selector-small {
        width: inherit;
        justify-content: right !important;
    }

    .logout-button {
        background-color: #BF0E01;
        padding: 5px;
        color: antiquewhite;
        border-radius: 4px;
    }

    .logout-button:hover {
        color: white;
    }

    .version {
        padding-top: 0.5em;
        font-size: 0.7em;
        color: #888888
    }

    .object-nav {
        text-align: right;
        width: 100%;
    }

    .nav-icon{
        padding-top:20px;
        padding-right: 10px;
        padding-bottom: 20px;
    }

    .small-navbar{
        float: none;
        width: 100%;
        display: inline-block;
    }

    .small-navbar{
        padding-top:10px
    }

    .small-navbar .uk-navbar-dropdown{
        margin-top: 0;
    }
    
    .footer {
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
      border-top: 1px solid #eee;
    }
</style>
