export default {
    data: function () {
        return {
            perPage: 16,
            swotType:{
                0: "SWOT-mrs",
                1: "SWOT/TOWS"
            },
            type: {
                "read": 0,
                "write": 1,
                "readwrite": 2
            },
            registerTypeName: {
                0: 'Odczyt',
                1: 'Zapis',
                2: 'Odczyt/Zapis'
            },
            registerTypeShortName: {
                0: 'O',
                1: 'Z',
                2: 'O/Z'
            },
            valueType: {
                "integer": 0,
                "float": 1,
                "string": 2,
                "double": 3,
                "long": 4,
                "coil": 5,
            },
            valueTypeName: {
                0: "integer",
                1: "float",
                2: "string",
                3: "double",
                4: "long",
                5: "coil",
            },
            contractorStatus: [
                "Klient",
                "Partner",
                "Konsorcjant",
                "Konkurent",
                "Podwykonawca",
                "Firma Projektowa",
                "Dostawca",
                "Wykonawca"
            ],
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                'Authorization': "Bearer " + localStorage.getItem('token'),
                blocker: {
                    requestKey: 'customRequestKeyName',
                    blockAllowed: false
                }
            },
            multipartHeaders: {
                'Content-Type': 'multipart/form-data',
                "Access-Control-Allow-Origin": "*",
                'Authorization': "Bearer " + localStorage.getItem('token')
            },
            headersHttpOptions: {
                'headers': {
                    'Content-Type': 'application/json;charset=UTF-8',
                    "Access-Control-Allow-Origin": "*",
                    'Authorization': "Bearer " + localStorage.getItem('token')
                }
            }
        }
    }
}
