<template>
    <span>
            <a
                    @click.prevent="beginAnalysis"
                    class="uk-icon-button"
                    href="#"
                    uk-tooltip="Szczegóły analizy"
            >
            <vk-icon class="hover" icon="pencil"/>
        </a>
        <a
                @click.prevent="analysis"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Podsumowanie analizy"
        >
            <vk-icon class="hover" icon="file-edit"/>
        </a>
        <a
                @click.prevent="removeAnalysis(datatable.item.id)"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Usuń analizę QFD"
        >
            <vk-icon class="hover" icon="trash"/>
        </a>
            <remove ref="remove" v-on:refresh="$emit('refresh')"/>
    </span>
</template>

<script>

    import mixin from "../../components/mixin";
    import UIkit from 'uikit';
    import apiConfig from "../../apiConfig";
    import remove from "../ui/remove";

    export default {
        name: 'qfdAnalysisActions',
        components: {
            remove
        },
        mixins: [mixin],
        props: {
            datatable: Object
        },
        methods: {
            beginAnalysis: function () {
                this.$router.push({
                    name: 'qfdAnalysis',
                    params: {
                        buildingId: this.$route.params.buildingId,
                        analysisId: this.datatable.item.id,
                        step: 1
                    }
                })
            },
            analysis: function () {
                this.$router.push({
                    name: 'qfdAnalysis',
                    params: {
                        buildingId: this.$route.params.buildingId,
                        analysisId: this.datatable.item.id,
                        step: 6
                    }
                })
            },
            removeAnalysis: function (analysisId) {
                if (this.datatable.item.parametersCount>0) {
                    UIkit.modal.alert('Nie można usunąć analizy posiadającej przypisane parametry powiązane z wymaganiami');
                } else {
                    this.$refs.remove.remove(apiConfig.qfdAnalysesApiUrl + "/" + analysisId, "analizę QFD");
                }
            }
        }
    }
</script>
