<template>
   <div>
       Logout
   </div>
</template>
<script>
    export default {
        data (){
            return {
            }
        },
        created:function(){
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('fullName');
            localStorage.removeItem('currentBuilding');
            localStorage.removeItem('roles');
            localStorage.removeItem('person');
            localStorage.removeItem('user');
            let url_login = 'http://' + window.location.hostname + ':' + window.location.port + '/#/login';
            window.location.href = url_login;
        }
    }
</script>
