<template>
    <div class="uk-flex-top uk-modal-container" container=".admin" id="wpip-parameter-add" uk-modal>
        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close/>
            <div class="uk-modal-body">
                <h3>{{ modal.title }}</h3>

                <div class="uk-margin">
                    <label class="uk-form-label">Nazwa parametru QFD</label>
                    <div class="uk-form-controls">
                        <input
                                class="uk-input"
                                data-vv-as="nazwa"
                                name="name"
                                type="text"
                                v-model="parameter.name"
                                v-validate="'required|max:255'"
                        >
                        <span class="validation-error">{{ errors.first('name') }}</span>
                    </div>
                </div>


                <div class="uk-margin">
                    <label class="uk-form-label">Opis parametru</label>
                    <div class="uk-form-controls">
                <textarea
                        class="uk-textarea"
                        rows="5"
                        v-model="parameter.description"
                ></textarea>
                    </div>
                </div>
                <div class="uk-grid">
                    <span class="uk-width-1-6 correlation-header">Korelacje</span>
                    <input
                            class="uk-input uk-width-expand"
                            name="search"
                            type="search"
                            v-model="filterValue"
                            placeholder="Wyszukaj parametry QFD"
                    >
                </div>

                <qfd-parameter-correlation-list :correlations="parameter.correlations" :filter="filterValue"
                                                :parameterId="parameter.id" ref="correlationList" v-bind:key="modalKey"
                                                v-on:setCorrelation="changeCorrelation"
                />
            </div>


            <div class="uk-modal-footer uk-text-right">
                <button class="uk-button uk-button-default uk-modal-close" type="button">Anuluj</button>
                <button @click="submitImprovement" class="uk-button wpip-button" type="button">{{modal.button}}
                </button>
            </div>
        </div>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import UIkit from "uikit";
    import axiosInstance from "../../axiosInstance";
    import QfdParameterCorrelationsList from "./qfdParameterCorrelationsList";

    export default {
        name: 'qfdParametersModal',
        components: {
            qfdParameterCorrelationList: QfdParameterCorrelationsList
        },
        data() {
            return {
                filterValue: "",
                modalKey: 0,
                parameter: {
                    id: null,
                    name: "",
                    description: "",
                    correlations: []
                },
                modal:
                    {
                        title: "Dodaj nowy parametr QFD",
                        button: "Dodaj"
                    },

            }
        },
        mixins: [mixin],
        created: function () {
            let vm = this;
            axiosInstance
                .get(apiConfig.unitsApiUrl, {
                    headers: this.$data.headers
                })
                .then(function (response) {
                    vm.units = response.data['member'];
                });
        },
        methods: {
            showModal: function (parameter) {
                this.modalKey++;
                if (parameter == null) {
                    this.parameter.id = null;
                    this.parameter.name = "";
                    this.parameter.description = "";
                    this.parameter.correlations = [];
                    this.modal.button = "Dodaj";
                    this.modal.title = "Dodaj nowy parametr QFD";
                } else {
                    this.parameter.id = parameter.id;
                    this.parameter.name = parameter.name;
                    this.parameter.description = parameter.description;
                    this.parameter.correlations = this.getCorrelations(parameter);
                    this.modal.button = "Zmień";
                    this.modal.title = "Zmień parametr QFD";
                }
                this.$validator.reset();

                this.$refs.correlationList.refresh();
                UIkit.modal("#wpip-parameter-add").show();
            },
            getCorrelations: function(parameter){
                let correlations = [];
                parameter.qfdParameterCorrelations1.forEach(function(correlation){
                    correlations[correlation.qfdParameter2.id] = correlation.correlation;
                });
                parameter.qfdParameter2Correlations.forEach(function(correlation){
                    correlations[correlation.qfdParameter1.id] = correlation.correlation;
                });
                return correlations;
            },
            submitImprovement: async function () {
                let vm = this;
                vm.$validator.validate().then((result) => {
                    if (result) {
                        let postData = {
                            name: this.parameter.name,
                            description: this.parameter.description,
                            // building: "/api/buildings/" + this.$route.params.buildingId
                        };
                        if (this.parameter.id == null) {
                            axiosInstance
                                .post(apiConfig.qfdParametersApiUrl, JSON.stringify(postData), {
                                    headers: this.$data.headers
                                })
                                .then(async function (response) {
                                    if (response.status === 201) {
                                        UIkit.modal("#wpip-parameter-add").hide();
                                        await vm.submitCorrelations(response.data.id, vm.parameter.correlations);
                                        vm.$emit('refresh');
                                    }
                                }).catch();
                        } else {
                            axiosInstance
                                .put(apiConfig.qfdParametersApiUrl + "/" + vm.parameter.id, JSON.stringify(postData), {
                                    headers: this.$data.headers
                                })
                                .then(async function (response) {
                                    if (response.status === 200) {
                                        UIkit.modal("#wpip-parameter-add").hide();
                                        await vm.submitCorrelations(response.data.id, vm.parameter.correlations);
                                        vm.$emit('refresh');
                                    }
                                }).catch();
                        }
                    }
                }).catch(() => {
                    return false;
                });
            },
            submitCorrelations: async function(id, correlations){
                let vm = this;

                let postData = {
                    parameterId: id,
                    correlations: []
                };

                correlations.forEach(function(correlation,key){
                   postData.correlations.push({
                       parameter: key,
                       correlation: correlation
                   })
                });
                axiosInstance
                    .post(apiConfig.qfdAddCorrelationsApiUrl, JSON.stringify(postData), {
                        headers: vm.$data.headers
                    }).then(function () {
                        vm.$emit('refresh');
                });
            },
            changeCorrelation: function(correlation){
                this.parameter.correlations[correlation.id]=correlation.value;
            }
        }

    }
</script>
<style scoped>
    .correlation-header{
        font-size: 1.3em;
    }
</style>
