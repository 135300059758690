<template>
    <admin>
        <div class="uk-container-expand uk-padding"> <vk-grid v-if="data.length>0">
            <div class="uk-width-1-2@m"><h4>Nazwa rejestru: </h4>{{name}}</div>
            <div class="uk-width-1-2@m"><h4>Numer rejestru: </h4> {{number}}</div>
        </vk-grid>
            <vk-grid>

                <div class="uk-width-expand@m">
                    <vk-table striped :data="data" responsive>
                        <vk-table-column title="Data" cell="createdAt"></vk-table-column>
                        <vk-table-column title="Wartość" cell="value"></vk-table-column>
                    </vk-table>
                </div>
            </vk-grid>
        </div>
    </admin>
</template>

<script>

    import mixin from "../../components/mixin";
    import {Grid} from 'vuikit/lib/grid';
    import {Table} from 'vuikit/lib/table';
    import apiConfig from "../../apiConfig";
    import axiosInstance from "../../axiosInstance";

    export default {
        name: 'registervalues',
        components: {
            VkGrid: Grid,
            VkTable: Table
        },
        mixins: [mixin],
        methods: {
        stringPad: function(value, size) {
            let result = value+"";
            while (result.length < size) {
                result = "0" + result;
            }
            return result;
        },
          get() {
              let vm = this;
              axiosInstance
                  .get(apiConfig.registerValuesApiUrl,
                      {
                          params: {
                              'register.id': this.$route.params.registerId
                          },
                          headers: this.$data.headers
                      })
                  .then(function (response) {
                      vm.data = response.data['member'];
                      if(response.data['member'][0].register.valueType === vm.valueType.coil){
                          vm.data.forEach(function (value) {
                              value.value = vm.stringPad(value.value.toString(2), response.data['member'][0].register.size);
                          })
                      }
                      vm.name = response.data['member'][0].register.name;
                      vm.number = response.data['member'][0].register.number;
                  });
          }
        },
        created: async function () {

            let url_admin = apiConfig.mercureUrl;
            const url = new URL(url_admin + '/.well-known/mercure');
            url.searchParams.append('topic', apiConfig.apiUrl + '/api/register_values/{id}');
            this.eventSource = new EventSource(url);
            this.eventSource.onmessage = () => {
                this.get();
            };

            this.get();
        },
        beforeDestroy() {
            this.eventSource.close();
        },
        data () {
            return {
                data: [],
                ability: null,
                name: "",
                number: "",
                eventSource: null,
            }
        },
        async mounted() {
            this.ability = await this.$ability.getAbility();
            if (!this.ability.can('manage', 'ROLE_BMS') && !this.ability.can('manage', 'ROLE_DEVICES') && !this.ability.can('manage', 'ROLE_SUPER_ADMIN')) {
                await this.$router.push({name: 'not-allowed'});
            }
        }
    }
</script>
