<template>
    <div class="uk-flex-top uk-modal-container" container=".admin" id="building-modal" uk-modal>
        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-modal-body">
                <h4>{{ modal.title }}</h4>
                <label class="uk-form-label">Nazwa</label>
                <div class="uk-margin">
                    {{ building.name }}
                </div>

                <label class="uk-form-label">Opis</label>
                <div class="uk-margin">
                    {{ building.description?building.description:'brak' }}
                </div>

                <label class="uk-form-label">Kontrahent</label>
                <div class="uk-margin">
                    {{ building.contractor.name }}
                </div>
                <label class="uk-form-label">Data Rozpoczęcia</label>
                <div class="uk-margin">
                    {{ building.beginDate?building.beginDate:'brak' }}
                </div>
                <label class="uk-form-label">Data Zakończenia</label>
                <div class="uk-margin">
                    {{ building.endDate?building.endDate:'brak' }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import mixin from "../mixin";
    import UIkit from "uikit";
    import 'vue-datetime/dist/vue-datetime.css'

    export default {
        name: 'buildingDetailsModal',
        data() {
            return {
                filterValue: "",
                modalDom: null,
                parameter: null,
                key: 0,
                types: [],
                type: 'datetime',
                building: {
                    name: "",
                    description: null,
                    fromDate: null,
                    toDate: null,
                    contractor: {
                        name: null,
                    }
                },
                modal:
                    {
                        title: "Szczegóły obiektu",
                        button: "",
                        blocked: false
                    },

            }
        },
        mixins: [mixin],
        methods: {
            showModal: function (building) {
                this.building = building

                this.modalDom.show()
            }
        },
        mounted() {
            if (this.modalDom == null) {
                let id = '#building-modal'
                this.modalDom = UIkit.modal(id)
            }
        }

    }
</script>
