<template>
    <div class="uk-flex-top uk-modal-container" container=".admin" id="renter-contacts-modal" uk-modal>
        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close/>
            <div class="uk-modal-body">
                <h3>{{ modal.title }}</h3>
                <empty-list v-if="totalItems===0"/>
                <b-table v-else ref="table" hover borderedless
                         :fields="fields"
                         :columns="fields"
                         :items="get"
                         :per-page="this.$data.perPage"
                         :current-page="currentPage"
                         :sort-by.sync="sortBy"
                         :sort-desc.sync="sortDesc"
                         no-sort-reset
                         :responsive="true">
                    <template v-slot:cell(object.firstName)="data">
                        <span :uk-tooltip="data.item.firstName">{{ limit(data.item, 30, 'firstName') }}</span>
                    </template>
                    <template v-slot:cell(object.lastName)="data">
                        <span :uk-tooltip="data.item.lastName">{{ limit(data.item, 30, 'lastName') }}</span>
                    </template>
                    <template v-slot:cell(object.email)="data">
                        <span :uk-tooltip="data.item.email">{{ limit(data.item, 50, 'email') }}</span>
                    </template>

                </b-table>
                <b-pagination :total-rows="totalItems" :per-page="perPage" v-model="currentPage"
                              align="center" v-if="totalItems>perPage" size="sm" page-class="wpip-page"
                              class="wpip-page"/>
            </div>

        </div>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import UIkit from "uikit";
    import charLimiter from "../charLimiter";
    import axiosInstance from "../../axiosInstance";
    import apiConfig from "../../apiConfig";
    import EmptyList from "../ui/emptyList";

    export default {
        name: 'renterContactsModal',
        components: {EmptyList},
        data() {
            return {
                filterValue: "",
                sortBy: 'name',
                sortDesc: false,
                data: [],
                contractorId: null,
                currentPage: 1,
                totalItems: '',
                fields: [
                    {key: 'firstName', label: 'Imie', sortable: true},
                    {key: 'lastName', label: 'Nazwisko'},
                    {key: 'email', label: 'Email'},
                    {key: 'phone', label: 'Telefon'}
                ],
                modal:
                    {
                        title: "Kontakty najemcy",
                        button: "Dodaj"
                    },

            }
        },
        mixins: [mixin, charLimiter],
        methods: {
            showModal: function (e) {
                this.contractorId = e
                this.get()
                this.modalDom.show()
            },
            async get() {
                let vm = this;
                let desc = 'desc';
                if (vm.sortDesc !== true) {
                    desc = 'asc';
                }


                var key = "order[" + this.sortBy + "]";
                var params = {};
                params[key] = desc;
                params['page'] = vm.currentPage;
                params['contractor.id'] = this.contractorId;

                let res = await axiosInstance
                    .get(apiConfig.contractorContactsApiUrl,
                        {
                            params,
                            headers: this.$data.headers
                        });
                vm.data = res.data['member'];
                vm.totalItems = res.data['totalItems'];

                return vm.data;
            }
        },

        mounted() {
            this.modalDom = UIkit.modal('#renter-contacts-modal');
        }

    }
</script>
<style>
    .section-title {
        color: white !important;
        background-color: #bf0e01 !important;
    }

    .item-selected.list-picker-selected {
        background-color: #bf4a3f !important;
    }

    .item-selected.list-picker-selected::selection {
        background-color: #bf4a3f !important;
        color: white !important;
    }

    .item-selected::selection {
        color: #666 !important;
    }

    .user-selection-buttons {
        margin-top: 2px !important;
        color: white !important;
    }


</style>
