<template>
    <vk-card class="uk-margin-bottom">
        <div id="w-filters" uk-grid>
            <form class="uk-form-horizontal uk-width-1-1">
                <fieldset class="uk-fieldset">
                    <legend class="uk-legend">Filtruj</legend>
                    <div class="uk-margin">
                        <vk-grid>
                            <label class="uk-form-label w-label">Obiekt</label>

                            <div class="uk-inline uk-width-expand">
                                <span class="uk-form-icon uk-form-icon-flip" uk-icon="" ></span>
                                <v-select multiple :filterable="false" :options="buildings" @search="searchBuildings"
                                        label="@id" :class="'wpip-select'" v-model="filter.buildings"
                                        data-vv-as="budynki" ref="multiSelectBuilding"
                                        name="buildings" v-validate="'required'">

                                    <template slot="option" slot-scope="option">
                                        <div class="d-center">
                                            {{ option.name }}
                                        </div>
                                    </template>

                                    <template slot="selected-option" slot-scope="option">
                                        <div class="selected d-center">
                                            {{ option.name }}
                                        </div>
                                    </template>

                                    <template v-slot:no-options="{ search, searching }">
                                        <template v-if="searching">
                                            Nie znaleziono <em>{{ search }}</em>.
                                        </template>
                                        <em style="opacity: 0.5;" v-else>Wyszukaj budynek</em>
                                    </template>
                                </v-select>
                            </div>
                        </vk-grid>
                    </div>
                    <div class="uk-margin">
                        <vk-grid>
                            <label class="uk-form-label w-label">Okres</label>
                            <div class="uk-inline uk-width-expand">
                                <datepicker :format="'MM.yyyy'" :input-class="'uk-input'"
                                            :language="pl" data-uk-datepicker="{format:'MM.YYYY'}"
                                            :minimumView="'month'" :maximumView="'month'"
                                            name="fromDate" type="text" v-model="filter.beginDate"  placeholder="od">
                                    <span slot="afterDateInput" class="clear-icon" @click.prevent="filter.beginDate=''"><vk-icon icon="close"></vk-icon></span>

                                </datepicker>
                            </div>

                            <div class="uk-inline uk-width-expand">
                                <datepicker :format="'MM.yyyy'" :input-class="'uk-input'" class=""
                                            :language="pl" data-uk-datepicker="{format:'MM.YYYY'}"
                                            :minimumView="'month'" :maximumView="'month'"
                                            name="fromDate" type="text" v-model="filter.endDate"  placeholder="do">
                                    <span slot="afterDateInput" class="clear-icon" @click.prevent="filter.endDate=''"><vk-icon icon="close"></vk-icon></span>
                                </datepicker>
                            </div>
                        </vk-grid>
                    </div>
                    <div class="uk-margin">
                        <vk-grid>
                            <label class="uk-form-label w-label">Plan Netto</label>
                            <div class="uk-inline uk-width-expand">
                                <span class="uk-form-icon uk-form-icon-flip" uk-icon="">PLN</span>
                                <input class="uk-input" type="text" v-model="filter.minValue" placeholder="od">
                            </div>
                            <div class="uk-inline uk-width-expand">
                                <span class="uk-form-icon uk-form-icon-flip" uk-icon="">PLN</span>
                                <input class="uk-input" type="text" v-model="filter.maxValue" placeholder="do">
                            </div>
                        </vk-grid>
                    </div>
                    <div class="uk-margin">
                        <vk-grid>
                            <label class="uk-form-label w-label">Koszty Netto</label>
                            <div class="uk-inline uk-width-expand">
                                <span class="uk-form-icon uk-form-icon-flip" uk-icon="">PLN</span>
                                <input class="uk-input" type="text" v-model="filter.minCosts" placeholder="od">
                            </div>
                            <div class="uk-inline uk-width-expand">
                                <span class="uk-form-icon uk-form-icon-flip" uk-icon="">PLN</span>
                                <input class="uk-input" type="text" v-model="filter.maxCosts" placeholder="do">
                            </div>
                        </vk-grid>
                    </div>

                    <div class="uk-margin">
                        <label class="uk-form-label w-label">Status należności</label>
                        <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                            <label><input class="uk-checkbox" type="checkbox" v-model="filter.status[0]"> OK</label>
                            <label><input class="uk-checkbox" type="checkbox" v-model="filter.status[1]"> Przekroczono plan</label>
                        </div>
                    </div>
                    <div class="uk-margin uk-align-right">
                        <button class="uk-button wpip-button uk-width-auto" @click.prevent="$emit('filter', filter)">Filtruj</button>
                    </div>
                </fieldset>
            </form>
        </div>
    </vk-card>
</template>

<script>
    import mixin from "../../components/mixin";
    import charLimiter from "../charLimiter";
    import axiosInstance from "../../axiosInstance";
    import apiConfig from "../../apiConfig";
    import Datepicker from 'vuejs-datepicker'
    import 'vue-datetime/dist/vue-datetime.css'
    import {pl} from "vuejs-datepicker/src/locale";

    export default {
        name: "budgetsFilters",
        components: {
            Datepicker
        },
        data() {
            return {
                pl: pl,
                buildings: [],
                filter: {
                    buildings: [],
                    beginDate: "",
                    endDate: "",
                    status: [],
                    minValue: null,
                    maxValue: null,
                    minCosts: null,
                    maxCosts: null,
                }
            };
        },
        methods: {
            searchBuildings: function (e) {
                let vm = this;
                clearTimeout(this.searchTimeout)
                this.searchTimeout = setTimeout(function () {
                    vm.getBuildings(e)
                }, 500);
            },
            setFilters: function(filter){
                this.filter = filter
            },
            getBuildings: function (e) {
                let vm = this;
                var params = {};
                params['name'] = e;
                axiosInstance
                    .get(apiConfig.buildingsApiUrl, {
                        params,
                        headers: this.$data.headers
                    })
                    .then(function (response) {
                        vm.buildings = response.data['member'];
                    });
            }
        },
        mixins: [mixin, charLimiter],
        mounted() {
            this.searchBuildings("");
            this.filter = this.$attrs.filters
        }
    };
</script>

<style scoped>
    .uk-accordion li {
        list-style-type: none;
    }

    .uk-accordion-title {
        color: black;
        font-size: 1.3em;
    }

    .uk-accordion {
        padding-left: 0;
    }
</style>
<style>
    .vdp-datepicker__calendar{
        white-space: normal;
    }

    .clear-icon{
        position: relative;
        right: 25px;
    }

    .clear-icon:hover{
        cursor: pointer;
    }
</style>