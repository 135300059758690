<template>
    <div class="uk-container-expand uk-padding">
        <vk-grid>
            <menuAdmin/>
            <div class="uk-width-expand@m ">
                <vk-grid class="uk-margin-bottom">
                    <h3 class="uk-width-expand">Użytkownicy </h3>
                    <button
                            @click="newUser"
                            class="uk-button wpip-button uk-width-auto"
                    ><vk-icon icon="plus-circle"></vk-icon> Dodaj użytkownika
                    </button>
                </vk-grid>
                <vk-card>
                <div>
                    <empty-list v-if="totalItems===0"/>
                    <b-table v-else :columns="fields" :current-page="currentPage" :fields="fields"
                             :items="get"
                             :per-page="this.$data.perPage"
                             :sort-by.sync="sortBy"
                             :sort-desc.sync="sortDesc"
                             borderedless
no-sort-reset
:responsive="true"
                             hover
                             ref="table">
                        <template v-slot:cell(actions)="data">
                            <users-actions :datatable="data" v-on:edit="editUser" v-on:password="changePassword"
                                           v-on:refresh="refresh"/>
                        </template>
                        <template v-slot:cell(username)="data">
                            <span :uk-tooltip="data.item.username">{{limit(data.item, 50, 'username')}}</span>
                        </template>
                    </b-table>
                    <b-pagination :per-page="perPage" :total-rows="totalItems" align="center"
                                  v-model="currentPage" v-if="totalItems>perPage" size="sm" page-class="wpip-page" class="wpip-page"/>
                </div></vk-card>
                <user-modal ref="usersModal" v-on:refresh="refresh"/>
                <password-modal ref="changePassword" v-on:refresh="refresh"/>
            </div>
        </vk-grid>
    </div>
</template>

<script>

    import {Grid} from 'vuikit/lib/grid';
    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import UsersActions from "./usersActions"
    import axiosInstance from "../../axiosInstance";
    import UserModal from "./userModal";
    import PasswordModal from "./passwordModal";
    import EmptyList from "../ui/emptyList";
    import charLimiter from "../charLimiter";

    export default {
        name: 'usersList',
        components: {
            EmptyList,
            PasswordModal,
            UserModal,
            usersActions: UsersActions,
            VkGrid: Grid
        },
        data() {
            return {
                sortBy: 'name',
                sortDesc: false,
                data: [],
                currentPage: 1,
                totalItems: '',
                fields: [
                    {key: 'username', label: 'Nazwa Użytkownika', sortable: true},
                    {key: 'email', label: 'E-mail', sortable: true},
                    {key: 'actions', label: ''},
                ]
            }
        },
        mixins: [mixin,charLimiter],
        methods: {
            refresh: function () {
                this.get();
                if(this.$refs.table) {
                    this.$refs.table.refresh();
                }
            },
            newUser: function () {
                this.$refs.usersModal.showModal(null)
            },
            editUser: function (user) {
                this.$refs.usersModal.showModal(user)
            },
            changePassword: function (user) {
                this.$refs.changePassword.showModal(user)
            },
            async get() {
                let vm = this;
                let desc = 'desc';
                if (vm.sortDesc !== true) {
                    desc = 'asc';
                }


                let key = "order[" + this.sortBy + "]";
                let params = {};
                params[key] = desc;
                params['page'] = vm.currentPage;

                let res = await axiosInstance
                    .get(apiConfig.usersApiUrl,
                        {
                            params,
                            headers: this.$data.headers
                        });
                vm.data = res.data['member'];
                vm.totalItems = res.data['totalItems'];

                return vm.data;
            }
        }
    }
</script>
