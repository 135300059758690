<template>
<admin>
  <div class="uk-container-expand uk-padding" id="ias-content">
    <file-download ref="download" />

    <vk-grid class="uk-margin-bottom">
      <h3 class="uk-width-expand">Procesy systemu IAS</h3>
    </vk-grid>

    <div id="ias-processTree" class="uk-child-width-1-3@m uk-grid-small uk-grid-match uk-flex-center" uk-grid>
      <div :key="process.name" v-for="process in processes">
          <div class="uk-card uk-card-default uk-card-body">
              <h3 class="uk-card-title mb-3" uk-grid>
                  <span class="uk-width-auto"><vk-icon v-bind:icon="process.icon"/></span>
                  <span class="uk-width-auto">{{ process.title }}</span>
                 <!-- <span class="uk-width-expand to-right">
                    <button class="uk-button wpip-button" @click="downloadPdf(process.file)">pobierz</button>
                  </span> -->

              </h3>
            <div v-bind:key="file.id" v-for="file in getFiles(process.category)">
              <a href="#" @click.prevent="downloadFile(file)">{{file.originalFilename}}</a>
            </div>
          </div>
      </div>
    </div>
    <file-download ref="download" />
    <button class="uk-button uk-button-default uk-width-auto mt-4" @click="$router.push('infos')"><vk-icon icon="arrow-left"></vk-icon> wstecz</button>
  </div>
</admin>
</template>

<style scoped>
.to-right {
  text-align: right;
}
</style>

<script>
import FileDownload from "@/components/ui/fileDownload";
import UIkit from "uikit";
import axiosInstance from "../../axiosInstance";
import apiConfig from "../../apiConfig";

export default {
  name: 'processTree',
  components: {
    FileDownload
  },
  data() {
    return {
      modalDom: null,
      filesData: [],
      modal: {
        title: "Schemat organizacyjny"
      },
      processes: [
        {
          name: "project",
          category: "process_design",
          title: "Projektowanie IAS",
          icon: "pencil",
          file: "02. Projektowanie IAS (2021-01-01_21-46-32).pdf",
          desc: "..."
        },
        {
          name: "build",
          category: "process_build",
          title: "Budowa IAS",
          icon: "move",
          file: "03. Budowa IAS (2021-01-01_21-33-17).pdf"
        },
        {
          name: "manage",
          category: "process_exploitation",
          title: "Eksplatacja IAS",
          icon: "cog",
          file: "04. Eksploatacja IAS (2021-01-01_21-29-29).pdf"
        },
        {
          name: "develop",
          category: "process_development",
          title: "Rozwój IAS",
          icon: "paint-bucket",
          file: "05. Rozwój IAS (2021-01-01_21-49-41).pdf"
        },
        {
          name: "other",
          category: "process_other",
          title: "Inne procesy WPIP",
          icon: "git-branch",
          file: "Inne_procesy_WPIP.pdf"
        }
      ]
    }
  },
  mounted() {
    this.getData()
    if (this.modalDom == null) {
      this.modalDom = UIkit.modal('#schema-modal');
    }
  },
  methods: {
    showSchema: function() {
      this.modalDom.show();
    },
    downloadFile: function(item){
      if(item.url){
        window.location.href = item.url
      }
      else{
        this.$refs.download.getApi("/api/ias_resources/" + item.id + "/file", item.originalFilename);
      }
    },
    async getData() {
      let vm = this

      let loader = this.$loading.show({
        // Optional parameters
        container: null,
        canCancel: false
      });

      let params = {
        categoriesList: true
      };

      let res = await axiosInstance
          .get(apiConfig.iasResourcesApiUrl,
              {
                params,
                headers: this.$data.headers
              }).catch(function (){
                loader.hide()
          });
      if (res.status === 200) {
        vm.filesData = res.data;
        loader.hide()
      }
    },
    getFiles(category){
      let vm = this

      let result = []

      vm.filesData.forEach(function(filesCategory){
        if(filesCategory.category === category){
          result = filesCategory.files
        }
      })
      return result
    },
    downloadPdf(filename) {
      this.$refs.download.download("/procesy/" + filename, filename);
    },
  }
};
</script>
