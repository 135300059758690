<template>
    <div class="card">
        <div class="card-title text-center">
        </div>
        <div class="card-body">
            <div class="row">
                <div class="uk-margin-small-top uk-margin-small-left uk-margin-small-right ">
                    <VueSvgGauge
                            :start-angle="-120"
                            :end-angle="120"
                            :value="getValue()"
                            :separator-step = "0"
                            :min="0"
                            :max="100"
                            :gauge-color="colors"
                            :inner-radius="90"
                    >
                        <div class="inner-text number-margin">
                            <div v-if="$props.element.register.length!=1" class="icon-danger inner-icon"><i
                                    class="nc-icon nc-simple-remove text-danger uk-text-bold"></i></div>
                            <div v-else-if="$props.element.register[0].lastValue>$props.element.register[0].superHigh" v-b-tooltip.hover="'Wartość krytycznie wysoka'" class="text-danger"><i
                                class="nc-icon nc-stre-up inner-icon uk-text-bold"></i></div>
                            <div v-else-if="$props.element.register[0].lastValue>$props.element.register[0].veryHigh" v-b-tooltip.hover="'Wartość bardzo wysoka'" class="text-orange"><i
                                class="nc-icon nc-stre-up inner-icon uk-text-bold"></i></div>
                            <div v-else-if="$props.element.register[0].lastValue>$props.element.register[0].maxExpectedValue" v-b-tooltip.hover="'Wartość wysoka'" class="text-warning"><i
                                class="nc-icon nc-stre-up inner-icon uk-text-bold"></i></div>
                            <div v-else-if="$props.element.register[0].lastValue<$props.element.register[0].minExpectedValue" v-b-tooltip.hover="'Wartość niska'" class="text-warning"><i
                                class="nc-icon nc-stre-down inner-icon uk-text-bold"></i></div>
                          <div v-else-if="$props.element.register[0].lastValue<$props.element.register[0].veryLow" v-b-tooltip.hover="'Wartość bardzo niska'" class="text-orange"><i
                              class="nc-icon nc-stre-down inner-icon uk-text-bold"></i></div>
                          <div v-else-if="$props.element.register[0].lastValue<$props.element.register[0].superLow" v-b-tooltip.hover="'Wartość krytycznie niska'" class="text-danger"><i
                                class="nc-icon nc-stre-down inner-icon uk-text-bold"></i></div>
                            <div v-else class="icon-success inner-icon"><i class="nc-icon nc-check-2 text-success uk-text-bold" v-b-tooltip.hover="'Wartość prawidłowa'"></i></div>
                            <div class="numbers">
                                <div><p class="card-category">{{getName()}}</p> <h4 class="card-title">{{ $props.element.register[0].lastValue }} {{$props.element.register[0].unitOfMeasure}}</h4>
                                </div>
                            </div>
                        </div>
                    </VueSvgGauge>
                </div>
            </div>
        </div>
        <div class="card-footer" v-if="$props.element.register.length!==0">
            <hr>
            <div class="stats">
                <div><i class="nc-icon nc-refresh-02"/> {{
                    $props.element.register[0].lastValueDate?$props.element.register[0].lastValueDate:"BRAK DATY" }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import charLimiter from "../charLimiter";
    import { VueSvgGauge } from 'vue-svg-gauge'

    export default {
        name: 'dashboardElementGauge',
        components: {
            VueSvgGauge
        },
        data() {
            return {
              min: 0,
              max: 100,
              colors: []
            }
        },
        props: {
            elementId: null,
            element: {}
        },
        mixins: [mixin, charLimiter],
        methods: {
            getName() {
                if (this.$props.element.register[0]) {
                    return this.$props.element.register[0].name;
                } else {
                    return "Nie wybrano rejestru";
                }
            },
            getValue() {
                return parseFloat((this.$props.element.register[0].lastValue-this.min)*100/(this.max-this.min))
            },
            calculateColors: function(){
              this.colors=[]
              this.colors.push({ offset: 0, color: '#bf0e01'})
              this.colors.push({ offset: parseInt((this.$props.element.register[0].superLow-this.min)*100/(this.max-this.min)), color: '#bf0e01'})
              this.colors.push({ offset: parseInt((this.$props.element.register[0].veryLow-this.min)*100/(this.max-this.min)), color: 'orange'})
              this.colors.push({ offset: parseInt((this.$props.element.register[0].minExpectedValue-this.min)*100/(this.max-this.min)), color: 'green'})
              this.colors.push({ offset: parseInt((this.$props.element.register[0].maxExpectedValue-this.min)*100/(this.max-this.min)), color: 'green'})
              this.colors.push({ offset: parseInt((this.$props.element.register[0].veryHigh-this.min)*100/(this.max-this.min)), color: 'orange'})
              this.colors.push({ offset: parseInt((this.$props.element.register[0].superHigh-this.min)*100/(this.max-this.min)), color:'#bf0e01'})
              this.colors.push({ offset: 100, color: '#bf0e01'})
            }
        },
        mounted: function () {
            this.min = this.$props.element.register[0].superLow - (this.$props.element.register[0].superHigh - this.$props.element.register[0].superLow)/10
            this.max = this.$props.element.register[0].superHigh + (this.$props.element.register[0].superHigh - this.$props.element.register[0].superLow)/10
          this.calculateColors()
        }
    }
</script>
<style>
    .inner-text {
        height: 50%;
        width: 100%;
        position: relative;
        padding-top: 30%;
        text-align: center;
    }

    .inner-icon{
        font-size: 2em;
    }

    .number-margin .numbers{
      margin-top: -0.75em!important;
    }

    .text-orange{
      color: #db7100;
    }


</style>
