<template>
    <div class="uk-flex-top uk-modal-container" container=".admin" id="contacts" uk-modal>
        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-modal-body">
                <h3>{{ modal.title }}</h3>
                <div class="uk-margin">
                    <label class="uk-form-label">Imię</label>
                    <input class="uk-input" name="firstName" v-model="contact.firstName" type="text" placeholder="Imię" data-vv-as="imię"
                           v-validate="'required|max:255'">
                    <span class="validation-error">{{ errors.first('firstName') }}</span>
                </div>
                <div class="uk-margin">
                    <label class="uk-form-label">Nazwisko</label>
                    <input class="uk-input" name="lastName" v-model="contact.lastName" type="text" placeholder="Nazwisko" data-vv-as="nazwisko"
                           v-validate="'required|max:255'">
                    <span class="validation-error">{{ errors.first('lastName') }}</span>
                </div>
                <div class="uk-margin">
                    <label class="uk-form-label">Stanowisko</label>
                    <input class="uk-input" name="position" v-model="contact.position" type="text" placeholder="Stanowisko" data-vv-as="stanowisko"
                           v-validate="'required|max:255'">
                    <span class="validation-error">{{ errors.first('position') }}</span>
                </div>

                <div class="uk-margin">
                    <label class="uk-form-label">Adres e-mail</label>
                    <input class="uk-input" name="email" v-model="contact.email" type="text" placeholder="E-mail" data-vv-as="e-mail"
                           v-validate="'required|email|max:255'">
                    <span class="validation-error">{{ errors.first('email') }}</span>
                </div>

                <div class="uk-margin">
                    <label class="uk-form-label">Numer telefonu</label>
                    <input class="uk-input" name="phone" v-model="contact.phone" type="text" placeholder="Nr telefonu" data-vv-as="numer telefonu"
                           v-validate="'required|phone|max:255'">
                    <span class="validation-error">{{ errors.first('phone') }}</span>
                </div>

                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-default uk-modal-close" type="button">Anuluj</button>
                    <button :disabled="modal.blocked" @click="submit" class="uk-button wpip-button" type="button">
                        {{modal.button}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import mixin from "../mixin";
    import UIkit from "uikit";
    import 'vue-datetime/dist/vue-datetime.css'
    import axiosInstance from "../../axiosInstance";
    import apiConfig from "../../apiConfig";

    export default {
        name: 'contractorModal',
        data() {
            return {
                modalDom: null,
                contact: {
                    id: null,
                    firstName: null,
                    lastName: null,
                    email: null,
                    phone: null,
                    position: null,
                },
                modal:
                    {
                        title: "Dodaj kontakt",
                        button: "Dodaj",
                        blocked: false
                    },

            }
        },
        mixins: [mixin],
        props: {
            contractorId: Number
        },
        methods: {
            showModal: async function (contact) {
                let vm = this;
                this.contact = contact;
                if (contact.id == null) {
                    this.contact.status = 1;
                    vm.modal =
                        {
                            title: "Dodaj kontakt",
                            button: "Dodaj",
                            blocked: false
                        }
                } else {

                    this.modal =
                        {
                            title: "Zmień kontakt",
                            button: "Zmień",
                            blocked: false
                        }
                }
                this.contact.contractor = "/api/contractors/" + this.$props.contractorId;

                await this.$validator.reset();

                this.modalDom.show();
            },
            submit: function () {
                let postData = this.contact;
                let vm = this;
                vm.$validator.validate().then((result) => {
                    if (result) {
                        this.modal.blocked = true;
                        if (!this.contact.id) {
                            axiosInstance
                                .post(apiConfig.contractorContactsApiUrl, JSON.stringify(postData), {
                                    headers: this.$data.headers
                                })
                                .then(async function (response) {
                                    if (response.status === 201) {
                                        vm.$emit('refresh');
                                        vm.modalDom.hide();
                                    }
                                    vm.modal.blocked = false;
                                }).catch();
                        } else {
                            axiosInstance
                                .put(apiConfig.contractorContactsApiUrl + '/' + vm.contact.id, JSON.stringify(postData), {
                                    headers: this.$data.headers
                                })
                                .then(async function (response) {
                                    if (response.status === 200) {
                                        vm.$emit('refresh');
                                        vm.modalDom.hide();
                                    }
                                    vm.modal.blocked = false;
                                }).catch();
                        }
                    }
                });
            }
        },
        mounted() {
            if (this.modalDom == null) {
                this.modalDom = UIkit.modal('#contacts');
            }
        }

    }
</script>
