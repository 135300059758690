<template>
    <div>
        <empty-list v-if="totalItems===0"/>
        <b-table v-else
                 :responsive="true"
                 no-sort-reset
                 ref="table" hover borderedless
                 :fields="fields"
                 :columns="fields"
                 :items="get"
                 :per-page="this.$data.perPage"
                 :current-page="currentPage"
                 :sort-by.sync="sortBy"
                 :sort-desc.sync="sortDesc">

            <template v-slot:cell(actions)="data">
                <fmeas-actions :datatable=data v-on:edit="edit" v-on:refresh="refresh"/>
            </template>
            <template v-slot:cell(type)="data">
                {{ types[data.item.type] }}
            </template>
            <template v-slot:cell(description)="data">
                <span  :uk-tooltip="data.item.description">{{ limit(data.item,100, "description") }}</span>
            </template>
        </b-table>
        <b-pagination :total-rows="totalItems" :per-page="perPage" v-model="currentPage"
                      align="center" v-if="totalItems>perPage" size="sm" page-class="wpip-page" class="wpip-page"/>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import FmeasActions from "./fmeasActions";
    import axiosInstance from "../../axiosInstance";
    import EmptyList from "../ui/emptyList";
    import charLimiter from "../charLimiter";

    export default {
        name: 'fmeasList',
        components: {
            EmptyList,
            fmeasActions: FmeasActions
        },
        data() {
            return {
                types: [
                    "Brak",
                    "Analiza obiektu IAS",
                    "Analiza Procesu"
                ],
                sortBy: 'name',
                sortDesc: false,
                data: [],
                currentPage: 1,
                totalItems: '',
                fields: [
                    {key: 'date', label: 'Data', sortable: true},
                    {key: 'description', label: 'Nazwa obiektu lub procesu', sortable: true},
                    {key: 'lpr', label: 'LPR', sortable: true},
                    {key: 'type', label: 'Typ analizy', sortable: true},
                    {key: 'actions', label: '', class: 'text-right'},
                ]
            }
        },
        mixins: [mixin, charLimiter],
        methods: {
            async get() {
                let vm = this;
                let desc = 'desc';
                if (vm.sortDesc !== true) {
                    desc = 'asc';
                }

                var params = {};

                if (this.sortBy !== '') {
                    var key = "order[" + this.sortBy + "]";
                    params[key] = desc;
                }
                params['page'] = vm.currentPage;
                params['building'] = vm.$route.params.buildingId;

                let res = await axiosInstance
                    .get(apiConfig.fmeasApiUrl,
                        {
                            params,
                            headers: this.$data.headers
                        });
                vm.data = res.data['member'];
                vm.totalItems = res.data['totalItems'];

                return vm.data;
            },
            edit(e) {
                this.$emit('edit', {...e});
            },
            refresh() {
                this.get();
                if (this.$refs.table) {
                    this.$refs.table.refresh();
                }
            }
        }
    }
</script>
