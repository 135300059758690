<template>
  <div>
    <div class="uk-grid uk-margin-bottom">
      <h3 class="uk-width-expand"> Koszty</h3>
      <button v-if="budgetExists" @click="$emit('addCost')"
              class="uk-button wpip-button uk-width-auto">Dodaj Koszt
      </button>
      <button v-else class="uk-button uk-width-auto" uk-tooltip="Brak obiektów z przypisanym budżetem">Dodaj Koszt
      </button>
    </div>
    <empty-list v-if="totalItems===0 && budgetExists"/>
    <div v-else-if="!budgetExists" class="text-center">
      <div uk-alert>
        <p>Brak budżetu dla danego okresu, dla wybranych w zadaniu obiektów. <a href="#" @click.prevent="$router.push({name: 'budgets'})">Dodaj budżet</a> w celu dodawania kosztów do zadania</p>
      </div>
    </div>
<div v-else>
    <b-table
             :current-page="currentPage" :fields="fields"
             :filter="filter"
             :items="data"
             :per-page="this.$data.perPage"
             :sort-by.sync="sortBy"
             :sort-desc.sync="sortDesc"
             hover
             no-sort-reset
             :responsive="true"
             ref="table">
      <template v-slot:cell(actions)="data">
        <cost-actions :datatable="data" v-on:refresh="refresh" v-on:editCost="editCost"/>
      </template>
      <template v-slot:cell(name)="data">
                <span :uk-tooltip="data.item.name">
                {{ limit(data.item, 20) }}
                </span>
      </template>
      <template v-slot:cell(budgetPosition.budget.name)="data">
                <span v-if="data.item.budgetPosition && data.item.budgetPosition.budget" :uk-tooltip="data.item.budgetPosition.budget.name">
                {{ limit(data.item.budgetPosition.budget, 20) }}
                </span>
        <span v-else-if="data.item.budgetPosition" :uk-tooltip="data.item.budgetPosition.name">
                {{ limit(data.item.budgetPosition, 20) }}
                </span>
      </template>
    </b-table>
    <b-pagination :per-page="perPage" :total-rows="totalItems" align="center" v-model="currentPage"
                  v-if="totalItems>perPage" size="sm" page-class="wpip-page" class="wpip-page"/>
  </div>
  </div>
</template>

<script>

import mixin from "../mixin";
import limiter from "../charLimiter";
import apiConfig from "../../apiConfig";
import axiosInstance from "../../axiosInstance";
import CostActions from "./costActions"
import EmptyList from "../ui/emptyList";

export default {
  name: 'costsList',
  components: {
    EmptyList,
    CostActions
  },
  data() {
    return {
      filter: null,
      sortBy: 'name',
      sortDesc: false,
      data: [],
      event: null,
      eventSource: null,
      budgetExists: false,
      currentPage: 1,
      totalItems: '',
      fields: [
        {key: 'name', label: 'Nazwa'},
        {key: 'value', label: 'Wartość netto (PLN)'},
        {key: 'budgetPosition.budget.name', label: 'Budżet'},
        {key: 'budgetPosition.budget.building.name', label: 'Budynek'},
        {key: 'actions', label: ''}
      ]
    }
  },
  props: {
    eventId: null,
    endDate: null
  },
  mixins: [mixin, limiter],
  methods: {
    editCost: function (cost) {
      this.$emit('editCost', cost)
    },
    setIdAndBuildings(id, buildings){
      this.event = id
      this.buildings = buildings
    },
    getPositions: function () {
      let vm = this
      vm.budgetExists = false
      this.buildings.forEach(function (building){
        let params = {}
        let fromDateFilter = new Date(new Date(vm.$props.endDate).getFullYear(), new Date(vm.$props.endDate).getMonth(), 1);
        let toDateFilter = new Date(new Date(vm.$props.endDate).getFullYear(), new Date(vm.$props.endDate).getMonth(), 1);
        fromDateFilter.setDate(fromDateFilter.getDate()-1)
        toDateFilter.setDate(toDateFilter.getDate()+1)
        params['building'] = building.id
        params['fromDate[after]'] = fromDateFilter.toISOString()
        params['fromDate[before]'] = toDateFilter.toISOString()
          axiosInstance
            .get(apiConfig.budgetsApiUrl,
                {
                  params,
                  headers: vm.$data.headers
                }).then(function(result){
                  if(result.data["totalItems"]>0){
                    vm.budgetExists = true
                  }
          });
      })
    },
    checkBudgetExists: function () {
      if(this.buildings)
      {
        this.getPositions()
      }
    },
    async get() {
      let vm = this
      let res = await axiosInstance
          .get(apiConfig.invoicesApiUrl,
              {
                params: {
                  'page': vm.currentPage,
                  'event.id': this.event,
                },
                headers: this.$data.headers
              });
      this.checkBudgetExists()
      vm.data = res.data['member']
      vm.totalItems = res.data['totalItems']
      return vm.data;
    },
    refresh: function () {
      this.get()
    }

  },
  mounted: function () {
    this.budgetExists = false
    if (this.$props.eventId !== null) {
      this.event = this.$props.eventId;
    }
    this.refresh();
  }
}
</script>
