<template>
    <div class="uk-flex-top uk-modal-container" container=".admin" id="event-details" uk-modal>
        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-modal-body">
                <vk-grid>
                    <div class="uk-width-1-3@l details">
                        <h3>{{ modal.title }}</h3>
                        <label class="uk-form-label">Nazwa</label>
                        <div class="uk-margin">
                            {{ event.name }}
                        </div>

                        <label class="uk-form-label">Opis</label>
                        <div class="uk-margin">
                            {{ event.description?event.description:'brak' }}
                        </div>

                        <label class="uk-form-label">Kategoria</label>
                        <div class="uk-margin">
                            {{ getCategory() }}
                        </div>

                        <label class="uk-form-label">Typ</label>
                        <div class="uk-margin">

                            <span v-if="event.allDay">Cały dzień</span>
                            <span v-else>Wybrane Godziny</span>

                        </div>

                        <label class="uk-form-label" v-if="event.end">Od</label>
                        <label class="uk-form-label" v-else>Data</label>
                        <div class="uk-margin" v-if="event.allDay">
                            {{ new Date(event.start).toLocaleDateString() }}
                        </div>
                        <div class="uk-margin" v-else>
                            {{ new Date(event.start).toLocaleString() }}
                        </div>
                        <div v-if="event.end">
                            <label class="uk-form-label">Do</label>
                            <div class="uk-margin" v-if="event.allDay">
                                {{ new Date(event.end).toLocaleDateString() }}
                            </div>
                            <div class="uk-margin" v-else>
                                {{ new Date(event.end).toLocaleString() }}
                            </div>
                        </div>
                        <label class="uk-form-label">Obiekty</label>
                        <div class="uk-margin">

                            <span v-bind:key="building['@id']" class="uk-label" v-for="building in event.buildings"  :uk-tooltip="building.name">{{limit(building,50)}}</span>

                        </div>

                        <span v-if="event.hasDevice">
                             <label class="uk-form-label">Urządzenia</label>
                            <div class="uk-margin">

                                <span v-bind:key="device['@id']" class="uk-label" v-for="device in event.devices">{{device.name}}</span>

                            </div>
                        </span>
                        <span v-if="event.hasContractor">
                            <label class="uk-form-label">Kontrahenci</label>
                            <div class="uk-margin">

                                <span v-bind:key="contractor['@id']" class="uk-label"
                                      v-for="contractor in event.contractors"  :uk-tooltip="contractor.name">{{limit(contractor,50)}}</span>

                            </div>
                        </span>
                        <label class="uk-form-label">Osoby odpowiedzialne</label>
                        <div class="uk-margin">

                            <span v-bind:key="person['@id']" class="uk-label" v-for="person in event.responsibles">{{person.firstName}} {{person.lastName}}</span>

                        </div>
                        <button @click="removeEvent" class="uk-button wpip-button uk-margin-right" type="button" v-if="event.id">Usuń</button>
                        <button @click="edit" class="uk-button wpip-button" type="button">Edytuj
                        </button>
                    </div>

                    <div class="uk-width-2-3@l">
                        <costs-list ref="costs" v-bind:eventId="eventId" v-bind:endDate="event.end" v-bind:buildings="event.buildings" @addCost="addCost" @editCost="editCost"/>
                    </div>
                </vk-grid>
            </div>
            <div class="uk-modal-footer uk-text-right">
                <button class="uk-button uk-button-default uk-modal-close" type="button">Zamknij</button>
            </div>
        </div>
    </div>
</template>

<script>
    import mixin from "../mixin";
    import UIkit from "uikit";
    import 'vue-datetime/dist/vue-datetime.css'
    import axiosInstance from "../../axiosInstance";
    import apiConfig from "../../apiConfig";
    import CostsList from "./costsList"
    import charLimiter from "../charLimiter";

    export default {
        name: 'eventDetailsModal',
        props: {},
        components: {
            CostsList
        },
        data() {
            return {
                filterValue: "",
                modalDom: null,
                parameter: null,
                key: 0,
                types: [],
                type: 'datetime',
                buildingIndex: 0,
                eventId: null,
                event: {
                    start: null,
                    end: null,
                    allDay: null,
                    id: null
                },
                modal:
                    {
                        title: "Dodaj do kalendarza",
                        button: "Dodaj",
                        mode: 0
                    },

            }
        },
        mixins: [mixin, charLimiter],
        methods: {
            edit: function () {
                this.$emit('editEvent', this.event)
            },
            removeEvent: async function () {
                this.remove = true;
                let vm = this;
                let res = await axiosInstance
                    .get(apiConfig.invoicesApiUrl,
                        {
                            params: {
                                'page': vm.currentPage,
                                'event.id': this.event.id,
                            },
                            headers: this.$data.headers
                        });
                if(res.data['member'].length!==0){
                    UIkit.modal.alert("Zadanie ma przypisane koszty. Proszę usunąć koszty w celu usunięcia zadania.", {labels: {ok: "OK"}, stack: true})
                    return
                }
                UIkit.modal.confirm("Czy chcesz usunąć wybrane zadanie?", {
                    labels: {ok: "Usuń", cancel: "Anuluj"}
                }).then(function () {
                    axiosInstance
                        .delete(apiConfig.eventsApiUrl + '/' + vm.event.id, {
                            headers: vm.$data.headers
                        }).then(function () {
                        vm.$emit('removeEvent', vm.event);
                    })
                });
            },
            showModal: function (event) {
                this.event = event;
                this.eventId = this.event.id;
                this.$refs.costs.setIdAndBuildings(this.eventId, this.event.buildings)
                this.event.start = new Date(this.event.start).toISOString();
                if (this.event.end != null) {
                    if (this.event.allDay) {
                        this.event.end = new Date(this.event.end);
                        this.event.end.setMinutes(this.event.end.getMinutes() - 1);
                        this.event.end = this.event.end.toISOString();
                    } else {
                        this.event.end = new Date(this.event.end).toISOString();
                    }
                }
                if (this.event.id) {
                    this.modal.title = "Szczegóły zadania";
                    this.modal.button = "Zmień";
                    this.modal.mode = 1;
                } else {
                    this.modal.title = "Dodaj do kalendarza";
                    this.modal.button = "Dodaj";
                    this.modal.mode = 0;
                }
                this.$refs.costs.refresh(this.event.id);
                this.modalDom.show();
            },
            addCost: function () {
                this.modalDom.hide();
                this.event.id = this.eventId;
                this.$emit('addCost', this.event);
            },
            editCost: function (cost) {
                this.modalDom.hide();
                this.$emit('editCost', cost, this.event);
            },
            getType: function () {
                if (this.event.allDay) {
                    return 'date'
                } else {
                    return 'datetime'
                }
            },
            getCategory: function () {
                let vm = this;
                let result = "";
                this.types.forEach(function (type) {
                    if (type['@id'] === vm.event.eventType) {
                        result = type.name;
                    }
                });
                return result;
            }
        },
        mounted() {
            let vm = this;
            axiosInstance
                .get(apiConfig.eventTypesApiUrl, {
                    headers: this.$data.headers
                })
                .then(function (response) {
                    vm.types = response.data['member'];
                });
            this.modalDom = UIkit.modal('#event-details');
        }

    }
</script>
<style scoped>
    .details .uk-margin {
        margin-top: 0 !important;
        border-bottom: 1px solid lightgrey;
    }

    .details label {
    }

    .correlation-header {
        font-size: 1.3em;
    }

    .uk-label{
       margin-right: 3px;
    }

    .wpip-theme .vdatetime-popup__header,
    .wpip-theme .vdatetime-calendar__month__day--selected > span > span,
    .wpip-theme .vdatetime-calendar__month__day--selected:hover > span > span {
        background: #bf0e01 !important;
    }

    .wpip-theme .vdatetime-year-picker__item--selected,
    .wpip-theme .vdatetime-time-picker__item--selected,
    .wpip-theme .vdatetime-popup__actions__button {
        color: #bf0e01;
    }
</style>
