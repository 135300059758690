<template>
    <div>
        <vk-grid>
            <h3 class="uk-width-expand">Lista analiz QFD </h3>
            <button
                    @click="newParameter"
                    class="uk-button wpip-button uk-width-auto"
            ><vk-icon icon="plus-circle"></vk-icon> Dodaj nową analizę QFD
            </button>
        </vk-grid>
        <vk-card>
        <empty-list v-if="totalItems===0"/>
        <b-table
                v-else
                :columns="fields"
                :current-page="currentPage"
                :fields="fields"
                :items="get"
                :per-page="this.$data.perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                borderedless
                no-sort-reset
                :responsive="true"
                class="uk-table vk-table-justify uk-table-divider"
                hover
                ref="table">


            <template v-slot:cell(description)="data">
                <span :uk-tooltip="data.item.description">{{ limit(data.item,100, "description") }}</span>
            </template>
            <template v-slot:cell(actions)="data">
                <analysis-actions :datatable=data v-on:edit="editParameter" v-on:refresh="refresh"/>
            </template>
        </b-table>
        <b-pagination :per-page="perPage" :total-rows="totalItems" align="center"
                      v-model="currentPage" v-if="totalItems>perPage" size="sm" page-class="wpip-page"
                      class="wpip-page"/>

        </vk-card>
        <analysis-modal ref="analysisModal" v-on:refresh="refresh"/>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import axiosInstance from "../../axiosInstance";
    import AnalysisModal from "./analysisModal";
    import AnalysisActions from "./analysisActions";
    import EmptyList from "../ui/emptyList";
    import charLimiter from "../charLimiter";

    export default {
        name: 'qfdAnalysesList',
        components: {
            EmptyList,
            analysisModal: AnalysisModal,
            analysisActions: AnalysisActions,
        },
        data() {
            return {
                sortBy: 'name',
                sortDesc: false,
                data: [],
                currentPage: 1,
                totalItems: '',
                fields: [
                    {key: 'date', label: 'Data', class: 'uk-width-1-5', sortable: true},
                    {key: 'description', label: 'Podsumowanie', sortable: true},
                    {key: 'actions', label: '', class: 'text-right'},
                ]
            }
        },
        mixins: [mixin, charLimiter],
        methods: {
            async get() {
                let vm = this;
                let desc = 'desc';
                if (vm.sortDesc !== true) {
                    desc = 'asc';
                }

                var params = {};

                if (this.sortBy != '') {
                    var key = "order[" + this.sortBy + "]";
                    params[key] = desc;
                }
                params['page'] = vm.currentPage;
                params['project'] = vm.$route.params.buildingId;

                let res = await axiosInstance
                    .get(apiConfig.qfdAnalysesApiUrl,
                        {
                            params,
                            headers: this.$data.headers
                        });
                vm.data = res.data['member'];
                vm.totalItems = res.data['totalItems'];
                return vm.data;
            },
            editParameter(e) {
                this.$refs.analysisModal.showModal(e);
            },
            newParameter: function () {
                this.$refs.analysisModal.showModal(null);
            },
            refresh() {
                this.get();
                if (this.$refs.table) {
                    this.$refs.table.refresh();
                }
            }
        }
    }
</script>
