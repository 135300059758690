<script>
    export default {
        name: 'notFound',
        template: '',
        created: function () {
            window.location.href = "/404.html";
        }
    }
</script>

