<template>
    <div class="text-right">
        <a
                @click.prevent="showDetails(datatable.item)"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Szczegółowe rozliczenie najmu"
        >
            <vk-icon class="hover" icon="search"/>
        </a>
        <a
                @click.prevent="remind()"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Wyślij przypomnienie"
        >
            <vk-icon class="hover" icon="bell"/>
        </a>
    </div>
</template>

<script>

    import mixin from "../mixin";

    export default {
        title: 'delete',
        components: {
        },
        name: 'rentalListActions',
        mixins: [mixin],
        props: {
            datatable: Object
        },
        methods: {
            remind: function(){

            },
            showDetails: function (contractor) {
                this.$emit('showDetails', contractor)
            }
        }
    }
</script>
<style scoped>
    div {
        white-space: nowrap;
    }

    .middleware-red {
        background-color: #bf0e01;
        color: white;
    }

    .middleware-orange {
        background-color: #e38703;
        color: white;
    }
    .middleware-green {
        background-color: #09a913;
        color: white;
    }
</style>
