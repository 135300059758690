<template>
    <admin>
        <div class="uk-container uk-padding">
            <vk-grid>
                <menuAdmin></menuAdmin>
                <div class="uk-width-expand@m">

                    <unit-form ref="form" v-on:submit="sendData"></unit-form>

                </div>
            </vk-grid>
        </div>
    </admin>
</template>

<script>

    import {Grid} from 'vuikit/lib/grid';
    import mixin from "../../components/mixin";
    import apiConfig from "../../apiConfig";
    import UnitForm from '../../components/unit/unitForm';
    import axiosInstance from "../../axiosInstance";

    export default {
        name: 'unitEdit',
        components: {
            VkGrid: Grid,
            unitForm: UnitForm
        },
        data() {

            return {
                name: null,
                improvements_limit: null,
                unit: null
            }
        },
        created: function () {
            let vm = this;
            axiosInstance.get(apiConfig.unitsApiUrl + '/' + this.$route.params.unitId, {
                headers: this.$data.headers
            }).then(function (result) {
                vm.unit = result.data;
                vm.$refs.form.loadData(vm.unit);
            });
        },
        mixins: [mixin],
        methods: {
            sendData: function (postData) {
                let vm = this;
                axiosInstance
                    .put(apiConfig.unitsApiUrl + '/' + this.$route.params.unitId, JSON.stringify(postData), {
                        headers: this.$data.headers
                    })
                    .then(function (response) {
                        if (response.status === 200) {
                            vm.$router.push('/units');
                        }
                    }).catch();
            }
        }
    }
</script>
