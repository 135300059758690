<template>
    <div class="uk-flex-top uk-modal-container" container=".admin" id="renter-spaces-modal" uk-modal>
        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close/>
            <div class="uk-modal-body">
                <vk-grid class="uk-margin-small-bottom">
                    <h3 class="uk-width-expand">{{ modal.title }} {{ renter }}</h3>
                    <button
                            @click="addRental(renter)"
                            class="uk-button wpip-button uk-width-auto"
                    >Dodaj Wynajem
                    </button>
                </vk-grid>
                <empty-list v-if="totalItems===0"/>
                <b-table v-else ref="table" hover borderedless
                         :fields="fields"
                         :columns="fields"
                         :items="data"
                         :per-page="this.$data.perPage"
                         :current-page="currentPage"
                         :sort-by.sync="sortBy"
                         :sort-desc.sync="sortDesc"
                         no-sort-reset
                         :responsive="true">
                    <template v-slot:cell(buildings)="data">
                        <span :uk-tooltip="getTooltip(data.item)">{{Object.entries(data.item.buildings).length}}</span>
                    </template>
                    <template v-slot:cell(spaces)="data">
                        <span class="uk-width-expand spaces" @click="toggle(data)">{{data.item.spaces.length}}</span>
                    </template>
                    <template v-slot:cell(date)="data">
                      <span v-if="data.item.date!==null">{{new Date(data.item.date).toLocaleDateString()}}</span>
                    </template>
                    <template v-slot:cell(actions)="data">
                        <renter-spaces-actions :datatable="data.item"  @edit="edit"></renter-spaces-actions>
                    </template>
                    <template v-slot:cell(object.name)="data">
                        <span :uk-tooltip="data.item.name">{{ limit(data.item, 30)}}</span>
                    </template>

                    <template v-slot:cell(isActive)="data">
                      <div class="text-center">
                        <input @change="changeIsActive(data.item)" class="uk-checkbox" name="isActive" type="checkbox" v-model="data.item.isActive">
                      </div>
                    </template>

                    <template v-slot:cell(status)="data">
                        <span v-if="data.item.date===null" class="badge badge-success">Zapłacone</span>
                        <span v-else-if="new Date(data.item.date)<new Date()" class="badge badge-danger">Zaległe</span>
                        <span v-else class="badge badge-primary">Wymagalne</span>
                    </template>

                    <template v-slot:row-details="row">
                        <empty-list v-if="row.item.spaces===0"/>
                       <b-table v-else ref="table" hover borderedless
                                 :fields="spaceFields"
                                 :columns="spaceFields"
                                 :items="row.item.spaces"
                                 no-sort-reset
                                 :responsive="true">
                        <template v-slot:cell(actions)="data">
                            <renter-spaces-actions></renter-spaces-actions>
                        </template>

                        <template v-slot:cell(status)="data">
                            <span :class="'badge ' + renterStatus[data.item.status+'-class'] ">{{ renterStatus[data.item.status+'-details'] }}</span>
                        </template>

                    </b-table>
                    </template>

                </b-table>
                <b-pagination :total-rows="totalItems" :per-page="perPage" v-model="currentPage"
                              align="center" v-if="totalItems>perPage" size="sm" page-class="wpip-page" class="wpip-page"/>
            </div>

        </div>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import UIkit from "uikit";
    import renterStatus from "../../constants/renterStatus";
    import charLimiter from "../charLimiter";
    import RenterSpacesActions from "./renterSpacesActions";
    import axiosInstance from "../../axiosInstance";
    import apiConfig from "../../apiConfig";

    export default {
        name: 'renterSpacesModal',
        components: {RenterSpacesActions},
        data() {
            return {
                renterStatus: renterStatus,
                filterValue: "",
                sortBy: 'name',
                sortDesc: false,
                data: [],
                details: [],
                contractorId: null,
                currentPage: 1,
                renter: "",
                totalItems: '',
                fields: [
                    {key: 'buildings', label: 'Budynki', sortable: true},
                    {key: 'spaces', label: 'Przestrzenie', sortable: true},
                    {key: 'area', label: 'Powierzchnia (M2)'},
                    {key: 'value', label: 'Należności (PLN)'},
                    {key: 'date', label: 'Data Wymagalności'},
                    {key: 'isActive', label: 'Aktywny'},
                    {key: 'status', label: 'Status'},
                    {key: 'actions', label: ''}
                ],
                spaceFields: [
                    {key: 'name', label: 'Nazwa', sortable: true},
                    {key: 'area', label: 'Powierzchnia (M2)'},
                    {key: 'value', label: 'Należności (PLN)'},
                    {key: 'actions', label: '', class: 'text-right'},
                ],
                modal:
                    {
                        title: "Lista wynajmów najemcy -",
                        button: "Dodaj"
                    },

            }
        },
        mixins: [mixin, charLimiter],
        methods: {
            toggle: function (data) {
                let vm = this;

                if (!data.detailsShowing) {
                    this.details.push(data.item.id);
                } else {
                    this.details.forEach(function (device, key) {
                        if (device === data.item.id) {
                            vm.details.splice(key, 1);
                        }
                    });
                }
                data.toggleDetails();
                if(this.$refs.table){
                    this.$refs.table.refresh();
                }
            },
          edit(data){
              this.$emit('edit', data)
          },
          changeIsActive: function (item) {
            let test = true;
            let vm = this
            if (!item.isActive) {

                UIkit.modal.confirm('Czy na pewno chcesz zakończyć wynajem?', {stack: true}).then(() => {
                  let postData = {
                    isActive: item.isActive
                  };
                  axiosInstance
                      .put(apiConfig.rentalsApiUrl + '/' + item.id, JSON.stringify(postData), {
                        headers: this.$data.headers
                      }).then(() => {
                    vm.$emit('refresh')
                  });
                }).catch(function () {
                  item.isActive = true;
                  test = false;
                });
            }
            else if (test) {
              let postData = {
                isActive: item.isActive
              };
              axiosInstance
                  .put(apiConfig.rentalsApiUrl + '/' + item.id, JSON.stringify(postData), {
                    headers: this.$data.headers
                  }).then(() => {
                    vm.$emit('refresh')
              });
            }
          },
            getTooltip: function(rental){
                let tooltip = "";
                Object.entries(rental.buildings).forEach(function (building, key) {
                    if(key>0){
                        tooltip += "</br>"
                    }
                    tooltip+=building[1];
                })
                return tooltip
            },
            showModal: function (e) {
                this.contractorId = e;
                this.get()
                this.modalDom.show();
            },
            get: async function () {
                let vm = this;
                let res = await axiosInstance
                    .get(apiConfig.contractorsApiUrl + '/' + vm.contractorId,
                        {
                            headers: this.$data.headers
                        });
                vm.data = res.data.spacesList
                vm.data.forEach(function (rental) {
                    let perSqm = rental.value/rental.area;
                    rental.spaces.forEach(function (space) {
                        space.value = (perSqm * space.area).toFixed(2)
                    })
                })
                vm.renter = res.data.name

                vm.totalItems = res.data['totalItems']

                return res.data.spacesList;
            },
            addRental: function(renter){
                this.$emit('addRental', renter)
            }
        },

        mounted() {
            this.modalDom = UIkit.modal('#renter-spaces-modal');
        }

    }
</script>
<style>
    .section-title {
        color: white !important;
        background-color: #bf0e01 !important;
    }

    .item-selected.list-picker-selected {
        background-color: #bf4a3f !important;
    }

    .item-selected.list-picker-selected::selection {
        background-color: #bf4a3f !important;
        color: white!important;
    }

    .item-selected::selection {
        color: #666 !important;
    }

    .user-selection-buttons {
        margin-top: 2px !important;
        color: white !important;
    }

    .spaces{
        text-decoration: underline;
    }

    .spaces:hover{
        cursor: pointer;
    }
</style>
