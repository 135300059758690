import {Validator} from 'vee-validate';

Validator.extend('cash', {
    validate: (value) => {
        let regex = /^[+-]{0,1}[0-9]{0,5}[.,]{0,1}[0-9]{0,2}$/;
        return regex.test(value);
    }
});

const dictionary = {
    pl: {
        messages:{
            cash: () => 'Pole nie zawiera poprawnej kwoty'
        }
    }
};

Validator.localize(dictionary);
