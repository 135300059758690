<template>
    <form method="post" id="unitEditor" @submit="checkForm">
        <fieldset class="uk-fieldset">

            <legend class="uk-legend">Kontrahent</legend>

            <div class="uk-margin">
                <label class="uk-form-label">Nazwa</label>
                <input class="uk-input" name="name" v-model="contractor.name" type="text" placeholder="Nazwa" data-vv-as="nazwa"
                       v-validate="'required'">
                <span class="validation-error">{{ errors.first('name') }}</span>
            </div>

            <div class="uk-margin">
                <label class="uk-form-label">Adres e-mail</label>
                <input class="uk-input" name="email" v-model="contractor.email" type="text" placeholder="E-mail" data-vv-as="e-mail"
                       v-validate="'required|email'">
                <span class="validation-error">{{ errors.first('email') }}</span>
            </div>

            <div class="uk-margin">
                <label class="uk-form-label">Numer telefonu</label>
                <input class="uk-input" name="phone" v-model="contractor.phone" type="text" placeholder="Nr telefonu" data-vv-as="numer telefonu"
                       v-validate="'required|phone'">
                <span class="validation-error">{{ errors.first('phone') }}</span>
            </div>

            <div class="uk-margin">
                <label class="uk-form-label">Adres</label>
                <input class="uk-input" name="address" v-model="contractor.address" type="text" placeholder="Adres" data-vv-as="adres"
                       v-validate="'required'">
                <span class="validation-error">{{ errors.first('address') }}</span>
            </div>

            <div class="uk-margin">
                <label class="uk-form-label">Miasto</label>
                <input class="uk-input" name="city" v-model="contractor.city" type="text" placeholder="Miasto" data-vv-as="miasto">
                <span class="validation-error">{{ errors.first('city') }}</span>
            </div>

            <div class="uk-margin">
                <label class="uk-form-label">Kod</label>
                <input class="uk-input" name="internalCode" v-model="contractor.internalCode" type="text"
                       placeholder="Kod" v-validate="'required'" data-vv-as="kod">
                <span class="validation-error">{{ errors.first('internalCode') }}</span>
            </div>

            <div class="uk-margin">
                <label class="uk-form-label">NIP</label>
                <input class="uk-input" name="taxNo" v-model="contractor.taxNo" type="text" placeholder="NIP"
                       v-validate="'required|nip'" data-vv-as="NIP">
                <span class="validation-error">{{ errors.first('taxNo') }}</span>
            </div>
            <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                <label><input class="uk-checkbox" type="checkbox" name="status[0]" v-model="contractor.statusBool[0]">
                    Klient</label>
                <label><input class="uk-checkbox" type="checkbox" name="status[1]" v-model="contractor.statusBool[1]">
                    Partner</label>
                <label><input class="uk-checkbox" type="checkbox" name="status[2]" v-model="contractor.statusBool[2]">
                    Konsorcjant</label>
                <label><input class="uk-checkbox" type="checkbox" name="status[3]" v-model="contractor.statusBool[3]">
                    Konkurent</label>
                <label><input class="uk-checkbox" type="checkbox" name="status[4]" v-model="contractor.statusBool[4]">
                    Podwykonawca</label>
                <label><input class="uk-checkbox" type="checkbox" name="status[5]" v-model="contractor.statusBool[5]"> Firma
                    projektowa</label>
                <label><input class="uk-checkbox" type="checkbox" name="status[6]" v-model="contractor.statusBool[6]">
                    Dostawca</label>
                <label><input class="uk-checkbox" type="checkbox" name="status[7]" v-model="contractor.statusBool[7]">
                    Wykonawca</label>
            </div>


            <div class="uk-margin">
                <label class="uk-form-label">Opis</label>
                <textarea class="uk-input" name="description" v-model="contractor.description"
                          placeholder="Opis"></textarea>
                <span>{{ errors.first('description') }}</span>
            </div>

            <input type="submit" class="uk-button uk-button-default" value="Zapisz">
        </fieldset>
    </form>
</template>

<script>
    export default {
        name: 'contractorForm',
        data() {
            return {
                contractor:
                    {
                        id: null,
                        name: null,
                        email: null,
                        phone: null,
                        address: null,
                        city: null,
                        internalCode: null,
                        taxNo: null,
                        status: 0,
                        description: null,
                        statusBool: [false, false, false, false, false, false, false, false]
                    }
            }
        },
        methods: {
            loadData: function (contractor) {
                this.contractor = contractor;
                this.contractor.statusBool = [false, false, false, false, false, false, false, false];
                this.contractor.status = 0;
                for(var i=0; i<8; i++){
                    this.contractor.statusBool[i] = this.contractor.status%2;
                    this.contractor.status = Math.floor(this.contractor.status/2)
                }
            },
            checkForm: function (e) {
                e.preventDefault();
                let vm = this;
                this.contractor.status=0;
                let value = 1;
                for(var i=0; i<8; i++){
                    if(this.contractor.statusBool[i]){
                        this.contractor.status+=value;
                    }
                    value*=2;
                }
                let postData = {
                    name: this.contractor.name,
                    email: this.contractor.email,
                    phone: this.contractor.phone,
                    address: this.contractor.address,
                    city: this.contractor.city,
                    internalCode: this.contractor.internalCode,
                    taxNo: this.contractor.taxNo,
                    status: parseInt(this.contractor.status),
                    description: this.contractor.description
                };

                vm.$validator.validate().then((result) => {
                    if (result) {
                        this.$emit('submit', postData)
                    }
                }).catch(() => {
                    return false;
                });
            }
        }
    }
</script>
