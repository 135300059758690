<template>
    <div class="uk-container-expand uk-padding">
        <vk-grid class="uk-margin-bottom">
            <h3 class="uk-width-expand">Benefity </h3>
            <button
                    @click="newBenefit"
                    class="uk-button wpip-button uk-width-auto"
            ><vk-icon icon="plus-circle"></vk-icon> Dodaj benefit
            </button>
        </vk-grid>
        <vk-card>
        <empty-list v-if="totalItems===0"/>
        <b-table
                v-else
                :columns="fields"
                :current-page="currentPage"
                :fields="fields"
                :items="get"
                :per-page="this.$data.perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                borderedless
no-sort-reset
:responsive="true"
                class="uk-table vk-table-justify uk-table-divider"
                hover
                ref="table">

            <template v-slot:cell(actions)="data">
                <benefits-actions :datatable=data v-on:edit="editBenefit" v-on:refresh="refresh"/>
            </template>
        </b-table>
        <benefit-modal ref="benefitModal" v-on:refresh="refresh"/>
        <b-pagination :per-page="perPage" :total-rows="totalItems" align="center"
                      v-model="currentPage" v-if="totalItems>perPage" size="sm" page-class="wpip-page" class="wpip-page"/>

        </vk-card>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import axiosInstance from "../../axiosInstance";
    import BenefitsActions from "./benefitsActions";
    import BenefitModal from "./benefitModal";
    import EmptyList from "../ui/emptyList";

    export default {
        name: 'benefitsList',
        components: {
            EmptyList,
            benefitsActions: BenefitsActions,
            benefitModal: BenefitModal,
        },
        data() {
            return {
                sortBy: 'name',
                sortDesc: false,
                data: [],
                currentPage: 1,
                totalItems: '',
                fields: [
                    {key: 'name', label: 'Nazwa', class: 'uk-width-1-5', sortable: true},
                    {key: 'cash', label: 'Premia finansowa', sortable: true},
                    {key: 'bonus', label: 'gratyfikacja pozafinansowa', class: 'uk-width-2-5'},
                    {key: 'actions', label: '', class: 'text-right'},
                ]
            }
        },
        mixins: [mixin],
        methods: {
            async get() {
                let vm = this;
                let desc = 'desc';
                if (vm.sortDesc !== true) {
                    desc = 'asc';
                }

                var params = {};

                if (this.sortBy != '') {
                    var key = "order[" + this.sortBy + "]";
                    params[key] = desc;
                }
                params['page'] = vm.currentPage;
                params['building'] = vm.$route.params.buildingId;

                let res = await axiosInstance
                    .get(apiConfig.benefitsApiUrl,
                        {
                            params,
                            headers: this.$data.headers
                        });
                vm.data = res.data['member'];
                vm.totalItems = res.data['totalItems'];

                return vm.data;
            },
            editBenefit(e) {
                this.$refs.benefitModal.showModal(e);
            },
            newBenefit: function () {
                this.$refs.benefitModal.showModal(null);
            },
            refresh() {
                if(this.$refs.table) {
                    this.$refs.table.refresh();
                } else {
                    this.get();
                }
            }
        }
    }
</script>
<style scoped>
    .negative-2{
        background-color: rgb(240, 80, 110);
        color: white;
    }

    .negative-1{
        background-color: rgb(240, 140, 61);
        color: white;
    }

    .positive-1{
        background-color: rgb(30, 135, 240);
        color: white;
    }

    .positive-2{
        background-color: rgb(50, 210, 150);
        color: white;
    }
</style>
