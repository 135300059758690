export default {
    methods: {
        limit: function(object, maxLength, property="name"){
            if(object[property]) {
                if (object[property].length > maxLength) {
                    let end = maxLength - 1;
                    for (let position = end; position > 0; position--) {
                        if (object[property][position] === " ") {
                            end = position;
                            break;
                        }
                    }
                    return object[property].slice(0, end) + "...";
                }
                return object[property];
            }
            else {
                return "brak";
            }
        },
    }
}
