<template>
    <div class="uk-flex-top uk-modal-container" container=".admin" id="renter-spaces-modal" uk-modal>
        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close/>
            <div class="uk-modal-body">
                    <vk-grid class="uk-margin-small-bottom">
                    <h3 class="uk-width-expand">{{ modal.title }}</h3>
                    <button
                            @click="addSpace()"
                            class="uk-button wpip-button uk-width-auto"
                    >Dodaj Przestrzeń
                    </button>
                </vk-grid>
                <empty-list v-if="totalItems===0"/>
                <b-table v-else ref="table" hover borderedless
                         :fields="fields"
                         :columns="fields"
                         :items="get"
                         :per-page="this.$data.perPage"
                         :current-page="currentPage"
                         :sort-by.sync="sortBy"
                         :sort-desc.sync="sortDesc"
                         no-sort-reset
                         :responsive="true">
                    <template v-slot:cell(actions)="data">
                        <rental-object-spaces-actions :datatable=data @editSpace="editSpace"/>
                    </template>
                    <template v-slot:cell(building.name)="data">
                        <span :uk-tooltip="data.item.name">{{ limit(data.item, 30)}}</span>
                    </template>
                    <template v-slot:cell(area)="data">
                        {{ parseFloat(data.item.area).toFixed(2) }}
                    </template>

                    <template v-slot:cell(status)="data">
                        <span v-if="data.item.renter!==null" class="badge badge-danger">WYNAJĘTE</span>
                        <span v-else class="badge badge-success">Wolne</span>
                    </template>

                </b-table>
                <b-pagination :total-rows="totalItems" :per-page="perPage" v-model="currentPage"
                              align="center" v-if="totalItems>perPage" size="sm" page-class="wpip-page"
                              class="wpip-page"/>
            </div>

        </div>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import UIkit from "uikit";
    import spaceStatus from "../../constants/spaceStatus";
    import charLimiter from "../charLimiter";
    import RentalObjectSpacesActions from "./rentalObjectSpacesActions";
    import axiosInstance from "../../axiosInstance";
    import apiConfig from "../../apiConfig";
    import EmptyList from "@/components/ui/emptyList";

    export default {
        name: 'rentalObjectSpacesModal',
        components: {EmptyList, RentalObjectSpacesActions},
        data() {
            return {
                spaceStatus: spaceStatus,
                filterValue: "",
                sortBy: 'name',
                sortDesc: false,
                data: [],
                buildingId: null,
                currentPage: 1,
                totalItems: '',
                fields: [
                    {key: 'building.name', label: 'Przestrzeń', sortable: true},
                    {key: 'area', label: 'Powierzchnia (M2)'},
                    {key: 'renter', label: 'Najemca'},
                    {key: 'status', label: 'Status'},
                    {key: 'actions', label: '', class: 'text-right'},
                ],
                modal:
                    {
                        title: "Przestrzenie"
                    },

            }
        },
        mixins: [mixin, charLimiter],
        methods: {
            showModal: function (e) {
                this.buildingId = e
                this.modalDom.show()
                if(this.$refs.table) {
                  this.$refs.table.refresh()
                } else {

                  this.get()
                }
            },
            addSpace: function(){
                this.$emit('addSpace', {space: null, building: this.buildingId})
            },
            editSpace: function(space){
                this.$emit('addSpace', {space: space, building: this.buildingId})
            },
        async get() {
            let vm = this;
            let desc = 'desc'
            if (vm.sortDesc !== true) {
                desc = 'asc'
            }


            var key = "order[" + this.sortBy + "]"
            var params = {}
            params[key] = desc
            params['page'] = vm.currentPage
            params['building.id'] = this.buildingId

            let res = await axiosInstance
                .get(apiConfig.spacesApiUrl,
                    {
                        params,
                        headers: this.$data.headers
                    });
            vm.data = res.data['member']

            vm.totalItems = res.data['totalItems']

            return vm.data;
        }
        },

        mounted() {
            this.modalDom = UIkit.modal('#renter-spaces-modal')
        }

    }
</script>
<style>
    .section-title {
        color: white !important;
        background-color: #bf0e01 !important;
    }

    .item-selected.list-picker-selected {
        background-color: #bf4a3f !important;
    }

    .item-selected.list-picker-selected::selection {
        background-color: #bf4a3f !important;
        color: white !important;
    }

    .item-selected::selection {
        color: #666 !important;
    }

    .user-selection-buttons {
        margin-top: 2px !important;
        color: white !important;
    }


</style>
