<template>
    <div>
        <vk-grid class="uk-margin-bottom">
            <h3 class="uk-width-expand">Osoby </h3>
            <button
                    @click="$refs.modal.showModal({})"
                    class="uk-button wpip-button uk-width-auto"
            ><vk-icon icon="plus-circle"></vk-icon> Dodaj Osobę
            </button>
        </vk-grid>
        <vk-card>
        <empty-list v-if="totalItems===0"/>
        <b-table v-else
                 ref="table" hover borderedless
                 :fields="fields"
                 :columns="fields"
                 :items="get"
                 :per-page="this.$data.perPage"
                 :current-page="currentPage"
                 :sort-by.sync="sortBy"
                 :sort-desc.sync="sortDesc"
no-sort-reset
:responsive="true">
            <template v-slot:cell(isManager)="data">
                <peopleIsManager :datatable="data"/>
            </template>
            <template v-slot:cell(firstName)="data">
                <span :uk-tooltip="data.item.firstName">{{ limit(data.item,30, 'firstName') }}</span>
            </template>

            <template v-slot:cell(lastName)="data">
                <span :uk-tooltip="data.item.lastName">{{ limit(data.item,30, 'lastName') }}</span>
            </template>

            <template v-slot:cell(unit.name)="data">
                <span v-if="data.item.unit" :uk-tooltip="data.item.unit.name">{{ limit(data.item.unit,100) }}</span>
            </template>

            <template v-slot:cell(actions)="data">
                <peopleActions :datatable=data v-on:edit="$refs.modal.showModal" v-on:refresh="refresh" />
            </template>
        </b-table>
        <b-pagination :total-rows="totalItems" :per-page="perPage" v-model="currentPage"
                      align="center"  v-if="totalItems>perPage" size="sm" page-class="wpip-page" class="wpip-page"/>

        </vk-card>
        <person-modal ref="modal" @refresh="refresh"/>
    </div>
</template>

<script>
    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import PeopleActions from "./peopleActions";
    import PeopleIsManager from "./peopleIsManager";
    import axiosInstance from "../../axiosInstance";
    import PersonModal from "./personModal";
    import EmptyList from "../ui/emptyList";
    import charLimiter from "../charLimiter";

    export default {
        name: 'peopleList',
        components: {
            EmptyList,
            PersonModal,
            'peopleIsManager': PeopleIsManager,
            'peopleActions': PeopleActions
        },
        data() {
            return {
                sortBy: 'name',
                sortDesc: false,
                data: [],
                currentPage: 1,
                totalItems: '',
                fields: [
                    {key: 'firstName', label: 'Imię', sortable: true},
                    {key: 'lastName', label: 'Nazwisko', sortable: true},
                    {key: 'email', label: 'E-mail', sortable: true},
                    {key: 'isManager', label: 'Manager', class: 'text-center'},
                    {key: 'unit.name', label: 'Jednostka Organizacyjna', sortable: true},
                    {key: 'actions', label: ''},
                ],
                unitId: null
            }
        },
        mixins: [mixin, charLimiter],
        methods: {
            loadData: function (person) {
                this.person = person;
            },
            edit: function(){
              this.$emit('edit');
            },
            async get() {
                let vm = this;
                let desc = 'desc';
                if (vm.sortDesc !== true) {
                    desc = 'asc';
                }

                let params = {};

                if (this.sortBy !== '') {
                    let key = "order[" + this.sortBy + "]";
                    params[key] = desc;
                }
                params['page'] = vm.currentPage;
                params['unit.id'] = vm.unitId;

                let res = await axiosInstance
                    .get(apiConfig.peopleApiUrl,
                        {
                            params,
                            headers: this.$data.headers
                        });
                vm.data = res.data['member'];
                vm.totalItems = res.data['totalItems'];

                return vm.data;
            },
            refresh: async function () {
                await this.get();
                if(this.$refs.table) {
                    this.$refs.table.refresh();
                }
            }
        }
    }
</script>
