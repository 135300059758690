<template>
    <admin>
        <div class="wpip-rates">
            <people-rates-list></people-rates-list>
            <div class="uk-container-expand uk-padding">
                <router-link :to="{ name: 'zpcs', params: { buildingId: $route.params.buildingId } }">
                    <button
                            class="uk-button wpip-button uk-width-auto uk-margin-small-right"
                    >Wstecz
                    </button>
                </router-link>
                <router-link :to="{ name: 'tools', params: { buildingId: $route.params.buildingId } }">
                    <button class="uk-button uk-button-default"><vk-icon icon="thumbnails"></vk-icon> Lista metod</button>
                </router-link>
            </div>
        </div>
    </admin>
</template>

<script>
    import PeopleRatesList from "../../components/zpc/peopleRatesList";

    export default {
        name: "zpcPeopleRates",
        components: {PeopleRatesList},
        async mounted() {
            await this.$ability.getAbility();
            if (!this.$ability.checkManageIasAbility('ZPC')) {
                await this.$router.push({name: 'not-allowed'});
            }
        }
    };
</script>
