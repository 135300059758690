<template>
    <div class="uk-flex-top uk-modal-containter-fluid" container=".admin" id="wpip-goal-add" uk-modal>
        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-modal-body">
                <h3>{{ modal.title }}</h3>

                <div class="uk-margin">
                    <label class="uk-form-label">Określ cel strategiczny</label>
                    <div class="uk-form-controls">
                <textarea
                        class="uk-textarea"
                        rows="5"
                        v-model="goal.name"/>
                    </div>
                </div>


                <div class="uk-margin">
                    <label class="uk-form-label">Opisz mierniki realizacji</label>
                    <div class="uk-form-controls">
                <textarea
                        class="uk-textarea"
                        rows="5"
                        v-model="goal.kpis"/>
                    </div>
                </div>

                <div class="uk-grid-small uk-margin" uk-grid>
                    <div class="uk-width-1-2@s">
                        <label class="uk-form-label">Osoba odpowiedzialna</label>
                        <div class="uk-form-controls">
                            <v-select :filterable="false" name="responsible"
                                      data-vv-as="Osoba odpowiedzialna" :options="responsible" @search="search"
                                      label="@id" :class="'wpip-select'" v-model="goal.responsible" v-validate="'required'">

                                <template slot="option" slot-scope="option">
                                    <div class="d-center">
                                        {{ option.firstName }} {{ option.lastName }}
                                    </div>
                                </template>

                                <template slot="selected-option" slot-scope="option">
                                    <div class="selected d-center">
                                        {{ option.firstName }} {{ option.lastName }}
                                    </div>
                                </template>

                                <template v-slot:no-options="{ search, searching }">
                                    <template v-if="searching">
                                        Nie znaleziono <em>{{ search }}</em>.
                                    </template>
                                    <em style="opacity: 0.5;" v-else>Wyszukaj osobę</em>
                                </template>
                            </v-select>
                            <span class="validation-error">{{ errors.first('responsible') }}</span>
                        </div>
                    </div>
                    <div class="uk-width-1-2@s">
                        <label class="uk-form-label">Termin realizacji</label>
                        <div class="uk-inline uk-width-1-1">
                            <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>
                            <datepicker type="date" name="dueDate" v-model="goal.realization"
                                        data-vv-as="Termin realizacji"
                                        :language="pl" :input-class="'uk-input'" :format="'dd.MM.yyyy'"
                                        v-validate="'required'"/>
                            <span class="validation-error">{{ errors.first('dueDate') }}</span>
                        </div>
                    </div>
                </div>

            </div>


            <div class="uk-modal-footer uk-text-right">
                <button @click="cancel" class="uk-button uk-button-default uk-modal-close" type="button">Anuluj</button>
                <button @click="submitBenefit" class="uk-button wpip-button" type="button">{{modal.button}}
                </button>
            </div>
        </div>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import UIkit from "uikit";
    import axiosInstance from "../../axiosInstance";
    import {pl} from "vuejs-datepicker/src/locale";
    import Datepicker from "vuejs-datepicker";

    export default {
        name: 'goalModal',
        data() {
            return {
                pl: pl,
                filterValue: "",
                responsible: [],
                goal: {
                    id: null,
                    name: "",
                    kpis: "",
                    responsible: null,
                },
                modal:
                    {
                        title: "Dodaj nowy goal",
                        button: "Dodaj"
                    },

            }
        },
        components: {
            datepicker: Datepicker,
        },
        mixins: [mixin],
        methods: {
            fullName: function (person) {
                return person.firstName + " " + person.lastName;
            },
            search: function (e) {
                let vm = this;
                clearTimeout(this.searchTimeout)
                this.searchTimeout = setTimeout(function () {
                    vm.getPeople(e)
                }, 500);
            },
            async getPeople(e) {
                let vm = this;
                let params = {};
                params['firstName'] = e.split(' ');
                params['lastName'] = e.split(' ');
                let res = await axiosInstance
                    .get(apiConfig.peopleApiUrl,
                        {
                            params,
                            headers: this.$data.headers
                        });
                vm.responsible = res.data['member'];
                return vm.data;
            },
            showModal: function (goal) {
                if (goal == null) {
                    this.goal.id = null;
                    this.goal.name = "";
                    this.goal.kpis = "";
                    this.goal.person = null;
                    this.goal.realization = null;
                    this.goal.responsible = null;

                    this.modal.button = "Dodaj";
                    this.modal.title = "Dodaj nowy cel";
                } else {
                    this.goal.id = goal.id;
                    this.goal.name = goal.name;
                    this.goal.kpis = goal.kpis;
                    this.goal.responsible = goal.person;
                    this.goal.realization = goal.realization;

                    this.modal.button = "Zmień";
                    this.modal.title = "Zmień cel";
                }
                this.$validator.reset();

                UIkit.modal("#wpip-goal-add").show();
            },
            submitBenefit: async function () {
                let vm = this;
                vm.$validator.validate().then((result) => {
                    if (result) {
                        let postData = {
                            name: this.goal.name,
                            kpis: this.goal.kpis,
                            person: this.goal.responsible['@id'],
                            realization: this.goal.realization,
                            building: "/api/buildings/" + this.$route.params.buildingId
                            //bonus: this.goal.bonus
                        };
                        if (this.goal.id == null) {
                            postData.author = '/api/users/' + localStorage.getItem('user')
                            axiosInstance
                                .post(apiConfig.goalsApiUrl, JSON.stringify(postData), {
                                    headers: this.$data.headers
                                })
                                .then(async function (response) {
                                    if (response.status === 201) {
                                        UIkit.modal("#wpip-goal-add").hide();
                                        vm.$emit('refresh');
                                    }
                                }).catch();
                        } else {
                            axiosInstance
                                .put(apiConfig.goalsApiUrl + "/" + vm.goal.id, JSON.stringify(postData), {
                                    headers: this.$data.headers
                                })
                                .then(async function (response) {
                                    if (response.status === 200) {
                                        UIkit.modal("#wpip-goal-add").hide();
                                        vm.$emit('refresh');
                                    }
                                }).catch();
                        }
                    }
                }).catch(() => {
                    return false;
                });
            },
            cancel: function () {
                this.$emit('refresh');
            }
        },
        mounted: function () {
            this.getPeople("");
        }

    }
</script>
<style scoped>
    .correlation-header {
        font-size: 1.3em;
    }
</style>
