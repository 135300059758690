<template>
    <span>
            <a
                    @click.prevent="removeCost(datatable)"
                    class="uk-icon-button"
                    href="#"
                    uk-tooltip="Usuń koszt"
            >
                <vk-icon class="hover" icon="trash"/>
            </a>
            <a
                    @click.prevent="editCost(datatable)"
                    class="uk-icon-button"
                    href="#"
                    uk-tooltip="Zmień koszt"
            >
                <vk-icon class="hover" icon="pencil"/>
            </a>
        <remove ref="remove" v-on:refresh="$emit('refresh')"/>
    </span>
</template>

<script>

    import mixin from "../../components/mixin";
    import apiConfig from "../../apiConfig";
    import remove from "../ui/remove";

    export default {
        name: 'costActions',
        components: {
            remove
        },
        mixins: [mixin],
        props: {
            datatable: Object,
            index: null
        },
        methods: {
            removeCost: function () {
                this.$refs.remove.remove(apiConfig.invoicesApiUrl + '/' + this.datatable.item.id, "koszt");
            },
            editCost: function (datatable) {
                this.$emit('editCost', datatable.item);
            }
        }
    }
</script>
