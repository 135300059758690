<template>
    <span>
            <a
                    @click.prevent="removeRelation(datatable)"
                    class="uk-icon-button"
                    href="#"
                    uk-tooltip="Usuń powiązanie"
            >
                <vk-icon class="hover" icon="trash"/>
            </a>
        <remove ref="remove" v-on:refresh="remove"/>
    </span>
</template>

<script>

    import mixin from "../../components/mixin";
    import apiConfig from "../../apiConfig";
    import remove from "../ui/remove";
    import axiosInstance from "../../axiosInstance";
    import UIkit from "uikit";

    export default {
        name: 'usersListActions',
        components: {
            remove
        },
        mixins: [mixin],
        props: {
            datatable: Object,
            index: null
        },
        methods: {
            removeRelation(relation) {
                let vm = this;
                UIkit.modal.confirm("Czy chcesz usunąć powiązanie użytkownika z obiektem?", {
                    labels: {ok: "Usuń", cancel: "Anuluj"}
                }).then(function () {
                    axiosInstance
                        .delete(apiConfig.buildingsApiUrl + '/remove-user/' + vm.$attrs.building + '/' + relation.id, {
                            headers: vm.$data.headers
                        }).then(function () {
                        vm.$parent.$emit('refresh', vm.index);

                    }).catch(function () {
                        UIkit.modal.alert("Nie można usunąć wybranego elementu")
                    });
                });
            },
            remove() {
                this.$parent.$el.remove();
                this.$parent.$emit('refresh', this.index);
            }
        }
    }
</script>
