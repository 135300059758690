<template>
    <form method="post" id="personEditor" @submit="checkForm">
        <fieldset class="uk-fieldset">

            <legend class="uk-legend">Osoba</legend>
            <div class="uk-margin">
                <label class="uk-form-label">Imię</label>
                <input class="uk-input" name="first-name" v-model="person.firstName" type="text" placeholder="Imię" data-vv-as="imię"
                       v-validate="'required'">
                <span class="validation-error">{{ errors.first('first-name') }}</span>
            </div>
            <div class="uk-margin">
                <label class="uk-form-label">Nazwisko</label>
                <input class="uk-input" name="last-name" v-model="person.lastName" type="text" placeholder="Nazwisko" data-vv-as="nazwisko"
                       v-validate="'required'">
                <span class="validation-error">{{ errors.first('last-name') }}</span>
            </div>
            <div class="uk-margin">
                <label class="uk-form-label">E-mail</label>
                <input class="uk-input" name="email" v-model="person.email" type="text" placeholder="E-mail" data-vv-as="e-mail"
                       v-validate="'required|email'">
                <span class="validation-error">{{ errors.first('email') }}</span>
            </div>
            <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                <label><input class="uk-checkbox" type="checkbox" name="is-manager" v-model="person.isManager">
                    Manager</label>
            </div>
            <div class="uk-margin">
                <label class="uk-form-label">Jednostka organizacyjna</label>
                <model-list-select
                        :class="'uk-input'"
                        :list="units"
                        @searchchange="getUnits"
                        option-text="name"
                        option-value="@id"
                        placeholder="Wybierz jednostkę organizacyjną"
                        v-model="person.unit"
                >
                </model-list-select>
                <span class="validation-error">{{ errors.first('unit') }}</span>
            </div>

            <input type="submit" class="uk-button uk-button-default" value="Zapisz">
        </fieldset>
    </form>
</template>

<script>
    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import axiosInstance from "../../axiosInstance";
    import {ModelListSelect} from 'vue-search-select'

    export default {
        name: 'personForm',
        components: {
            ModelListSelect
        },
        data() {
            return {
                person:
                    {
                        id: null,
                        firstName: null,
                        lastName: null,
                        email: null,
                        isManager: false,
                        unit: null,
                        user: null
                    },
                units: [],
                users: []
            }
        },
        mixins: [mixin],
        created: function () {
            let vm = this;
            var params = {};

            params['status'] = 1;
            axiosInstance
                .get(apiConfig.unitsApiUrl, {
                    params,
                    headers: this.$data.headers
                })
                .then(function (response) {
                    vm.units = response.data['member'];
                });
            axiosInstance
                .get(apiConfig.usersApiUrl, {
                    headers: this.$data.headers
                })
                .then(function (response) {
                    vm.users = response.data['member'];
                });
        },
        methods: {
            loadData: function (person) {
                this.person = person;
                this.person.unit = person.unit['@id'];
            },
            getUnits: async function (e) {
                let vm = this;
                var params = {};

                params['status'] = 1;
                params['name'] = e;
                await axiosInstance
                    .get(apiConfig.unitsApiUrl, {
                        params,
                        headers: this.$data.headers
                    })
                    .then(function (response) {
                        vm.units = response.data['member'];
                    });
            },
            checkForm: function (e) {
                e.preventDefault();
                let vm = this;
                let postData = {
                    firstName: this.person.firstName,
                    lastName: this.person.lastName,
                    email: this.person.email,
                    isManager: this.person.isManager,
                    unit: this.person.unit
                };

                vm.$validator.validate().then((result) => {
                    if (result) {
                        this.$emit('submit', postData);
                    }
                }).catch(() => {
                    return false;
                });
            }
        }
    }
</script>
