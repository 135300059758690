<template>
    <span>
        <!--
            <a
                    @click.prevent="editParameter(datatable.item)"
                    class="uk-icon-button"
                    href="#"
                    uk-tooltip="Zmień parametr"
            >
                <vk-icon class="hover" icon="file-edit"></vk-icon>
            </a> -->
            <a
                    @click.prevent="removeProperty(datatable.item)"
                    class="uk-icon-button"
                    href="#"
                    uk-tooltip="Usuń Cechę"
            >
                <vk-icon class="hover" icon="trash"/>
            </a>
        <remove ref="remove" v-on:refresh="refresh"/>
    </span>
</template>

<script>

    import mixin from "../../components/mixin";
    import apiConfig from "../../apiConfig";
    import remove from "../ui/remove";

    export default {
        name: 'qfdPropertiesActions',
        components: {
            remove
        },
        mixins: [mixin],
        props: {
            datatable: Object
        },
        methods: {
            removeProperty(property) {
                this.$refs.remove.remove(apiConfig.qfdPropertiesApiUrl + "/" + property.propertyId, "cechę");
            },
            refresh() {
                this.$emit('remove', this.datatable.index);
            }
        }
    }
</script>
