<template>
    <form method="post" id="unitEditor" @submit="checkForm">
        <fieldset class="uk-fieldset">

            <legend class="uk-legend">Jednostka organizacyjna</legend>
            <div class="uk-margin">
                <label class="uk-form-label">Nazwa</label>
                <input class="uk-input" name="name" v-model="unit.name" type="text" placeholder="Nazwa" data-vv-as="nazwa"
                       v-validate="'required'">
                <span class="validation-error">{{ errors.first('name') }}</span>
            </div>


            <div class="uk-margin">
                <label class="uk-form-label">Liczba usprawnień w miesiącu</label>
                <input class="uk-input" name="limit" v-model="unit.improvementsLimit" type="text" placeholder="0" data-vv-as="liczba usprawnień w miesiącu"
                       v-validate="'required|integer'" >
                <span class="validation-error">{{ errors.first('limit') }}</span>
            </div>

            <input type="submit" class="uk-button uk-button-default" value="Zapisz">
        </fieldset>
    </form>
</template>

<script>
    export default {
        name: 'unitForm',
        data() {
            return {
                unit:
                    {
                        id: null,
                        name: null,
                        improvementsLimit: null
                    }
            }
        },
        methods: {
            loadData: function (unit) {
                this.unit = unit;
            },
            checkForm: function (e) {
                e.preventDefault();
                let vm = this;
                let postData = {
                    name: this.unit.name,
                    improvementsLimit: parseInt(this.unit.improvementsLimit)
                };
                vm.$validator.validate().then((result) => {
                    if (result) {
                        this.$emit('submit', postData)
                    }
                }).catch(() => {
                    return false;
                });
            }
        }
    }
</script>
