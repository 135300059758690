<template>
    <div class="text-right nowrap">
        <a
                @click.prevent="budgetDetails()"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Szczegóły budżetu"
        >
            <vk-icon class="hover" icon="plus"/>
        </a>
        <a
                @click.prevent="editBudget()"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Edytuj budżet"
        >
            <vk-icon class="hover" icon="pencil"/>
        </a>
        <a
                @click.prevent="copyBudget()"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Kopiuj budżet"
        >
            <vk-icon class="hover" icon="copy"/>
        </a>
        <a
                @click.prevent="deleteBudget()"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Usuń budżet"
        >
            <vk-icon class="hover" icon="trash"/>
        </a>
        <remove ref="remove" v-on:refresh="$emit('refresh')"/>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import remove from "../ui/remove";

    export default {
        name: 'budgetsActions',
        components: {
            remove
        },
        mixins: [mixin],
        props: {
            datatable: Object
        },
        methods: {
            editBudget: function () {
                this.$emit('edit', this.datatable.item);
            },
            copyBudget: function () {
                this.$emit('copy', this.datatable.item);
            },
            budgetDetails: function(){
                this.$router.push({name:"budgetDetails", params: {budgetId: this.datatable.item.id}});
            },
            deleteBudget: function () {
                this.$refs.remove.remove(apiConfig.budgetsApiUrl + '/' + this.datatable.item.id, "budżet");
            }
        }
    }
</script>
<style scoped>
    .nowrap{
        white-space: nowrap;
    }
</style>