<template>
    <div class="uk-flex-top uk-modal-container uk-modal" id="unit-modal">
        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close/>
            <div class="uk-modal-body">
                <h3>{{ modal.title }}</h3>
            <div>
                <table class="table">
                    <tr>
                        <th class="uk-width-1-6">Nazwa</th>
                        <td class="uk-width-5-6">{{ modal.unit.name }}</td>
                    </tr>
                    <tr>
                        <th>Usprawnienia / miesiąc</th>
                        <td>{{ modal.unit.improvementsLimit }}</td>
                    </tr>
                </table>
            </div>
            <h3>Osoby:</h3>
                <people-list ref="people" v-on:edit="hide"/>
            </div>
        </div>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import PeopleList from "../person/peopleList";
    import axiosInstance from "../../axiosInstance";
    import UIkit from 'uikit'

    export default {
        name: 'unitDetails',
        components: {
            'peopleList': PeopleList
        },
        data() {
            return {
                sortBy: 'name',
                sortDesc: false,
                data: [],
                currentPage: 1,
                totalItems: '',
                showDetails: false,
                modalDom: null,
                fields: [
                    {key: 'name', label: 'Nazwa', sortable: true},
                    {key: 'email', label: 'E-mail', sortable: true},
                    {key: 'taxNo', label: 'NIP', class: 'text-center'},
                    {key: 'actions', label: ''},
                ],
                modal:
                    {
                        title: "Jednostka Organizacyjna",
                        unit: [],
                    },

            }
        },
        mixins: [mixin],
        methods: {
            clickedRow: async function (unitId) {
                this.$refs.people.unitId = unitId;
                this.$refs.people.refresh();
                let vm = this;
                await axiosInstance.get(apiConfig.unitsApiUrl + '/' + unitId, {
                    headers: this.$data.headers
                }).then(function (result) {
                    vm.modal.unit = result.data;
                });
                this.modalDom.show();
            },
            hide: function(){
                this.modalDom.hide();
            }
        },
        mounted() {
             this.modalDom = UIkit.modal('#unit-modal');
        }
    }
</script>
