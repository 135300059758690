<template>
    <admin>
        <div>
            <h3>Wprowadź silne i słabe strony oraz szanse i zagrożenia (po ok. 10)</h3>
            <div class="uk-grid-match uk-child-width-1-2@m" uk-grid>
                <div :key="index" v-for="(block, index) in swot">
                    <swot-elements-block v-bind:analysisType="data.type" v-bind:block="block" v-bind:index="index"/>
                </div>
            </div>
            <div class="uk-grid" uk-grid>
                <div class="uk-width-expand uk-text-left">
                    <router-link v-bind:to="backLocation">
                        <button
                                class="uk-button wpip-button uk-width-auto"
                        >Lista analiz typu SWOT
                        </button>
                    </router-link>
                </div>
                <div class="uk-text-right uk-width-expand">
                    <span>
                        <button
                                class="uk-button wpip-button uk-width-auto" v-on:click="nextStep"
                        >Ustal Korelacje
                        </button>
                    </span>
                </div>
            </div>
        </div>
    </admin>
</template>

<script>

    import mixin from "../../components/mixin";
    import apiConfig from "../../apiConfig";
    import SwotElementsBlock from "../../components/swot/swotElementsBlock";
    import axiosInstance from "../../axiosInstance";
    import UIkit from "uikit";
    import swotAnalysisTypes from "../../constants/swotAnalysisTypes";

    export default {
        name: 'swotElements',
        components: {
            'swotElementsBlock': SwotElementsBlock
        },
        mixins: [mixin],
        data() {
            return {
                sortBy: 'name',
                sortDesc: false,
                data: [],
                swotAnalysisTypes: swotAnalysisTypes,
                backLocation: "/buildings/" + this.$route.params.buildingId + "/swots",
                currentPage: 1,
                totalItems: '',
                showDetails: false,
                swot: {
                    strengths: {
                        title: "Silne strony",
                        inputName: "strength",
                        typeFilter: 's',
                        color: "uk-text-success",
                        sum: 0,
                        data: []
                    },
                    weaknesses: {
                        title: "Słabe strony",
                        inputName: "weakness",
                        typeFilter: 'w',
                        color: "",
                        sum: 0,
                        data: []
                    },
                    opportunities: {
                        title: "Szanse",
                        inputName: "oportunity",
                        typeFilter: 'o',
                        color: "uk-text-primary",
                        sum: 0,
                        data: []
                    },
                    threads: {
                        title: "Zagrożenia",
                        inputName: "thread",
                        typeFilter: 't',
                        color: "uk-text-danger",
                        sum: 0,
                        data: []
                    }
                }

            }
        },
        methods: {
            nextStep: async function () {
                let vm = this;
                if (this.data.type === this.swotAnalysisTypes.TYPE_TOWS) {
                    await this.get();
                    let errors = [];
                    let warnings = [];
                    Object.keys(this.swot).forEach(function (key) {
                        vm.swot[key].data.forEach(function (element) {
                            if (!element.weight || element.weight === 0) {
                                errors.push(`W sekcji <span class="error-label">${vm.swot[key].title}</span> istnieje element <span class="error-label">${element.name}</span>, który nie ma przypisanej wagi`)
                            }
                        });
                        if (vm.swot[key].sum.toFixed(2) !== "1.00") {
                            errors.push(`Suma wag elementów w sekcji  <span class="error-label"> ${vm.swot[key].title} </span> jest różna od 1`);
                        }
                        if (vm.swot[key].data.length < 10) {
                            warnings.push("Liczba elementów w sekcji " + vm.swot[key].title + " jest mniejsza od 10");
                        }
                    });
                    if (errors.length > 0) {
                        let msg = `<h2>Wystąpiły następujące błędy:</h2>`;
                        errors.forEach(function (error) {
                            msg += `<p>${error}</p>`
                        });
                        UIkit.modal.alert(msg, {
                            labels: {ok: "Powrót"}
                        })
                    } else if (warnings.length > 0) {
                        let msg = ""
                        warnings.forEach(function (warning) {
                            msg += `<p>${warning}</p>`;
                        });
                        msg += `<p>Czy na pewno przejść do kolejnego kroku?</p>`;
                        UIkit.modal.confirm(msg, {
                            labels: {ok: "Dalej", cancel: "Anuluj"}
                        }).then(function () {
                            vm.$router.push({name: 'swotElementsCorrelation'});
                        })
                    } else {
                        this.$router.push({name: 'swotElementsCorrelation'});
                    }
                } else {
                  let warnings = [];
                  Object.keys(this.swot).forEach(function (key) {
                    if (vm.swot[key].data.length < 10) {
                      warnings.push("Liczba elementów w sekcji " + vm.swot[key].title + " jest mniejsza od 10");
                    }
                  })
                  if (warnings.length > 0) {
                    let msg = ""
                    warnings.forEach(function (warning) {
                      msg += `<p>${warning}</p>`;
                    });
                    msg += `<p>Czy na pewno przejść do kolejnego kroku?</p>`;
                    UIkit.modal.confirm(msg, {
                      labels: {ok: "Dalej", cancel: "Anuluj"}
                    }).then(function () {
                      vm.$router.push({name: 'swotElementsCorrelation'});
                    })
                  } else {
                    this.$router.push({name: 'swotElementsCorrelation'});
                  }
                }
            },
            async get() {

                let vm = this;
                axiosInstance
                    .get(apiConfig.swotApiUrl + '/' + this.$route.params.swotId,
                        {
                            headers: vm.$data.headers
                        }).then(function (res) {
                    vm.data = res.data;
                });

                Object.keys(vm.swot).forEach(function (key) {


                    var params = {};
                    params['page'] = vm.currentPage;
                    params['swotAnalysis'] = vm.$route.params.swotId;
                    params['swotElementType'] = vm.swot[key].typeFilter;
                    axiosInstance
                        .get(apiConfig.swotElementsApiUrl,
                            {
                                params,
                                headers: vm.$data.headers
                            }).then(function (res) {
                        vm.swot[key].data = res.data['member'];
                        vm.swot[key].sum = 0;
                        res.data['member'].forEach(function (element) {
                            if (element.weight) {
                                vm.swot[key].sum += parseFloat(element.weight);
                            }
                        });

                    });
                    return vm.data;
                });
            },

        },
        async mounted() {
            await this.$ability.getAbility();
            if (!this.$ability.checkManageIasAbility('SWOT')) {
                await this.$router.push({name: 'not-allowed'});
            }
            this.get();
        }

    }
</script>
<style>
    .error-label {
        color: red;
        font-weight: bold;
    }
</style>
