<template>
    <div class="uk-width-1-6@m">
        <vk-nav>
            <li>
                <router-link :to="{ name: 'buildings' }"><vk-icon icon="home"></vk-icon> Budynki</router-link>
            </li>
            <li>
                <router-link :to="{ name: 'units' }" ><vk-icon icon="git-branch"></vk-icon> Jednostki organizacyjne</router-link>
            </li>
            <li>
                <router-link :to="{ name: 'users' }" ><vk-icon icon="users"></vk-icon> Użytkownicy</router-link>
            </li>
            <li>
                <router-link :to="{ name: 'people' }"><vk-icon icon="user"></vk-icon> Osoby</router-link>
            </li>
            <li>
                <router-link :to="{ name: 'contractors' }"><vk-icon icon="happy"></vk-icon> Kontrahenci</router-link>
            </li>
            <li>
                <router-link :to="{ name: 'industries' }"><vk-icon icon="tag"></vk-icon> Branże</router-link>
            </li>
            <li>
                <router-link :to="{ name: 'iasPhases' }"><vk-icon icon="album"></vk-icon> Fazy IAS</router-link>
            </li>
            <li>
                <router-link :to="{ name: 'iasFiles' }"><vk-icon icon="folder"></vk-icon> Pliki IAS</router-link>
            </li>
            <li>
                <router-link :to="{ name: 'eventTypes' }"><vk-icon icon="future"></vk-icon> Typy zdarzeń</router-link>
            </li>
        </vk-nav>
    </div>
</template>

<script>

    export default {
        name: 'admin-menu'
    }

</script>
<style>
  .router-link-active{
    color: #666!important;
  }
</style>
