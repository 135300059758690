<!--suppress HtmlFormInputWithoutLabel -->
<template>
<div>
  <div class="uk-grid uk-margin-bottom" uk-grid>
    <div class="uk-width-expand">
      <form class="uk-search uk-search-default uk-width-1-1">
        <span class="uk-search-icon-flip" uk-search-icon />
        <input class="uk-search-input" v-model="filter" @input="search" placeholder="Wyszukaj elementy analizy" type="search" />
      </form>
    </div>
  </div>
  <empty-list v-if="totalItems===0" />
  <b-table v-else ref="table" hover borderedless no-sort-reset :responsive="true" :tbody-tr-class="rowClass" :fields="fields" :columns="fields" :filter="filter" :items="get" :per-page="this.$data.perPage" :current-page="currentPage"
    :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">

    <template v-slot:cell(actions)="data">
      <fmea-elements-actions :datatable=data v-on:edit="edit" v-on:rate="rate" v-on:refresh="refresh" />
    </template>
    <template v-slot:cell(iasPhase)="data">
      {{ iasPhase[data.item.iasPhase-1].name }}
    </template>
    <template v-slot:cell(object)="data">
      {{ iasSubphase[data.item.object-1].name }}
    </template>
    <template v-slot:cell(lpr)="data">
      <span v-if="data.item.newRisk && data.item.newClientValue && data.item.newDetection">{{ data.item.newClientValue * data.item.newRisk * data.item.newDetection }} ({{ data.item.clientValue * data.item.risk * data.item.detection }})</span>
      <span v-else>{{ data.item.clientValue * data.item.risk * data.item.detection }}</span>
    </template>
    <template v-slot:cell(person)="data">

      {{ data.item.person.firstName + " " + data.item.person.lastName }}
    </template>
    <template v-slot:cell(status)="data">
      <span v-if="!data.item.takenActions" class="uk-label wpip-label">nowy</span>
      <span v-else>
        <span v-if="!data.item.newRisk" class="uk-label">do oceny</span>
        <span v-else class="uk-label uk-label-success">oceniony</span>
      </span>
    </template>

  </b-table>
  <b-pagination :total-rows="totalItems" :per-page="perPage" v-model="currentPage" align="center" v-if="totalItems>perPage" size="sm" page-class="wpip-page" class="wpip-page" />
</div>
</template>

<script>
import mixin from "../mixin";
import apiConfig from "../../apiConfig";
import FmeaElementsActions from "./fmeaElementsActions";
import axiosInstance from "../../axiosInstance";
import EmptyList from "../ui/emptyList";

export default {
  name: 'fmeasList',
  components: {
    EmptyList,
    fmeaElementsActions: FmeaElementsActions
  },
  data() {
    return {
      iasPhase: [{
          id: 1,
          name: "Projektowanie"
        },
        {
          id: 2,
          name: "Budowa"
        },
        {
          id: 3,
          name: "Eksploatacja"
        },
        {
          id: 4,
          name: "Rozwój"
        }
      ],
      iasSubphase: [{
          id: 1,
          name: "Magazyn"
        },
        {
          id: 2,
          name: "Biuro"
        },
        {
          id: 3,
          name: "Hala produkcyjna"
        },
        {
          id: 4,
          name: "Parking"
        }
      ],
      sortBy: 'name',
      sortDesc: false,
      filter: '',
      searchTimeout: null,
      data: [],
      props: {
        limit: 5
      },
      currentPage: 1,
      totalItems: '',
      fields: [{
          key: 'iasPhase',
          label: 'Faza IAS',
          sortable: true
        },
        {
          key: 'object',
          label: 'Obiekt',
          sortable: true
        },
        {
          key: 'failureDescription',
          label: 'Wada',
          sortable: true
        },
        {
          key: 'lpr',
          label: 'LPR',
          class: 'lpr'
        },
        {
          key: 'dueDate',
          label: 'Termin realizacji',
          sortable: true,
          class: 'uk-table-expand'
        },
        {
          key: 'person',
          label: 'Odpowiedzialny'
        },
        {
          key: 'status',
          label: 'Status',
          sortable: true
        },
        {
          key: 'actions',
          label: '',
          class: 'text-right uk-table-expand'
        }
      ]
    }
  },
  mixins: [mixin],
  methods: {
    async get() {
      let vm = this;
      let desc = 'desc';
      if (vm.sortDesc !== true) {
        desc = 'asc';
      }

      let params = {};

      if (this.sortBy !== '') {
        let key = "order[" + this.sortBy + "]";
        params[key] = desc;
      }
      params['page'] = vm.currentPage;
      params['fmeaAnalysis'] = vm.$route.params.analysisId;
      params['failureDescription'] = vm.filter;

      let res = await axiosInstance
        .get(apiConfig.fmeaElementsApiUrl, {
          params,
          headers: this.$data.headers
        });
      vm.data = res.data['member'];
      vm.totalItems = res.data['totalItems'];
      return vm.data;
    },
    search: function(e) {
      let vm = this;
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(function() {
        vm.get(e)
      }, 500);
    },
    edit(e) {
      this.$emit('edit', {
        ...e
      });
    },
    rate(e) {
      this.$emit('rate', {
        ...e
      });
    },
    rowClass(item) {
      if ((item.clientValue * item.risk * item.detection > this.$attrs.limit && item.newClientValue == null) || item.newClientValue * item.newRisk * item.newDetection > this.$attrs.limit) return 'wpip-fmea-danger'

    },
    refresh() {
      this.get();
      if (this.$refs.table) {
        this.$refs.table.refresh();
      }
    }
  }
}
</script>
<style>
.wpip-fmea-danger {
  background-color: #bf0e011e;
  font-weight: bold;
}

.wpip-fmea-danger .lpr {
  color: #bf0e01;
  font-size: 1.3em;
}

.wpip-label {
  border: 1px solid #bf0e01;
  background-color: #fff;
  color: #bf0e01;
}
</style>
