<template>
    <div>
        <dashboard-element-modal ref="modal" v-on:refresh="refresh"/>
        <vk-grid class="uk-margin-small-bottom">
            <h3 class="uk-width-expand">{{ dashboard.name }}
            </h3>
        </vk-grid>
        <vk-grid :key="'hp'+i" v-for="(row, i) in elements">
            <div :key="'vp'+j" v-for="(element,j) in row" :class="'uk-width-'+getWidth(element)" @click="add(i,j)">
                <div class="card" v-if="element!==-1">
                    <div class="card-body text-center" v-if="element===0"> <vk-icon class="hover" icon="plus"/> </div>
                    <div class="card-body uk-width" v-else>{{element.title}}</div>
                </div>
                <span v-else></span>
            </div>
        </vk-grid>

    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import axiosInstance from "../../axiosInstance";
    import DashboardElementModal from "./dashboardElementModal";
    import charLimiter from "../charLimiter";

    export default {
        name: 'devicesList',
        components: {
            DashboardElementModal,
        },
        data() {
            return {
                dashboard: [],
                elements: []
            }
        },
        mixins: [mixin, charLimiter],
        methods: {
            getWidth(element){
                if(element===0){
                    return "1-6@m";
                }
                if(element===-1){
                    return "0";
                }
                else {
                    if(element.width===1){
                        return "1-6@m";
                    }
                    if(element.width===2){
                        return "1-3@m";
                    }
                    if(element.width===3){
                        return "1-2@m";
                    }
                    if(element.width===4){
                        return "2-3@m";
                    }
                    if(element.width===5){
                        return "5-6@m";
                    }
                    if(element.width===6){
                        return "1-1@m";
                    }
                }
            },
            async get() {
                let vm = this;
                let res = await axiosInstance
                    .get(apiConfig.dashboardsApiUrl + "/" + vm.$route.params.dashboardId,
                        {
                            headers: this.$data.headers
                        });
                vm.dashboard = res.data;
                vm.generateElements();
                return vm.dashboard;
            },
            refresh: async function () {
                await this.get();
            },
            generateElements: function(){
                this.elements = [];
                let vm = this;
                this.dashboard.elements.forEach(function (element) {
                    if(!vm.elements[element.verticalPosition]){
                        vm.elements[element.verticalPosition] = [0,0,0,0,0,0]
                    }
                    for(let i=element.horizontalPosition+1;i<element.horizontalPosition+element.width; i++){
                        vm.elements[element.verticalPosition][i] = -1;
                    }
                    vm.elements[element.verticalPosition][element.horizontalPosition]=element;
                })
                this.elements.push([0,0,0,0,0,0]);
            },
            add: function (i,j) {
                let element = null;

                if(this.elements[i][j]!==0 && this.elements[i][j]!==-1){
                    element = this.elements[i][j];
                }

                let maxWidth = 1;
                let n = j+1;
                if(element!==null){
                    n = j + element.width;
                    maxWidth = element.width;
                }
                for(n; n<6; n++){
                    if(this.elements[i][n]===0){
                        maxWidth++
                    }
                    else {
                        break
                    }
                }
                this.$refs.modal.showModal(element,i,j,maxWidth);
            },
            edit: function (template) {
                this.$refs.modal.showModal({...template});
            }
        },
        mounted: function () {
            this.get();
        }
    }
</script>
<style scoped>
    .uk-width-0{
        display:none
    }
</style>
