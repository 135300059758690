<template>
  <div>
    <register-value-modal @refresh="refresh" ref="changeValue"></register-value-modal>
    <middleware-modal @refresh="refresh" ref="middlewaresModal"></middleware-modal>
    <register-modal :ref="'registerModal_'+$attrs.deviceId" :device="$attrs.deviceId"
                    :deviceRegisterTypes="$attrs.deviceRegisterTypes" @refresh="refresh"/>
    <div class="mt-3" v-if="!$attrs.deviceId">
      <vk-card class="uk-margin-bottom">
        <h4>Filtry</h4>
        <b-row>
          <b-col>
            <label>Budynek</label>
            <v-select multiple :filterable="false" :options="buildings" @search="searchBuildings"
                      label="@id" :class="'wpip-select'" v-model="filter.buildings"
                      data-vv-as="budynki" ref="multiSelectBuilding"
                      name="buildings" v-validate="'required'" @input="get">

              <template slot="option" slot-scope="option">
                <div class="d-center">
                  {{ option.name }}
                </div>
              </template>

              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  {{ option.name }}
                </div>
              </template>

              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  Nie znaleziono <em>{{ search }}</em>.
                </template>
                <em style="opacity: 0.5;" v-else>Wyszukaj budynek</em>
              </template>
            </v-select>
          </b-col>
          <b-col>
            <label>Urządzenie</label>
            <v-select multiple :filterable="false" :options="devices" @search="searchDevices"
                      label="@id" :class="'wpip-select'" v-model="filter.devices"
                      data-vv-as="urządzenia" ref="multiSelectDevice"
                      name="devices" v-validate="'required'" @input="get">

              <template slot="option" slot-scope="option">
                <div class="d-center">
                  {{ option.name }}
                </div>
              </template>

              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  {{ option.name }}
                </div>
              </template>

              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  Nie znaleziono <em>{{ search }}</em>.
                </template>
                <em style="opacity: 0.5;" v-else>Wyszukaj urządzenie</em>
              </template>
            </v-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <label>Numer</label>
            <b-input class="uk-input" v-model="search.number" debounce="500" @update="get"/>
          </b-col>
          <b-col>
            <label>Nazwa</label>
            <b-input class="uk-input" v-model="search.name" debounce="500" @update="get"/>
          </b-col>
          <b-col>
            <label>Jednostka miary</label>
            <b-input class="uk-input" v-model="search.unitOfMeasure" debounce="500" @update="get"/>
          </b-col>
        </b-row>
      </vk-card>
    </div>
    <div class="uk-grid uk-margin-bottom mt-2">
      <h3 class="uk-width-expand mt-3">Lista Rejestrów</h3>
      <button v-if="$attrs.deviceId" @click="$refs['registerModal_'+$attrs.deviceId].showModal()"
              class="uk-button wpip-button uk-width-auto"
      ><vk-icon icon="plus-circle"></vk-icon> Dodaj rejestr
      </button>

      <button v-else @click="downloadXlsx()"
              class="ml-1 uk-button uk-button uk-button-default uk-width-auto"
      ><vk-icon icon="download"></vk-icon> Eksportuj dane
      </button>
    </div>
    <vk-card>
      <empty-list v-if="totalItems===0"/>
      <b-table v-else :fields="fields" :columns="fields"
               :filter="filter"
               :items="data"
               :primary-key="'id'"
               :sort-by.sync="sortBy"
               :sort-desc.sync="sortDesc"
               :tbody-tr-class="'uk-width-1-1'"
               hover
               borderedless
               no-sort-reset
               :responsive="true"
               ref="table">
        <template v-slot:cell(type)="data">
          <span :uk-tooltip="registerTypeName[data.item.type]">{{ registerTypeShortName[data.item.type] }}</span>
        </template>

        <template v-slot:cell(valueType)="data">
          {{ valueTypeName[data.item.valueType] }}
        </template>

        <template v-slot:cell(name)="data">
          <span :uk-tooltip="data.item.name">{{ limit(data.item, 50) }}</span>
        </template>
        <template v-slot:cell(lastValue)="data">
          <span
              v-if="data.item.valueType !== valueType.string && data.item.valueType !== valueType.coil">{{ data.item.lastValue }}</span>
          <span
              v-if="data.item.valueType === valueType.string || data.item.valueType === valueType.coil">{{ data.item.lastValueString }}</span>
        </template>
        <template v-slot:cell(unitOfMeasure)="data">
          <span v-if="data.item.unitOfMeasure">{{ data.item.unitOfMeasure }}</span>
          <span class="badge badge-secondary" v-else>brak</span>
        </template>
        <!--
          <template v-slot:cell(change_lastvalue)="data">
              <registerChangeLastValue :datatable=data v-on:modified="refresh"/>
          </template>

          -->
        <template v-slot:cell(delete)="data">
          <registersActions :datatable="data" v-on:refresh="refresh" @editRegister="editRegister"
                            @middlewares="showModal" @changeValue="changeValue"/>
        </template>
      </b-table>
      <b-pagination :per-page="perPage" :total-rows="totalItems" align="center" v-model="currentPage"
                    v-if="totalItems>perPage" size="sm" page-class="wpip-page" class="wpip-page" @input="refresh"/>
    </vk-card>
    <file-download ref="download"/>
  </div>
</template>

<script>

import mixin from "../mixin";
import apiConfig from "../../apiConfig";
import RegistersActions from "./registersActions";
import axiosInstance from "../../axiosInstance";
import MiddlewareModal from "./middlewareModal";
import RegisterModal from "./registerModal";
import EmptyList from "../ui/emptyList";
import charLimiter from "../charLimiter";
import RegisterValueModal from "@/components/register/registerValueModal";
import FileDownload from "@/components/ui/fileDownload";

export default {
  name: 'registersList',
  components: {
    FileDownload,
    RegisterValueModal,
    EmptyList,
    RegisterModal,
    MiddlewareModal,
    registersActions: RegistersActions,
  },
  data() {
    return {
      sortBy: 'name',
      sortDesc: false,
      search: {
        name: null,
        number: null,
      },
      filter: {
        buildings: [],
        types: [],
        devices: []
      },
      data: [],
      buildings: [],
      devices: [],
      types: [
        {id: 0, name: "odczyt"},
        {id: 1, name: "zapis"},
        {id: 2, name: "odczyt/zapis"}
      ],
      timeout: null,
      eventSource: null,
      currentPage: 1,
      totalItems: '',
      fields: [
        {key: 'name', label: 'Nazwa', sortable: true},
        {key: 'device.name', label: 'Urządzenie', sortable: true},
        {key: 'device.building.name', label: 'Budynek', sortable: true},
        {key: 'type', label: 'Typ', sortable: true},
        {key: 'valueType', label: 'Typ wartości', sortable: true},
        {key: 'number', label: 'Numer', sortable: true},
        {key: 'lastValueDate', label: 'Data ostatniej wartości', sortable: true},
        {key: 'lastValue', label: 'Ostatnia wartość'},
        {key: 'unitOfMeasure', label: 'Jednostka miary'},
        {key: 'delete', label: ''},
      ]
    }
  },
  mixins: [mixin, charLimiter],
  methods: {
    changeValue: function (e) {
      this.$refs.changeValue.showModal(e)
    },
    editRegister: function (e) {
      this.$refs['registerModal_' + this.$attrs.deviceId].showModal(e)
    },
    async get() {
      let vm = this;
      let desc = 'desc';
      if (vm.sortDesc !== true) {
        desc = 'asc';
      }
      let params = {
        page: vm.currentPage
      }
      if (this.$attrs.deviceId) {
        params['device.id'] = this.$attrs.deviceId
      } else {
        params['device.building.id'] = []
        vm.filter.buildings.forEach((building=>{
          params['device.building.id'].push(building.id)
        }))
        params['device.id'] = []
        vm.filter.devices.forEach((device=>{
          params['device.id'].push(device.id)
        }))
        params['type'] = []
        vm.filter.types.forEach((type=>{
          params['type'].push(type.id)
        }))
        if(this.search.number) {
          params.number = this.search.number
        }
        if(this.search.name) {
          params.name = this.search.name
        }
        if(this.search.unitOfMeasure) {
          params.unitOfMeasure = this.search.unitOfMeasure
        }
      }
      if (this.sortBy !== null && this.sortBy !== "") {
        params['order[' + this.sortBy + ']'] = desc;
      }
      let res = await axiosInstance
          .get(apiConfig.registersApiUrl,
              {
                params,
                headers: this.$data.headers
              });
      vm.data = res.data['member'];
      vm.totalItems = res.data['totalItems'];

      return vm.data;
    },
    refresh: function () {
      this.get();
      //if (this.$refs.table) {
      //this.$refs.table.refresh();
      //}
    }, showModal: function (e) {
      this.$refs.middlewaresModal.showModal(e, e.id)
    },
    timeoutRefresh: function () {

      this.timeout = null;
      this.refresh();
    },
    searchBuildings: function (e) {
      let vm = this;
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(function () {
        vm.getBuildings(e)
      }, 500);
    },
    getBuildings: function (e) {
      let vm = this;
      var params = {};
      params['name'] = e;
      axiosInstance
          .get(apiConfig.buildingsApiUrl, {
            params,
            headers: this.$data.headers
          })
          .then(function (response) {
            vm.buildings = response.data['member'];
          });
    },
    searchDevices: function (e) {
      let vm = this;
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(function () {
        vm.getDevices(e)
      }, 500);
    },
    getDevices: function (e) {
      let vm = this;
      var params = {};
      params['name'] = e;
      axiosInstance
          .get(apiConfig.devicesApiUrl, {
            params,
            headers: this.$data.headers
          })
          .then(function (response) {
            vm.devices = response.data['member'];
          });
    },
    downloadXlsx: function() {
      let params = {
      }

      if(this.search.unitOfMeasure){
        params.unitOfMeasure = this.search.unitOfMeasure
      }
      if(this.search.name){
        params.registerName = this.search.name
      }
      if(this.search.number){
        params.number = this.search.number
      }


      if(this.filter.buildings.length>0){
        params.buildingId = []
        this.filter.buildings.forEach((building)=>{
          params.buildingId.push(building.id)
        })
      }
      if(this.filter.devices.length>0){
        params.deviceId = []
        this.filter.devices.forEach((device)=>{
          params.buildingId.push(device.id)
        })
      }

      this.$refs.download.getApi(apiConfig.registersApiUrl + "/export", "rejestry_"+new Date().toLocaleDateString()+".xlsx", params);
    }
  },
  created: function () {
    let vm = this;
    let url_admin = apiConfig.mercureUrl;
    const url = new URL(url_admin + '/.well-known/mercure');
    url.searchParams.append('topic', apiConfig.apiUrl + '/api/registers/{id}');
    this.eventSource = new EventSource(url);
    this.eventSource.onmessage = () => {
      if (this.timeout == null) {
        this.timeout = setTimeout(function () {
          vm.timeoutRefresh()
        }, 10000);
      }
    };

    this.get();
  },
  mounted() {
    this.getBuildings()
    this.getDevices()
  },

  beforeDestroy() {
    this.eventSource.close();
  }

}
</script>
