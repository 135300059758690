<template>
  <admin>
    <div class="uk-container-expand uk-padding" id="ias-content">
      <vk-grid>
        <menuAdmin/>
        <div class="uk-width-expand">
          <div class="uk-grid uk-margin-bottom">
            <h3 class="uk-width-expand">Typy zdarzeń</h3>
            <button @click="addEventType"
                    class="uk-button wpip-button uk-width-auto"
            ><vk-icon icon="plus-circle"></vk-icon> Dodaj nowy rodzaj zdarzenia
            </button>
          </div>
          <vk-card>
            <event-types-list v-on:edit="editEventType" ref="table"></event-types-list></vk-card>
        </div>
      </vk-grid>
    </div>
    <event-type-modal @refresh="refresh" ref="modal"/>
  </admin>
</template>

<script>

import mixin from "../../components/mixin";
import EventTypesList from "@/components/eventTypes/eventTypeList";
import EventTypeModal from "@/components/eventTypes/eventTypeModal";

export default {
  name: 'iasPhases',
  components: {
    EventTypeModal,
    EventTypesList,
  },
  mixins: [mixin],
  data() {
    return {
      sortBy: 'name',
      sortDesc: false,
      data: [],
      currentPage: 1,
      totalItems: ''
    }
  },
  methods: {
    addEventType: function () {
      this.$refs.modal.showModal({id: null, name: ''});
    },
    editEventType: function (fmea) {
      this.$refs.modal.showModal(fmea);
    },
    refresh: function () {
      this.$refs.table.refresh();
    }

  },
  async mounted() {
    this.ability = await this.$ability.getAbility();
    if (!this.ability.can('manage', 'ROLE_SUPER_ADMIN')) {
      await this.$router.push({name: 'not-allowed'});
    }
  }
}
</script>
