<template>
    <admin>
        <div id="wpip-project">
            <div uk-grid>
                <h3 class="uk-width-expand">Zarządzanie przez cele</h3>
                <button @click="back()" class="uk-button wpip-button uk-width-auto">Lista metod</button>
            </div>
            <div class="uk-child-width-1-3@m uk-grid-small uk-grid-match" id="wpip-tools" uk-grid>
                <div :key="tool.name" v-for="tool in tools">
                    <div class="uk-card uk-card-default uk-card-body">
                        <h3 class="uk-card-title">
                            <vk-icon v-bind:icon="tool.icon"/>
                            {{ tool.title }}
                        </h3>
                        <p>{{ tool.desc }}</p>
                        <button @click="getTool(tool.name)" class="uk-button wpip-button">Wybierz</button>
                    </div>
                </div>
            </div>
        </div>
    </admin>
</template>

<script>
    export default {
        name: "zpcOptions",
        data() {
            return {
                tools: [
                    {
                        name: "goals",
                        icon: "world",
                        title: "Zarządzaj celami",
                        desc: "Zdefiniuj cele strategiczne oraz powiąż z nimi cele szczegółowe oraz operacyjne"
                    },
                    {
                        name: "benefits",
                        icon: "info",
                        title: "Zarządzaj benefitami",
                        desc: "Ustal benefity pracowników, które będą przyznawane za realizację celów."
                    },
                    {
                        name: "peopleRates",
                        icon: "users",
                        title: "Oceń pracowników",
                        desc: "Wygeneruj karty oceny pracownika wynikające z realizacji przypisanych im celów."
                    },
                    {
                        name: "myRates",
                        icon: "users",
                        title: "Moje oceny",
                        desc: "Zapoznaj się ze swoimi ocenami."
                    },
                    {
                        name: "myGoals",
                        icon: "users",
                        title: "Moje cele",
                        desc: "Sprawdź swoje cele."
                    }
                ]
            };
        },
        created() {
            this.pid = this.$route.params.Pid;
        },
        methods: {
            back() {
                this.$router.push({name: "tools"});
            },
            getTool(tool) {
                this.$router.push({name: tool, params: {buildingId: this.$route.params.buildingId}});
            }
        },
        async mounted() {
            await this.$ability.getAbility();
            if (!this.$ability.checkManageIasAbility('ZPC')) {
              this.tools =[
                {
                  name: "myRates",
                  icon: "users",
                  title: "Moje oceny",
                  desc: "Zapoznaj się ze swoimi ocenami."
                },
                {
                  name: "myGoals",
                  icon: "users",
                  title: "Moje cele",
                  desc: "Sprawdź swoje cele."
                }
              ]
            }
        }
    };
</script>
