<template>
    <div>
        <budget-template-modal ref="modal" v-on:refresh="refresh"/>
        <vk-grid class="uk-margin-bottom">
            <h3 class="uk-width-expand">Szablony budżetu
            </h3>
            <button @click="add"
                    class="uk-button wpip-button uk-width-auto"
            ><vk-icon icon="plus-circle"></vk-icon> Dodaj szablon
            </button>
        </vk-grid>
        <vk-card>
            <empty-list v-if="totalItems===0"/>
            <b-table
                    v-else
                    :columns="fields" :current-page="currentPage" :fields="fields"
                    :items="data"
                    :per-page="this.$data.perPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    borderedless
                    no-sort-reset
                    :responsive="true"
                    hover
                    ref="table">

                <template v-slot:cell(name)="data">
                    <span  :uk-tooltip="data.item.name">{{ limit(data.item,100) }}</span>
                </template>
                <template v-slot:cell(actions)="data">
                    <devices-actions v-on:edit="edit" :datatable=data v-on:refresh="refresh"/>
                </template>
            </b-table>
            <b-pagination :per-page="perPage" :total-rows="totalItems" align="center" v-model="currentPage"
                        v-if="totalItems>perPage" size="sm" page-class="wpip-page" class="wpip-page"/>
        </vk-card>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import DevicesActions from "./budgetTemplatesActions";
    import axiosInstance from "../../axiosInstance";
    import BudgetTemplateModal from "./budgetTemplateModal";
    import EmptyList from "../ui/emptyList";
    import charLimiter from "../charLimiter";

    export default {
        name: 'devicesList',
        components: {
            EmptyList,
            BudgetTemplateModal,
            devicesActions: DevicesActions
        },
        data() {
            return {
                sortBy: 'name',
                sortDesc: false,
                data: [],
                currentPage: 1,
                totalItems: '',
                fields: [
                    {key: 'name', label: 'Nazwa', sortable: true},
                    {key: 'actions', label: ''},
                ],
                buildingId: null
            }
        },
        mixins: [mixin, charLimiter],
        methods: {
            async get() {
                let vm = this;
                let desc = 'desc';
                if (vm.sortDesc !== true) {
                    desc = 'asc';
                }

                var params = {};

                if (this.sortBy !== '') {
                    var key = "order[" + this.sortBy + "]";
                    params[key] = desc;
                }
                params['page'] = vm.currentPage;
                params['contractor.id'] = vm.contractorId;
                params['building.id'] = this.$route.params.buildingId;

                let res = await axiosInstance
                    .get(apiConfig.budgetTemplatesApiUrl,
                        {
                            params,
                            headers: this.$data.headers
                        });
                vm.data = res.data['member'];
                vm.totalItems = res.data['totalItems'];
                return vm.data;
            },
            refresh: async function () {
                await this.get();
                if (this.$refs.table) {
                    this.$refs.table.refresh();
                }
            },
            add: function () {
                this.$refs.modal.showModal(null);
            },
            edit: function (template) {
                this.$refs.modal.showModal({...template});
            }
        },
        mounted: function () {
            this.get();
        }
    }
</script>
