<template>
    <admin>
        <div class="uk-container uk-padding">
            <vk-grid>
                <menuAdmin></menuAdmin>
                <div class="uk-width-expand@m">
                    <person-form ref="form" v-on:submit="sendData"></person-form>
                </div>
            </vk-grid>
        </div>
    </admin>
</template>

<script>

    import {Grid} from 'vuikit/lib/grid';
    import mixin from "../../components/mixin";
    import apiConfig from "../../apiConfig";
    import PersonForm from "../../components/person/personForm";
    import axiosInstance from "../../axiosInstance";

    export default {
        name: 'personAdd',
        components: {
            VkGrid: Grid,
            personForm: PersonForm
        },
        data() {
            return {}
        },
        mixins: [mixin],
        methods: {
            sendData: function (postData) {
                let vm = this;
                axiosInstance
                    .post(apiConfig.peopleApiUrl, JSON.stringify(postData), {
                        headers: this.$data.headers
                    })
                    .then(function (response) {
                        if (response.status === 201) {
                            vm.$router.push('/people');
                        }
                    }).catch();
            }
        },

    }
</script>
