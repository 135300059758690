<template>
    <div class="uk-flex-top uk-modal-container uk-modal" container=".admin" id="copy-modal">

        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-modal-body">
                <vk-grid>
                    <h3 class="uk-width-expand">{{ modal.title }}</h3>
                    <button @click="addPosition" v-if="budget.id" class="uk-button wpip-button" type="button">Dodaj
                        Kategorię
                    </button>
                </vk-grid>
                <div class="uk-form-controls">
                    <label class="uk-form-label">
                        Nazwa
                    </label>
                    <input
                            type="text"
                            class="uk-input"
                            data-vv-as="nazwa"
                            name="name"
                            placeholder="Proszę podać nazwę budżetu"
                            v-model="budget.name"
                            v-validate="'required|max:255'"
                    />
                    <span class="validation-error">{{ errors.first('name') }}</span>
                </div>
                <div class="uk-margin">
                    <label>Miesiąc</label>
                    <datepicker :format="'MM.yyyy'" :input-class="'uk-input'"
                                :language="pl" data-uk-datepicker="{format:'MM.YYYY'}" data-vv-as="miesiąc"
                                v-validate="'required'"
                                :minimumView="'month'" :maximumView="'month'"
                                name="fromDate" type="text" v-model="budget.fromDate"
                                v-on:selected="checkMonth">>
                    </datepicker>
                    <span class="validation-error">{{exists}}{{ errors.first('fromDate') }}</span>
                </div>


                <div class="uk-form-controls uk-margin" v-if="budget.id==null">
                    <label>
                        Obiekt IAS
                    </label>
                    <select
                            class="uk-select"
                            data-vv-as="Obiekt IAS"
                            name="building"
                            v-model="budget.building"
                            @change="checkMonth()"
                            v-validate="'required'"
                    >
                        <option v-bind:key="'b_'+building.id" v-for="building in buildings" :value="building['@id']">{{
                            building.name }}
                        </option>

                    </select>
                    <span class="validation-error">{{ errors.first('building') }}</span>
                </div>

                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-default uk-modal-close" type="button">Anuluj</button>
                    <button @click="submit" class="uk-button wpip-button" type="button">{{ modal.button }}</button>
                </div>
            </div>
        </div>
        <category-modal @refresh="$emit('refresh', budget)" ref="addCategory"/>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import UIkit from "uikit";
    import axiosInstance from "../../axiosInstance";
    import Datepicker from 'vuejs-datepicker'
    import 'vue-datetime/dist/vue-datetime.css'
    import {pl} from "vuejs-datepicker/src/locale";
    import CategoryModal from "./categoryModal";

    export default {
        name: 'copyModal',
        components: {
            CategoryModal,
            Datepicker
        },
        data() {
            return {
                templates: [],
                buildings: [],
                categories: [],
                exists: "",
                template: {},
                pl: pl,
                budget: {
                    fromDate: null,
                    toDate: null
                },
                modal:
                    {
                        title: "Budżet",
                        button: "Dodaj"
                    },

            }
        },
        mixins: [mixin],
        methods: {
            addPosition: function () {
                this.$refs.addCategory.showModal(this.budget, this.categories)
            },
            getTemplates: async function (page = 1) {
                if (page === 1) {
                    this.templates = [];
                }
                let vm = this;
                var params = {};
                params['page'] = page;

                let res = await axiosInstance
                    .get(apiConfig.budgetTemplatesApiUrl,
                        {
                            params,
                            headers: this.$data.headers
                        });
                vm.templates = vm.templates.concat(res.data['member']);
                return vm.templates;
            },
            changeTemplate: async function (e) {
                let res = await axiosInstance
                    .get(e.target.value,
                        {
                            headers: this.$data.headers
                        });
                this.template = res.data;
                this.getCategories();
            },
            checkMonth: async function (date = null) {

                if(!date){
                    date = this.budget.fromDate.toDateString()
                } else {
                    date = new Date(date).toDateString();
                }
                let vm = this
                let params = {}
                if (date && vm.budget.building) {
                    params['building'] = vm.budget.building
                    params['fromDate[before]'] = date
                    params['fromDate[after]'] = date
                    let res = await axiosInstance
                        .get(apiConfig.budgetsApiUrl,
                            {
                                params,
                                headers: this.$data.headers
                            });
                    if (res.data['totalItems'] > 0) {
                        vm.exists = "Istnieje już budżet dla wybranego obiektu na wskazany miesiąc"
                    } else {
                        vm.exists = ""
                    }
                }
            },
            getCategories: function () {
                let vm = this;
                vm.categories = [];
                vm.template.budgetTemplateMainCategories.forEach(function (mainCategory) {
                    mainCategory.budgetMainCategory.budgetCategories.forEach(function (category) {
                        vm.categories.push({
                            id: category['@id'],
                            mainCategory: mainCategory.budgetMainCategory.name,
                            category: category.name,
                            value: "0"
                        });
                    })
                })
                vm.categories.push({
                    id: null,
                    name: "Inne",
                    category: "Inne",
                    value: "0"
                });
            },
            getBuildings: async function (page = 1) {
                if (page === 1) {
                    this.buildings = [];
                }
                let vm = this;
                var params = {};
                params['page'] = page;

                let res = await axiosInstance
                    .get(apiConfig.buildingsApiUrl,
                        {
                            params,
                            headers: this.$data.headers
                        });
                vm.buildings = vm.buildings.concat(res.data['member']);
                if (res.data['view'] && res.data['view']['next']) {
                    await vm.getBuildings(page + 1);
                }
                return vm.buildings;
            },
            showModal: function (budget) {
                this.getTemplates()
                this.getBuildings()
                this.budget = budget
                this.budget.id = null
                this.budget.fromDate = null
                this.exists=""
                this.budget.name = budget.name + " - KOPIA"
                this.modal =
                    {
                        title: "Kopiuj budżet",
                        button: "Kopiuj",
                        blocked: false
                    }
                this.categories = this.budget.budgetPositions;
                this.categories.forEach(function (category) {
                    if (category.budgetCategory) {
                        category.category = category.budgetCategory.name;
                        category.mainCategory = category.budgetCategory.budgetMainCategory.name;
                    } else {
                        category.category = category.name;
                        if (category.budgetMainCategory) {
                            category.mainCategory = category.budgetMainCategory.name;
                        } else {
                            category.mainCategory = "Inne";
                        }
                    }
                })

                this.$validator.reset();
                this.modalDom.show();
            },
            submit: function () {
                let postData = {...this.budget};
                postData.fromDate = new Date(postData.fromDate).toLocaleString();
                postData.toDate = new Date(postData.toDate).toLocaleString();
                postData.budgetTemplate = postData.budgetTemplate['@id']
                postData.budgetPositions = []
                postData.costs = 0
                postData.status = null

              if(postData.building && typeof postData.building!=='string'){
                postData.building = postData.building['@id'];
              }

                let vm = this;
                vm.$validator.validate().then((result) => {
                    if (result && vm.exists === "") {
                        this.modal.blocked = true;
                        if (this.budget.id == null) {
                            axiosInstance
                                .post(apiConfig.budgetsApiUrl, JSON.stringify(postData), {
                                    headers: this.$data.headers
                                })
                                .then(async function (response) {
                                    if (response.status === 201) {
                                        let categories = []
                                        vm.budget.budgetPositions.forEach(function (category) {
                                            let categoryPostData = {
                                                budget: response.data['@id'],
                                                budgetTemplate: vm.budget.budgetTemplate,
                                                budgetCategory: category.budgetCategory,
                                                budgetMainCategory: category.budgetMainCategory,
                                                name: category.name,
                                                value: category.value
                                            }
                                            if(categoryPostData.budgetMainCategory){
                                                categoryPostData.budgetMainCategory = categoryPostData.budgetMainCategory['@id']
                                            }
                                            if(categoryPostData.budgetCategory){
                                                categoryPostData.budgetCategory = categoryPostData.budgetCategory['@id']
                                            }
                                            categories.push(categoryPostData)

                                        })
                                        await axiosInstance
                                            .post(apiConfig.budgetsApiUrl + '/add-positions', JSON.stringify({
                                                budgetPositions: categories,
                                                budgetId: response.data.id
                                            }), {
                                                headers: vm.$data.headers
                                            })
                                        vm.$emit('refresh', response.data);
                                        vm.modalDom.hide();
                                    }
                                    vm.modal.blocked = false;
                                }).catch();
                        } else {
                            delete postData.budgetTemplate;
                            axiosInstance
                                .put(apiConfig.budgetsApiUrl + '/' + vm.budget.id, JSON.stringify(postData), {
                                    headers: this.$data.headers
                                })
                                .then(async function (response) {
                                    if (response.status === 200) {
                                        vm.$emit('refresh');
                                        vm.modalDom.hide();
                                    }
                                    vm.modal.blocked = false;
                                }).catch();
                        }
                    }
                });
            }
        },
        mounted() {
            if (this.modalDom == null) {
                this.modalDom = UIkit.modal('#copy-modal');
            }
        }
    }
</script>
