<template>
  <div>
    <vk-grid class="uk-margin-bottom">
      <h3 class="uk-width-expand">Zarządzaj plikami </h3>
    </vk-grid>
    <remove ref="remove" v-on:refresh="getData"/>
    <ias-file-modal ref="modal" @refresh="getData"/>
    <vk-card>
    <empty-list v-if="totalItems===0"/>
    <b-table v-else
             ref="table" hover borderedless
             :fields="fields"
             :columns="fields"
             :items="filesData"
             no-sort-reset
             :responsive="true">


      <template v-slot:cell(files)="data">
        <template v-for="file in data.item.files"><div v-bind:key="file.id">{{file.originalFilename}}
          <a
            @click.prevent="deleteResource(file.id)"
            href="#"
            class="color-red"
            uk-tooltip="Usuń zasób"
        >
          <vk-icon class="Usuń" icon="close"/>
        </a>
        </div></template>
      </template>


      <template v-slot:cell(actions)="data">
        <ias-file-actions :datatable=data v-on:add="add"/>
      </template>

    </b-table></vk-card>
  </div>
</template>

<script>
import mixin from "../mixin";
import EmptyList from "../ui/emptyList";
import charLimiter from "../charLimiter";
import axiosInstance from "../../axiosInstance";
import apiConfig from "../../apiConfig";
import Remove from "../ui/remove";
import IasFileActions from "./iasFileActions";
import IasFileModal from "./fileModal";

export default {
  name: 'iasFileList',
  components: {
    IasFileModal,
    IasFileActions,
    Remove,
    EmptyList
  },
  data() {
    return {
      sortBy: 'name',
      sortDesc: false,
      data: [],
      filesData: [],
      totalItems: '',
      fields: [
        {key: 'name', label: 'Nazwa', sortable: true},
        {key: 'files', label: 'Pliki', sortable: true},
        {key: 'actions', label: ''},
      ],
      unitId: null
    }
  },
  mixins: [mixin, charLimiter],
  mounted() {
    this.getData()
  },
  methods: {
    add(category){
      this.$refs.modal.showModal(category)
    },
    async getData() {
      let vm = this

      let params = {
        categoriesList: true
      };

      let res = await axiosInstance
          .get(apiConfig.iasResourcesApiUrl,
              {
                params,
                headers: this.$data.headers
              });
      if (res.status === 200) {
        vm.filesData = res.data;
      }
    },
    deleteResource: function (id) {
      this.$refs.remove.remove(apiConfig.iasResourcesApiUrl + '/' + id, 'zasób');
    }
  }
}
</script>
<style scoped>
.color-red{
  color: red;
}
</style>
