<template>
    <div class="uk-flex-top uk-modal-container" container=".admin" id="ias-phases" uk-modal>
        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-modal-body">
                <h3>{{ modal.title }}</h3>
                <label class="uk-form-label">Nazwa</label>
                <div class="uk-margin">
                    <input class="uk-input" data-vv-as="nazwa" name="name" placeholder="" type="text"
                           v-model="eventType.name"
                           v-validate="'required|max:255'">
                    <span class="validation-error">{{ errors.first('name') }}</span>
                </div>
              <label class="uk-form-label">Kolor</label>
                <div class="uk-margin">
                    <input class="uk-input" data-vv-as="kolor" name="color" placeholder="" type="color"
                           v-model="eventType.color"
                           v-validate="'required|max:255'">
                    <span class="validation-error">{{ errors.first('color') }}</span>
                </div>
            </div>
            <div class="uk-modal-footer uk-text-right">
                <button class="uk-button uk-button-default uk-modal-close" type="button">Anuluj</button>
                <button @click="submit" class="uk-button wpip-button" type="button" :disabled="modal.blocked">{{modal.button}}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import mixin from "../mixin";
    import UIkit from "uikit";
    import 'vue-datetime/dist/vue-datetime.css'
    import axiosInstance from "../../axiosInstance";
    import apiConfig from "../../apiConfig";

    export default {
        name: 'eventTypeModal',
        components:{
        },
        props: {},
        data() {
            return {
                filterValue: "",
                modalDom: null,
                parameter: null,
                key: 0,
                types: [],
                type: 'datetime',
                buildingIndex: 0,
                eventType: {
                },
                modal:
                    {
                        title: "Zmień typ zdarzenia",
                        button: "Dodaj"
                    },

            }
        },
        mixins: [mixin],
        methods: {
            showModal: function (eventType) {
                this.eventType = eventType;
                if(this.eventType.id==null){
                    this.modal =
                        {
                            title: "Dodaj typ zdarzenia",
                            button: "Dodaj",
                            blocked: false
                        }
                } else {
                    this.modal =
                        {
                            title: "Zmień typ zdarzenia",
                            button: "Zmień",
                            blocked: false
                        }
                }
                this.modalDom.show();
            },
            submit: function(){
                let postData = this.eventType;
                let vm = this;
                vm.$validator.validate().then((result) => {
                    if (result) {
                        this.modal.blocked = true;
                        if (this.eventType.id == null) {
                            axiosInstance
                                .post(apiConfig.eventTypesApiUrl, JSON.stringify(postData), {
                                    headers: this.$data.headers
                                })
                                .then(async function (response) {
                                    if (response.status === 201) {
                                        vm.$emit('refresh');
                                        vm.modalDom.hide();
                                    }
                                    vm.modal.blocked = false;
                                }).catch();
                        } else {
                            axiosInstance
                                .put(apiConfig.eventTypesApiUrl + '/' + vm.eventType.id, JSON.stringify(postData), {
                                    headers: this.$data.headers
                                })
                                .then(async function (response) {
                                    if (response.status === 200) {
                                        vm.$emit('refresh');
                                        vm.modalDom.hide();
                                    }
                                    vm.modal.blocked = false;
                                }).catch();
                        }
                    }
                });
            }
        },
        mounted() {
            this.modalDom = UIkit.modal('#ias-phases');
        }

    }
</script>
