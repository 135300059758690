<template>
    <span>
        <span v-if="testRateEnabled()">
            <a @click="rateFmeaElementForm(datatable.item)" class="uk-icon-button" uk-tooltip="Podjęte działania"><vk-icons-bolt/></a>
        </span>

        <span>
            <a @click="editFmeaElementForm(datatable.item)" v-if="testEditEnabled()" class="uk-icon-button" uk-tooltip="Edytuj element"><vk-icons-pencil/></a>
        </span>

        <span v-if="testRemoveEnabled()">
            <a @click="deleteFmeaElement(datatable.item.id)" class="uk-icon-button" uk-tooltip="Usuń element"><vk-icons-trash/></a>
        </span>
        <remove ref="remove" v-on:refresh="$emit('refresh')"/>
    </span>
</template>

<script>

    import mixin from "../../components/mixin";
    import apiConfig from "../../apiConfig";
    import remove from "../ui/remove";

    export default {
        name: 'fmeaElementsActions',
        components: {
            remove
        },
        mixins: [mixin],
        props: {
            datatable: Object
        },
        data: function(){
            return {
                ability: null,
            }
        },
        methods: {
            testEditEnabled: function(){
                let item = this.datatable.item;
                let result = true;
                result = !(item.newClientValue || item.newRisk || item.newDetection || item.takenActions!=null) && item.author==="/api/users/"+localStorage.getItem('user');
                result = result || this.ability.can('manage', 'ROLE_SUPER_ADMIN')
                return result;
            },
            testRateEnabled: function () {
                return ((parseInt(localStorage.getItem('person')) === this.datatable.item.person.id) && this.datatable.item.takenActions==null) || (this.datatable.item.author === "/api/users/"+localStorage.getItem('user') && this.datatable.item.takenActions!=null)|| this.ability.can('manage', 'ROLE_SUPER_ADMIN')
            },
            testRemoveEnabled: function () {
                return (this.datatable.item.takenActions==null || this.ability.can('manage', 'ROLE_SUPER_ADMIN'))
            },
            editFmeaElementForm(element) {
                this.$emit('edit', element)
            },
            rateFmeaElementForm(element) {
                this.$emit('rate', element)
            },
            deleteFmeaElement(fmeaElementId) {
                this.$refs.remove.remove(apiConfig.fmeaElementsApiUrl + "/" + fmeaElementId, "element analizy FMEA");
            },
        },
        async mounted() {
            this.ability = await this.$ability.getAbility();
        }
    }
</script>
