<template>
  <admin>
    <info-modal ref="modal"/>
    <div id="ias-content">
      <vk-grid class="uk-margin-bottom">
        <h3 class="uk-width-expand">Plan funkcji IAS</h3>
      </vk-grid>
      <vk-card  class="uk-margin-bottom">
        <div class="uk-width-expand">
          <div class="function-table" v-if="!haveFilters()">
            <p v-if="err!=''">{{ err }}</p>

            <div class="row">
              <span class="col-5">
                Funkcja
                <vk-icon @click="showInfoModal(0)" class="hover info-icon" icon="info"/>
              </span>
              <span class="col-1">Rodzaj
                <vk-icon @click="showInfoModal(1)" class="hover info-icon" icon="info"/></span>
              <span class="col-2">Odpowiedzialny za wynik
                <vk-icon @click="showInfoModal(2)" class="hover info-icon" icon="info"/></span>
              <span class="col-2">Główny realizator
                <vk-icon @click="showInfoModal(3)" class="hover info-icon" icon="info"/>	</span>
              <span class="col-1">Nadzór
                <vk-icon @click="showInfoModal(4)" class="hover info-icon" icon="info"/></span>
              <span class="col-1">Współpraca
                <vk-icon @click="showInfoModal(5)" class="hover info-icon" icon="info"/></span>
            </div>
            <div class="row">
              <b-input class="filter-input col-5" debounce="500" placeholder="Szukaj..." v-model="filters.name"
                       @update="filter"/>
              <select class="filter-input col-1" placeholder="Szukaj..." v-model="filters.type" @change="filter">
                <option value="">Wszystkie</option>
                <option value="O">O</option>
                <option value="S">S</option>
                <option value="T">T</option>
              </select>

              <select class="filter-input col-2" placeholder="Szukaj..." v-model="filters.resp" @change="filter">
                <option value="">Wszystkie</option>
                <option value="Z">Z</option>
                <option value="DN">DN</option>
                <option value="NB">NB</option>
                <option value="NM">NM</option>
                <option value="NP">NP</option>
                <option value="NZ">NZ</option>
                <option value="NO">NO</option>
                <option value="NF">NF</option>
                <option value="NI">NI</option>
                <option value="DB">DB</option>
                <option value="FK">FK</option>
                <option value="FA">FA</option>
                <option value="FS">FS</option>
                <option value="BP">BP</option>
                <option value="BO">BO</option>
                <option value="BA">BA</option>
                <option value="BZ">BZ</option>
                <option value="BE">BE</option>
                <option value="BS">BS</option>
                <option value="BB">BB</option>
                <option value="OUT">OUT</option>
              </select><select class="filter-input col-2" placeholder="Szukaj..." v-model="filters.real"
                               @change="filter">
              <option value="">Wszystkie</option>
              <option value="Z">Z</option>
              <option value="DN">DN</option>
              <option value="NB">NB</option>
              <option value="NM">NM</option>
              <option value="NP">NP</option>
              <option value="NZ">NZ</option>
              <option value="NO">NO</option>
              <option value="NF">NF</option>
              <option value="NI">NI</option>
              <option value="DB">DB</option>
              <option value="FK">FK</option>
              <option value="FA">FA</option>
              <option value="FS">FS</option>
              <option value="BP">BP</option>
              <option value="BO">BO</option>
              <option value="BA">BA</option>
              <option value="BZ">BZ</option>
              <option value="BE">BE</option>
              <option value="BS">BS</option>
              <option value="BB">BB</option>
              <option value="OUT">OUT</option>
            </select><select class="filter-input col-1" placeholder="Szukaj..." v-model="filters.cont" @change="filter">
              <option value="">Wszystkie</option>
              <option value="Z">Z</option>
              <option value="DN">DN</option>
              <option value="NB">NB</option>
              <option value="NM">NM</option>
              <option value="NP">NP</option>
              <option value="NZ">NZ</option>
              <option value="NO">NO</option>
              <option value="NF">NF</option>
              <option value="NI">NI</option>
              <option value="DB">DB</option>
              <option value="FK">FK</option>
              <option value="FA">FA</option>
              <option value="FS">FS</option>
              <option value="BP">BP</option>
              <option value="BO">BO</option>
              <option value="BA">BA</option>
              <option value="BZ">BZ</option>
              <option value="BE">BE</option>
              <option value="BS">BS</option>
              <option value="BB">BB</option>
              <option value="OUT">OUT</option>
            </select><select class="filter-input col-1" placeholder="Szukaj..." v-model="filters.coop" @change="filter">
              <option value="">Wszystkie</option>
              <option value="Z">Z</option>
              <option value="DN">DN</option>
              <option value="NB">NB</option>
              <option value="NM">NM</option>
              <option value="NP">NP</option>
              <option value="NZ">NZ</option>
              <option value="NO">NO</option>
              <option value="NF">NF</option>
              <option value="NI">NI</option>
              <option value="DB">DB</option>
              <option value="FK">FK</option>
              <option value="FA">FA</option>
              <option value="FS">FS</option>
              <option value="BP">BP</option>
              <option value="BO">BO</option>
              <option value="BA">BA</option>
              <option value="BZ">BZ</option>
              <option value="BE">BE</option>
              <option value="BS">BS</option>
              <option value="BB">BB</option>
              <option value="OUT">OUT</option>
            </select>
            </div>
            <item ref="items" :level=0 :content="content" :rStyle="'main'" :filters="filters"></item>
          </div>

          <div class="function-table filtered" v-else>
            <p v-if="err!=''">{{ err }}</p>

            <div class="row">
              <span class="col-5">
                Funkcja
                <vk-icon @click="showInfoModal(0)" class="hover info-icon" icon="info"/>
              </span>
              <span class="col-1">Rodzaj
                <vk-icon @click="showInfoModal(1)" class="hover info-icon" icon="info"/></span>
              <span class="col-2">Odpowiedzialny za wynik
                <vk-icon @click="showInfoModal(2)" class="hover info-icon" icon="info"/></span>
              <span class="col-2">Główny realizator
                <vk-icon @click="showInfoModal(3)" class="hover info-icon" icon="info"/>	</span>
              <span class="col-1">Nadzór
                <vk-icon @click="showInfoModal(4)" class="hover info-icon" icon="info"/></span>
              <span class="col-1">Współpraca
                <vk-icon @click="showInfoModal(5)" class="hover info-icon" icon="info"/></span>
            </div>
            <div class="row">
              <b-input class="filter-input col-5" debounce="500" placeholder="Szukaj..." v-model="filters.name"
                       @update="filter"/>
              <select class="filter-input col-1" placeholder="Szukaj..." v-model="filters.type" @change="filter">
                <option value="">Wszystkie</option>
                <option value="O">O</option>
                <option value="S">S</option>
                <option value="T">T</option>
              </select>

              <select class="filter-input col-2" placeholder="Szukaj..." v-model="filters.resp" @change="filter">
                <option value="">Wszystkie</option>
                <option value="Z">Z</option>
                <option value="DN">DN</option>
                <option value="NB">NB</option>
                <option value="NM">NM</option>
                <option value="NP">NP</option>
                <option value="NZ">NZ</option>
                <option value="NO">NO</option>
                <option value="NF">NF</option>
                <option value="NI">NI</option>
                <option value="DB">DB</option>
                <option value="FK">FK</option>
                <option value="FA">FA</option>
                <option value="FS">FS</option>
                <option value="BP">BP</option>
                <option value="BO">BO</option>
                <option value="BA">BA</option>
                <option value="BZ">BZ</option>
                <option value="BE">BE</option>
                <option value="BS">BS</option>
                <option value="BB">BB</option>
                <option value="OUT">OUT</option>
              </select><select class="filter-input col-2" placeholder="Szukaj..." v-model="filters.real"
                               @change="filter">
              <option value="">Wszystkie</option>
              <option value="Z">Z</option>
              <option value="DN">DN</option>
              <option value="NB">NB</option>
              <option value="NM">NM</option>
              <option value="NP">NP</option>
              <option value="NZ">NZ</option>
              <option value="NO">NO</option>
              <option value="NF">NF</option>
              <option value="NI">NI</option>
              <option value="DB">DB</option>
              <option value="FK">FK</option>
              <option value="FA">FA</option>
              <option value="FS">FS</option>
              <option value="BP">BP</option>
              <option value="BO">BO</option>
              <option value="BA">BA</option>
              <option value="BZ">BZ</option>
              <option value="BE">BE</option>
              <option value="BS">BS</option>
              <option value="BB">BB</option>
              <option value="OUT">OUT</option>
            </select><select class="filter-input col-1" placeholder="Szukaj..." v-model="filters.cont" @change="filter">
              <option value="">Wszystkie</option>
              <option value="Z">Z</option>
              <option value="DN">DN</option>
              <option value="NB">NB</option>
              <option value="NM">NM</option>
              <option value="NP">NP</option>
              <option value="NZ">NZ</option>
              <option value="NO">NO</option>
              <option value="NF">NF</option>
              <option value="NI">NI</option>
              <option value="DB">DB</option>
              <option value="FK">FK</option>
              <option value="FA">FA</option>
              <option value="FS">FS</option>
              <option value="BP">BP</option>
              <option value="BO">BO</option>
              <option value="BA">BA</option>
              <option value="BZ">BZ</option>
              <option value="BE">BE</option>
              <option value="BS">BS</option>
              <option value="BB">BB</option>
              <option value="OUT">OUT</option>
            </select><select class="filter-input col-1" placeholder="Szukaj..." v-model="filters.coop" @change="filter">
              <option value="">Wszystkie</option>
              <option value="Z">Z</option>
              <option value="DN">DN</option>
              <option value="NB">NB</option>
              <option value="NM">NM</option>
              <option value="NP">NP</option>
              <option value="NZ">NZ</option>
              <option value="NO">NO</option>
              <option value="NF">NF</option>
              <option value="NI">NI</option>
              <option value="DB">DB</option>
              <option value="FK">FK</option>
              <option value="FA">FA</option>
              <option value="FS">FS</option>
              <option value="BP">BP</option>
              <option value="BO">BO</option>
              <option value="BA">BA</option>
              <option value="BZ">BZ</option>
              <option value="BE">BE</option>
              <option value="BS">BS</option>
              <option value="BB">BB</option>
              <option value="OUT">OUT</option>
            </select>
            </div>
            <div v-bind:key="item.code+key" v-for="(item, key) in filteredList">
              <div class="row">
                <span class="col-5">{{ item.code }} {{ item.name }}</span>
                <span class="col-1"><span class="cell-value" v-if="typeof item.type !== 'object'">{{ item.type }}</span></span>
                <span class="col-2"><span class="cell-value" v-if="typeof item.resp !== 'object'">{{ item.resp }}</span></span>
                <span class="col-2"><span class="cell-value" v-if="typeof(item.real) !== 'object'">{{
                    item.real
                  }}</span></span>
                <span class="col-1"><span class="cell-value" v-if="typeof item.cont !== 'object'">{{ item.cont }}</span></span>
                <span class="col-1"><span class="cell-value" v-if="typeof item.coop !== 'object'">{{ item.coop }}</span></span>
              </div>

            </div>
          </div>

        </div>
      </vk-card>
    </div>
    <button class="uk-button uk-button-default uk-width-auto" @click="$router.push('infos')"><vk-icon icon="arrow-left"></vk-icon> wstecz</button>
  </admin>
</template>

<style scoped>
.to-right {
  text-align: right;
}

.feat-row {
  margin-bottom: 3px !important;
}

.card {
  margin-bottom: 10px;
}

</style>

<script>
import XLSX from 'xlsx';
import item from '../../components/functionTree/item'
import axiosInstance from "../../axiosInstance";
import apiConfig from "../../apiConfig";
import UIkit from "uikit";
import mixin from "@/components/mixin";
import InfoModal from "@/components/functionTree/infoModal";

export default {
  name: 'functionTree',
  components: {InfoModal, item},
  mixins: [mixin],
  data() {
    return {
      tmpContent: [],
      filteredList: [],
      originalContent: {},
      content: '',
      err: '',
      start: '',
      end: '',
      modalDom: null,
      modal: {
        title: "Schemat organizacyjny"
      },
      filters: {
        name: '',
        type: '',
        resp: '',
        real: '',
        cont: '',
        coop: '',

      }
    }
  },
  mounted() {
    this.get();

    if (this.modalDom == null) {
      this.modalDom = UIkit.modal('#schema-modal');
    }
  },
  methods: {
    showInfoModal(column) {
      this.$refs.modal.showModal(column)
    },
    async get() {
      let vm = this;
      let res = await axiosInstance
          .get(apiConfig.functionTreeApiUrl,
              {
                headers: vm.$data.headers
              });

      vm.content = [...res.data];
      vm.originalContent = [...res.data]
      if (this.$refs.items) {
        this.$refs.items.getContent(vm.content);
      }
    },
    haveFilters() {
      return (this.filters.type !== '' || this.filters.resp !== '' || this.filters.name !== '' || this.filters.real !== '' || this.filters.coop !== '' || this.filters.cont !== '')
    },
    getFilteredList(items) {
      let vm = this
      items.forEach((item) => {
        if ((((typeof item.name == "string" && item.name.toLowerCase().includes(vm.filters.name.toLowerCase())
            || (typeof item.code == "string" && item.code.includes(vm.filters.name))))
            && (!vm.filters.type || typeof item.type == "string" && item.type.includes(vm.filters.type))
            && (!vm.filters.resp || typeof item.resp == "string" && item.resp.includes(vm.filters.resp))
            && (!vm.filters.real || typeof item.real == "string" && item.real.includes(vm.filters.real))
            && (!vm.filters.cont || typeof item.cont == "string" && item.cont.includes(vm.filters.cont))
            && (!vm.filters.coop || typeof item.coop == "string" && item.coop.includes(vm.filters.coop)))) {
          vm.filteredList.push(item)
        }
        vm.getFilteredList(item.children)
      })
    },
    filter: async function () {
      await this.get()
      this.filteredList = []
      this.content = [...this.originalContent]
      this.getFilteredList(this.content)
    },
    filterChildren: function (root) {
      let vm = this
      root.show = false
      let show = true;
      let hidden = true;
      if (((typeof root.name == "string" && root.name.toLowerCase().includes(vm.filters.name.toLowerCase()) || (typeof root.code == "string" && root.code.includes(vm.filters.name)))) && (typeof root.type == "string" && root.type.includes(vm.filters.type)) && (typeof root.resp == "string" && root.resp.includes(vm.filters.resp)) && (typeof root.real == "string" && root.real.includes(vm.filters.real)) && (typeof root.cont == "string" && root.cont.includes(vm.filters.cont)) && (typeof root.coop == "string" && root.coop.includes(vm.filters.coop))) {
        root.hidden = false
        root.show = true
      } else {
        root.hidden = true
      }
      root.children.forEach(function (child) {
        vm.filterChildren(child)
        show = show || child.show
        hidden = hidden && child.hidden
        root.show = show;
        root.hidden = hidden;
        if (vm.$refs.items) {
          vm.$refs.items.getContent(vm.content);
          vm.$refs.items.$forceUpdate()
        }
      })
    },
    showSchema: function () {
      this.modalDom.show();
    },
    transformSheets(sheets) {
      var content = []
      var tmpContent = []
      var tmpList = []

      for (let key in sheets) {
        tmpList.push(XLSX.utils.sheet_to_json(sheets[key], {
          range: 'A1:G500',
          header: 1
        }).length)
        tmpContent.push(XLSX.utils.sheet_to_json(sheets[key], {
          range: 'A1:G500',
          header: 0
        }))
      }

      this.end = new Date().toLocaleString();

      for (let i in [...Array(tmpContent[0].length)]) {
        let fObject = tmpContent[0][i];
        let fCode = fObject.code;
        let fCodeLength = fCode.length;
        let fChildrenCodeLength = fCodeLength + 3;
        if (fCode === '00.') fChildrenCodeLength = fChildrenCodeLength + 2;
        let fChildren = tmpContent[0].filter(function (el) {
          return el.code.startsWith(fCode) && el.code.length === fChildrenCodeLength;
        });
        fObject.children = fChildren;
        fObject.show = false;
        this.tmpContent.push(fObject);
      }

      content = this.tmpContent.filter(function (el) {
        return el.code.length === 3;
      });
      this.$refs.items.getContent(content);
      return content
    },
    openAll() {
      this.$refs.items.openAll();
    },
    closeAll: function (root) {
      let vm = this
      root.show = false
      root.hidden = false
      root.children.forEach(function (child) {
        child.show = false
        child.hidden = false

        vm.closeAll(child)
      })
    }
  }
};
</script>
<style scoped>
.function-table .row span, .function-table .row select {
  border: 1px solid lightgrey;
  border-left: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  white-space: normal;
  font-weight: bold;
}

.info-icon {
  border: 0px !important;
}

.function-table .row span:first-child, .function-table .row input:first-child {
  border: 1px solid lightgrey;
}

.function-table.filtered .cell-value {
  border: none !important;
  font-weight: normal;
}
</style>
