<template>
    <div class="h-100">
        <vk-grid>
            <h3 class="uk-width-expand">Analiza QFD - Wymagania klienta </h3>
            <button
                    @click="back()"
                    class="uk-button wpip-button uk-width-auto"
            >Lista analiz QFD
            </button>
        </vk-grid>
        <vk-card>
        <empty-list v-if="data.length===0"/>
        <b-table
                v-else
                :columns="fields"
                :current-page="currentPage"
                :fields="fields"
                :items="this.data"
                :per-page="this.$data.perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                borderedless
                no-sort-reset
                :responsive="true"
                class="uk-table vk-table-justify uk-table-divider"
                hover
                ref="table">


            <template v-slot:cell(weight)="data">
                <star-rating :maxRate="5" :value=data.item.weight v-bind:id=data.index v-on:rated="changeRating"/>
            </template>

            <template v-slot:cell(actions)="data">
                <properties-actions :datatable="data" v-on:remove="remove"/>
            </template>

            <template v-slot:cell(key)="data">
                {{ data.index+1 }}
            </template>
        </b-table></vk-card>
        <vk-grid>
            <span class="uk-width-expand">
                <input class="uk-input"  data-vv-as="nazwa" name="newProperty" ref="input" type="text" v-model="inputValue"
                       v-on:keyup="isSubmit" v-validate="'required|max:255'">

            </span>
            <span class="uk-width-1-6">
                <button
                        @click="addProperty"
                        class="uk-button wpip-button uk-width-1-1"
                >dodaj
            </button>
                </span>

        </vk-grid>
        <span class="validation-error">{{ errors.first('newProperty') }}</span>
        <b-pagination :per-page="perPage" :total-rows="totalItems" align="center"
                      v-model="currentPage" v-if="totalItems>perPage" size="sm" page-class="wpip-page"
                      class="wpip-page"/>
        <div class="uk-grid uk-child-width-1-2@m uk-margin-top" uk-grid>
            <div></div>
            <div class="uk-text-right">
                <button class="uk-button wpip-button" v-on:click="nextStep">Parametry techniczne</button>
            </div>
        </div>
    </div>
</template>

<script>

    import mixin from "../../components/mixin";
    import StarRating from "../ui/starRating";
    import PropertiesActions from "./propertiesActions";
    import axiosInstance from "../../axiosInstance";
    import apiConfig from "../../apiConfig";
    import EmptyList from "../ui/emptyList";

    export default {
        name: 'qfdAnalysisClientRequirements',
        components: {EmptyList, StarRating, PropertiesActions},
        mixins: [mixin],
        data() {
            return {
                sortBy: null,
                sortDesc: false,
                data: [],
                currentPage: 1,
                totalItems: '',
                inputValue: '',
                fields: [
                    {key: 'key', label: "LP", class: "wi"},
                    {key: 'name', label: 'wymagania klienta', sortable: false},
                    {key: 'weight', label: 'Istotność dla klienta', sortable: false, class: "qfd-cell-no-wrap"},
                    {key: 'actions', label: '', class: 'text-right'},
                ]
            }
        },
        props: {},
        mounted() {
            this.get();
        },
        methods: {
            async get() {
                this.data = this.$attrs.properties;
                return this.data;
            },
            refresh: function () {
                if (this.$refs.table) {
                    this.$refs.table.refresh();
                }
            },
            changeRating: function (rating) {
                let vm = this;
                this.data[rating.key].weight = rating.rate;
                let postData = {
                    weight: rating.rate
                };
                axiosInstance
                    .put(apiConfig.qfdAnalysisPropertiesApiUrl + "/qfdAnalysis=" + this.$route.params.analysisId + ";qfdProperty=" + this.data[rating.key].propertyId, postData,
                        {
                            headers: vm.$data.headers
                        }).then(function () {

                });
                this.$emit("propertiesChange", this.data);
                this.get();
            },
            addProperty: function () {
                let vm = this;
                let postData = {
                    name: this.inputValue,
                    qfdAnalysisProperties: [
                        {
                            qfdAnalysis: 'api/qfd_analyses/' + this.$route.params.analysisId,
                            weight: 0,
                            competition: 0
                        }
                    ]
                };
                vm.$validator.validate().then((result) => {
                    if (result) {
                        axiosInstance
                            .post(apiConfig.qfdPropertiesApiUrl, JSON.stringify(postData), {
                                headers: this.$data.headers
                            })
                            .then(function (response) {
                                if (response.status === 201) {
                                    vm.data.push({
                                        key: null,
                                        name: vm.inputValue,
                                        weight: 0,
                                        competition: 0,
                                        propertyId: response.data.id,
                                        parameters: []
                                    });
                                    vm.inputValue = "";
                                    vm.$refs.input.focus();
                                    vm.refresh()
                                    vm.$validator.reset()

                                }
                            }).catch()

                    }
                }).catch(() => {
                    return false;
                });

            },
            isSubmit: function (e) {
                if (e.keyCode === 13) {
                    this.addProperty();
                }
            },
            nextStep: function () {
                this.$emit('nextStep');
            },
            back: function () {
                this.$router.push({name: 'qfdAnalyses'})
            },
            remove: function (e) {
                this.$emit('remove', e);
                this.$refs.table.refresh();
            }
        }
    }
</script>
<style>
    .h-100 {
        max-height: 100%;
    }
    
    .qfd-cell-no-wrap {
      white-space: nowrap;
    }
</style>
