<template>
  <div>
    <vk-grid class="uk-margin-bottom">
      <h3 class="uk-width-expand">Moje cele</h3>
    </vk-grid>
    <vk-card>
      <b-table
        :columns="fields"
        :fields="fields"
        :items="data"
        :per-page="this.$data.perPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        borderedless
        no-sort-reset
        :responsive="true"
        class="uk-table vk-table-justify uk-table-divider"
        hover
        ref="table">

      <template v-slot:cell(actions)="data">
          <my-goals-actions @showGoal="showGoal" :datatable=data></my-goals-actions>
      </template>
      <template v-slot:cell(fromDate)="data">
        {{ new Date(data.item.fromDate).toLocaleDateString() }}
      </template>
      <template v-slot:cell(toDate)="data">
        {{ new Date(data.item.toDate).toLocaleDateString() }}
      </template>
      <template v-slot:cell(goals)="data">
        <span class="uk-label wpip-label">{{ getGoalsCount(data.item) }}</span>
      </template>
      <template v-slot:cell(operationalGoals)="data">
        <span class="uk-label wpip-label">{{ data.item.goals.length - getGoalsCount(data.item) }}</span>
      </template>
    </b-table>
    <b-pagination :per-page="perPage" :total-rows="totalItems" align="center"
                  v-model="currentPage" v-if="totalItems>perPage" size="sm" page-class="wpip-page" class="wpip-page"
                  @change="getGoals"/>
    </vk-card>
    <goal-preview-modal ref="modal"/>
  </div>
</template>

<script>

import mixin from "../mixin";
import apiConfig from "../../apiConfig";
import axiosInstance from "../../axiosInstance";
import MyGoalsActions from "@/components/zpc/myGoalsActions";
import GoalPreviewModal from "@/components/zpc/previewModal";

export default {
  name: 'myGoalsList',
  components: {
    GoalPreviewModal,
    MyGoalsActions
  },
  data() {
    return {
      sortBy: 'name',
      sortDesc: false,
      data: [],
      selected: null,
      currentPage: 1,
      totalItems: '',
      fields: [
        {key: 'name', label: 'Nazwa', class: 'uk-width-1-5', sortable: true},
        {key: 'realization', label: 'Termin realizacji', sortable: true},
        {key: 'actions', label: '', class: 'text-right'},
      ]
    }
  },
  mixins: [mixin],
  methods: {
    showGoal(goal){
      this.$refs.modal.showModal(goal)
    },
    async getGoals(e = null) {
      if (e !== null) {
        this.currentPage = e
      }
      let vm = this;
      let desc = 'desc';
      if (vm.sortDesc !== true) {
        desc = 'asc';
      }

      var params = {
        page: this.currentPage
      };
      params['person'] = '/api/people/' + localStorage.getItem('person')
      if (this.sortBy !== '') {
        var key = "order[" + this.sortBy + "]";
        params[key] = desc;
      }
      let res = await axiosInstance
          .get(apiConfig.goalsApiUrl,
              {
                params,
                headers: this.$data.headers
              });
      vm.data = res.data['member'];
      vm.$refs.table.refresh()
      vm.totalItems = res.data['totalItems'];

      return vm.data;
    },
    showDetails(e) {
      this.selected = e;
      this.$refs.personRatingsModal.showModal(e);
    },
    async refresh() {
      await this.$refs.table.refresh();
    }
  },
  mounted() {
    this.getGoals()
  }
}
</script>
<style scoped>
.wpip-label {
  background-color: rgb(195, 22, 50);
  width: 2em;
  height: 2em;
  padding-top: 0.3em;
  text-align: center;
  border-radius: 50%;
  color: white;
}

</style>
