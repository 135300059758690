<template>
    <div>
        <empty-list v-if="totalItems===0"/>
        <b-table v-else ref="table" hover borderedless
                 :fields="fields"
                 :columns="fields"
                 :items="get"
                 :per-page="this.$data.perPage"
                 :current-page="currentPage"
                 :sort-by.sync="sortBy"
                 :sort-desc.sync="sortDesc"
no-sort-reset
:responsive="true">
            <template v-slot:cell(name)="data">
                <span  :uk-tooltip="data.item.name">{{ limit(data.item,100) }}</span>
            </template>
            <template v-slot:cell(actions)="data">
                <event-types-actions :datatable=data v-on:edit="edit" v-on:refresh="refresh"/>
            </template>

        </b-table>
        <b-pagination :total-rows="totalItems" :per-page="perPage" v-model="currentPage"
                      align="center" v-if="totalItems>perPage" size="sm" page-class="wpip-page" class="wpip-page"/>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import eventTypesActions from "./eventTypeActions";
    import axiosInstance from "../../axiosInstance";
    import EmptyList from "../ui/emptyList";
    import charLimiter from "../charLimiter";

    export default {
        name: 'eventTypesList',
        components: {
            EmptyList,
            eventTypesActions
        },
        data() {
            return {
                sortBy: 'name',
                sortDesc: false,
                data: [],
                currentPage: 1,
                totalItems: '',
                fields: [
                    {key: 'name', label: 'Nazwa', sortable: true},
                    {key: 'actions', label: '', class: 'text-right'},
                ]
            }
        },
        mixins: [mixin, charLimiter],
        methods: {
            async get() {
                let vm = this;
                let desc = 'desc';
                if (vm.sortDesc !== true) {
                    desc = 'asc';
                }

                var params = {};

                if (this.sortBy !== '') {
                    var key = "order[" + this.sortBy + "]";
                    params[key] = desc;
                }
                params['page'] = vm.currentPage;
                params['building'] = vm.$route.params.buildingId;

                let res = await axiosInstance
                    .get(apiConfig.eventTypesApiUrl,
                        {
                            params,
                            headers: this.$data.headers
                        });
                vm.data = res.data['member'];
                vm.totalItems = res.data['totalItems'];

                return vm.data;
            },
            edit(e) {
                this.$emit('edit', {...e});
            },
            refresh(){
                this.get();
                if(this.$refs.table) {
                    this.$refs.table.refresh();
                }
            }
        }
    }
</script>
