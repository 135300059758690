<template>
    <admin ref="admin">
        <div class="uk-container-expand uk-padding">
            <vk-grid>
                <menu-budgets/>
                <div class="uk-width-expand@m">
                    <vk-grid>
                    <h4 class="uk-width-expand">Budżet
                    </h4>
                    </vk-grid>
                    <div>
                        <budget-positions-list ref="list"/>
                    </div>
                </div>
            </vk-grid>
        </div>

    </admin>

</template>

<script>

    import mixin from "../../components/mixin";
    import BudgetPositionsList from "../../components/budget/budgetPositionsList"

    export default {
        name: 'budgetTemplates',
        components: {
            BudgetPositionsList
        },
        mixins: [mixin],
        data() {
            return {
                sortBy: 'name',
                sortDesc: false,
                data: [],
                currentPage: 1,
                totalItems: '',
                fields: [
                    {key: 'name', label: 'Imię', sortable: true},
                    {key: 'actions', label: ''},
                ]
            }
        },
        methods: {
            addNewBudget: function(){
                this.$refs.list.add();
            }
        }
    }
</script>

