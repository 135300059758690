<template>
    <div class="text-center">
        <input class="uk-checkbox" type="checkbox" name="status" v-model="datatable.item.isManager" disabled>
    </div>
</template>

<script>
    export default {
        name: 'peopleIsManager',
        props: {
            datatable: Object
        }
    }
</script>
