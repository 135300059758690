<template>
    <admin ref="admin">
        <div class="uk-container-expand uk-padding">
            <vk-grid>
                <div class="uk-width-expand@m">

                    <div>
                        <dashboard ref="dashboard"/>
                    </div>
                </div>
            </vk-grid>
        </div>

    </admin>

</template>

<script>

    import mixin from "../../components/mixin";
    import Dashboard from "../../components/dashboard/dashboard"

    export default {
        name: 'dashboards',
        components: {
            Dashboard
        },
        mixins: [mixin],
        data() {
            return {
                ability: null,
            }
        },
        async mounted() {
            this.ability = await this.$ability.getAbility();
            if (!this.ability.can('manage', 'ROLE_BMS') && !this.ability.can('manage', 'ROLE_SUPER_ADMIN')) {
                await this.$router.push({name: 'not-allowed'});
            }
        }
    }
</script>
