<template>
    <div class="uk-flex-top uk-modal-container" container=".admin" id="event-modal" @hide="hideModal" uk-modal>
        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-modal-body">
                <h3>{{ modal.title }}</h3>
                <label class="uk-form-label">Nazwa</label>
                <div class="uk-margin">
                    <input class="uk-input" data-vv-as="nazwa" name="name" placeholder="" type="text"
                           v-model="event.name"
                           v-validate="'required|max:255'">
                    <span class="validation-error">{{ errors.first('name') }}</span>
                </div>

                <label class="uk-form-label">Opis</label>
                <div class="uk-margin">
                    <textarea class="uk-textarea" name="description" placeholder="Opis"
                              rows="10" v-model="event.description" v-validate="'max:1024'"></textarea>
                    <span class="validation-error">{{ errors.first('description') }}</span>
                </div>

                <label class="uk-form-label">Kategoria</label>
                <div class="uk-margin">
                    <select class="uk-select" data-vv-as="kategoria" name="type" placeholder="" type="text"
                            v-model="event.eventType"
                            v-validate="'required'">
                        <option :value="type['@id']" v-bind:key="type.id" v-for="type in types">{{ type.name }}</option>
                    </select>

                    <span class="validation-error">{{ errors.first('type') }}</span>
                </div>

                <label class="uk-form-label">Typ</label>
                <div class="uk-margin">
                    <select class="uk-select" data-vv-as="typ" name="allDay" placeholder="" type="text"
                            v-model="event.allDay"
                            v-validate="'required'">
                        <option :value="true">Cały dzień</option>
                        <option :value="false">Wybrane Godziny</option>
                    </select>
                    <span class="validation-error">{{ errors.first('allDay') }}</span>
                </div>

                <label class="uk-form-label">Od</label>
                <div class="uk-margin">
                    <datetime :minute-step="15" value-zone="local" :phrases="{ok: 'Wybierz', cancel: 'Anuluj'}"
                              :type="getType()" auto
                              class="wpip-theme" input-class="uk-input" v-model="event.start"></datetime>

                    <span class="validation-error">{{ errors.first('start') }}</span>
                </div>
                <label class="uk-form-label">Do</label>
                <div class="uk-margin">
                    <datetime :minute-step="15" value-zone="local" :phrases="{ok: 'Wybierz', cancel: 'Anuluj'}"
                              :type="getType()" auto
                              class="wpip-theme" input-class="uk-input" v-model="event.end"></datetime>

                    <span class="validation-error">{{ errors.first('end') }}</span>
                </div>

                <label class="uk-form-label">Budynki</label>
                <div class="uk-form-controls">
                    <v-select multiple :filterable="false" :options="buildings" @search="searchBuildings"
                              label="@id" :class="'wpip-select'" v-model="event.buildings"
                              data-vv-as="budynki" ref="multiSelectBuilding"
                              name="buildings" v-validate="'required'">

                        <template slot="option" slot-scope="option">
                            <div class="d-center">
                                {{ option.name }}
                            </div>
                        </template>

                        <template slot="selected-option" slot-scope="option">
                            <div class="selected d-center">
                                {{ option.name }}
                            </div>
                        </template>

                        <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                                Nie znaleziono <em>{{ search }}</em>.
                            </template>
                            <em style="opacity: 0.5;" v-else>Wyszukaj budynek</em>
                        </template>
                    </v-select>
                    <span class="validation-error">{{ errors.first('buildings') }}</span>
                </div>


                <div>
                    <label class="uk-form-label"><input class="uk-checkbox" type="checkbox" v-model="event.hasDevice">
                        Dotyczy urządzenia</label>
                </div>
                <div v-if="event.hasDevice">
                    <label class="uk-form-label">Urządzenia</label>
                    <v-select multiple :filterable="false" :options="devices" @search="searchDevices" label="@id"
                              :class="'wpip-select'" v-model="event.devices"
                              data-vv-as="urządzenia" ref="multiSelectDevice"
                              name="devices" v-validate="'required'">

                        <template slot="option" slot-scope="option">
                            <div class="d-center">
                                {{ option.name }}
                            </div>
                        </template>

                        <template slot="selected-option" slot-scope="option">
                            <div class="selected d-center">
                                {{ option.name }}
                            </div>
                        </template>

                        <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                                Nie znaleziono <em>{{ search }}</em>.
                            </template>
                            <em style="opacity: 0.5;" v-else>Wyszukaj urządzenia</em>
                        </template>
                    </v-select>
                    <span class="validation-error">{{ errors.first('devices') }}</span>
                </div>
                <div>
                    <label class="uk-form-label"><input class="uk-checkbox" type="checkbox"
                                                        v-model="event.hasContractor">
                        Zewnętrzny realizator</label>
                </div>
                <div v-if="event.hasContractor">
                    <label class="uk-form-label">Kontrahent</label>
                    <v-select multiple :filterable="false" :options="contractors" @search="searchContractors"
                              label="@id"
                              data-vv-as="kontrahent"
                              name="contractor" ref="multiSelectContractor"
                              :class="'wpip-select'" v-model="event.contractors" v-validate="'required'">

                        <template slot="option" slot-scope="option">
                            <div class="d-center">
                                {{ option.name }}
                            </div>
                        </template>

                        <template slot="selected-option" slot-scope="option">
                            <div class="selected d-center">
                                {{ option.name }}
                            </div>
                        </template>

                        <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                                Nie znaleziono <em>{{ search }}</em>.
                            </template>
                            <em style="opacity: 0.5;" v-else>Wyszukaj kontrahenta</em>
                        </template>
                    </v-select>
                    <span class="validation-error">{{ errors.first('contractor') }}</span>
                </div>

                <label class="uk-form-label">Osoba odpowiedzialna</label>
                <div class="uk-form-controls">
                    <v-select multiple :filterable="false" :options="responsible" @search="searchPeople"
                              label="@id" :class="'wpip-select'" v-model="event.responsibles"
                              data-vv-as="osoba odpowiedzialna" ref="multiSelectPerson"
                              name="responsible" v-validate="'required'">

                        <template slot="option" slot-scope="option">
                            <div class="d-center">
                                {{ option.firstName }} {{ option.lastName }}
                            </div>
                        </template>

                        <template slot="selected-option" slot-scope="option">
                            <div class="selected d-center">
                                {{ option.firstName }} {{ option.lastName }}
                            </div>
                        </template>

                        <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                                Nie znaleziono <em>{{ search }}</em>.
                            </template>
                            <em style="opacity: 0.5;" v-else>Wyszukaj osobę</em>
                        </template>
                    </v-select>
                    <span class="validation-error">{{ errors.first('responsible') }}</span>
                </div>


            </div>
            <div class="uk-modal-footer uk-text-right">

                <button class="uk-button uk-button-default uk-modal-close" type="button">Anuluj</button>
                <button @click="addEvent" class="uk-button wpip-button" type="button">{{modal.button}}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import mixin from "../mixin";
    import UIkit from "uikit";
    import {Datetime} from 'vue-datetime'
    import 'vue-datetime/dist/vue-datetime.css'
    import axiosInstance from "../../axiosInstance";
    import apiConfig from "../../apiConfig";

    export default {
        name: 'addEventModal',
        components: {
            Datetime
        },
        props: {},
        data() {
            return {
                filterValue: "",
                modalDom: null,
                parameter: null,
                key: 0,
                remove: false,
                types: [],
                responsible: [],
                contractors: [],
                devices: [],
                buildings: [],
                showed: false,
                type: 'datetime',
                buildingIndex: 0,
                originalEvent: {
                    start: null,
                    end: null,
                    allDay: null,
                    id: 0,
                    hasContractor: false,
                    hasDevice: false,
                    responsibles: [],
                    contractors: [],
                    devices: [],
                    buildings: []
                },
                event: {
                    start: null,
                    end: null,
                    allDay: null,
                    id: 0,
                    hasContractor: false,
                    hasDevice: false,
                    responsibles: [],
                    contractors: [],
                    devices: [],
                    buildings: []
                },
                modal:
                    {
                        title: "Dodaj do kalendarza",
                        button: "Dodaj",
                        mode: 0
                    },

            }
        },
        mixins: [mixin],
        methods: {
            searchDevices: function (e) {
                let vm = this;
                clearTimeout(this.searchTimeout)
                this.searchTimeout = setTimeout(function () {
                    vm.getDevices(e)
                }, 500);
            },
            getDevices: function (e) {
                let vm = this;
                var params = {};
                params['name'] = e;
                let buildings = [-1]
                if (this.event.buildings) {
                    this.event.buildings.forEach(function (building) {
                        buildings.push(building.id)
                    })
                }
                params['building.id'] = buildings
                axiosInstance
                    .get(apiConfig.devicesApiUrl, {
                        params,
                        headers: this.$data.headers
                    })
                    .then(function (response) {
                        vm.devices = response.data['member'];
                    });
            },
            searchBuildings: function (e) {
                let vm = this;
                clearTimeout(this.searchTimeout)
                this.searchTimeout = setTimeout(function () {
                    vm.getBuildings(e)
                }, 500);
            },
            getBuildings: function (e) {
                let vm = this;
                var params = {};
                params['name'] = e;
                params['isExploatation'] = true,
                axiosInstance
                    .get(apiConfig.buildingsApiUrl, {
                        params,
                        headers: this.$data.headers
                    })
                    .then(function (response) {
                        vm.buildings = response.data['member'];
                    });
            },
            searchContractors: function (e) {
                let vm = this;
                clearTimeout(this.searchTimeout)
                this.searchTimeout = setTimeout(function () {
                    vm.getContractors(e)
                }, 500);
            },
            getContractors: function (e) {
                let vm = this;
                var params = {};
                params['name'] = e;
                axiosInstance
                    .get(apiConfig.contractorsApiUrl, {
                        params,
                        headers: this.$data.headers
                    })
                    .then(function (response) {
                        vm.contractors = response.data['member'];
                    });
            },
            searchPeople: function (e) {
                let vm = this;
                clearTimeout(this.searchTimeout)
                this.searchTimeout = setTimeout(function () {
                    vm.getPeople(e)
                }, 500);
            },
            async getPeople(e) {
                let vm = this;
                let params = {};
                params['firstName'] = e.split(' ');
                params['lastName'] = e.split(' ');
                let res = await axiosInstance
                    .get(apiConfig.peopleApiUrl,
                        {
                            params,
                            headers: this.$data.headers
                        });
                vm.responsible = res.data['member'];
                return vm.responsible;
            },
            hideModal: function (e, changed=false) {
                let showed = this.showed;
                this.showed = false;
                if (showed && this.event.id && !this.remove) {
                    if(changed){
                        this.$emit('showEventDetails', this.event);
                    }
                    else{
                        this.$emit('showEventDetails', this.originalEvent);
                    }

                }
            },
            showModal: async function (event) {
                this.getPeople("");
                this.getContractors("");
                this.getDevices("");
                this.getBuildings("");
                this.event = {...event};
                this.originalEvent = {...event}
                this.event.start = new Date(this.event.start).toISOString();
                if (this.event.end != null) {
                    this.event.end = new Date(this.event.end).toISOString();
                    if (this.event.allDay && this.event.id == null) {
                        this.event.end = new Date(this.event.end);
                        this.event.end.setDate(this.event.end.getDate() - 1);
                        this.event.end = this.event.end.toISOString();
                    }
                } else this.event.end = null;
                if (this.event.id) {
                    this.modal.title = "Edytuj zadanie";
                    this.modal.button = "Zmień";
                    this.modal.mode = 1;
                } else {
                    this.modal.title = "Dodaj do kalendarza";
                    this.modal.button = "Dodaj";
                    this.modal.mode = 0;
                }
                this.showed = true;
                this.remove = false;
                this.modalDom.show();
                if (this.$refs.multiSelectDevice) {
                    this.$refs.multiSelectDevice.search = "";
                }
                if (this.$refs.multiSelectContractor) {
                    this.$refs.multiSelectContractor.search = "";
                }
                if (this.$refs.multiSelectPerson) {
                    this.$refs.multiSelectPerson.search = "";
                }
                if (this.$refs.multiSelectBuilding) {
                    this.$refs.multiSelectBuilding.search = "";
                }
                await this.$validator.reset();
            },
            addCost: function () {
                this.modalDom.hide();
                this.$emit('addCost', this.event);
            },
            addEvent: function () {
                this.$validator.validate().then((result) => {
                    if (result) {
                        let postData = {...this.event};
                        if (postData.responsibles) {
                            postData.responsibles.forEach(function (person, key) {
                                if (typeof postData.responsibles !== 'string') {
                                    postData.responsibles[key] = person['@id'];
                                }
                            })
                        }
                        if (postData.buildings) {
                            postData.buildings.forEach(function (building, key) {
                                if (typeof postData.buildings !== 'string') {
                                    postData.buildings[key] = building['@id'];
                                }
                            })
                        }
                        if (postData.hasContractor) {
                            if (postData.contractors) {
                                postData.contractors.forEach(function (contractor, key) {
                                    if (typeof postData.contractors !== 'string') {
                                        postData.contractors[key] = contractor['@id'];
                                    }
                                })
                            }
                        } else {
                            postData.contractors = []
                            postData.hasContractor = false;
                        }
                        if (postData.hasDevice) {
                            if (postData.devices) {
                                postData.devices.forEach(function (device, key) {
                                    if (typeof postData.device !== 'string') {
                                        postData.devices[key] = device['@id'];
                                    }
                                })
                            }
                        } else {
                            postData.devices = []
                            postData.hasDevice = false;
                        }
                        if (postData.end === null) {
                            postData.end = postData.start;
                        }
                        if (postData.allDay && postData.end !== null) {
                            let date = new Date(postData.end);
                            date.setDate(date.getDate() + 1);
                            postData.end = date.toISOString();
                        }
                        postData.endDate = postData.end;
                        let vm = this;
                        if (this.modal.mode === 0) {
                            axiosInstance
                                .post(apiConfig.eventsApiUrl, JSON.stringify(postData), {
                                    headers: this.$data.headers
                                })
                                .then(async function (response) {
                                    if (response.status === 201) {
                                        vm.types.forEach(function (type) {
                                            if (type['@id'] === vm.event.eventType) {
                                                postData.color = type.color;
                                            }
                                        });
                                        response.data.title = postData.name;
                                        postData.id = response.data.id;
                                        postData.eventType = response.data.eventType;
                                        response.data.color = postData.color;
                                        vm.$emit('addEvent', response.data);
                                        vm.modalDom.hide(null, true);
                                    }
                                }).catch();
                        } else {
                            axiosInstance
                                .put(apiConfig.eventsApiUrl + '/' + vm.event.id, JSON.stringify(postData), {
                                    headers: this.$data.headers
                                })
                                .then(async function (response) {
                                    if (response.status === 200) {
                                        vm.types.forEach(function (type) {
                                            if (type['@id'] === vm.event.eventType) {
                                                vm.event.color = type.color;
                                            }
                                        });
                                        vm.event.title = vm.event.name;
                                        vm.event.id = response.data.id;
                                        vm.event.start = response.data.start;
                                        response.data.end = response.data.endDate;
                                        vm.event.eventType = response.data.eventType;
                                        response.data.color = postData.color;
                                        response.data.title = postData.name;
                                        vm.$emit('editEvent', response.data);
                                        vm.modalDom.hide(null,true);
                                    }
                                }).catch();
                        }
                    }
                });
            },
            getType: function () {
                if (this.event.allDay) {
                    return 'date'
                } else {
                    return 'datetime'
                }
            }
        },
        watch: {
            'event.buildings': function () {
                this.searchDevices("")
            }
        },
        mounted() {
            let vm = this;
            axiosInstance
                .get(apiConfig.eventTypesApiUrl, {
                    headers: this.$data.headers
                })
                .then(function (response) {
                    vm.types = response.data['member'];
                });
            this.modalDom = UIkit.modal('#event-modal');
        }

    }
</script>
<style>
    .correlation-header {
        font-size: 1.3em;
    }

    .wpip-theme .vdatetime-popup__header,
    .wpip-theme .vdatetime-calendar__month__day--selected > span > span,
    .wpip-theme .vdatetime-calendar__month__day--selected:hover > span > span {
        background: #bf0e01 !important;
    }

    .wpip-theme .vdatetime-year-picker__item--selected,
    .wpip-theme .vdatetime-time-picker__item--selected,
    .wpip-theme .vdatetime-popup__actions__button {
        color: #bf0e01;
    }
</style>
