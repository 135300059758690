<template>
    <admin>
        <users-list/>
    </admin>
</template>

<script>

    import mixin from "../../components/mixin";
    import UsersList from '../../components/user/usersList';

    export default {
        name: 'users',
        components: {
            'usersList': UsersList,
        },
        mixins: [mixin],
        data() {
            return{}
        },
        async mounted() {
            this.ability = await this.$ability.getAbility();
            if (!this.ability.can('manage', 'ROLE_SUPER_ADMIN')) {
                await this.$router.push({name: 'not-allowed'});
            }
        }
    }
</script>
