<template>
    <div class="uk-card uk-card-body uk-card-default">
        <span class="uk-grid">
            <h4 :class="block.color+' uk-card-title uk-width-auto'">{{ block.title }}</h4>
            <span class="uk-width-expand text-right">
                <span class="uk-label wpip-label block-sum" v-bind:class="{red: block.sum.toFixed(2)!=='1.00'}"
                      v-if="block.sum > 0 && analysisType === 1">Suma wag: {{ block.sum.toFixed(2) }}</span>
            </span>
        </span>
        <ul
                :id="index"
                class="uk-list uk-list-divider"
        >
            <li
                    :key="element.id"
                    class="uk-grid uk-flex uk-flex-middle wpip-swot-element"
                    uk-grid
                    v-for="element in block.data"
            >
                <div class="uk-width-expand uk-text-truncate" :uk-tooltip="element.name">{{ element.name }}</div>
                <div class="uk-width-auto" v-if="element.weight > 0 && analysisType === 1">
                    <span class="uk-label wpip-label">{{ element.weight }}</span>
                </div>
                <swot-elements-actions v-bind:analysisType="analysisType"
                                       v-bind:block="block"
                                       v-bind:element="element"
                                       v-on:calculate="calculateSum"
                                       v-on:refresh="removeElement">
                </swot-elements-actions>
            </li>
        </ul>
        <div class="uk-grid" uk-grid>
            <div class="uk-width-expand">
                <input class="uk-input vk-width-expand" ref="input" v-model="inputValue" v-on:keyup="inputKeyPressed"/>
            </div>
            <div class="uk-width-auto">
                <button
                        @click="addElement(index)"
                        class="uk-button uk-button-default wpip-button-clean"
                >Dodaj
                </button>
            </div>
        </div>
    </div>
</template>

<script>

    import mixin from "../../components/mixin";
    import apiConfig from "../../apiConfig";
    import SwotElementsActions from "../../components/swot/swotElementsActions"
    import axiosInstance from "../../axiosInstance";

    export default {
        name: 'swotElementsBlock',
        components: {
            swotElementsActions: SwotElementsActions
        },
        mixins: [mixin],
        props: {
            block: {
                title: "",
                inputName: "",
                typeFilter: '',
                color: "",
                sum: null,
                data: []
            },
            index: null,
            analysisType: null
        },
        data:
            function () {
                return {
                    inputValue: ""
                }
            },
        methods: {
            calculateSum: function () {
                let vm = this;
                vm.$props.block.sum = 0;
                this.$props.block.data.forEach(function (element) {
                    if (element.weight) {
                        vm.$props.block.sum += element.weight;
                    }
                })
            },
            inputKeyPressed: function (e) {
                if (e.keyCode === 13) {
                    this.addElement();
                }
            },
            removeElement: function(e){
                this.block.data.splice(this.block.data.indexOf(e), 1);
                this.calculateSum();
            },
            addElement() {
                if (
                    this.$refs.input.value.length > 0
                ) {
                    let vm = this;
                    let postData = {
                        name: this.inputValue,
                        swotElementType: this.block.typeFilter,
                        swotAnalysis: {
                            id: this.$route.params.swotId
                        }
                    };
                    axiosInstance
                        .post(apiConfig.swotElementsApiUrl, postData,
                            {
                                headers: vm.$data.headers
                            }).then(function (result) {
                        let newElement = {
                            id: result.data.id,
                            name: result.data.name,
                            weight: 0,
                            correlations: []
                        };
                        {
                            vm.block.data.push(newElement);
                            vm.inputValue = "";
                            vm.$refs.input.focus();
                        }

                    });

                }
            }
        }
    }
</script>
<style scoped>
    .block-sum {
        font-size: 1.1em;
        background-color: #00a000;
    }

    .block-sum.red {
        background-color: #cf0000;
    }
</style>
