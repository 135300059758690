<template>
    <form @submit="checkForm" id="building-add" method="post">
        <fieldset class="uk-fieldset">
            <legend class="uk-legend">Dodaj nowy budynek</legend>
            <label class="uk-form-label">Nazwa</label>
            <div class="uk-margin">
                <input class="uk-input" data-vv-as="nazwa" name="name" placeholder="Nazwa" type="text"
                       v-model="building.name"
                       v-validate="'required'">
                <span class="validation-error">{{ errors.first('name') }}</span>
            </div>

            <label class="uk-form-label">Sekwencja</label>
            <div class="uk-margin">
                <input class="uk-input" data-vv-as="sekwencja" name="sequence" placeholder="" type="text"
                       v-model="building.sequence"
                       v-validate="'numeric'">
                <span class="validation-error">{{ errors.first('sequence') }}</span>
            </div>
            <label class="uk-form-label">Obraz</label>
            <div class="uk-margin">
                <img :src="imageSrc" height="100px" v-if="showImage" width="100px">
                <input @change="changePreview" name="image" accept="image/*" id="file" ref="file" type="file" data-vv-as="obraz budynku" v-validate="'ext:jpg,jpeg,png,bmp,svg'">
                <span class="validation-error">{{ errors.first('image') }}</span>
            </div>

            <label class="uk-form-label">Opis</label>
            <div class="uk-margin">
                                <textarea class="uk-textarea" name="description" placeholder="Opis"
                                          v-model="building.description" rows="10"></textarea>
            </div>

            <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                <label><input class="uk-checkbox" name="isIAS" type="checkbox" v-model="building.isIAS">
                    Narzędzia IAS</label>
                <label><input class="uk-checkbox" name="isBMS" type="checkbox" v-model="building.isBMS">
                    Automatyka</label>
                <label><input class="uk-checkbox" name="isDevice" type="checkbox" v-model="building.isDevice">
                    Urządzenia</label>
                <label><input class="uk-checkbox" name="isExploatation" type="checkbox"
                              v-model="building.isExploatation"> Eksploatacja</label>
                <label><input class="uk-checkbox" name="isRental" type="checkbox" v-model="building.isRental">
                    Wynajem</label>
                <label><input class="uk-checkbox" name="isAnalysis" type="checkbox"
                              v-model="building.isAnalysis"> Analiza</label>
            </div>

            <div class="uk-margin">
                <label class="uk-form-label">Kontrahent</label>
                <model-list-select
                        :class="'uk-input'"
                        :list="contractors"
                        @searchchange="getContractors"
                        option-text="name"
                        option-value="@id"
                        placeholder="Wybierz kontrahenta"
                        v-model="building.contractor"
                >
                </model-list-select>
                <span>{{ errors.first('unit') }}</span>
            </div>

            <div class="uk-margin">
                <label>Data rozpoczęcia</label>
                <datepicker :disabledDates="disabledBeginDates" :format="'dd.MM.yyyy'" :input-class="'uk-input'"
                            :language="pl" data-uk-datepicker="{format:'DD.MM.YYYY'}" data-vv-as="data rozpoczęcia"
                            id="test"
                            name="beginDate" type="text" v-model="building.beginDate"
                            v-on:selected="changeBeginDate">>
                </datepicker>
                <span class="validation-error">{{ errors.first('beginDate') }}</span>
            </div>

            <div class="uk-margin">
                <label>Data zakończenia</label>
                <datepicker :disabledDates="disabledEndDates" :format="'dd.MM.yyyy'" :input-class="'uk-input'"
                            :language="pl"
                            data-vv-as="data zakończenia" name="endDate"
                            ref="endDate" type="date" v-model="building.endDate"
                            v-on:selected="changeEndDate"></datepicker>
                <span class="validation-error">{{ errors.first('endDate') }}</span>
            </div>

            <input class="uk-button uk-button-default" type="submit" value="Zapisz">
        </fieldset>
    </form>
</template>

<script>
    import apiConfig from "../../apiConfig";
    import mixin from "../mixin";
    import axiosInstance from "../../axiosInstance";
    import Datepicker from "vuejs-datepicker";
    import {pl} from "vuejs-datepicker/src/locale";
    import {ModelListSelect} from 'vue-search-select';

    export default {
        components: {
            Datepicker,
            ModelListSelect
        },
        name: 'buildingForm',
        data() {
            return {
                pl: pl,
                contractors: [],
                building: {
                    name: null,
                    contractor: null,
                    description: null,
                    isIAS: false,
                    isBMS: false,
                    isDevice: false,
                    isExploatation: false,
                    isRental: false,
                    isAnalysis: false,
                    beginDate: null,
                    endDate: null,
                    image: null,
                    imageFile: null,
                    sequence: null
                },
                disabledEndDates: {},
                disabledBeginDates: {},
                imageSrc: '',
                showImage: false
            }
        },
        mixins: [mixin],
        created: function () {
            this.getContractors("");
        },
        methods: {
            getContractors: function (e) {
                let vm = this;
                var params = {};
                params['name'] = e;
                axiosInstance
                    .get(apiConfig.contractorsApiUrl, {
                        params,
                        headers: this.$data.headers
                    })
                    .then(function (response) {
                        vm.contractors = response.data['member'];
                    });
            },
            changeBeginDate: function (date) {
                this.$nextTick(() => this.disabledEndDates = {to: date});
                if (date > this.building.endDate) {
                    this.building.endDate = date;
                }

            },
            changeEndDate: function (date) {
                this.$nextTick(() => this.disabledBeginDates = {from: date});
                if (date < this.building.beginDate) {
                    this.building.beginDate = date;
                }
            },
            changePreview: function () {
                let vm = this;
                var reader = new FileReader();

                reader.onload = function (e) {
                    vm.showImage = true;
                    vm.imageSrc = e.target.result;
                };

                reader.readAsDataURL(this.$refs.file.files[0]);
            },
            loadData: function (building) {
                this.building = building;
                this.disabledEndDates.to = new Date(building.beginDate);
                this.disabledBeginDates.from = new Date(building.endDate);
                this.imageSrc = apiConfig.apiUrl + "/uploads/images/building/" + this.building.image;
                if (this.building.image != null) {
                    this.showImage = true;
                }
                this.building.contractor = this.building.contractor['@id'];
            },
            setImage: function (postData) {
                this.building.imageFile = this.$refs.file.files[0];
                let vm = this;
                let formData = new FormData();
                formData.append('image', this.building.imageFile);
                formData.append('oldImage', this.building.image);
                axiosInstance.post("/api/uploadFile", formData, {
                    headers: this.$data.multipartHeaders
                })
                    .then(function (response) {
                        if (response.status === 200) {
                            vm.building.image = response.data.fileName;
                            postData.image = vm.building.image;
                            vm.$emit('submit', postData);
                        }
                    });

            },
            checkForm: function (e) {
                e.preventDefault();
                let vm = this;
                let postData = {
                    name: vm.building.name,
                    description: vm.building.description,
                    image: vm.building.image,
                    isIAS: vm.building.isIAS,
                    isBMS: vm.building.isBMS,
                    isDevice: vm.building.isDevice,
                    isExploatation: vm.building.isExploatation,
                    isRental: vm.building.isRental,
                    isAnalysis: vm.building.isAnalysis,
                    beginDate: vm.building.beginDate,
                    endDate: vm.building.endDate,
                    contractor: vm.building.contractor,
                    sequence: parseInt(vm.building.sequence)
                };
                if (postData.beginDate === "") {
                    postData.beginDate = null;
                }
                if (postData.endDate === "") {
                    postData.endDate = null;
                }
                vm.$validator.validate().then(function (result) {
                    if (result) {
                        if (vm.$refs.file.files[0]) {
                            vm.setImage(postData);
                        } else {
                            vm.$emit('submit', postData);
                        }
                    }
                }).catch(() => {
                    return false;
                });
            }
        }
    }
</script>
