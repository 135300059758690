<template>
    <span>
            <a
                    @click.prevent="detailedGoals(datatable.item)"
                    class="uk-icon-button"
                    href="#"
                    uk-tooltip="Szczegóły"
                    v-if="$route.name !== 'operationalGoals'"
            >
                <vk-icon class="hover" icon="search"/>
            </a>
            <a
                    @click.prevent="editGoal(datatable.item)"
                    class="uk-icon-button"
                    href="#"
                    uk-tooltip="Zmień cel"
            >
                <vk-icon class="hover" icon="file-edit"/>
            </a>
            <a
                    @click.prevent="removeGoal(datatable.item)"
                    class="uk-icon-button"
                    href="#"
                    uk-tooltip="Usuń cel"
            >
                <vk-icon class="hover" icon="trash"/>
                <remove ref="remove" v-on:refresh="$emit('refresh')"/>
            </a>
    </span>
</template>

<script>

    import mixin from "../../components/mixin";
    import apiConfig from "../../apiConfig";
    import remove from "../ui/remove"

    export default {
        name: 'goalsActions',
        components: {
            remove
        },
        mixins: [mixin],
        props: {
            datatable: Object
        },
        methods: {
            editGoal(goal) {
                this.$emit('edit', goal);
            },
            detailedGoals(goal) {
                if (this.$route.name !== 'detailedGoals') {
                    this.$router.push({name: 'detailedGoals', params: {goalId: goal.id}})
                } else {
                    this.$router.push({name: 'operationalGoals', params: {detailedId: goal.id}})
                }
            },
            removeGoal(goal) {
                let label = "cel";
                if(goal.goals.length!==0){
                    label = "cel z powiązanymi z nim innymi celami"
                }
                this.$refs.remove.remove(apiConfig.goalsApiUrl + "/" + goal.id, label);
            }

        }
    }
</script>
