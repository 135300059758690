<template>
  <admin ref="admin">
    <div class="uk-container-expand uk-padding">
      <vk-grid>
        <menuAdmin/>
        <div class="uk-width-expand@m">
          <div>
            <resources-list/>
          </div>
        </div>
      </vk-grid>
    </div>

  </admin>

</template>

<script>

import mixin from "../../components/mixin";
import ResourcesList from "../../components/building/resourcesList";

export default {
  name: 'buildings',
  components: {
    ResourcesList,
  },
  mixins: [mixin],
  data() {
    return {}
  },
  async mounted() {
    this.ability = await this.$ability.getAbility();
    if (!this.ability.can('manage', 'ROLE_SUPER_ADMIN')) {
      await this.$router.push({name: 'not-allowed'});
    }
  }
}
</script>
