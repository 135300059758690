<template>
    <admin>
        <div class="uk-container-expand uk-padding">
            <vk-grid>
                <sub-menu></sub-menu>
                <div class="uk-width-expand@m">
                    <registers-list ref="list"></registers-list>
                </div>
            </vk-grid>
        </div>
    </admin>
</template>

<script>
    import mixin from "../../components/mixin";
    import RegistersList from "../../components/register/RegistersList";
    import SubMenu from "@/components/ui/subMenu";

    export default {
        name: 'register',
        components: {
          SubMenu,
            'registersList': RegistersList,
        },
        data(){
          return {
            search: {
              name: "",
              number: ""
            },
            timeout: null,
          }
        },
      methods: {
        getData()  {
          let vm = this
          if(this.timeout) {
            clearTimeout(this.timeout)
          }
          this.timeout = setTimeout(function(){vm.get()}, 500)
        },
        get(){
          this.$refs.list.getData(this.search.number, this.search.name);
          this.timeout = null
        }
      },
        mixins: [mixin]
    }
</script>
