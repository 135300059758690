<template>
    <div class="uk-flex-top uk-modal-container" container=".admin" id="devices" uk-modal>
        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-modal-body">
                <h3>{{ modal.title }}</h3>
                <legend class="uk-legend">Nazwa</legend>
                <div class="uk-margin">
                    <input class="uk-input" data-vv-as="nazwa" name="name" placeholder="Nazwa" type="text"
                           v-model="device.name"
                           v-validate="'required|max:255'">
                    <span class="validation-error">{{ errors.first('name') }}</span>
                </div>

              <legend class="uk-legend">Obiekt</legend>
              <div class="uk-form-controls">
                <v-select :filterable="false" :options="buildings" @search="searchBuildings"
                          label="@id" :class="'wpip-select'" v-model="device.building"
                          data-vv-as="budynek" ref="selectBuilding"
                          name="building" v-validate="'required'" :reduce="building => building.id">

                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.name }}
                    </div>
                  </template>

                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.name }}
                    </div>
                  </template>

                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      Nie znaleziono <em>{{ search }}</em>.
                    </template>
                    <em style="opacity: 0.5;" v-else>Wyszukaj budynek</em>
                  </template>
                </v-select>
                <span class="validation-error">{{ errors.first('building') }}</span>
              </div>

              <legend class="uk-legend">Rodzaj</legend>
                <div class="uk-margin">
                    <input class="uk-input" data-vv-as="rodzaj" name="model" placeholder="Rodzaj" type="text"
                           v-model="device.model"
                           v-validate="'required|max:255'">
                    <span class="validation-error">{{ errors.first('model') }}</span>
                </div>

              <legend class="uk-legend">Typ</legend>
                <div class="uk-margin">
                    <select class="uk-select" data-vv-as="typ" name="name" placeholder="Typ"
                           v-model="device.type">
                      <option :key="key" :value="parseInt(key)" v-for="(type,key) in types">{{ type }}</option>
                    </select>
                    <span class="validation-error">{{ errors.first('name') }}</span>
                </div>

              <div v-if="device.type===1">
              <legend class="uk-legend">Identyfikator</legend>
              <div class="uk-margin">
                <input class="uk-input" data-vv-as="identyfikator" name="nodeId" placeholder="Podaj identyfikator" type="number"
                       v-model="device.nodeId"
                       v-validate="'required'">
                <span class="validation-error">{{ errors.first('nodeId') }}</span>
              </div>

              </div>
                <legend class="uk-legend">Opis</legend>
                <div class="uk-margin">
                     <textarea
                             class="uk-textarea"
                             data-vv-as="opis"
                             name="description"
                             placeholder="Proszę podać krótki opis urządzenia"
                             rows="5"
                             v-model="device.description"
                     ></textarea>
                    <span class="validation-error">{{ errors.first('description') }}</span>
                </div>

                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-default uk-modal-close" type="button">Anuluj</button>
                    <button :disabled="modal.blocked" @click="submit" class="uk-button wpip-button" type="button">
                        {{modal.button}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import mixin from "../mixin";
    import UIkit from "uikit";
    import 'vue-datetime/dist/vue-datetime.css'
    import axiosInstance from "../../axiosInstance";
    import apiConfig from "../../apiConfig";
    import DeviceTypes from "../../constants/deviceTypes";

    export default {
        name: 'deviceModal',
        data() {
            return {
                filterValue: "",
                modalDom: null,
                parameter: null,
                key: 0,
                buildings: [],
                types: DeviceTypes,
                type: 'datetime',
                buildingIndex: 0,
                device: {},
                modal:
                    {
                        title: "Dodaj urządzenie",
                        button: "Dodaj",
                        blocked: false
                    },

            }
        },
        mixins: [mixin],
        methods: {
            showModal: function (device) {
                let vm = this;
                this.device = device;
                if (device == null) {
                    vm.modal =
                        {
                            title: "Dodaj urządzenie",
                            button: "Dodaj",
                            blocked: false
                        }
                    vm.device = {
                        name: "",
                        type: null,
                        nodeId: null,
                        building: JSON.parse(localStorage.getItem('currentBuilding'))
                    }
                } else {
                    vm.device.id = device.id;
                    vm.device.name = device.name;
                    vm.device.description = device.description;
                    this.modal =
                        {
                            title: "Zmień urządzenie",
                            button: "Zmień",
                            blocked: false
                        }
                }

                this.modalDom.show();
            },
            submit: function () {
                let postData = {
                    name: this.device.name,
                    type: this.device.type,
                    model: this.device.model,
                    nodeId: parseInt(this.device.nodeId),
                    description: this.device.description,
                };

                let vm = this;
                vm.$validator.validate().then((result) => {
                    if (result) {
                        this.modal.blocked = true;
                        if (!this.device.id) {
                            postData.building = '/api/buildings/' + this.$route.params.buildingId;
                            axiosInstance
                                .post(apiConfig.devicesApiUrl, JSON.stringify(postData), {
                                    headers: this.$data.headers
                                })
                                .then(async function (response) {
                                    if (response.status === 201) {
                                        vm.$emit('refresh');
                                        vm.modalDom.hide();
                                    }
                                    vm.modal.blocked = false;
                                }).catch();
                        } else {
                            axiosInstance
                                .put(apiConfig.devicesApiUrl + '/' + vm.device.id, JSON.stringify(postData), {
                                    headers: this.$data.headers
                                })
                                .then(async function (response) {
                                    if (response.status === 200) {
                                        vm.$emit('refresh');
                                        vm.modalDom.hide();
                                    }
                                    vm.modal.blocked = false;
                                }).catch();
                        }
                    }
                });
            },
          searchBuildings: function (e) {
            let vm = this;
            clearTimeout(this.searchTimeout)
            this.searchTimeout = setTimeout(function () {
              vm.getBuildings(e)
            }, 500);
          },
          getBuildings: function (e) {
            let vm = this;
            var params = {};
            params['name'] = e;
            axiosInstance
                .get(apiConfig.buildingsApiUrl, {
                  params,
                  headers: this.$data.headers
                })
                .then(function (response) {
                  vm.buildings = response.data['member'];
                });
          }
        },
        mounted() {
            if (this.modalDom == null) {
                this.modalDom = UIkit.modal('#devices');
            }
            this.getBuildings()
        }

    }
</script>
