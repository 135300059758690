<template>
  <div>
    <div class="steps-header">ETAPY ANALIZY QFD</div>
    <div @click="selectStep(1)" class="step" name="clientRequirements" v-bind:class="{current: step===1}">Wymagania
      klienta
    </div>
    <div @click="selectStep(2)" class="step" name="technicalParameters" v-bind:class="{current: step===2}">Parametry
      techniczne
    </div>
    <div @click="selectStep(3)" class="step" name="correlationsPreview" v-bind:class="{current: step===3}">Zależność
      parametrów
    </div>
    <div @click="selectStep(4)" class="step" name="correlationsPreview" v-bind:class="{current: step===4}">Ranga
      parametrów
    </div>
    <div @click="selectStep(5)" class="step" name="competitionRating" v-bind:class="{current: step===5}">Ocena
      konkurencyjności
    </div>
    <div @click="selectStep(6)" class="step" name="technicalDifficulty" v-bind:class="{current: step===6}">
      Ocena trudności wykonania
    </div>
  </div>
</template>

<script>

import mixin from "../../components/mixin";

export default {
  name: 'qfdAnalysisSteps',
  mixins: [mixin],
  props: {
    step: null
  },
  methods: {
    selectStep: function (step) {
      this.$emit('selectStep', step);
    }
  }
}
</script>
<style scoped>
.steps-header {
  color: #000;
}

.step {
  margin-top: 10px;
  font-size: 0.9em;
  color: #888888;
  cursor: pointer;
}

.step.current {
  color: #000;
}

.step:hover {
  color: #444444
}
</style>
