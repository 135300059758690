<template>
    <div>
        <vk-grid class="uk-margin-bottom">
            <h3 class="uk-width-expand">Budynki </h3>
            <button
                    @click="$refs.modal.showModal({})"
                    class="uk-button wpip-button uk-width-auto"
            ><vk-icon icon="plus-circle"></vk-icon> Dodaj Budynek
            </button>
        </vk-grid>
        <vk-card>
            <empty-list v-if="totalItems===0"/>
            <b-table v-else
                    ref="table" hover borderedless
                    no-sort-reset
                    :responsive="true"
                    :fields="fields"
                    :columns="fields"
                    :items="get"
                    :per-page="this.$data.perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc">

                <template v-slot:cell(name)="data">
                    <span  :uk-tooltip="data.item.name">
                    {{ limit(data.item,100) }}
                    </span>
                </template>

                <template v-slot:cell(actions)="data">
                    <buildings-actions :datatable=data v-on:edit="edit" v-on:refresh="refresh()"
                                    v-on:addPerson="addPerson" v-on:showPeople="showModal"/>
                </template>


            </b-table>
            <building-users-list-modal ref="usersModal" v-on:refresh="refresh"/>
            <building-user-modal ref="addUserModal" v-on:refresh="refresh"/>
            <building-modal ref="modal" @refresh="refresh"/>
            <b-pagination :total-rows="totalItems" :per-page="perPage" v-model="currentPage"
                        align="center" v-if="totalItems>perPage" size="sm" page-class="wpip-page" class="wpip-page"/>
        </vk-card>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import BuildingsActions from "./buildingsActions";
    import axiosInstance from "../../axiosInstance";
    import 'vue-loading-overlay/dist/vue-loading.css';
    import BuildingUsersListModal from "./usersListModal";
    import BuildingUserModal from "./userModal";
    import BuildingModal from "./buildingModal";
    import EmptyList from "../ui/emptyList";
    import charLimiter from "../charLimiter";

    export default {
        name: 'buildingsList',
        components: {
            EmptyList,
            BuildingModal,
            BuildingUsersListModal,
            BuildingUserModal,
            buildingsActions: BuildingsActions
        },
        data() {
            return {
                sortBy: 'name',
                sortDesc: false,
                data: [],
                isLoading: true,
                currentPage: 1,
                totalItems: '',
                fields: [
                    {key: 'name', label: 'Nazwa', sortable: true},
                    {key: 'actions', label: ''},
                ],
                contractorId: null
            }
        },
        mixins: [mixin, charLimiter],
        methods: {
            showModal: function (e) {
                this.$refs.usersModal.showModal(e, e.id)
            },
            addPerson: function (e) {
                this.$refs.addUserModal.showModal(e.id)
            },
            async get() {
                let loader = this.$loading.show({
                    // Optional parameters
                    container: null,
                    canCancel: false
                });
                let vm = this;
                let desc = 'desc';
                if (vm.sortDesc !== true) {
                    desc = 'asc';
                }

                var params = {};

                if (this.sortBy !== '') {
                    var key = "order[" + this.sortBy + "]";
                    params[key] = desc;
                }
                params['page'] = vm.currentPage;
                params['contractor.id'] = vm.contractorId;

                let res = await axiosInstance
                    .get(apiConfig.buildingsApiUrl,
                        {
                            params,
                            headers: this.$data.headers
                        });
                if (res.status === 200) {
                    vm.data = res.data['member'];
                    vm.totalItems = res.data['totalItems'];
                }
                loader.hide();
                return vm.data;
            },
            showParameters: function (e) {
                this.$refs.listModal.showModal(this.data[e], e)
            },
            refresh: async function () {
                let vm = this;

                let res = await axiosInstance
                    .get(apiConfig.userBuildingsApiUrl + '/' + localStorage.getItem('user'), {
                        headers: vm.$data.headers,
                    });
                if (res.status === 200) {
                    localStorage.setItem('buildings', JSON.stringify(res.data));
                }
              let building = await axiosInstance
                  .get(apiConfig.buildingsApiUrl + '/' + JSON.parse(localStorage.getItem('currentBuilding')).id, {
                    headers: vm.$data.headers,
                  });
              if (res.status === 200) {
                localStorage.setItem('currentBuilding', JSON.stringify(building.data));
              }


                await this.get();
                this.$emit('refreshBuildings');
                if (this.$refs.table) {
                    this.$refs.table.refresh();
                }
            },
            showTools: function (id, e) {
                e.preventDefault();
                this.$router.push({name: 'tools', params: {buildingId: id}})
            },
            edit: function (e) {
                this.$refs.modal.showModal(e)
                //this.$emit('edit');
            }
        }
    }
</script>
