<template>
    <admin>
        <div id="fmea-page">
            <div class="uk-container-expand">
                <div uk-grid>
                    <h3 class="uk-width-auto@s">
                        Analiza FMEA
                    </h3>
                    <div class="uk-width-expand@s uk-text-center wpip-text-large">
                        Wartość krytyczna LPR
                        <span class="uk-label wpip-label uk-text-large">{{ data.lpr }}</span>
                    </div>
                    <div class="uk-width-auto">
                        <button
                                @click="addFmeaElementForm"
                                class="uk-button wpip-button uk-width-auto@s"
                        >Dodaj element analizy Fmea
                        </button>
                    </div>

                </div>


                <fmea-elements-list class="uk-margin-top" ref="table" v-bind:limit="data.lpr"
                                    v-on:addElement="addFmeaElementForm" v-on:edit="edit" v-on:rate="rate"/>
                <p>
                    <file-download ref="download"/>
                    Raport z analizy FMEA (
                    <span class="download" v-on:click="downloadPdf">.pdf</span>) (
                    <span class="download" v-on:click="downloadXlsx">.xlsx</span>)
                </p>
                <button @click="fmeaList()" class="uk-button wpip-button uk-width-auto@s">Lista analiz Fmea</button>
            </div>

        </div>
        <fmea-element-modal ref="modal" v-on:modified="refresh"/>
        <fmea-element-rate ref="rate" v-on:modified="refresh"/>
    </admin>
</template>

<script>

    import mixin from "../../components/mixin";
    import apiConfig from "../../apiConfig";
    import FmeaElementModal from "../../components/fmea/fmeaElementModal";
    import FmeaElementRate from "../../components/fmea/fmeaElementRate";
    import FmeaElementsList from "../../components/fmea/fmeaElementsList";
    import axiosInstance from "../../axiosInstance";
    import FileDownload from "../../components/ui/fileDownload"

    export default {
        name: 'fmeaAnalysis',
        components: {
            fmeaElementModal: FmeaElementModal,
            fmeaElementRate: FmeaElementRate,
            fmeaElementsList: FmeaElementsList,
            fileDownload: FileDownload
        },
        mixins: [mixin],
        data() {
            return {
                ability: null,
                sortBy: 'name',
                sortDesc: false,
                data: [],
                currentPage: 1,
                totalItems: '',
                showDetails: false,
                buildingId: null,
            }
        },
        methods: {
            async get() {
                let vm = this;

                let res = await axiosInstance
                    .get(apiConfig.fmeasApiUrl + '/' + vm.$route.params.analysisId,
                        {
                            headers: this.$data.headers
                        });
                vm.data = res.data;
                return vm.data;
            },
            fmeaList: function () {
                this.$router.push('/buildings/' + this.$route.params.buildingId + '/fmeas/')
            },
            addFmeaElementForm: function () {
                this.$refs.modal.showModal(null);
            },
            edit: function (element) {
                this.$refs.modal.showModal(element);
            },
            rate: function (element) {
                let admin = false;
                admin = admin || this.ability.can('manage', 'ROLE_SUPER_ADMIN');
                admin = admin || this.ability.can('manage', 'ROLE_MANAGEMENT');
                if((element.person['id'] == localStorage.getItem('person') || admin) && !element.takenActions){
                  this.$refs.rate.showModal(element, 1);
                } else {
                  this.$refs.rate.showModal(element, 0);
                }
            },
            refresh: function () {
                this.$refs.table.refresh();
            },
            downloadPdf: function () {
                this.$refs.download.download("/fmea/" + this.$route.params.analysisId, "FMEA.pdf");
            },
            downloadXlsx: function () {
                this.$refs.download.download("/fmea/xlsx/" + this.$route.params.analysisId, "FMEA.xlsx");
            }
        },
        async mounted() {
            this.ability = await this.$ability.getAbility();
            if (!this.$ability.checkManageIasAbility('FMEA')) {
                await this.$router.push({name: 'not-allowed'});
            }
            this.get();
        }
    }
</script>

<style scoped>
    .wpip-label {
        border: 1px solid #bf0e01;
        background-color: #fff;
        color: #bf0e01;
    }

    .wpip-text-large {
        font-size: 1.3em;
    }

    .wpip-fmea-danger td {
        background-color: #bf0e011e;
        font-weight: bold;
    }

    .download {
        cursor: pointer;
    }

    .uk-table-justify td:first-child {
        padding-left: 8px;
    }

    .uk-table-justify td:last-child {
        padding-right: 8px;
    }
</style>
