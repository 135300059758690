<template>
    <div class="uk-flex-top uk-modal-container" container=".admin" id="spaces-modal" uk-modal>
        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-modal-body">
                <h3>{{ modal.title }}</h3>

                <div class="uk-margin">
                    <label>Nazwa</label>
                    <input class="uk-input" name="name"  v-model="space.name" type="text" placeholder="Nazwa"
                           v-validate="'required|max:255'" data-vv-as="nazwa">
                    <span class="validation-error">{{ errors.first('name') }}</span>
                </div>

                <div class="uk-margin">
                    <label>Powierzchnia</label>
                    <input class="uk-input" name="area" v-model="space.area" type="text" placeholder="Powierzchnia" v-validate="'required|cash'" data-vv-as="powierzchnia">
                    <span class="validation-error">{{ errors.first('area') }}</span>
                </div>

                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-default uk-modal-close" type="button">Anuluj</button>
                    <button :disabled="modal.blocked" @click="submit" class="uk-button wpip-button" type="button">
                        {{modal.button}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import mixin from "../mixin";
    import UIkit from "uikit";
    import 'vue-datetime/dist/vue-datetime.css'
    import axiosInstance from "../../axiosInstance";
    import apiConfig from "../../apiConfig";

    export default {
        name: 'spacesModal',
        data() {
            return {
                filterValue: "",
                modalDom: null,
                parameter: null,
                key: 0,
                types: [],
                type: 'datetime',
                buildingId: null,
                space: {
                    name: "",
                    area: null
                },
                modal:
                    {
                        title: "Dodaj przestrzeń",
                        button: "Dodaj",
                        blocked: false
                    },

            }
        },
        mixins: [mixin],
        methods: {
            showModal: function (data) {
                let vm = this;
                if (data != null) {
                    this.space = {...data.space};
                    this.buildingId = data.building;

                if (data.space == null) {
                    vm.modal =
                        {
                            title: "Dodaj przestrzeń",
                            button: "Dodaj",
                            blocked: false
                        }
                    vm.space = {
                        name: "",
                        area: null
                    }
                } else {
                    vm.modal =
                        {
                            title: "Zmień przestrzeń",
                            button: "Zmień",
                            blocked: false
                        }
                }

                this.$validator.reset()
                }
                this.modalDom.show()
            },
            submit: function () {
                let postData = {
                    name: this.space.name,
                    area: this.space.area,
                    building: '/api/buildings/'+this.buildingId
                };
                postData.area = postData.area.replace(",",".");
                let vm = this
                vm.$validator.validate().then((result) => {
                    if (result) {
                        this.modal.blocked = true
                        if (!this.space.id) {
                            axiosInstance
                                .post(apiConfig.spacesApiUrl, JSON.stringify(postData), {
                                    headers: this.$data.headers
                                })
                                .then(async function (response) {
                                    if (response.status === 201) {
                                        vm.space.id = response.data.id
                                    }
                                    vm.modal.blocked = false
                                    vm.modalDom.hide()
                                    vm.$emit('refresh', vm.buildingId)
                                }).catch();
                        } else {
                            axiosInstance
                                .put(apiConfig.spacesApiUrl + '/' + vm.space.id, JSON.stringify(postData), {
                                    headers: this.$data.headers
                                })
                                .then(async function (response) {
                                    if (response.status === 200) {
                                        vm.$emit('refresh')
                                        vm.modalDom.hide()
                                    }
                                    vm.modal.blocked = false
                                }).catch()
                        }
                    }
                });
            }
        },
        mounted() {
            if (this.modalDom == null) {
                let id = '#spaces-modal'
                this.modalDom = UIkit.modal(id)
            }
        }

    }
</script>

