<template>
    <admin>
        <div class="uk-container-expand uk-padding">
            <vk-grid>
                <div class="uk-width-expand@m">
                    <h3>Wybór obiektu do analizy</h3>
                </div>
            </vk-grid>
            <div class="uk-margin-top wpip-buildings">
                <building-panel v-bind:key="building.id" v-for="building in data" v-bind:building="building"/>
            </div>
        </div>

    </admin>

</template>

<script>

    import mixin from "../../components/mixin";
    import BuildingPanel from "../../components/building/buildingPanel";
    export default {
        name: 'buildings',
        components: {
            BuildingPanel,
        },
        mixins: [mixin],
        data() {
            return {
                sortBy: 'name',
                sortDesc: false,
                data: [],
                currentPage: 1,
                totalItems: '',
                fields: [
                    {key: 'name', label: 'Imię', sortable: true},
                    {key: 'lastName', label: 'Nazwisko', sortable: true},
                    {key: 'email', label: 'E-mail', sortable: true},
                    {key: 'isManager', label: 'Manager', class: 'text-center'},
                    {key: 'unit.name', label: 'Jednostka Organizacyjna', sortable: true},
                    {key: 'actions', label: ''},
                ]
            }
        },
        methods: {
            async get() {
                let vm = this;
                let desc = 'desc';
                if (vm.sortDesc !== true) {
                    desc = 'asc';
                }

                var params = {};

                if (this.sortBy !== '') {
                    var key = "order[" + this.sortBy + "]";
                    params[key] = desc;
                }
                params['page'] = vm.currentPage;

                vm.data = JSON.parse(localStorage.getItem('buildings'));
                return vm.data;
            },
        },
        mounted: function(){
          this.get();
        }
    }
</script>
