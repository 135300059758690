<template>
    <div>
        <dashboard-element-modal ref="modal" v-on:refresh="refresh"/>
        <vk-grid class="uk-margin-small-bottom">
            <h3 class="uk-width-expand">{{ dashboard.name }}
            </h3>
        </vk-grid>
        <vk-grid :key="'hp'+i" v-for="(row, i) in elements">
            <div :key="'vp'+j" v-for="(element,j) in row" :class="'uk-width-'+getWidth(element)">
                <div v-if="element!==-1">
                    <dashboard-element-value v-if="element.type===0" :element="element"></dashboard-element-value>
                    <dashboard-element-multiple-values v-else-if="element.type===1" :element="element"></dashboard-element-multiple-values>
                    <dashboard-element-line-chart :ref="'chart'" v-else-if="element.type===2" :element="element"></dashboard-element-line-chart>
                    <dashboard-element-gauge  v-else-if="element.type===3" :element="element"></dashboard-element-gauge>
                    <dashboard-element-button  v-else-if="element.type===4" :element="element"></dashboard-element-button>
                    <dashboard-element-input-button v-else-if="element.type===5" :element="element"></dashboard-element-input-button>
                </div>
                <span v-else></span>
            </div>
        </vk-grid>

    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import axiosInstance from "../../axiosInstance";
    import DashboardElementModal from "./dashboardElementModal";
    import charLimiter from "../charLimiter";
    import DashboardElementValue from "../dashboardElements/dashboardElementValue"
    import DashboardElementGauge from "../dashboardElements/dashboardElementGauge"
    import DashboardElementLineChart from "../dashboardElements/dashboardElementLineChart"
    import DashboardElementMultipleValues from "../dashboardElements/dashboardElementMultipleValues"
    import DashboardElementButton from "@/components/dashboardElements/dashboardElementButton";
    import DashboardElementInputButton from "@/components/dashboardElements/dashboardElementInputButton";


    export default {
        name: 'devicesList',
        components: {
          DashboardElementInputButton,
          DashboardElementButton,
            DashboardElementValue,
            DashboardElementLineChart,
            DashboardElementMultipleValues,
            DashboardElementModal,
            DashboardElementGauge
        },
        data() {
            return {
                dashboard: [],
                elements: [],
                timeout: null
            }
        },
        mixins: [mixin, charLimiter],
        methods: {
            getWidth(element){
                if(element===0){
                    return "1-6@m";
                }
                if(element===-1){
                    return "0";
                }
                else {
                    if(element.width===1){
                        return "1-6@m";
                    }
                    if(element.width===2){
                        return "1-3@m";
                    }
                    if(element.width===3){
                        return "1-2@m";
                    }
                    if(element.width===4){
                        return "2-3@m";
                    }
                    if(element.width===5){
                        return "5-6@m";
                    }
                    if(element.width===6){
                        return "1-1@m";
                    }
                }
            },
            async get() {
                let vm = this;
                let res = await axiosInstance
                    .get(apiConfig.dashboardsApiUrl + "/" + vm.$route.params.dashboardId,
                        {
                            headers: this.$data.headers
                        });
                vm.dashboard = res.data;
                vm.generateElements();
                return vm.dashboard;
            },
            refresh: async function () {
                await this.get();
                this.$refs.chart.forEach(function (chart) {
                    chart.refresh();
                })
            },
            generateElements: function(){
                this.elements = [];
                let vm = this;
                this.dashboard.elements.forEach(function (element) {
                    if(!vm.elements[element.verticalPosition]){
                        vm.elements[element.verticalPosition] = [0,0,0,0,0,0]
                    }
                    for(let i=element.horizontalPosition+1;i<element.horizontalPosition+element.width; i++){
                        vm.elements[element.verticalPosition][i] = -1;
                    }
                    vm.elements[element.verticalPosition][element.horizontalPosition]=element;
                })
                this.elements.push([0,0,0,0,0,0]);
            },
            add: function (i,j) {
                let element = null;

                if(this.elements[i][j]!==0 && this.elements[i][j]!==-1){
                    element = this.elements[i][j];
                }

                let maxWidth = 1;
                let n = j+1;
                if(element!==null){
                    n = j + element.width;
                    maxWidth = element.width;
                }
                for(n; n<6; n++){
                    if(this.elements[i][n]===0){
                        maxWidth++
                    }
                    else {
                        break
                    }
                }
                this.$refs.modal.showModal(element,i,j,maxWidth);
            },
            edit: function (template) {
                this.$refs.modal.showModal({...template});
            },
            timeoutRefresh: function(){
                this.timeout = null;
                this.refresh();
            }
        },
        mounted: function () {
            this.get();
        },
        created: function() {
            let vm = this;
            let url_admin = apiConfig.mercureUrl;
            const url = new URL(url_admin+'/.well-known/mercure');
            url.searchParams.append('topic', apiConfig.apiUrl + '/api/registers/{id}');
            this.eventSource = new EventSource(url);
            this.eventSource.onmessage = () => {
                if(this.timeout==null){
                    this.timeout = setTimeout(function(){vm.timeoutRefresh()}, 10000);
                }
            };

            this.get();
        },

        beforeDestroy() {
            this.eventSource.close();
        },
    }
</script>
<style scoped>
    .uk-width-0{
        display:none
    }
</style>
