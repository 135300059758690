<template>
<admin>
  <div id="wpip-project">
    <div uk-grid>
      <h3 class="uk-width-expand">Schemat organizacyjny</h3>
      <button class="uk-button wpip-button uk-width-auto mr-2" @click="$router.push('functionTree')">
        <vk-icon icon="move"></vk-icon> Plan funkcji
      </button>
      <button class="uk-button uk-button-default uk-width-auto" @click="downloadPdf">
        <vk-icon icon="download"></vk-icon> Pobierz (.pdf)
      </button>
      <file-download ref="download" />
    </div>
    <vk-card class="uk-margin-top uk-margin-bottom">
    <div class="text-center">
      <img src="../../assets/struktura_organizacyjna.png">
    </div></vk-card>
    <button class="uk-button uk-button-default uk-width-auto" @click="$router.push('infos')"><vk-icon icon="arrow-left"></vk-icon> wstecz</button>
  </div>
</admin>
</template>

<script>
import FileDownload from "@/components/ui/fileDownload";
export default {
  name: "infos",
  components: {
    FileDownload
  },
  data() {
    return {};
  },
  methods: {
    downloadPdf() {
      this.$refs.download.download("/struktura_organizacyjna", "Struktura Organizacyjna.pdf");
    },
  }
};
</script>
