<template>
    <admin>
        <contractor-contacts-list/>

    </admin>
</template>

<script>
    import mixin from "../../components/mixin";
    import ContractorContactsList from '../../components/contractorContact/contractorContactsList';

    export default {
        name: 'units',
        components: {
            'contractorContactsList': ContractorContactsList,
        },
        mixins: [mixin],
        data() {
            return {
                sortBy: 'name',
                sortDesc: false,
                data: [],
                currentPage: 1,
                totalItems: '',
                showDetails: false,
            }
        },
        methods: {
            refresh: async function () {
                await this.get();
                this.$refs.table.refresh();
            }
        },
        async mounted() {
            this.ability = await this.$ability.getAbility();
            if (!this.ability.can('manage', 'ROLE_SUPER_ADMIN')) {
                await this.$router.push({name: 'not-allowed'});
            }
        }
    }
</script>
