<template>
  <div class="uk-flex-top uk-modal-container" container=".admin" :id="'register-value-modal'" uk-modal>
    <div class="uk-modal-dialog uk-margin-auto-vertical">
      <button class="uk-modal-close-default" type="button" uk-close></button>
      <div class="uk-modal-body">
        <h3>{{ modal.title }}</h3>
        <div class="uk-margin">
          <label>Nowa wartość</label>
        <div class="w-100" uk-form-custom="target: false" v-if="register.valueType == valueType.float">
          <input class="uk-input"  data-vv-as="ostatnia wartość" name="value"
                 type="text" v-model="value"
                 v-validate="'required|decimal:2'">
          <span class="validation-error">{{ errors.first('value') }}</span>
        </div>
        <div class="w-100" uk-form-custom="target: false" v-if="register.valueType == valueType.double">
          <input class="uk-input"  data-vv-as="ostatnia wartość" name="value"
                 type="text" v-model="value"
                 v-validate="'required|decimal:10'">
          <span class="validation-error">{{ errors.first('value') }}</span>
        </div>
        <div class="w-100" uk-form-custom="target: false" v-if="register.valueType == valueType.integer">
          <input  class="uk-input"  data-vv-as="ostatnia wartość" name="value"
                 type="text" v-model="value"
                 v-validate="'required|integer'">
          <span class="validation-error">{{ errors.first('value') }}</span>
        </div>
        <div class="w-100" uk-form-custom="target: false" v-if="register.valueType == valueType.string">
          <input  class="uk-input"  data-vv-as="ostatnia wartość" name="value"
                 type="text" v-model="value"
                 v-validate="'required|alpha_spaces'">
          <span class="validation-error">{{ errors.first('value') }}</span>
        </div>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button class="uk-button uk-button-default uk-modal-close" type="button">Anuluj</button>
          <button :disabled="modal.blocked" @click="submit" class="uk-button wpip-button" type="button">
            {{ modal.button }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "../mixin";
import UIkit from "uikit";
import 'vue-datetime/dist/vue-datetime.css'
import axiosInstance from "@/axiosInstance";
import apiConfig from "@/apiConfig";

export default {
  name: 'registerValueModal',
  data() {
    return {
      filterValue: "",
      modalDom: null,
      parameter: null,
      key: 0,
      selectedMiddlewares: [],
      middlewares: [],
      types: [],
      type: 'datetime',
      value: null,
      buildingIndex: 0,
      register: {
        name: "",
        isCoil: null,
        number: null,
        size: null,
        type: null,
        valueType: null,
        expectedValueType: 0,
        expectedValue: "",
        minExpectedValue: "",
        maxExpectedValue: "",
      },
      modal:
          {
            title: "Zmień ostatnią wartość",
            button: "Zmień",
            blocked: false
          },

    }
  },
  mixins: [mixin],
  methods: {
    showModal: function (register) {
      this.register = {...register};
      this.value = null;
      this.$validator.reset();
      this.modalDom.show();
    },
    submit: function () {
      let vm = this;
      let postData = {
        values: []
      }

        postData.values.push({register: vm.register.id, value: vm.value})

      vm.$validator.validate().then((result) => {
        if (result) {
          axiosInstance
              .post(apiConfig.registerValuesApiUrl + "/add", JSON.stringify(postData), {
                headers: this.$data.headers
              }).then((result)=>{
            if(result.status=="200"){
              this.$emit("refresh");
              this.modalDom.hide()
            }
          });
        }
      })
          .catch(() => {
            return false;
          });

    }
  },
  mounted() {
    if (this.modalDom == null) {
      let id = '#register-value-modal';
      this.modalDom = UIkit.modal(id);
    }
  }

}
</script>
