<template>
    <span>
            <a
                    @click.prevent="showRates(datatable.item)"
                    class="uk-button wpip-button"
                    href="#"
                    uk-tooltip="Oceń"
                    v-if="$route.name !== 'operationalGoals'"
            >
                <span v-if="$attrs.mode==='self'">Zobacz ocenę</span>
                <span v-else>OCEŃ</span>
            </a>
    </span>
</template>

<script>

    import mixin from "../../components/mixin";

    export default {
        name: 'personRatesActions',
        mixins: [mixin],
        props: {
            datatable: Object
        },
        methods: {
            showRates(person) {
                this.$emit('showRates', person);
            }
        }
    }
</script>
