<template>
    <admin ref="admin">
        <div class="uk-container-expand uk-padding">
            <vk-grid>
                <sub-menu/>
                <div class="uk-width-expand@m">
                    <vk-grid>
                    <h4 class="uk-width-expand">Logi
                    </h4>

                    </vk-grid>
                    <div>
                        <div v-if="!logsReady">Proszę czekać.<br/> Oczekiwanie na przesłanie logów z middleware.</div>
                        <div v-else class="uk-card uk-card-default logs"><div class="uk-card-body">{{ logs.logs }}</div></div>
                    </div>
                </div>
            </vk-grid>
        </div>

    </admin>

</template>

<script>

    import mixin from "../../components/mixin";
    import axiosInstance from "../../axiosInstance";
    import apiConfig from "../../apiConfig";
    import SubMenu from "@/components/ui/subMenu";

    export default {
        name: 'middlewares',
        components: {
          SubMenu
        },
        mixins: [mixin],
        data() {
            return {
                logsReady: false,
                interval: null,
                logs: {
                    logs:"Test"
                }
            }
        },
        methods: {
            getLogs: async function () {
                let vm = this;
                await axiosInstance
                    .get(apiConfig.middlewaresApiUrl + '/' + this.$route.params.middlewareId, {
                        headers: this.$data.headers
                    })
                    .then(async function (response) {
                        if(response.data.logsReady){
                            clearInterval(vm.interval);
                            await axiosInstance
                                .get(apiConfig.logsApiUrl + '/' + vm.$route.params.middlewareId, {
                                    headers: vm.$data.headers
                                })
                                .then(async function (response) {
                                   vm.logs = response.data;
                                });
                            vm.logsReady = true;
                        }
                    });
            }
        },
        mounted() {
            this.interval = setInterval(this.getLogs, 5000);
            this.getLogs();
        },
        beforeDestroy() {
            clearInterval(this.interval);
        }
    }
</script>
<style scoped>
    .logs{
        white-space: pre;
    }
</style>
