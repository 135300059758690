<template>
    <admin>
        <div class="wpip-fmea">
            <div class="uk-container-expand" id="ias-content">
                <div class="uk-grid-divider uk-child-width-expand@s" uk-grid>
                    <div class="uk-width-expand">
                        <div class="uk-grid uk-margin-bottom">
                            <h3 class="uk-width-expand">Lista analiz FMEA</h3>
                            <button @click="addNewFmea"
                                    class="uk-button wpip-button uk-width-auto"
                            ><vk-icon icon="plus-circle"></vk-icon> Dodaj nową analizę FMEA
                            </button>
                        </div>
                        <vk-card>
                            <fmeas-list v-on:edit="editFmea" ref="table"></fmeas-list></vk-card>
                        <fmea-details-modal ref="modal" v-on:modified="refresh"></fmea-details-modal>
                    </div>
                </div>

            </div>

            <div class="uk-container-expand uk-padding">
                <router-link :to="{ name: 'tools', params: { buildingId: $route.params.buildingId } }">
                    <button class="uk-button uk-button-default"><vk-icon icon="thumbnails"></vk-icon> Lista metod</button>
                </router-link>
            </div>
        </div>
    </admin>
</template>

<script>

    import mixin from "../../components/mixin";
    import FmeaDetailsModal from "../../components/fmea/fmeaDetailsModal";
    import FmeasList from "../../components/fmea/fmeasList";

    export default {
        name: 'swots',
        components: {
            'fmeasList': FmeasList,
            'fmeaDetailsModal': FmeaDetailsModal
        },
        mixins: [mixin],
        data() {
            return {
                sortBy: 'name',
                sortDesc: false,
                data: [],
                currentPage: 1,
                totalItems: '',
                showDetails: false,
                buildingId: null
            }
        },
        methods: {
            addNewFmea: function () {
                this.$refs.modal.showModal(null);
            },
            editFmea: function (fmea) {
                this.$refs.modal.showModal(fmea);
            },
            refresh: function () {
                this.$refs.table.refresh();
            }

        },
        async mounted() {
            await this.$ability.getAbility();
            if (!this.$ability.checkManageIasAbility('FMEA')) {
                await this.$router.push({name: 'not-allowed'});
            }
        }
    }
</script>
