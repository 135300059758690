<template>
    <div class="uk-flex-top uk-modal-container" container=".admin" id="wpip-swot-correlation" uk-modal>
        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-modal-body">
                <p>{{ el1.name }}</p>
                <div class="uk-margin uk-grid-small uk-child-width-1-3 uk-grid">
                    <label>
                        <input
                                class="uk-radio"
                                name="swotCorrelation"
                                type="radio"
                                v-model="value"
                                value=2
                        />
                        {{ a }}
                    </label>
                    <label>
                        <input
                                class="uk-radio"
                                name="swotCorrelation"
                                type="radio"
                                v-model="value"
                                value=1
                        />
                        {{ b }}
                    </label>
                    <label>
                        <input
                                class="uk-radio"
                                name="swotCorrelation"
                                type="radio"
                                v-model="value"
                                value=0
                        /> nie ma wpływu na
                    </label>
                </div>
                <p>{{ el2.name }}</p>
            </div>
            <div class="uk-modal-footer uk-text-right">
                <button class="uk-button uk-button-default uk-modal-close" type="button">Anuluj</button>
                <button @click="sendData" class="uk-button wpip-button" type="button">Zapisz</button>
            </div>
        </div>
    </div>
</template>

<script>

    import mixin from "../../components/mixin";

    export default {
        name: 'swotsActions',
        mixins: [mixin],
        data() {
            return {
                el1: "",
                el2: "",
                a: "",
                b: "",
                type: "",
                value: 0,
                correlationLabels: {
                    so: {
                        a: "w dużym stopniu wspomaga wykorzystanie",
                        b: "w małym stopniu wspomaga wykorzystanie",
                        typeFrom: "s",
                        typeTo: "o"
                    },
                    st: {
                        a: "w dużym stopniu przeciwdziała",
                        b: "w małym stopniu przeciwdziała",
                        typeFrom: "s",
                        typeTo: "t"
                    },
                    wo: {
                        a: "w dużym stopniu utrudnia wykorzystanie",
                        b: "w małym stopniu utrudnia wykorzystanie",
                        typeFrom: "w",
                        typeTo: "o"
                    },
                    wt: {
                        a: "w dużym stopniu wzmacnia",
                        b: "w małym stopniu wzmacnia",
                        typeFrom: "w",
                        typeTo: "t"
                    }
                },
            }
        },
        props: {
            datatable: Object
        },
        methods: {
            sendData() {
                this.$emit('send-data')
            }

        }
    }
</script>
