<template>
    <div class="uk-flex-top uk-modal-container" container=".admin" id="wpip-parameter-add" uk-modal>
        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close/>
            <div class="uk-modal-body">
                <h3>{{ modal.title }}</h3>
                <div class="uk-card uk-card-default uk-card-body uk-width-6-6@m">
                    <h3 class="uk-card-title">Wymaganie klienta</h3>
                    <p>{{propertyName}}</p>
                </div>
                <div class="uk-margin">
                    <label class="uk-form-label" for="parameter">Parametr</label>
                    <v-select :filterable="false" v-validate="'required'" name="parameter" :options="parameters" @search="search"
                              label="id" :class="'wpip-select'" v-model="parameter.id">

                        <template slot="option" slot-scope="option">
                            <div class="d-center">
                                {{ option.name }}
                            </div>
                        </template>

                        <template slot="selected-option" slot-scope="option">
                            <div class="selected d-center">
                                {{ option.name }}
                            </div>
                        </template>

                        <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                                Nie znaleziono <em>{{ search }}</em>.
                            </template>
                            <em style="opacity: 0.5;" v-else>Wyszukaj parametr</em>
                        </template>
                    </v-select>

                    <span class="validation-error">{{ errors.first('parameter') }}</span>
                </div>

                <div class="uk-margin" v-if="parameter!==null && parameter.id!=null">
                    <label class="uk-form-label">Określ siłę oddziaływania cechy i parametru</label>
                    <star-rating :maxRate="10" v-on:rated="setRate"/>
                </div>


            </div>


            <div class="uk-modal-footer uk-text-right">
                <button class="uk-button uk-button-default uk-modal-close" type="button">Anuluj</button>
                <button @click="addParameter" class="uk-button wpip-button" type="button">{{modal.button}}
                </button>
            </div>
        </div>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import UIkit from "uikit";
    import axiosInstance from "../../axiosInstance";
    import StarRating from "../ui/starRating";

    export default {
        name: 'technicalParameterModal',
        components: {
            StarRating
        },
        props: {
          propertyName: String
        },
        data() {
            return {
                filterValue: "",
                property: 0,
                propertyId: 0,
                parameters:[],
                modalDom: null,
                parameter: {
                    id: null,
                    weight: 0
                },
                modal:
                    {
                        title: "",
                        button: "Dodaj"
                    },

            }
        },
        mixins: [mixin],
        methods: {
            showModal: function (property, parameter, propertyId) {
                if (parameter == null) {
                    this.parameter = {id: null, weight: 0};
                    this.property = property;
                    this.propertyId = propertyId;
                    this.modal.button = "Dodaj";
                    this.modal.title = "Dodaj nowy parametr techniczny do wymagania klienta";
                } else {
                    this.parameter.id = parameter.parameter;
                    this.parameter.weight = parameter.rate;
                    this.propertyId = propertyId;
                    this.modal.button = "Zmień";
                    this.modal.title = "Zmień parametr techniczny przypisany do wymagania klienta";
                }
                this.$validator.reset();
                this.getParameters('');
                this.modalDom.show();

            },
            search: function(e){
                let vm = this;
                clearTimeout(this.searchTimeout)
                this.searchTimeout = setTimeout(function () {
                    vm.getParameters(e)
                }, 500);
            },
            getParameters: function(e){
                let vm = this;
                var params = {};
                params['name'] = e;
                axiosInstance
                    .get(apiConfig.qfdParametersApiUrl, {
                        params,
                        headers: this.$data.headers
                    })
                    .then(function (response) {
                        vm.parameters = response.data['member'];
                        vm.parameters = vm.filterParameters();
                    });
            },
            setRate: function (rating) {
                this.parameter.weight = rating.rate;
            },
            filterParameters: function(){
                let vm = this;
                let parameters =  [];
                this.parameters.forEach(function (parameter) {
                    let found = false;
                    vm.$attrs.properties[vm.property].parameters.forEach(function (p) {
                        if(p.id === parameter.id){
                            found = true
                        }
                    });
                    if(!found){
                        parameters.push(parameter)
                    }
                });
                return parameters;
            },
            addParameter: function () {
                let vm = this;

                vm.$validator.validate().then((result) => {
                    if (result) {
                        let postData = {
                            qfdParameter: '/api/qfd_parameters/' + vm.parameter.id.id,
                            weight: this.parameter.weight,
                            qfdProperty: '/api/qfd_properties/' + vm.propertyId,
                        };
                        axiosInstance
                            .post(apiConfig.qfdPropertyParametersApiUrl, JSON.stringify(postData), {
                                headers: vm.$data.headers
                            })
                            .then(function (response) {
                                if (response.status === 201) {
                                    vm.modalDom.hide();
                                    let parameter = vm.parameter;
                                    parameter.relationId = response.data.id;
                                    parameter.id = vm.parameter.id.id;
                                    vm.$emit('addParameter', {parameter: parameter, property: vm.property});
                                    //vm.$attrs.properties[vm.property].parameters.push(parameter);
                                }
                            }).catch();
                    }
                }).catch(() => {
                    return false;
                });
            }
        },
        mounted() {
            this.modalDom = UIkit.modal('#wpip-parameter-add');
        }

    }
</script>
