<template>
  <admin>
    <div id="wpip-project">
      <div uk-grid>
        <h3 class="uk-width-expand">Proszę wybrać narzędzie</h3>
        <button class="uk-button wpip-button uk-width-auto uk-button-primary" @click="projects()"><vk-icon icon="thumbnails"></vk-icon>
          Lista projektów</button>
      </div>
      <div id="wpip-tools" class="uk-child-width-1-3@m uk-grid-small uk-grid-match" uk-grid>
        <div :key="tool.name" v-for="tool in activeTools">
          <div class="uk-card uk-card-default uk-card-body">
            <h3 class="uk-card-title">
              <span class="uk-margin-small-right"><vk-icon v-bind:icon="tool.icon"/></span>
              {{ tool.title }}
            </h3>
            <p>{{ tool.desc }}</p>
            <button class="uk-button wpip-button" @click="getTool(tool.name)">Wybierz</button>
          </div>
        </div>
      </div>
    </div>
  </admin>
</template>

<script>
export default {
  name: "projectPage",
  data() {
    return {
      activeTools: [],
      building: {},
      tools: [
        {
          name: "swots",
          role: "SWOT",
          icon: "thumbnails",
          title: "Analiza SWOT",
          desc: "Kompleksowa metoda analizy strategicznej. Na podstawie wskazanych silnych i słabych stron oraz szans i zagrożeń, umożliwia opracowanie strategii postępowania."
        },
        {
          name: "qfds",
          role: "QFD",
          icon: "info",
          title: "Analiza QFD",
          desc: "Metoda QFD jest narzędziem, które pozwala przełożyć wymagania rynkowe co do produktu na zbiór warunków, jakie muszą być spełnione przez produkujący go podmiot na każdym etapie powstawania (od projektowania po serwis), czyli stanowi narzędzie przełożenia informacji pochodzących z rynku na cechy techniczne produktu."
        },
        {
          name: "fmeas",
          role: "FMEA",
          icon: "warning",
          title: "Analiza FMEA",
          desc: "Analiza FMEA (Failure Mode and Effect (Critical) Analysis - FMEA, FMECA) to metoda analizy przyczyn i skutków wad. Celem stosowania metody jest zapobieganie powstawania wad, błędów w produktach bądź procesach już na etapie projektowania."
        },
        {
          name: "zpcs",
          role: "ZPC",
          icon: "check",
          title: "Zarządzanie przez cele",
          desc: "Ideą przewodnią ZPC jest skoncentrowanie kierownictwa i podwładnych na wspólnym określaniu i negocjowaniu celów, wspólnym określaniu mierników pożądanych wyników końcowych oraz wspólnych okresowych przeglądach i ocenie uzyskanych rezultatów"
        },
        {
          name: "kaizens",
          role: "KEIZEN",
          icon: "bolt",
          title: "Kaizen",
          desc: "W języku japońskim Kaizen oznacza ciągłe usprawnianie, ulepszanie, udoskonalanie ukierunkowane na zaangażowanie wszystkich pracowników realizujących procesy doskonalenia w przedsiębiorstwie (wszyscy pracownicy mogą być źródłem pomysłów dotyczących wprowadzania usprawnień zmierzających do pozytywnych zmian)."
        },
        {
          name: "exploatation",
          role: "EXPLOATATION",
          icon: "settings",
          title: "Zarządzenie eksploatacją",
          desc: "Moduł wspomagający zarządzanie eksploatacją, wielowariantowe projektowanie budynków i wielokryterialną ocenę nakładów inwestycyjnych i kosztów eksploatacji."
        }
      ]
    };
  },
  created() {
    this.pid = this.$route.params.Pid;
  },
  methods: {
    checkAbilty: function (role) {
      let result = false;
      result = result || this.ability.can('manage', 'ROLE_SUPER_ADMIN');
      result = result || this.ability.can('manage', 'ROLE_IAS');
      result = result || this.ability.can('manage', 'ROLE_' + role);
      return result;
    },
    projects() {
      this.$router.push({name: "projects"});
    },
    getTool(tool) {
      if (tool === 'exploatation') {
        if(this.ability.can('manage', 'ROLE_EXPLOATATION') && this.building.isExploatation || this.ability.can('manage', 'ROLE_SUPER_ADMIN')){
          this.$router.push({name: 'calendar', params: {buildingId: this.$route.params.buildingId}});
        }
        else if(this.ability.can('manage', 'ROLE_DEVICES') && this.building.isDevices){
          this.$router.push({name: 'devices', params: {buildingId: this.$route.params.buildingId}});
        }
        else if(this.building.isRental){
          this.$router.push({name: 'rentalDashboard', params: {buildingId: this.$route.params.buildingId}});
        }
        else {
          this.$bvModal.msgBoxOk('Brak dostępnych narzędzi dla wybranego obiektu')
        }
      } else {
        this.$router.push({name: tool, params: {buildingId: this.$route.params.buildingId}});
      }
    }
  },
  async mounted() {
    let vm = this;
    this.building = JSON.parse(localStorage.getItem('currentBuilding'))
    this.ability = await this.$ability.getAbility();
    this.tools.forEach(function (tool) {
      if(tool.name==='exploatation'){
        if((vm.ability.can('manage', 'ROLE_SUPER_ADMIN') || vm.ability.can('manage', 'ROLE_EXPLOATATION') || vm.ability.can('manage', 'ROLE_DEVICES') || vm.ability.can('manage', 'ROLE_RENTAL')) && (vm.building.isExploatation || vm.building.isBMS || vm.building.isRental|| vm.building.isDevice )){
          vm.activeTools.push(tool);
        }
      }
      else if(tool.name==='zpcs'){
         vm.activeTools.push(tool);
      }
      else if (vm.checkAbilty(tool.role) && vm.building.isIAS) {
        vm.activeTools.push(tool);
      }
    })

  }
};
</script>
