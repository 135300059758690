<template>
    <div class="text-right nowrap">
      <!--
        <a
                @click.prevent="showList"
                class="uk-icon-button"
                uk-tooltip="Przejdź do listy obiektów"
        >
            <vk-icon class="hover" icon="sign-in"/>
        </a>
        -->
      <a
                @click.prevent="edit()"
                class="uk-icon-button"
                uk-tooltip="Edytuj"
        >
            <vk-icon class="hover" icon="pencil"/>
        </a>
      <a
                @click.prevent="remove"
                class="uk-icon-button"
                uk-tooltip="Usuń wynajem"
        >
            <vk-icon class="hover" icon="trash"/>
        </a>
      <remove ref="remove" v-on:refresh="$emit('refresh')"/>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "@/apiConfig";
    import Remove from "@/components/ui/remove";

    export default {
        components: {
          Remove
        },
        name: 'renterSpacesActions',
        mixins: [mixin],
        props: {
            datatable: Object
        },
        methods: {
            edit: function(){
              this.$emit('edit', this.$props.datatable)
            },
          remove(){
              this.$refs.remove.remove(apiConfig.rentalsApiUrl + "/" + this.$props.datatable.id, "wynajem");
              this.$emit('refresh')
          }
        }
    }
</script>
<style scoped>
    div {
        white-space: nowrap;
    }

    .middleware-red {
        background-color: #bf0e01;
        color: white;
    }

    .middleware-orange {
        background-color: #e38703;
        color: white;
    }
    .middleware-green {
        background-color: #09a913;
        color: white;
    }
</style>
