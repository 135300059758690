<template>
    <admin>
        <div class="wpip-swot">
            <parameters-list/>
            <div class="uk-container-expand uk-padding">
                <router-link :to="{ name: 'qfds', params: { buildingId: $route.params.buildingId } }">
                    <button
                            class="uk-button wpip-button uk-width-auto uk-margin-small-right"
                    >Wstecz
                    </button>
                </router-link>

                <router-link :to="{ name: 'tools', params: { buildingId: $route.params.buildingId } }">
                    <button class="uk-button uk-button-default"><vk-icon icon="thumbnails"></vk-icon> Lista metod</button>
                </router-link>
            </div>
        </div>
    </admin>
</template>

<script>

    import mixin from "../../components/mixin";
    import QfdParametersList from "../../components/qfd/qfdParametersList"

    export default {
        name: 'qfdParameters',
        components: {
            'parametersList': QfdParametersList
        },
        mixins: [mixin],
        data() {
            return {
                sortBy: 'name',
                sortDesc: false,
                data: [],
                currentPage: 1,
                totalItems: '',
                showDetails: false,
                buildingId: null
            }
        },
        async mounted() {
            await this.$ability.getAbility();
            if (!this.$ability.checkManageIasAbility('QFD')) {
                await this.$router.push({name: 'not-allowed'});
            }
        }
    }
</script>
