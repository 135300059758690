<template>
    <span>
            <a
                    @click.prevent="showGoal(datatable.item)"
                    class="uk-button wpip-button"
                    href="#"
                    uk-tooltip="Oceń"
                    v-if="$route.name !== 'operationalGoals'"
            >
                <span>Zobacz cel</span>
            </a>
    </span>
</template>

<script>

    import mixin from "../../components/mixin";

    export default {
        name: 'myGoalsActions',
        mixins: [mixin],
        props: {
            datatable: Object
        },
        methods: {
            showGoal(goal) {
                this.$emit('showGoal', goal);
            }
        }
    }
</script>
