<template>
    <div class="uk-container-expand uk-padding">
        <vk-grid>
            <h3 class="uk-width-expand">Cele operacyjne</h3>
            <button
                    @click="newGoal"
                    class="uk-button wpip-button uk-width-auto"
            >Dodaj cel operacyjny
            </button>
        </vk-grid>
        <empty-list v-if="totalItems===0"/>
        <b-table
                v-else
                :columns="fields"
                :current-page="currentPage"
                :fields="fields"
                :items="get"
                :per-page="this.$data.perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                borderedless
no-sort-reset
:responsive="true"
                class="uk-table vk-table-justify uk-table-divider"
                hover
                ref="table">

            <template v-slot:cell(actions)="data">
                <goals-actions :datatable=data v-on:edit="editGoal" v-on:refresh="refresh"/>
            </template>
            <template v-slot:cell(rate)="data">
                <goals-rate :datatable=data v-on:edit="editGoal"/>
            </template>
            <template v-slot:cell(person.firstName)="data">
                <span v-if="data.item.person!=null">
                    {{ data.item.person.firstName }} {{ data.item.person.lastName }}
                </span>
            </template>
        </b-table>
        <operational-modal ref="goalModal" v-on:refresh="refresh" :max-date="mainGoal.realization"/>
        <b-pagination :per-page="perPage" :total-rows="totalItems" align="center"
                      v-model="currentPage" v-if="totalItems>perPage" size="sm" page-class="wpip-page" class="wpip-page"/>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import axiosInstance from "../../axiosInstance";
    import GoalsActions from "./goalsActions";
    import GoalsRate from "./goalsRate";
    import OperationalModal from "./operationalModal";
    import EmptyList from "../ui/emptyList";

    export default {
        name: 'goalsList',
        components: {
            EmptyList,
            goalsActions: GoalsActions,
            goalsRate: GoalsRate,
            operationalModal: OperationalModal,
        },
        data() {
            return {
                sortBy: 'realization',
                sortDesc: false,
                data: [],
                currentPage: 1,
                mainGoal: {},
                totalItems: '',
                fields: [
                    {key: 'name', label: 'Opis', sortable: true},
                    {key: 'kpis', label: 'Mierniki', sortable: true},
                    {key: 'realization', label: 'Termin', sortable: true},
                    {key: 'person.firstName', label: 'Odpowiedzialny', sortable: true},
                    {key: 'rate', label: ''},
                    {key: 'actions', label: '', class: 'text-right'},
                ]
            }
        },
        mixins: [mixin],
        methods: {
            async get() {
                let vm = this;
                let desc = 'desc';
                if (vm.sortDesc !== true) {
                    desc = 'asc';
                }

                var params = {};

                if (this.sortBy !== '') {
                    var key = "order[" + this.sortBy + "]";
                    params[key] = desc;
                }
                params['page'] = vm.currentPage;
                params['parent'] = vm.$route.params.detailedId;
                params['type'] = 1;

                let res = await axiosInstance
                    .get(apiConfig.goalsApiUrl,
                        {
                            params,
                            headers: this.$data.headers
                        });
                vm.data = res.data['member'];
                vm.totalItems = res.data['totalItems'];
                return vm.data;
            },
            editGoal(e) {
                this.$refs.goalModal.showModal(e);
            },
            newGoal: function () {
                this.$refs.goalModal.showModal(null);
            },
            refresh() {
                if(this.$refs.table) {
                    this.$refs.table.refresh();
                } else {
                    this.get();
                }
            }
        },
        async mounted() {
            let params = {};
            let vm = this;
            params['page'] = vm.currentPage;
            params['parent'] = vm.$route.params.detailedId;
            params['type'] = 1;

            let mainGoal = await axiosInstance
                .get(apiConfig.goalsApiUrl + "/" + this.$route.params.goalId,
                    {
                        params,
                        headers: this.$data.headers
                    });
            vm.mainGoal = mainGoal.data;
        }
    }
</script>
<style scoped>
    .negative-2{
        background-color: rgb(240, 80, 110);
        color: white;
    }

    .negative-1{
        background-color: rgb(240, 140, 61);
        color: white;
    }

    .positive-1{
        background-color: rgb(30, 135, 240);
        color: white;
    }

    .positive-2{
        background-color: rgb(50, 210, 150);
        color: white;
    }
</style>
