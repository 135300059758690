<template>
    <div>
        <vk-grid>
            <div class="uk-width-expand">
                <vk-grid class="uk-margin-bottom">
                    <h3 class="uk-width-expand">Lista analiz typu SWOT</h3>
                    <button
                            @click="newSwot"
                            class="uk-button wpip-button uk-width-auto"
                    ><vk-icon icon="plus-circle"></vk-icon> Nowa analiza typu SWOT
                    </button>
                </vk-grid>
                <vk-card>
                <empty-list v-if="totalItems===0"/>
                <b-table v-else
                         :columns="fields"
                         :current-page="currentPage"
                         :fields="fields"
                         :items="get"
                         :per-page="this.$data.perPage"
                         :sort-by.sync="sortBy"
                         :sort-desc.sync="sortDesc"
                         borderedless
                         no-sort-reset
                         :responsive="true"
                         class="uk-table vk-table-justify uk-table-divider"
                         hover
                         ref="table">
                    <template v-slot:cell(type)="data">
                        {{ swotType[data.item.type] }}
                    </template>
                    <template v-slot:cell(description)="data">
                        <span :uk-tooltip="data.item.description">{{ limit(data.item,100, "description") }}</span>
                    </template>
                    <template v-slot:cell(actions)="data">
                        <swots-actions :datatable=data
                                       v-on:refresh="refresh" v-on:showModal="showModal"/>
                    </template>
                </b-table>
                <b-pagination :per-page="perPage" :total-rows="totalItems" align="center"
                              v-model="currentPage" v-if="totalItems>perPage" size="sm" page-class="wpip-page"
                              class="wpip-page"/></vk-card>
            </div>

        </vk-grid>
    </div>
</template>

<script>

    import {Grid} from 'vuikit/lib/grid';
    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import SwotsActions from "./swotsActions";
    import axiosInstance from "../../axiosInstance";
    import EmptyList from "../ui/emptyList";
    import charLimiter from "../charLimiter";

    export default {
        name: 'swotsList',
        components: {
            EmptyList,
            VkGrid: Grid,
            swotsActions: SwotsActions
        },
        data() {
            return {
                sortBy: 'date',
                sortDesc: true,
                swots: [],
                fields: [
                    {key: 'date', label: 'Data utworzenia', sortable: true, class: "uk-width-auto"},
                    {key: 'type', label: 'Typ', sortable: true, class: "uk-width-auto"},
                    {key: 'description', sortable: true, label: 'Podsumowanie', class: "uk-width-1-3"},
                    {key: 'actions', label: '', class: "uk-width-auto text-right"},
                ],
                currentPage: 1,
                totalItems: ''
            }
        },
        mixins: [mixin, charLimiter],
        methods: {
            async get() {
                let vm = this;
                let desc = 'desc';
                if (vm.sortDesc !== true) {
                    desc = 'asc';
                }
                let key = "order[" + this.sortBy + "]";
                let params = {};
                params[key] = desc;
                params['page'] = vm.currentPage;
                params['buildings'] = vm.$route.params.buildingId;

                let res = await axiosInstance
                    .get(apiConfig.swotApiUrl,
                        {
                            params,
                            headers: this.$data.headers
                        });
                vm.swots = res.data['member'];
                vm.totalItems = res.data['totalItems'];
                return vm.swots;
            },
            newSwot() {
                this.$emit('showModal', null);
            },
            showModal(e) {
                this.$emit('showModal', e);
            },
            refresh() {
                this.get();
                if (this.$refs.table) {
                    this.$refs.table.refresh();
                }
            }
        }
    }
</script>
