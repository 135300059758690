<template>
    <div class="uk-flex-top uk-modal-container" container=".admin" id="wpip-benefit-add" uk-modal>
        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-modal-body">
                <h3>{{ modal.title }}</h3>

                <div class="uk-margin">
                    <label class="uk-form-label">Nazwa</label>
                    <div class="uk-form-controls">
                        <input
                                class="uk-input"
                                data-vv-as="nazwa"
                                name="name"
                                type="text"
                                v-model="benefit.name"
                                v-validate="'required|max:255'"
                        >
                        <span class="validation-error">{{ errors.first('name') }}</span>
                    </div>
                </div>


                <div class="uk-margin">
                    <label class="uk-form-label">Premia</label>
                    <div class="uk-form-controls">
                <input
                        class="uk-input"
                        type="text"
                        name="cash"
                        v-model="benefit.cash"
                        v-validate="'cash'"
                >
                    </div>
                    <span class="validation-error">{{ errors.first('cash') }}</span>
                </div>

                <div class="uk-margin">
                    <label class="uk-form-label">Benefity pozapłacowe</label>
                    <div class="uk-form-controls">
                <textarea
                        class="uk-textarea"
                        rows="5"
                        data-vv-as="benefity"
                        name="bonus"
                        v-model="benefit.bonus"
                        v-validate="'max:255'"
                />
                    </div>

                    <span class="validation-error">{{ errors.first('bonus') }}</span>
                </div>

            </div>


            <div class="uk-modal-footer uk-text-right">
                <button @click="cancel" class="uk-button uk-button-default uk-modal-close" type="button">Anuluj</button>
                <button @click="submitBenefit" class="uk-button wpip-button" type="button">{{modal.button}}
                </button>
            </div>
        </div>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import UIkit from "uikit";
    import axiosInstance from "../../axiosInstance";

    export default {
        name: 'benefitModal',
        data() {
            return {
                filterValue: "",
                benefit: {
                    id: null,
                    name: "",
                    cash: "",
                    bonus: ""
                },
                modal:
                    {
                        title: "Dodaj nowy benefit",
                        button: "Dodaj"
                    },

            }
        },
        mixins: [mixin],
        methods: {
            showModal: function (benefit) {
                if (benefit == null) {
                    this.benefit.id = null;
                    this.benefit.name = "";
                    this.benefit.cash = "";
                    this.benefit.bonus = "";
                    this.modal.button = "Dodaj";
                    this.modal.title = "Dodaj nowy benefit";
                } else {
                    this.benefit.id = benefit.id;
                    this.benefit.name = benefit.name;
                    if(benefit.cash!=null) {
                        this.benefit.cash = benefit.cash.toFixed(2);
                    }
                    this.benefit.bonus = benefit.bonus;
                    this.modal.button = "Zmień";
                    this.modal.title = "Zmień benefit";
                }
                this.$validator.reset();

                UIkit.modal("#wpip-benefit-add").show();
            },
            submitBenefit: async function () {
                let vm = this;
                vm.$validator.validate().then((result) => {
                    if (result) {
                        let postData = {
                            name: this.benefit.name,
                            cash: parseFloat(this.benefit.cash.replace(',','.')),
                            bonus: this.benefit.bonus
                        };
                        if (this.benefit.id == null) {
                            axiosInstance
                                .post(apiConfig.benefitsApiUrl, JSON.stringify(postData), {
                                    headers: this.$data.headers
                                })
                                .then(async function (response) {
                                    if (response.status === 201) {
                                        UIkit.modal("#wpip-benefit-add").hide();
                                        vm.$emit('refresh');
                                    }
                                }).catch();
                        } else {
                            axiosInstance
                                .put(apiConfig.benefitsApiUrl + "/" + vm.benefit.id, JSON.stringify(postData), {
                                    headers: this.$data.headers
                                })
                                .then(async function (response) {
                                    if (response.status === 200) {
                                        UIkit.modal("#wpip-benefit-add").hide();
                                        vm.$emit('refresh');
                                    }
                                }).catch();
                        }
                    }
                }).catch(() => {
                    return false;
                });
            },
            cancel: function(){
                this.$emit('refresh');
            }
        }

    }
</script>
<style scoped>
    .correlation-header{
        font-size: 1.3em;
    }
</style>
