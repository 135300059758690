<template>
    <div>
      <div v-for="f in localContent" :key="f.code+f.name">
        <div class="row" :class="{main: rowStyle=='main'}" @click="clicked(f)" v-if="!f.hidden">
          <span class="col-5" :style="{paddingLeft: padding}">{{ f.code }} {{ f.name }}</span>
          <span class="col-1"><span v-if="typeof f.type !== 'object'">{{ f.type }}</span></span>
          <span class="col-2"><span v-if="typeof f.resp !== 'object'">{{ f.resp }}</span></span>
          <span class="col-2"><span v-if="typeof(f.real) !== 'object'">{{ f.real }}</span></span>
            <span class="col-1"><span v-if="typeof f.cont !== 'object'">{{ f.cont }}</span></span>
            <span class="col-1"><span v-if="typeof f.coop !== 'object'">{{ f.coop }}</span></span>
        </div>

        <div v-if="f.show">
          <function-tree-item ref="items" id="f.code" v-if="f.children && f.children.length>0"
                              :content="f.children" :level="$props.level+1"/>
        </div>
      </div>
    </div>
</template>

<style scoped>
    .to-right {
        text-align: right;
    }

    .feat-row {
        margin-bottom: 3px !important;
    }

    .card {
        margin-bottom: 10px;
    }

</style>

<script>

    export default {
        name: 'functionTreeItem',
        data() {
            return {
                localContent: [],
                start: '',
                end: '',
                rowStyle: '',
                padding: '',
            }
        },
        methods: {
            clicked: function(item){
                item.show = !item.show
                this.$forceUpdate();
            },
            openAll() {
                this.localContent.forEach(f => {
                    f.show = true
                })
                if(this.$refs.items) {
                    this.$refs.items.forEach(function (items) {
                        items.openAll();
                    })
                }
                this.$forceUpdate()
            },
            closeAll() {
                this.localContent.forEach(f => {
                    f.show = false

                  this.$forceUpdate()
                })
                if(this.$refs.items) {
                    this.$refs.items.forEach(function (items) {
                        items.closeAll();
                    })
                }
                this.$forceUpdate()
            },
            getContent: function(content){
                this.localContent = content;
            }
        },
        props: [
            'content',
            'rStyle',
            'level'
        ],
        mounted() {
            this.localContent = this.$props.content
          this.rowStyle = this.$props.rStyle
          this.padding = (this.$props.level*10+15)+'px'
        }
    };
</script>
<style scoped>
  .row span{
    white-space: normal;
  }

  .row span{
    border: 1px solid lightgrey;
    border-left: 0;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .row span span{
    border: 0
  }
  .row span span:first-child{
    border-left: 0;
  }
  
  .row.main span{
    background-color: rgb(23, 162, 184);
    color: white;
    font-weight: bold;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .row span:first-child{
    border-left: 1px solid lightgrey;
  }
</style>