<template>
    <div class="uk-flex-top uk-modal-container" container=".admin" id="spaces-modal" uk-modal>
        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-modal-body">
                <h3>{{ modal.title }}</h3>

                <div class="uk-margin">
                    <label>Od</label>
                    <datepicker :format="'dd.MM.yyyy'" :input-class="'uk-input'" :language="pl"
                                data-vv-as="Od"
                                name="fromDate" type="date" v-model="rental.fromDate"
                                v-validate="'required'"></datepicker>
                    <span class="validation-error">{{ errors.first('fromDate') }}</span>
                </div>

                <div class="uk-margin">
                    <label>Do</label>
                    <datepicker :format="'dd.MM.yyyy'" :input-class="'uk-input'" :language="pl"
                                data-vv-as="Od"
                                name="toDate" type="date" v-model="rental.toDate"
                                v-validate="''"></datepicker>
                    <span class="validation-error">{{ errors.first('toDate') }}</span>
                </div>


                <div class="uk-margin">
                    <label>Koszt</label>
                    <input class="uk-input" data-vv-as="wartość" name="rentTotal" placeholder="" type="text"
                           v-model="rental.rentTotal"
                           v-validate="'cash'">
                    <span class="validation-error">{{ errors.first('rentTotal') }}</span>

                </div>
              <div class="uk-margin">
                    <label>Koszt/m2</label>
                    <input class="uk-input" data-vv-as="wartość" name="rentPerSqm" placeholder="" type="text"
                           v-model="rental.rentPerSqm"
                           v-validate="'cash'">
                    <span class="validation-error">{{ errors.first('rentPerSqm') }}</span>

                </div>

                <div class="uk-margin">
                    <label>Koszt (Eksploatacja)</label>
                    <input class="uk-input" data-vv-as="wartość" name="exploatationTotal" placeholder="" type="text"
                           v-model="rental.exploatationTotal"
                           v-validate="'cash'">
                    <span class="validation-error">{{ errors.first('exploatationTotal') }}</span>

                </div>


                <div class="uk-margin">
                    <label>Koszt (Eksploatacja)/m2</label>
                    <input class="uk-input" data-vv-as="wartość" name="exploatationPerSqm" placeholder="" type="text"
                           v-model="rental.exploatationPerSqm"
                           v-validate="'cash'">
                    <span class="validation-error">{{ errors.first('exploatationPerSqm') }}</span>

                </div>

                <div class="uk-grid-small uk-margin spaces-form" uk-grid>
                    <div class="uk-width-1-1">
                        <label>Przestrzenie</label>
                        <empty-list v-if="rental.spaces.length===0"/>
                        <b-table v-else ref="table" hover borderedless
                                 :fields="fields"
                                 :columns="fields"
                                 :items="rental.spaces"
                                 no-sort-reset
                                 :responsive="true">
                            <template v-slot:cell(actions)="data">
                                <a
                                        @click.prevent="deleteSpace(data)"
                                        class="uk-icon-button"
                                        href="#"
                                        uk-tooltip="Usuń przestrzeń"
                                >
                                    <vk-icon class="hover" icon="trash"/>
                                </a>
                            </template>
                            <template v-slot:cell(building.name)="data">
                                <span :uk-tooltip="data.item.building.name">{{ limit(data.item.building, 30)}}</span>
                            </template>

                        </b-table>
                    </div>

                    <div class="uk-width-2-5@s">
                        <label class="uk-form-label">Obiekt</label>
                        <div class="uk-form-controls">
                            <v-select :filterable="false" :options="buildings" @search="searchBuildings"
                                      label="@id" :class="'wpip-select'" v-model="building"
                                      data-vv-as="budynek" ref="selectBuilding"
                                      name="building" v-validate="''" @close="selectBuilding('')">

                                <template slot="option" slot-scope="option">
                                    <div class="d-center">
                                        {{ option.name }}
                                    </div>
                                </template>

                                <template slot="selected-option" slot-scope="option">
                                    <div class="selected d-center">
                                        {{ option.name }}
                                    </div>
                                </template>

                                <template v-slot:no-options="{ search, searching }">
                                    <template v-if="searching">
                                        Nie znaleziono <em>{{ search }}</em>.
                                    </template>
                                    <em style="opacity: 0.5;" v-else>Wyszukaj budynek</em>
                                </template>
                            </v-select>
                            <span class="validation-error">{{ errors.first('building') }}</span>
                        </div>
                    </div>
                    <div class="uk-width-2-5@s">
                        <label class="uk-form-label">Przestrzeń</label>
                        <div class="uk-form-controls">
                            <v-select :filterable="false" :options="spaces" @search="searchSpaces"
                                      label="@id" :class="'wpip-select'" v-model="space"
                                      data-vv-as="przestrzeń" ref="selectSpace"
                                      name="space" v-validate="''">

                                <template slot="option" slot-scope="option">
                                    <div class="d-center">
                                      <span :class="{'text-red': option.rented, 'text-green': isAdded(option) }">
                                        {{ option.name }}
                                      </span>
                                    </div>
                                </template>

                                <template slot="selected-option" slot-scope="option">
                                    <div class="selected d-center">
                                        {{ option.name }}
                                    </div>
                                </template>

                                <template v-slot:no-options="{ search, searching }">
                                    <template v-if="searching">
                                        Nie znaleziono <em>{{ search }}</em>.
                                    </template>
                                    <em style="opacity: 0.5;" v-else>Wyszukaj budynek</em>
                                </template>
                            </v-select>
                            <span class="validation-error">{{ errors.first('space') }}</span>
                            <span class="text-danger uk-text-small">Przestrzenie oznaczone kolorem czerwonym posiadają przypisany aktywny wynajem</span>
                        </div>

                    </div>
                    <div class="uk-width-1-5">
                        <span class="uk-button wpip-button" id='add-space' type="button" @click="addSpace">Dodaj przestrzeń</span>
                    </div>
                </div>

                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-default uk-modal-close" type="button">Anuluj</button>
                    <button :disabled="modal.blocked" @click="submit" class="uk-button wpip-button" type="button">
                        {{modal.button}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import mixin from "../mixin";
    import UIkit from "uikit";
    import 'vue-datetime/dist/vue-datetime.css'
    import axiosInstance from "../../axiosInstance";
    import apiConfig from "../../apiConfig";
    import {pl} from "vuejs-datepicker/src/locale";
    import Datepicker from "vuejs-datepicker";
    import emptyList from "../ui/emptyList";
    import charLimiter from "../charLimiter";

    let FoundException = {}

    export default {
        name: 'rentalModal',
        components: {
            Datepicker,
            emptyList
        },
        props: {
            contractor: {
                type: Number,
                default: -1
            }
        },
        data() {
            return {
                pl: pl,
                filterValue: "",
                modalDom: null,
                parameter: null,
                key: 0,
                types: [],
                type: 'datetime',
                building: null,
                buildings: [],
                space: null,
                spaces: [],
                rental: {
                    fromDate: null,
                    toDate: null,
                    exploatationTotal: null,
                    rentTotal: null,
                    spaces: []
                },
                modal:
                    {
                        title: "Dodaj przestrzeń",
                        button: "Dodaj",
                        blocked: false
                    },
                fields: [
                    {key: 'building.name', label: 'Obiekt', sortable: true},
                    {key: 'name', label: 'Nazwa'},
                    {key: 'area', label: 'Powierzchnia (m2)'},
                    {key: 'actions', label: '', class: 'text-right'},
                ]
            }
        },
        mixins: [mixin, charLimiter],
        methods: {
            showModal: function (data) {
                let vm = this;
                this.space = null
                this.building = null
                if (data == null) {
                    vm.modal =
                        {
                            title: "Dodaj wynajem",
                            button: "Dodaj",
                            blocked: false
                        }
                    vm.rental = {
                        fromDate: null,
                        toDate: null,
                        exploatationTotal: null,
                        rentTotal: null,
                        spaces: []
                    }
                } else {
                  axiosInstance.get(apiConfig.rentalsApiUrl + "/" + data.id, { headers: this.$data.headers }).then(function(result){
                    vm.rental = {...result.data};
                  })
                    vm.modal =
                        {
                            title: "Zmień wynajem",
                            button: "Zmień",
                            blocked: false
                        }
                }

                this.$validator.reset()
                if (this.$refs.table) {
                    this.$refs.table.refresh()
                }
                this.modalDom.show()
            },
            addSpace: function () {
                this.space.building = this.building
                if(!this.isAdded(this.space)) {
                  this.rental.spaces.push(this.space)
                }
                this.space = null
                this.spaces = []
                this.building = null
            },
            searchBuildings: function (e) {
                let vm = this;
                clearTimeout(this.searchTimeout)
                this.searchTimeout = setTimeout(function () {
                    vm.getBuildings(e)
                }, 500);
            },
            deleteSpace: function (data) {
                this.rental.spaces.splice(data.index, 1)
                this.getSpaces()
            },
            getBuildings: function (e) {
                let vm = this;
                var params = {};
                params['name'] = e;
                params['isRental'] = true;
                axiosInstance
                    .get(apiConfig.buildingsApiUrl, {
                        params,
                        headers: this.$data.headers
                    })
                    .then(function (response) {
                        vm.buildings = response.data['member'];
                    });
            },
            selectBuilding: function(){
              this.space = null
              this.searchSpaces('')
            },
            searchSpaces: function (e='') {
                let vm = this;
                clearTimeout(this.searchTimeout)
                this.searchTimeout = setTimeout(function () {
                    vm.getSpaces(e)
                }, 500);
            },
            getSpaces: function (e) {
                let vm = this;
                var params = {};
                params['name'] = e;
                params['building.id'] = this.building.id
                axiosInstance
                    .get(apiConfig.spacesApiUrl, {
                        params,
                        headers: this.$data.headers
                    })
                    .then(function (response) {
                        vm.spaces = response.data['member'];
                    });
            },
            isAdded(space){
              let vm = this
              let isAdded = false
              try {
                vm.rental.spaces.forEach((rentalSpace) => {
                  if (rentalSpace.id === space.id) {
                    isAdded = true
                    throw FoundException;
                  }
                })
              }
              catch (e) {
                  if (e !== FoundException) throw e;
                }
              return isAdded
            },
            submit: function () {
                let postData = {
                    fromDate: this.rental.fromDate,
                    toDate: this.rental.toDate,
                    rentTotal: this.rental.rentTotal,
                    rentPerSqm: this.rental.rentPerSqm,
                    exploatationPerSqm: this.rental.exploatationPerSqm,
                    exploatationTotal: this.rental.exploatationTotal,
                    contractor: '/api/contractors/' + this.$props.contractor,
                    spaces: []
                };
                this.rental.spaces.forEach(function (space) {
                    postData.spaces.push(space['@id'])
                });
                if(postData.toDate==null){
                    delete postData.toDate
                }
                let vm = this
                vm.$validator.validate().then((result) => {
                    if (result) {
                        this.modal.blocked = true
                        if (!this.rental.id) {
                            axiosInstance
                                .post(apiConfig.rentalsApiUrl, JSON.stringify(postData), {
                                    headers: this.$data.headers
                                })
                                .then(async function (response) {
                                    if (response.status === 201) {
                                        vm.rental.id = response.data.id
                                    }
                                    vm.modal.blocked = false
                                    vm.modalDom.hide()
                                    vm.$emit('refresh', vm.buildingId)
                                }).catch();
                        } else {
                            axiosInstance
                                .put(apiConfig.rentalsApiUrl + '/' + vm.rental.id, JSON.stringify(postData), {
                                    headers: this.$data.headers
                                })
                                .then(async function (response) {
                                    if (response.status === 200) {
                                        vm.$emit('refresh')
                                        vm.modalDom.hide()
                                    }
                                    vm.modal.blocked = false
                                }).catch()
                        }
                    }
                });
            }
        },
        mounted() {
            if (this.modalDom == null) {
                let id = '#spaces-modal'
                this.modalDom = UIkit.modal(id)
                this.searchBuildings()
            }
        }

    }
</script>
<style scoped>
    .spaces-form {
        position: relative;
    }

    #add-space {
        position: absolute;
        bottom: 1px;

    }
</style>
<style>
.text-red{
  color: red;
}
.text-green{
  color: darkgreen;
}
</style>
