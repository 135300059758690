<template>
    <admin>
        <div class="wpip-swot">
            <swots-list ref="table" v-on:showModal="showParameterModal"></swots-list>
            <div class="uk-container-expand uk-padding">
                <router-link :to="{ name: 'tools', params: { buildingId: $route.params.buildingId } }">
                    <button class="uk-button uk-button-default"><vk-icon icon="thumbnails"></vk-icon> Lista metod</button>
                </router-link>
            </div>
            <swot-parameters-modal ref="parametersModal" v-on:modified="refresh"></swot-parameters-modal>
        </div>
    </admin>
</template>

<script>

    import mixin from "../../components/mixin";
    import SwotsList from '../../components/swot/swotsList';
    import apiConfig from "../../apiConfig";
    import SwotParametersModal from "../../components/swot/swotParametersModal";
    import UIkit from 'uikit'
    import axiosInstance from "../../axiosInstance";

    export default {
        name: 'swots',
        components: {
            'swotsList': SwotsList,
            'swotParametersModal': SwotParametersModal
        },
        mixins: [mixin],
        data() {
            return {
                sortBy: 'name',
                sortDesc: false,
                data: [],
                currentPage: 1,
                totalItems: '',
                showDetails: false,
                buildingId: null
            }
        },
        methods: {
            showParameterModal: async function (swot = null) {
                let parametersModal = this.$refs.parametersModal;
                if (swot !== null) {
                    parametersModal.swot = {
                        '@id': swot['@id'],
                        id: swot.id,
                        description: swot.description,
                        type: swot.type,
                        soa: swot.soa,
                        wtc: swot.wtc,
                        formattedSoa: swot.formattedSoa,
                        formattedWtc: swot.formattedWtc,
                    };
                    parametersModal.modal.button = "Zmień";
                } else {
                    parametersModal.swot = {
                        description: "",
                        type: null,
                        soa: 0.3,
                        wtc: 0.5,
                        formattedSoa: "30%",
                        formattedWtc: "50%"
                    };
                    parametersModal.modal.button = "Dodaj";
                }
                UIkit.modal("#wpip-swot-parameters").show();

            },
            async get() {
                let vm = this;
                let desc = 'desc';
                if (vm.sortDesc !== true) {
                    desc = 'asc';
                }


                var key = "order[" + this.sortBy + "]";
                var params = {};
                params[key] = desc;
                params['page'] = vm.currentPage;
                let res = await axiosInstance
                    .get(apiConfig.swotApiUrl,
                        {
                            params,
                            headers: this.$data.headers
                        });
                vm.data = res.data['member'];
                vm.totalItems = res.data['totalItems'];

                return vm.data;
            },
            refresh: async function () {
                this.$refs.table.refresh();
            }
        },
        async mounted() {
            await this.$ability.getAbility();
            if (!this.$ability.checkManageIasAbility('SWOT')) {
                await this.$router.push({name: 'not-allowed'});
            }
        }
    }
</script>
