<template>
    <admin>
        <div class="wpip-rates">
            <my-goals-list></my-goals-list>
            <div class="uk-container-expand uk-padding">
                <router-link :to="{ name: 'zpcs', params: { buildingId: $route.params.buildingId } }">
                    <button
                            class="uk-button wpip-button uk-width-auto uk-margin-small-right"
                    >Wstecz
                    </button>
                </router-link>
                <router-link :to="{ name: 'tools', params: { buildingId: $route.params.buildingId } }">
                    <button class="uk-button uk-button-default"><vk-icon icon="thumbnails"></vk-icon> Lista metod</button>
                </router-link>
            </div>
        </div>
    </admin>
</template>

<script>
    import MyGoalsList from "../../components/zpc/myGoalsList";

    export default {
        name: "zpcMyGoals",
        components: {MyGoalsList},
        async mounted() {
        }
    };
</script>
