<template>
  <div class="uk-flex-top uk-modal-container" container=".admin" id="resources" uk-modal>
    <div class="uk-modal-dialog uk-margin-auto-vertical">
      <button class="uk-modal-close-default" type="button" uk-close></button>
      <div class="uk-modal-body">
        <h3>{{ modal.title }}</h3>
        <div class="uk-margin">
          <input class="uk-input" data-vv-as="opis" name="description" placeholder="Opis..." type="text"
                 v-model="resource.description"
                 v-validate="'required|max:255'">
          <span class="validation-error">{{ errors.first('description') }}</span>
        </div>
        <div class="uk-margin">
          <div class="mb-2">
            Aktualny plik: {{ resource.originalFilename }}
          </div>
          <b-form-file v-model="resource.file" placeholder="Nie wybrano pliku..."/>
          <b-alert variant="danger" :show="showAlert">Nie można przetworzyć wybranego pliku</b-alert>
        </div>

        <div class="uk-modal-footer uk-text-right">
          <button class="uk-button uk-button-default uk-modal-close" type="button">Anuluj</button>
          <button :disabled="modal.blocked" @click="submit" class="uk-button wpip-button" type="button">
            {{ modal.button }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "../mixin";
import UIkit from "uikit";
import 'vue-datetime/dist/vue-datetime.css'
import {pl} from "vuejs-datepicker/src/locale";
import axiosInstance from "../../axiosInstance";
import apiConfig from "../../apiConfig";

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result.split('base64,')[1]);
  reader.onerror = error => reject(error);
});

export default {
  name: 'iasFileModal',
  components: {},
  data() {
    return {
      pl: pl,
      modalDom: null,
      phases: [],
      resource: {
        file: null,
        url: null,
        description: null,
      },
      showAlert:false,
      modal:
          {
            title: "Dodaj zasób",
            button: "Dodaj",
            blocked: false
          },

    }
  },
  mixins: [mixin],
  methods: {
    showModal: async function (category, resource = {}) {
      let vm = this;
      this.resource = resource;
      this.resource.systemCategory = category
      if (resource.id == null) {
        vm.modal =
            {
              title: "Dodaj zasób",
              button: "Dodaj",
              blocked: false
            }
      } else {
        this.modal =
            {
              title: "Zmień zasób",
              button: "Zmień",
              blocked: false
            }
      }

      await this.$validator.reset();
      this.modalDom.show();
    },
    submit: async function () {
      let vm = this;

      vm.showAlert = false
      let postData = {
        systemCategory: vm.resource.systemCategory,
        url: vm.resource.url,
        description: vm.resource.description
      };
      if(vm.resource.file){
        postData.fileContent = await toBase64(this.resource.file)
        postData.filename = vm.resource.file.name
      }
      vm.$validator.validate().then(async (result) => {
        if (result) {
          if (!vm.resource.id) {
            axiosInstance
                .post(apiConfig.iasResourcesApiUrl, JSON.stringify(postData), {
                  headers: this.$data.headers
                })
                .then(async function (response) {
                  if (response.status === 201) {
                    vm.$emit('refresh');
                    vm.modalDom.hide();
                  }
                  vm.modal.blocked = false;
                }).catch(function (){
              vm.showAlert = true
            });
          } else {
            axiosInstance
                .put(apiConfig.iasResourcesApiUrl + "/" + vm.resource.id, JSON.stringify(postData), {
                  headers: this.$data.headers
                })
                .then(async function (response) {
                  if (response.status === 200) {
                    vm.$emit('refresh');
                    vm.modalDom.hide();
                  }
                  vm.modal.blocked = false;
                }).catch(function (){
              vm.showAlert = true
            });
          }
        }
      });
    }
  },
  mounted() {
    if (this.modalDom == null) {
      this.modalDom = UIkit.modal('#resources');
    }
  }

}
</script>
