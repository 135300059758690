<template>
    <span>
        <a
                @click.prevent="editEventType(datatable.item)"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Edytuj rodzaj zdarzenia"
        >
            <vk-icon class="hover" icon="pencil"/>
        </a>
        <a
                @click.prevent="deleteEventType(datatable.item.id)"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Usuń rodzaj zdarzenia"
        >
            <vk-icon class="hover" icon="trash"/>
        </a>
        <remove ref="remove" v-on:refresh="$emit('refresh')"/>
    </span>
</template>

<script>

    import mixin from "../../components/mixin";
    import apiConfig from "../../apiConfig";
    import remove from "../ui/remove";

    export default {
        name: 'eventTypesActions',
        components: {
            remove
        },
        mixins: [mixin],
        props: {
            datatable: Object
        },
        methods: {
            editEventType(phase) {
                this.$emit('edit', phase)
            },
            deleteEventType(phaseId) {
                this.$refs.remove.remove(apiConfig.eventTypesApiUrl + "/" + phaseId, "fazę IAS");
            }
        }
    }
</script>
