<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<style lang="scss">
    @import "../src/assets/scss/lbd/_partial-nucleo-icons.scss";
    @import "../src/assets/scss/light-bootstrap-dashboard.scss";
</style>
<style lang="css">
    @import '../src/assets/css/app.css';
    @import "../src/assets/scss/app.scss";
    @import "../src/assets/scss/button.scss";
    @import "../src/assets/scss/input.scss";

    .card .table tbody td:last-child, .card .table thead th:last-child{
        display: table-cell
    }

    .table th, .table td{
        vertical-align: middle!important;
    }
</style>




<script>
    import axiosInstance from "./axiosInstance";
    import apiConfig from "./apiConfig";

    export default {
        name: 'App',
        requestCounter: 0,
        loader: null,
        data: function () {
            return {
                timer: null,
            }
        },
        methods: {
            hideLoader: function () {
                this.requestCounter--;
                if (this.requestCounter <= 0) {
                    this.loader.hide();
                    this.requestCounter = 0;
                }
            }
        },
        created() {
            let vm = this;
            this.requestCounter = 0;
            axiosInstance.interceptors.request.use(config => {
               if(config.url!=="/api/registers" || config.params && !config.params.loader) {
                   if (vm.requestCounter == 0) {
                       vm.loader = vm.$loading.show({
                           // Optional parameters
                           container: null,
                           canCancel: false,
                           fullPage: true,
                           zIndex: 100000
                       });
                   }
                   vm.requestCounter++;
               }
                return config;
            }, error => {
                Promise.reject(error)
            });
            axiosInstance.interceptors.response.use(
                response => {

                    this.timer = setTimeout(function () {
                        vm.hideLoader()
                    }, 250);
                    return response;
                },
                async error => {
                    // eslint-disable-next-line no-console
                    console.log(error);
                    this.timer = setTimeout(function () {
                        vm.hideLoader()
                    }, 250);
                    if (error.response.status !== 401) {
                        return new Promise((resolve, reject) => {
                            reject(error);
                        });
                    }
                    if(this.$route.path!=="/login") {
                        if ((error.config.url === apiConfig.tokenRefreshApiUrl) || (error.response.message) === 'Account is disabled.') {
                            await vm.$router.push({name: 'logout'});
                        }

                        let postData = {
                            refresh_token: localStorage.getItem('refreshToken')
                        };
                        let res;
                        await axiosInstance.post(apiConfig.tokenRefreshApiUrl, postData).then(function (result) {
                            localStorage.setItem('token', result.data.token);
                            localStorage.setItem('refreshToken', result.data.refresh_token);
                            const config = error.config;
                            config.headers['Authorization'] = `Bearer ${result.data.token}`;
                            vm.timer = setTimeout(function () {
                                vm.hideLoader()
                            }, 250);
                            res = new Promise((resolve, reject) => {
                                axiosInstance.request(config).then(response => {

                                    vm.timer = setTimeout(function () {
                                        vm.hideLoader()
                                    }, 250);
                                    resolve(response);
                                }).catch((error) => {

                                    vm.timer = setTimeout(function () {
                                        vm.hideLoader()
                                    }, 250)
                                    reject(error);
                                })
                            });
                        });
                        return res;

                    }

                }
            )
        }
    }
</script>
