<template>
    <div>
        <vk-grid>
            <h3 class="uk-width-expand">Analiza QFD - ranga parametrów obiektu IAS</h3>
            <button
                    @click="back()"
                    class="uk-button wpip-button uk-width-auto"
            >lista analiz QFD
            </button>
        </vk-grid>
        <div class="area">
        <table class="uk-table  uk-table-divider overflow-y-scroll text-center scroll">
            <tr>
                <td>Wymaganie klienta</td>
                <td class="weight-col">Istotność cechy (waga)</td>
                <td v-for="(parameter, index) in selectParameters()" v-bind:key="'p_'+index"><span :uk-tooltip="parameter.name">{{limit(parameter, 25)}}</span></td>
            </tr>
            <tr v-bind:key="property.id" v-for="(property) in $props.properties">
                <td><span :uk-tooltip="property.name">{{limit(property, 25)}}</span></td>
                <td class="weight-col">{{ property.weight }}</td>
                <td  v-bind:key="parameter.id" v-for="(parameter) in selectParameters()">{{getParameterValue(property,parameter)}}</td>
            </tr>
            <tr>
                <td colspan="2" class="one-column">Suma iloczynów</td>
                <td v-bind:key="parameter.id" v-for="(parameter) in selectParameters()">{{sums[parameter.id]}}</td>
            </tr>
            <tr>
                <td colspan="2" class="one-column">% całości</td>
                <td  v-bind:key="parameter.id" v-for="(parameter) in selectParameters()"><span v-if="sums[0]>0 && sums[parameter.id]>0">{{(sums[parameter.id]*100/sums[0]).toFixed(2)}}%</span></td>
            </tr>
        </table>
        </div>
        <div class="uk-grid uk-child-width-1-2@m uk-margin-top" uk-grid>
            <div class="uk-text-left">

                <button class="uk-button wpip-button" v-on:click="previousStep">Zależność parametrów</button>
            </div>
            <div class="uk-text-right">
                <button class="uk-button wpip-button" v-on:click="nextStep">Ocena konkurencyjności</button>
            </div>
        </div>
    </div>
</template>

<script>

    import mixin from "../../components/mixin";
    import charLimiter from "../charLimiter";

    export default {
        name: 'qfdParametersRating',
        mixins: [mixin, charLimiter],
        data() {
            return {
                correlationsCount: [],
                sums: []
            }
        },
        props: {
            parameters: Array,
            properties: Array
        },
        methods: {
            async get() {

            },
            selectParameters: function(){
                let vm = this;
                let parameters = [];
                this.$props.parameters.forEach(function (parameter) {
                    if(vm.correlationsCount[parameter.id]>0){
                        parameters.push(parameter)
                    }
                });
                return parameters;
            },
            findCorrelation: function(parameter1, parameter2){
                let result = 0;
                parameter1.qfdParameterCorrelations1.forEach(function(correlation){
                    if(correlation.qfdParameter2.id===parameter2.id){
                        result = correlation.correlation
                    }
                });
                parameter1.qfdParameter2Correlations.forEach(function(correlation){
                    if(correlation.qfdParameter1.id===parameter2.id){
                        result = correlation.correlation
                    }
                });
                if(result>0){
                    result = "+" + result;
                }
                return result
            },
            calculateSums: function(){
                let vm = this;
                this.sums[0]=0;
                this.$props.parameters.forEach(function (parameter) {
                    vm.sums[parameter.id] = 0;
                    vm.correlationsCount[parameter.id] = 0;
                });
                this.$props.properties.forEach(function (property) {
                    property.parameters.forEach(function (parameter) {
                        if(parameter.weight>0) {
                            vm.correlationsCount[parameter.id]++;
                        }
                        vm.sums[parameter.id]+=property.weight*parameter.weight;
                        vm.sums[0]+=property.weight*parameter.weight;
                    })
                });
            },
            getParameterValue(property,parameter){
                let weight = null;
                property.parameters.forEach(function (propertyParameter) {
                    if(propertyParameter.id === parameter.id) {
                        weight = propertyParameter.weight;
                    }
                });
                return weight;
            },
            previousStep: function(){
                this.$emit('previousStep');
            },
            nextStep: function(){
                this.$emit('nextStep');
            },
            back: function(){
                this.$router.push({name: 'qfdAnalyses'})
            }

        },
        created() {
            this.calculateSums();
            this.get();
        }
    }
</script>

<style scoped>
    .weight-col{
        background-color: #eee;
        position: sticky;
      left:6.7em;
      border-collapse: collapse;
      background-clip: padding-box;

        border-top: 1px solid rgb(229, 229, 229);
        margin-top: -1px;
    }

    .area{
        overflow-x: scroll;
    }

    .area td:first-child.one-column{
        width: 25em;
    }

    .area td:first-child{
      border-collapse: collapse;
      background-clip: padding-box;
      background-color: white;
        position:sticky;

      left:0;
        margin-top: -1px;
        top: auto;
        border-top:1px solid rgb(229, 229, 229);
    }

    .area tr:first-child td:first-child{
        border-top:none;
    }

</style>
