<template>
    <div class="uk-flex-top uk-modal-container" container=".admin" id="wpip-activity-add" uk-modal>
        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-modal-body">
                <h3>{{ modal.title }}</h3>

                <div class="uk-margin">
                    <label class="uk-form-label">Działanie strategiczne</label>
                    <div class="uk-form-controls">
                <textarea
                        class="uk-textarea"
                        rows="5"
                        v-model="activity.activity"/>
                    </div>
                </div>

            </div>


            <div class="uk-modal-footer uk-text-right">
                <button @click="cancel" class="uk-button uk-button-default uk-modal-close" type="button">Anuluj</button>
                <button @click="submitActivity" class="uk-button wpip-button" type="button">{{modal.button}}
                </button>
            </div>
        </div>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import UIkit from "uikit";
    import axiosInstance from "../../axiosInstance";

    export default {
        name: 'goalModal',
        data() {
            return {
                filterValue: "",
                responsible: [],
                activity: {
                    id: null,
                    activity: ""
                },
                modal:
                    {
                        title: "Dodaj nowe działanie",
                        button: "Dodaj"
                    },

            }
        },
        components: {
        },
        mixins: [mixin],
        methods: {
            showModal: function (activity) {
                if (activity == null) {
                    this.activity.id = null;
                    this.activity.activity = "";

                    this.modal.button = "Dodaj";
                    this.modal.title = "Dodaj nowe działanie";
                } else {

                    this.activity.id = activity.id;
                    this.activity.activity = activity.activity;

                    this.modal.button = "Zmień";
                    this.modal.title = "Zmień działanie";
                }
                this.$validator.reset();

                UIkit.modal("#wpip-activity-add").show();
            },
            submitActivity: async function () {
                let vm = this;
                vm.$validator.validate().then((result) => {
                    if (result) {
                        let postData = {
                            activity: this.activity.activity,
                            goal: '/api/goals/' + this.$route.params.detailedId
                        };
                        if (this.activity.id == null) {
                            axiosInstance
                                .post(apiConfig.zpcActivitiesApiUrl, JSON.stringify(postData), {
                                    headers: this.$data.headers
                                })
                                .then(async function (response) {
                                    if (response.status === 201) {
                                        UIkit.modal("#wpip-activity-add").hide();
                                        vm.$emit('refresh');
                                    }
                                }).catch();
                        } else {
                            axiosInstance
                                .put(apiConfig.zpcActivitiesApiUrl + "/" + vm.activity.id, JSON.stringify(postData), {
                                    headers: this.$data.headers
                                })
                                .then(async function (response) {
                                    if (response.status === 200) {
                                        UIkit.modal("#wpip-activity-add").hide();
                                        vm.$emit('refresh');
                                    }
                                }).catch();
                        }
                    }
                }).catch(() => {
                    return false;
                });
            },
            cancel: function(){
                this.$emit('refresh');
            }
        }

    }
</script>
<style scoped>
    .correlation-header{
        font-size: 1.3em;
    }
</style>
