<template>
<admin>
  <div>
    <b-row>
      <b-col>
        <h3>Ustal zależności pomiędzy elementami analizy {{ swotType[this.data.type] }}</h3>
      </b-col>
      <b-col class="uk-text-right" cols="6" xl="3">
        <b-card header="Siła związku jest określana za pomocą strzałek" border-variant="success" align="center" v-if="data.type === SwotAnalysisTypes.TYPE_SWOT">
          <b-card-text>
            <vk-icon icon="arrow-up"></vk-icon>
            <vk-icon icon="arrow-up"></vk-icon> Silny wpływ
            <vk-icon icon="arrow-up" class="ml-3"></vk-icon> Słaby wpływ
            <span class="ml-3"></span>- Brak wpływu
          </b-card-text>
        </b-card>
        <b-card header="Związek jest określany za pomocą" border-variant="success" align="center" v-if="data.type === SwotAnalysisTypes.TYPE_TOWS">
          <b-card-text>
            <vk-icon icon="check"></vk-icon> jest związek
            <span class="ml-3">- nie ma związku</span>
          </b-card-text>
        </b-card>        
      </b-col>
    </b-row>
    <div class="uk-margin-bottom uk-width-1-1" id="wpip-swot-korelacja" vk-grid>
      <div class="uk-width-1-1">
        <table class="uk-table">
          <tr>
            <td colspan="2"></td>

            <td :colspan="swot.opportunities.data.length" class="uk-text-center uk-text-large" v-if="swot.opportunities.data.length > 0">Szanse (O)
            </td>
            <td :colspan="swot.threads.data.length" class="uk-text-center uk-text-large" v-if="swot.threads.data.length > 0">Zagrożenia (T)
            </td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td :class="{'wpip-swot-last-vertical': index === swot.opportunities.data.length - 1}" :key="'o_'+o.id" :uk-tooltip="o.name" class="uk-text-center" v-for="(o, index) in swot.opportunities.data">{{ (index + 1) + '.' }}
            </td>
            <td :key="'t_'+t.id" :uk-tooltip="t.name" class="uk-text-center" v-for="(t, index) in swot.threads.data">{{ (index + 1) + '.' }}
            </td>
          </tr>
          <tr :key="'s'+s.id" v-for="(s, sindex) in swot.strengths.data">
            <td :rowspan="swot.strengths.data.length" class="uk-text-large uk-text-middle uk-text-right  uk-width-1-6 " v-if="swot.strengths.data.length > 0 && sindex===0">Silne strony (S)
            </td>
            <td :class="{'wpip-swot-last-horizontal': sindex === swot.strengths.data.length - 1}" :uk-tooltip="s.name" class="uk-text-center">{{ (sindex + 1) + '.' }}
            </td>
            <swot-correlation-cell v-bind:analysis-type="data.type" v-bind:correlation-type="'so'" v-bind:e1="s" v-bind:e2=so v-bind:modal="$refs.modal" v-bind:key="'so_'+so.id" v-bind:last-horizontal="sindex === swot.strengths.data.length - 1"
              v-bind:last-vertical="index === swot.opportunities.data.length - 1" v-for="(so, index) in swot.opportunities.data" />
            <swot-correlation-cell v-bind:analysis-type="data.type" v-bind:correlation-type="'st'" v-bind:e1="s" v-bind:e2=st v-bind:modal="$refs.modal" v-bind:key="'st_'+st.id" v-bind:last-horizontal="sindex === swot.strengths.data.length - 1"
              v-for="(st) in swot.threads.data" />
          </tr>
          <tr></tr>
          <tr :key="'w_'+w.id" v-for="(w, windex) in swot.weaknesses.data">
            <td :rowspan="swot.weaknesses.data.length" class="uk-text-large uk-text-middle uk-text-right" v-if="swot.weaknesses.data.length > 0 && windex===0">Słabe strony (W)
            </td>
            <td :uk-tooltip="w.name" class="uk-text-center">{{ (windex + 1) + '.' }}</td>
            <swot-correlation-cell v-bind:analysis-type="data.type" v-bind:correlation-type="'wo'" v-bind:e1="w" v-bind:e2=wo v-bind:modal="$refs.modal" v-bind:key="'wo_'+wo.id" v-bind:last-vertical="index === swot.opportunities.data.length - 1"
              v-for="(wo, index) in swot.opportunities.data" />
            <swot-correlation-cell v-bind:analysis-type="data.type" v-bind:correlation-type="'wt'" v-bind:e1="w" v-bind:e2=wt v-bind:modal="$refs.modal" v-bind:key="'wt_'+wt.id" v-for="(wt) in swot.threads.data" />
          </tr>
        </table>
      </div>
    </div>
    <div class="uk-grid uk-child-width-1-2@m" uk-grid>
      <div class="uk-text-left">
        <router-link v-bind:to="prevStep">
          <button class="uk-button wpip-button">Ustal elementy</button>
        </router-link>
      </div>
      <div class="uk-text-right">
        <router-link v-bind:to="nextStep">
          <button class="uk-button wpip-button">{{ nextStepButtonText }}</button>
        </router-link>
      </div>
    </div>
    <swots-correlation-modal ref="modal" v-on:send-data="sendData"></swots-correlation-modal>


  </div>
</admin>
</template>

<script>
import mixin from "../../components/mixin";
import apiConfig from "../../apiConfig";
import SwotsCorrelationModal from "../../components/swot/swotsCorrelationModal";
import SwotAnalysisTypes from "../../constants/swotAnalysisTypes";
import SwotCorrelationCell from "../../components/swot/swotCorrelationCell"
import UIkit from 'uikit'
import axiosInstance from "../../axiosInstance";

export default {
  name: 'swotCorrelations',
  components: {
    swotsCorrelationModal: SwotsCorrelationModal,
    swotCorrelationCell: SwotCorrelationCell
  },
  mixins: [mixin],
  data() {
    return {
      SwotAnalysisTypes: SwotAnalysisTypes,
      sortBy: 'name',
      sortDesc: false,
      data: [],
      prevStep: "/buildings/" + this.$route.params.buildingId + "/swots/" + this.$route.params.swotId + "/elements",
      nextStep: "/buildings/" + this.$route.params.buildingId + "/swots/" + this.$route.params.swotId + "/result",
      nextStepButtonText: "Analizuj",
      currentPage: 1,
      totalItems: '',
      showDetails: false,
      swot: {
        strengths: {
          title: "Silne strony",
          inputName: "strength",
          typeFilter: 's',
          color: "uk-text-success",
          data: []
        },
        weaknesses: {
          title: "Słabe strony",
          inputName: "weakness",
          typeFilter: 'w',
          color: "",
          data: []
        },
        opportunities: {
          title: "Szanse",
          inputName: "oportunity",
          typeFilter: 'o',
          color: "uk-text-primary",
          data: []
        },
        threads: {
          title: "Zagrożenia",
          inputName: "thread",
          typeFilter: 't',
          color: "uk-text-danger",
          data: []
        }
      }

    }
  },
  methods: {
    async get() {

      let vm = this;
      axiosInstance
        .get(apiConfig.swotApiUrl + '/' + this.$route.params.swotId, {
          headers: vm.$data.headers
        }).then(function(res) {
          vm.data = res.data;
          if (vm.data.type === SwotAnalysisTypes.TYPE_TOWS) {
            vm.nextStep = "/buildings/" + vm.$route.params.buildingId + "/swots/" + vm.$route.params.swotId + "/correlation/tows";
            vm.nextStepButtonText = "Ustal korelację TOWS";
          }
        });

      Object.keys(vm.swot).forEach(function(key) {

        var params = {};
        params['page'] = vm.currentPage;
        params['swotAnalysis'] = vm.$route.params.swotId;
        params['swotElementType'] = vm.swot[key].typeFilter;

        axiosInstance
          .get(apiConfig.swotElementsApiUrl, {
            params,
            headers: vm.$data.headers
          }).then(function(res) {
            vm.swot[key].data = res.data['member'];
          });
      });
    },
    sendData() {
      let modal = this.$refs.modal;
      let types = {
        's': 'strengths',
        'w': 'weaknesses'
      };

      let type = types[modal.el1.swotElementType];
      let swotElements = this.swot[type].data;
      let vm = this;
      UIkit.modal("#wpip-swot-correlation").hide();
      swotElements.forEach(function(el) {
        if (el.id === modal.el1.id) {
          vm.setCorrelationValue(el, modal.el2, modal.value);
        }
      });


      axiosInstance
        .get(apiConfig.swotCorrelationsApiUrl + "/primaryElement=" + modal.el2.id + ";secondaryElement=" + modal.el1.id, {
          headers: vm.$data.headers
        })
        .then(function() {
          let postData = {
            value: parseInt(modal.value)
          };
          axiosInstance
            .put(apiConfig.swotCorrelationsApiUrl + "/primaryElement=" + modal.el2.id + ";secondaryElement=" + modal.el1.id, postData, {
              headers: vm.$data.headers
            }).then(function() {})
        })
        .catch(function(res) {
          if (res.response.status === 404) {

            let postData = {
              primaryElement: modal.el2["@id"],
              secondaryElement: modal.el1["@id"],
              value: parseInt(modal.value)
            };
            axiosInstance
              .post(apiConfig.swotCorrelationsApiUrl, postData, {
                headers: vm.$data.headers
              }).then(function() {})
          }
        });
    },
    setCorrelationValue(el1, el2, value) {
      let correlations = el1.secondaryElementCorrelations;
      let loopExit = false;
      for (let el of correlations) {
        if (!loopExit) {
          if (el2.id === el.primaryElement.id) {
            UIkit.modal("#wpip-swot-correlation").hide();
            el.value = parseInt(value);
            loopExit = true;
          }
        }
      }
      if (!loopExit) {
        el1.secondaryElementCorrelations.push({
          primaryElement: el2,
          secondaryElement: el1,
          value: parseInt(value)
        });

        UIkit.modal("#wpip-swot-correlation").hide();
      }
    }
  },
  async mounted() {    
    await this.$ability.getAbility();
    if (!this.$ability.checkManageIasAbility('SWOT')) {
      await this.$router.push({
        name: 'not-allowed'
      });
    }
    this.get();

  }

}
</script>

<style scoped>
table.wpip-swot td {
  border: 1px solid #eee;
}

.wpip-high {
  background-color: #eee;
}

td.wpip-swot-last-vertical {
  border-right: 2px solid #bf0e01;
}

td.wpip-swot-last-horizontal {
  border-bottom: 2px solid #bf0e01;
}
</style>
