<template>
    <div class="text-right">
        <a
                @click.prevent="editUser(datatable.item)"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Edytuj użytkownika"
        >
            <vk-icon class="hover" icon="pencil"/>
        </a>
        <a
                @click.prevent="changePassword(datatable.item)"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Zmień hasło"
        >
            <vk-icon class="hover" icon="lock"/>
        </a>
        <a
                @click.prevent="removeUser()"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Usuń użytkownika"
        >
            <vk-icon class="hover" icon="trash"/>
        </a>
        <remove ref="remove" v-on:refresh="$emit('refresh')"/>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import Remove from "../ui/remove";
    import apiConfig from "../../apiConfig";
    import UIkit from "uikit";

    export default {
        name: 'usersActions',
        components: {Remove},
        mixins: [mixin],
        props: {
            datatable: Object
        },
        methods: {
            editUser: function (user) {
                this.$emit('edit', user)
            },
            changePassword: function (user) {
                this.$emit('password', user)
            },
            removeUser: function () {
                if(this.datatable.item.id !== parseInt(localStorage.getItem('user'))){
                    this.$refs.remove.remove(apiConfig.usersApiUrl + "/" + this.datatable.item.id, "użytkownika");
                }
                else {
                    UIkit.modal.alert("Nie można usunąć własnego konta");
                }
            }
        }
    }
</script>
