<template>
  <admin>
    <div class="uk-container-expand uk-padding">
      <vk-grid>
        <sub-menu></sub-menu>
        <div class="uk-width-expand@m">
          <vk-grid class="uk-width-auto uk-margin-bottom">
            <div class="uk-width-1-6@m">

            </div>
            <div class="uk-width-1-3@m">
              <div class="card">
                <div class="card-title text-center">
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-5">
                      <div class="icon-big text-center">
                        <div class="icon-danger"><i
                            class="nc-icon nc-bell-55 text-danger uk-text-bold"></i></div>
                      </div>
                    </div>
                    <div class="col-7">
                      <div class="numbers">
                        <div><p class="card-category">Zaległości</p> <h4 class="card-title">{{ overdue.toFixed(2) }}
                          PLN</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <hr>
                  <div class="stats">
                    <div><i class="nc-icon nc-refresh-02"/> 2020-05-05</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="uk-width-1-3@m">
              <div class="card">
                <div class="card-title text-center">
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-5">
                      <div class="icon-big text-center">
                        <div class="icon-success"><i
                            class="nc-icon  nc-money-coins text-success uk-text-bold"></i></div>
                      </div>
                    </div>
                    <div class="col-7">
                      <div class="numbers">
                        <div><p class="card-category">Wymagalne</p> <h4 class="card-title">{{ toPay.toFixed(2) }}
                          PLN</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <hr>
                  <div class="stats">
                    <div><i class="nc-icon nc-refresh-02"/> 2020-05-05</div>
                  </div>
                </div>
              </div>
            </div>
          </vk-grid>
          <vk-card class="uk-margin-bottom">
            <rental-filters v-if="showFilters" @filter="filter" :filters="filters"></rental-filters>
          </vk-card>
          <vk-card>
          <rental-list ref="list" @toggleFilters="toggleFilters" :filterNum="filterNum" :filters="filters"
                       @refresh="refresh()"></rental-list>
          </vk-card>
        </div>
      </vk-grid>

    </div>
  </admin>
</template>

<script>
import mixin from "../../components/mixin";
import RentalList from "../../components/rentalDashboard/rentalList";
import RentalFilters from "../../components/rentalDashboard/rentalFilters";
import axiosInstance from "../../axiosInstance";
import apiConfig from "../../apiConfig";
import SubMenu from "@/components/ui/subMenu";

export default {
  name: "rentalDashboard",
  components: {SubMenu, RentalFilters, RentalList},
  data() {
    return {
      showFilters: false,
      toPay: 0,
      overdue: 0,
      filters: {
        buildings: [],
        beginDate: "",
        endDate: "",
        status: [],
        minValue: null,
        maxValue: null
      },
      filterNum: 0,
    };
  },
  mixins: [mixin],
  async mounted() {
    await this.getDashboardData()
    if (localStorage.getItem('buildingFilter') !== null) {
      let building = JSON.parse(localStorage.getItem('buildingFilter'))
      this.filters.buildings.push(building)
      this.filter(this.filters)
      localStorage.removeItem('buildingFilter')
    }
  },
  methods: {
    async getDashboardData(filters={}) {
      let vm = this
      let params = {filters: filters}
      await axiosInstance
          .get(apiConfig.rentalsApiUrl + '/dashboards/rental',
              {
                params,
                headers: this.$data.headers
              }).then(function (result) {
            vm.toPay = result.data.toPay
            vm.overdue = result.data.overdue
          });
    },
    refresh: async function () {
      let vm = this
      await axiosInstance
          .get(apiConfig.rentalsApiUrl + '/dashboards/rental',
              {
                headers: this.$data.headers
              }).then(function (result) {
            vm.toPay = result.data.toPay
            vm.overdue = result.data.overdue
          });
    },
    toggleFilters: function () {
      this.showFilters = !this.showFilters
    },
    filter: function (filters) {
      this.filters = filters
      filters = {...filters}
      let buildings = [];
      let buildingsIds = [];
      let filterNum = 0
      if (filters.buildings.length > 0) {
        filterNum++
      }
      filters.buildings.forEach(function (building) {
        buildings.push(building['@id'])
        buildingsIds.push(building['id'])
      })
      if (buildings.length > 0) {
        filters['rentals.spaces.building'] = buildings
        filters['buildingIds'] = buildingsIds
      }
      delete filters.buildings
      if (filters.endDate.toLocaleString() !== "") {
        filters['paymentDate[before]'] = filters.endDate.toLocaleString()
        filterNum++
      }
      if (filters.beginDate.toLocaleString() !== "") {
        filters['paymentDate[after]'] = filters.beginDate.toLocaleString()
        filterNum++
      }
      if (filters.minValue) {
        filters['toPay[gte]'] = filters.minValue
        filterNum++
      }
      if (filters.maxValue) {
        filters['toPay[lte]'] = filters.maxValue
        filterNum++
      }
      if (filters.status[2]) {
        filters['toPay[lte]'] = 0
      }
      if (filters.status[1]) {
        filters['paymentDate[before]'] = new Date().toLocaleString()
      }
      if (filters.status[0]) {
        filters['paymentDate[after]'] = new Date().toLocaleString()
      }

      delete filters.beginDate
      delete filters.endDate
      delete filters.minValue
      delete filters.maxValue
      delete filters.status
      this.filterNum = filterNum
      this.$refs.list.get(filters)
      this.getDashboardData(filters)
    }
  }
};
</script>

<style scoped>
.uk-accordion li {
  list-style-type: none;
}

.uk-accordion-title {
  color: black;
  font-size: 1.3em;
}

.uk-accordion {
  padding-left: 0;
}

.icon-big {
  font-size: 3em
}
</style>
