<template>
    <admin>
        <div class="uk-container uk-padding">
            <vk-grid>
                <menuAdmin></menuAdmin>
                <div class="uk-width-expand@m">
                    <person-form ref="form" v-on:submit="sendData"></person-form>
                </div>
            </vk-grid>
        </div>
    </admin>
</template>

<script>

    import {Grid} from 'vuikit/lib/grid';
    import mixin from "../../components/mixin";
    import apiConfig from "../../apiConfig";
    import PersonForm from "../../components/person/personForm";
    import axiosInstance from "../../axiosInstance";

    export default {
        name: 'personEdit',
        components: {
            VkGrid: Grid,
            personForm: PersonForm
        },
        data() {
            return {}
        },
        mixins: [mixin],
        created: function () {
            let vm = this;
            axiosInstance.get(apiConfig.peopleApiUrl + '/' + this.$route.params.personId, {
                headers: this.$data.headers
            }).then(function (result) {
                vm.person = result.data;
                if(vm.person.unit == null){
                    vm.person.unit = {"@id": null};
                }
                vm.$refs.form.loadData(vm.person);
            });
        },
        methods: {
            sendData: function (postData) {
                let vm = this;
                axiosInstance
                    .put(apiConfig.peopleApiUrl + "/" + this.$route.params.personId, JSON.stringify(postData), {
                        headers: this.$data.headers
                    })
                    .then(function (response) {
                        if (response.status === 200) {
                            vm.$router.push('/people');
                        }
                    }).catch();
            }
        },

    }
</script>
