<template>
  <div class="uk-flex-top uk-modal-container" container=".admin" :id="'registers-'+$attrs.device" uk-modal>
    <div class="uk-modal-dialog uk-margin-auto-vertical">
      <button class="uk-modal-close-default" type="button" uk-close></button>
      <div class="uk-modal-body">
        <h3>{{ modal.title }}</h3>

        <div class="uk-margin">
          <label>Numer</label>
          <input class="uk-input" name="number" v-model="register.number" type="text" placeholder="Numer"
                 v-validate="'required|numeric|max_value:65535'" data-vv-as="numer">
          <span class="validation-error">{{ errors.first('number') }}</span>
        </div>

        <div class="uk-margin">
          <label>Nazwa</label>
          <input class="uk-input" name="name" v-model="register.name" type="text" placeholder="Nazwa"
                 v-validate="'required|max:255'" data-vv-as="nazwa">
          <span class="validation-error">{{ errors.first('name') }}</span>
        </div>

        <div class="uk-margin" v-if="$attrs.deviceRegisterTypes==null">

          <label>Typ</label>
          <select class="uk-select" data-vv-as="typ" name="type" v-model="register.type" v-validate="'required'">
            <option value="0">Odczyt</option>
            <option value="1">Zapis</option>
            <option value="2">Odczyt/Zapis</option>
          </select>
          <span class="validation-error">{{ errors.first('type') }}</span>
        </div>

        <div class="uk-margin" v-if="$attrs.deviceRegisterTypes">

          <label>Wartość</label>
          <select class="uk-select" data-vv-as="wartość" name="value" v-model="register.deviceRequest"
                  v-validate="'required'">
            <option :key="key" :value="key" v-for="(key,value) in $attrs.deviceRegisterTypes">{{ value }}</option>
          </select>
          <span class="validation-error">{{ errors.first('value') }}</span>
        </div>

        <div class="uk-margin">

          <label>Typ wartości</label>
          <select class="uk-select" data-vv-as="typ wartości" name="valueType" v-model="register.valueType"
                  v-validate="'required'">
            <option value="0">Integer</option>
            <option value="1">Float</option>
            <option value="2">String</option>
            <option value="3">Double</option>
            <option value="4">Long</option>
            <option value="5">Coil</option>
          </select>
          <span class="validation-error">{{ errors.first('valueType') }}</span>
        </div>

        <div class="uk-margin">
          <label>Jednostka miary</label>
          <input class="uk-input" name="unitOfMeasure" v-model="register.unitOfMeasure" type="text" placeholder="Jednostka miary"
                 v-validate="" data-vv-as="Jednostka miary">
          <span class="validation-error">{{ errors.first('unitOfMeasure') }}</span>
        </div>


        <div class="uk-margin" v-if="register.valueType===valueType.coil">
          <label>Liczba bitów</label>
          <input class="uk-input" name="size" v-model="register.size" type="text" placeholder="Liczba bitów"
                 v-validate="'numeric'" data-vv-as="liczba bitów">
          <span class="validation-error">{{ errors.first('size') }}</span>
        </div>

        <div class="uk-margin">

          <label>Typ wartości oczekiwanej</label>
          <select class="uk-select" name="expectedValueType" v-model="register.expectedValueType">
            <option value="0">Brak</option>
            <option value="1" v-if="register.valueType!==valueType.string && register.valueType!==valueType.coil">
              Wartość w przedziale
            </option>
            <option value="2">Konkretna wartość</option>
          </select>
        </div>

        <div class="uk-margin" v-if="parseInt(register.expectedValueType)===2">
          <label>Wartość oczekiwana</label>
          <input class="uk-input" name="expectedValue" v-model="register.expectedValue" type="text"
                 placeholder="Wartość oczekiwana" data-vv-as="wartość oczekiwana">
          <span class="validation-error">{{ errors.first('expectedValue') }}</span>
        </div>

        <div class="uk-margin" v-if="parseInt(register.expectedValueType)===1">
          <label>Wartość krytycznie niska</label>
          <input class="uk-input" name="superLow" v-model="register.superLow" type="text"
                 placeholder="Wartość krytycznie niska" data-vv-as="wartość krytycznie niska">
          <span class="validation-error">{{ errors.first('superLow') }}</span>
        </div>
        <div class="uk-margin" v-if="parseInt(register.expectedValueType)===1">
          <label>Wartość bardzo niska</label>
          <input class="uk-input" name="veryLow" v-model="register.veryLow" type="text"
                 placeholder="Wartość bardzo niska" data-vv-as="wartość bardzo niska">
          <span class="validation-error">{{ errors.first('veryLow') }}</span>
        </div>
        <div class="uk-margin" v-if="parseInt(register.expectedValueType)===1">
          <label>Wartość niska</label>
          <input class="uk-input" name="minExpectedValue" v-model="register.minExpectedValue" type="text"
                 placeholder="Wartość niska" data-vv-as="wartość niska">
          <span class="validation-error">{{ errors.first('minExpectedValue') }}</span>
        </div>

        <div class="uk-margin" v-if="parseInt(register.expectedValueType)===1">
          <label>Wartość Wysoka</label>
          <input class="uk-input" name="maxExpectedValue" v-model="register.maxExpectedValue" type="text"
                 placeholder="Wartość wysoka" data-vv-as="wartość wysoka">
          <span class="validation-error">{{ errors.first('minExpectedValue') }}</span>
        </div>
        <div class="uk-margin" v-if="parseInt(register.expectedValueType)===1">
          <label>Wartość bardzo wysoka</label>
          <input class="uk-input" name="veryHigh" v-model="register.veryHigh" type="text"
                 placeholder="Wartość bardzo wysoka" data-vv-as="wartość bardzo wysoka">
          <span class="validation-error">{{ errors.first('veryHigh') }}</span>
        </div>
        <div class="uk-margin" v-if="parseInt(register.expectedValueType)===1">
          <label>Wartość krytycznie wysoka</label>
          <input class="uk-input" name="superHigh" v-model="register.superHigh" type="text"
                 placeholder="Wartośc krytyczcnie wysoka" data-vv-as="wartość krytycznie wysoka">
          <span class="validation-error">{{ errors.first('superHigh') }}</span>
        </div>

        <vue-list-picker
            v-if="register.id==null"
            :left-items="filterMiddlewares()"
            :right-items="selectedMiddlewares"
            button-class="uk-button user-selection-buttons"
            content-attr="name"
            content-class="item-selected"
            content-key="@id"
            title-class="section-title"
            title-left="Dostępne middleware"
            title-right="Wybrane middleware"
            :content-substr=128
        />

        <div class="uk-modal-footer uk-text-right">
          <button class="uk-button uk-button-default uk-modal-close" type="button">Anuluj</button>
          <button :disabled="modal.blocked" @click="submit" class="uk-button wpip-button" type="button">
            {{ modal.button }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "../mixin";
import UIkit from "uikit";
import 'vue-datetime/dist/vue-datetime.css'
import axiosInstance from "../../axiosInstance";
import apiConfig from "../../apiConfig";
import registerTypes from "@/constants/registerTypes";

export default {
  name: 'registerModal',
  data() {
    return {
      filterValue: "",
      modalDom: null,
      parameter: null,
      key: 0,
      selectedMiddlewares: [],
      middlewares: [],
      types: [],
      type: 'datetime',
      buildingIndex: 0,
      register: {
        name: "",
        unitOfMeasure: "",
        isCoil: null,
        number: null,
        size: null,
        type: null,
        valueType: null,
        expectedValueType: 0,
        expectedValue: "",
        minExpectedValue: "",
        maxExpectedValue: "",
      },
      modal:
          {
            title: "Dodaj urządzenie",
            button: "Dodaj",
            blocked: false
          },

    }
  },
  mixins: [mixin],
  methods: {
    showModal: function (register) {
      let vm = this;
      this.selectedMiddlewares = [];
      this.getMiddlewares('');
      this.register = register;
      if (register == null) {
        vm.modal =
            {
              title: "Dodaj rejestr",
              button: "Dodaj",
              blocked: false
            }
        vm.register = {
          name: "",
          unitOfMeasure: "",
          expectedValueType: 0,
          expectedValue: "",
          deviceRequest: "",
          valueType: null,
          minExpectedValue: "",
          maxExpectedValue: "",
          isCoil: false,
          device: '/api/devices/' + this.$attrs.device
        }
      } else {
        vm.register = {...register}
        vm.register.id = register.id;
        vm.register.name = register.name;
        if (register.valueType === this.valueType.coil) {
          vm.register.expectedValue = register.expectedValue?.toString(2);
        }
        if (register.valueType === this.valueType.string) {
          vm.register.expectedValue = register.expectedStringValue;
        }
        vm.modal =
            {
              title: "Zmień rejestr",
              button: "Zmień",
              blocked: false
            }
      }

      this.$validator.reset();

      this.modalDom.show();
    },
    filterMiddlewares: function () {
      let result = this.middlewares;
      let vm = this;
      vm.selectedMiddlewares.forEach(function (selectedMiddleware) {
        result.forEach(function (middleware, key) {
          if (middleware.id === selectedMiddleware.id) {
            result = result.splice(key, 1);
          }
        })
      });
      return result;

    },
    addMiddleware: function () {
      let vm = this;
      if (vm.selectedMiddlewares.length === 0) {
        vm.$emit('refresh');
        vm.modalDom.hide();
      }
      let selected = [];
      vm.selectedMiddlewares.forEach(function (middleware) {
        selected.push(middleware['@id']);
      });
      let postData = {
        middlewares: selected
      };

      axiosInstance
          .put(apiConfig.registersApiUrl + "/" + this.register.id, JSON.stringify(postData), {
            headers: vm.$data.headers
          })
          .then(function (response) {
            if (response.status === 200) {
              vm.modalDom.hide();
              vm.$emit('refresh');
            }
          });
    },
    getMiddlewares: function (e, page = 1) {
      let vm = this;
      if (page === 1) {
        vm.middlewares = [];
      }
      let params = {};
      params['name'] = e;
      params.page = page;
      axiosInstance
          .get(apiConfig.middlewaresApiUrl, {
            params,
            headers: this.$data.headers
          })
          .then(function (response) {
            vm.middlewares = vm.middlewares.concat(response.data['member']);

            if (response.data['view'] && response.data['view']['next']) {
              vm.getMiddlewares(e, page + 1);
            }
          });
    },
    submit: function () {
      let postData = {
        name: this.register.name,
        isCoil: this.register.isCoil,
        unitOfMeasure: this.register.unitOfMeasure,
        number: parseInt(this.register.number),
        size: parseInt(this.register.size),
        type: parseInt(this.register.type),
        valueType: parseInt(this.register.valueType),
        expectedValueType: parseInt(this.register.expectedValueType),
        minExpectedValue: parseFloat(this.register.minExpectedValue),
        maxExpectedValue: parseFloat(this.register.maxExpectedValue),
        deviceRequest: this.register.deviceRequest,
        superLow: parseFloat(this.register.superLow),
        superHigh: parseFloat(this.register.superHigh),
        veryHigh: parseFloat(this.register.veryHigh),
        veryLow: parseFloat(this.register.veryLow),
        expectedValue: this.register.expectedValue,
      };
      if (this.register.id==null && this.register.deviceRequest) {
        postData.type = registerTypes.READ_WRITE;
      }
      if (this.register.valueType !== 2 && this.register.valueType !== this.valueType.coil) {
        postData.expectedValue = parseFloat(postData.expectedValue);
      }
      if (this.register.valueType === this.valueType.string) {
        postData.expectedStringValue = postData.expectedValue;
        delete postData.expectedValue;
      }
      if (this.register.valueType === this.valueType.coil) {
        postData.expectedValue = parseInt(postData.expectedValue, 2);
      }
      let vm = this;
      vm.$validator.validate().then((result) => {
        if (result) {
          this.modal.blocked = true;
          if (!this.register.id) {

            postData.device = vm.register.device;
            axiosInstance
                .post(apiConfig.registersApiUrl, JSON.stringify(postData), {
                  headers: this.$data.headers
                })
                .then(async function (response) {
                  if (response.status === 201) {
                    vm.register.id = response.data.id;
                    vm.addMiddleware();
                  }
                  vm.modal.blocked = false;
                }).catch();
          } else {
            axiosInstance
                .put(apiConfig.registersApiUrl + '/' + vm.register.id, JSON.stringify(postData), {
                  headers: this.$data.headers
                })
                .then(async function (response) {
                  if (response.status === 200) {
                    vm.$emit('refresh');
                    vm.modalDom.hide();
                  }
                  vm.modal.blocked = false;
                }).catch();
          }
        }
      });
    }
  },
  mounted() {
    if (this.modalDom == null) {
      let id = '#registers-' + this.$attrs.device;
      this.modalDom = UIkit.modal(id);
    }
  }

}
</script>
