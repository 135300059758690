<template>
    <span/>
</template>
<script>

    import mixin from "../mixin";
    import axiosInstance from "../../axiosInstance";
    import UIkit from "uikit";

    export default {
        name: 'remove',
        mixins: [mixin],
        methods: {
            remove: function (object, objectName) {
                let vm = this;
                UIkit.modal.confirm("Czy chcesz usunąć " + objectName + "?", {
                    labels: {ok: "Usuń", cancel: "Anuluj"}
                }).then(function () {

                    axiosInstance
                        .delete(object, {
                            headers: vm.$data.headers
                        }).then(function (response) {
                        if (response.status === 204) {
                            vm.$emit('refresh');
                        }
                    }).catch(function () {
                        UIkit.modal.alert("Nie można usunąć wybranego elementu")
                    });
                });
            }
        }
    }
</script>
