<template>
    <div class="text-right">
        <a
                @click.prevent="editUnit()"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Edytuj jednostkę organizacyjną"
        >
            <vk-icon class="hover" icon="pencil"/>
        </a>
    </div>
</template>

<script>

    import mixin from "../mixin";

    export default {
        name: 'unitsActions',
        mixins: [mixin],
        props: {
            datatable: Object
        },
        methods: {
            editUnit: function () {
                this.$emit("edit", this.datatable.item);
                //router.push({name: 'unitEdit', params: {unitId: this.datatable.item.id}})
            }
        }
    }
</script>
