<template>
    <div class="text-right">
        <a
                @click.prevent="dashboard()"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Otwórz dashboard"
        >
            <vk-icon class="hover" icon="play"/>
        </a>
        <a
                @click.prevent="dashboardDetails()"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Szczegóły dashboardu"
        >
            <vk-icon class="hover" icon="settings"/>
        </a>
        <a
                @click.prevent="editDashboard()"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Edytuj dashboard"
        >
            <vk-icon class="hover" icon="pencil"/>
        </a>
        <a
                @click.prevent="deleteDashboard()"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Usuń dashboard"
        >
            <vk-icon class="hover" icon="trash"/>
        </a>
        <remove ref="remove" v-on:refresh="$emit('refresh')"/>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import remove from "../ui/remove";

    export default {
        name: 'devicesActions',
        components: {
            remove
        },
        mixins: [mixin],
        props: {
            datatable: Object
        },
        methods: {
            editDashboard: function () {
                this.$emit('edit', this.datatable.item);
            },
            dashboard: function(){
                this.$router.push({name:"dashboardView", params: {dashboardId: this.datatable.item.id}});
            },
            dashboardDetails: function(){
                this.$router.push({name:"dashboardDetails", params: {dashboardId: this.datatable.item.id}});
            },
            deleteDashboard: function () {
                this.$refs.remove.remove(apiConfig.dashboardsApiUrl + '/' + this.datatable.item.id, "dashboard");
            }
        }
    }
</script>
