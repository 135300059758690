<template>
  <div class="text-right">
    <a
        @click.prevent="getFile(datatable.item.id)"
        class="uk-icon-button"
        href="#"
        uk-tooltip="Pobierz plik"
    >
      <vk-icon class="hover" icon="download"/>
    </a>
    <a
        @click.prevent="editResource()"
        class="uk-icon-button"
        href="#"
        uk-tooltip="Edytuj zasób"
    >
      <vk-icon class="hover" icon="pencil"/>
    </a>
    <a
        @click.prevent="deleteResource()"
        class="uk-icon-button"
        href="#"
        uk-tooltip="Usuń zasób"
    >
      <vk-icon class="Usuń" icon="trash"/>
    </a>
    <remove ref="remove" v-on:refresh="$emit('refresh')"/>
    <file-download ref="download" />
  </div>

</template>

<script>

import mixin from "../../components/mixin";
import remove from "../ui/remove";
import FileDownload from "../ui/fileDownload";
import apiConfig from "../../apiConfig";

export default {
  name: 'resourcesActions',
  components: {FileDownload, remove},
  mixins: [mixin],
  props: {
    datatable: Object
  },
  methods: {
    getFile: function(){
      if(this.datatable.item.url){
        window.location.href = this.datatable.item.url
      }
      else{
        this.$refs.download.getApi("/api/ias_resources/" + this.datatable.item.id + "/file", this.datatable.item.originalFilename);
      }
    },
    editResource: function () {
      this.$emit('edit', this.datatable.item);
    },
    deleteResource: function () {
        this.$refs.remove.remove(apiConfig.iasResourcesApiUrl + '/' + this.datatable.item.id, 'zasób');
    }
  }
}
</script>
