<template>
    <div>
        <empty-list v-if="totalItems===0"/>
        <b-table v-else
                 :columns="fields" :current-page="currentPage" :fields="fields"
                 :items="get"
                 :per-page="this.$data.perPage"
                 :sort-by.sync="sortBy"
                 :sort-desc.sync="sortDesc"
                 borderedless
                 hover
                 ref="table"
                 no-sort-reset
                 :responsive="true">

            <template v-slot:cell(name)="data">
                <span @click="toggle(data)" class="table-toggle">
                    <vk-icon :icon="data.detailsShowing ? 'chevron-up' : 'chevron-down'"></vk-icon>
                    <span :uk-tooltip="data.item.name">{{limit(data.item, 100)}}</span>
                </span>
            </template>

            <template v-slot:row-details="row">
                <b-card>
                    <middleware-registers-list :ref="'registersList['+row.item.id+']'" :middlewareId="row.item.id"
                                               :registers="row.item.registers"/>
                </b-card>
            </template>

            <template v-slot:cell(actions)="data">
                <middlewares-actions :datatable=data @edit="edit" v-on:refresh="refresh"/>
            </template>
        </b-table>
        <b-pagination :per-page="perPage" :total-rows="totalItems" align="center" v-model="currentPage"
                      v-if="totalItems>perPage" size="sm" page-class="wpip-page" class="wpip-page"/>
        <middleware-modal ref="mod" v-on:refresh="refresh"/>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import MiddlewaresActions from "./middlewaresActions";
    import axiosInstance from "../../axiosInstance";
    import MiddlewareModal from "./middlewareModal";
    import MiddlewareRegistersList from "./middlewareRegistersList";
    import EmptyList from "../ui/emptyList";
    import charLimiter from "../charLimiter";

    export default {
        name: 'middlewaresList',
        components: {
            EmptyList,
            MiddlewareModal,
            MiddlewaresActions,
            MiddlewareRegistersList
        },
        data() {
            return {
                sortBy: 'name',
                sortDesc: false,
                data: [],
                details: [],
                currentPage: 1,
                eventSource: null,
                totalItems: '',
                fields: [
                    {key: 'name', label: 'Nazwa', class: '', sortable: true},
                    {key: 'actions', label: '', class: ''},
                ],
                buildingId: null
            }
        },
        mixins: [mixin, charLimiter],
        methods: {
            toggle: async function (data) {
                let vm = this;
                data.toggleDetails();
                if (data.item._showDetails) {
                    this.details.push(data.item.id);
                } else {
                    this.details.forEach(function (middleware, key) {
                        if (middleware === data.item.id) {
                            vm.details.splice(key, 1);
                        }
                    });
                }
                this.$refs.table.refresh();
            },
            async get() {
                let vm = this;
                let desc = 'desc';
                if (vm.sortDesc !== true) {
                    desc = 'asc';
                }

                var params = {};

                if (this.sortBy !== '') {
                    var key = "order[" + this.sortBy + "]";
                    params[key] = desc;
                }
                params['page'] = vm.currentPage;

                let res = await axiosInstance
                    .get(apiConfig.middlewaresApiUrl,
                        {
                            params,
                            headers: this.$data.headers
                        });
                vm.data = res.data.member;
                vm.totalItems = res.data.totalItems;
                vm.data.forEach(function (element) {
                    if (!element.active) {
                        element._rowVariant = '  ';
                    }
                    vm.details.forEach(function (middleware) {
                        if (middleware === element.id) {
                            element['_showDetails'] = true;
                        }
                    });
                });
                return vm.data;
            },
            refresh: async function () {
                await this.get();
                if (this.$refs.table) {
                    this.$refs.table.refresh();
                }
            },
            edit: function (middleware) {
                this.$refs.mod.showModal({...middleware});
            },
            add: function () {
                this.$refs.mod.showModal({});
            }
        },
        created: function () {
            let url_admin = apiConfig.mercureUrl;
            const url = new URL(url_admin + '/.well-known/mercure');
            url.searchParams.append('topic', apiConfig.apiUrl + '/api/register_values/{id}');
            this.eventSource = new EventSource(url);
            this.eventSource.onmessage = () => {
                this.refresh();
            };

            this.get();
        },
        beforeDestroy() {
            this.eventSource.close();
        },
    }
</script>
<style scoped>
    .uk-width-expand span {
        white-space: nowrap;
    }
</style>
