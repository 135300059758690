<template>
    <div class="uk-flex-top uk-modal-container uk-modal" id="contractor-modal">
        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-modal-body">
                <h3>{{ modal.title }}</h3>
                <div>
                    <div>
                        <table class="table">
                            <tr>
                                <th>Nazwa</th>
                                <td>{{ modal.contractor.name }}</td>
                            </tr>
                            <tr>
                                <th>E-mail</th>
                                <td>{{ modal.contractor.email }}</td>
                            </tr>
                            <tr>
                                <th>Nr telefonu</th>
                                <td>{{ modal.contractor.phone }}</td>
                            </tr>
                            <tr>
                                <th>Adres</th>
                                <td>{{ modal.contractor.address }}</td>
                            </tr>
                            <tr>
                                <th>Kod</th>
                                <td>{{ modal.contractor.internalCode }}</td>
                            </tr>
                            <tr>
                                <th>NIP</th>
                                <td>{{ modal.contractor.taxNo }}</td>
                            </tr>
                            <tr>
                                <th>Status</th>
                                <td v-html="modal.contractor.statusString"></td>
                            </tr>
                            <tr>
                                <th>Opis</th>
                                <td>{{ modal.contractor.description }}</td>
                            </tr>
                        </table>
                    </div>
                    <buildings-list ref="buildings" v-on:edit="hide"></buildings-list>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import BuildingsList from "../building/buildingsList";
    import axiosInstance from "../../axiosInstance";
    import UIkit from "uikit"

    export default {
        name: 'contractorDetails',
        components: {
            buildingsList: BuildingsList
        },
        data() {
            return {
                sortBy: 'name',
                sortDesc: false,
                data: [],
                currentPage: 1,
                totalItems: '',
                showDetails: false,
                modalDom: null,
                fields: [
                    {key: 'name', label: 'Nazwa', sortable: true},
                    {key: 'email', label: 'E-mail', sortable: true},
                    {key: 'taxNo', label: 'NIP', class: 'text-center'},
                    {key: 'actions', label: ''},
                ],
                modal:
                    {
                        title: "Kontrahent",
                        contractor: [],
                        fields: [
                            {key: 'name', label: 'Nazwa'},
                        ]
                    },

            }
        },
        mixins: [mixin],
        methods: {
            clickedRow: async function (contractorId) {
                this.$refs.buildings.contractorId = contractorId;
                this.$refs.buildings.refresh();

                let vm = this;
                await axiosInstance.get(apiConfig.contractorsApiUrl + '/' + contractorId, {
                    headers: this.$data.headers
                }).then(function (result) {
                    vm.modal.contractor = result.data;
                    vm.modal.contractor.statusString = "";
                    for (var i = 0; i < 8; i++) {
                        if (vm.modal.contractor.status % 2)
                            vm.modal.contractor.statusString += "<span class='badge badge-secondary'>" + vm.$data.contractorStatus[i] + "</span> ";
                        vm.modal.contractor.status = Math.floor(vm.modal.contractor.status / 2)
                    }
                });
               this.modalDom.show();
            },
            hide: function (){
                this.modalDom.hide();
            }
        },
        mounted() {
            this.modalDom = UIkit.modal('#contractor-modal');
        }
    }
</script>
