<template>
    <span>
        <a
                @click.prevent="addParameter"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Dodaj parametr"
        >
            <vk-icon class="hover" icon="plus"/>
        </a>
        <a
                @click.prevent="showParameters"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Lista parametrów"
        >
            <vk-icon class="hover" icon="list"/>
        </a>
    </span>
</template>

<script>

    import mixin from "../../components/mixin";

    export default {
        name: 'qfdTechnicalParametersActions',
        mixins: [mixin],
        props: {
            datatable: Object
        },
        methods: {
            addParameter: function () {
                this.$emit('addParameter', {
                    index: this.$props.datatable.index,
                    id: this.$props.datatable.item.propertyId
                })
            },
            showParameters: function () {
                this.$emit('showParameters', this.$props.datatable.index)
            }
        }
    }
</script>
