<template>
    <div>
        <budget-template-main-category-modal ref="modal" v-on:refresh="refresh"/>
        <budget-template-category-modal ref="categoryModal" v-on:refresh="refresh"/>
        <empty-list v-if="data.budgetTemplateMainCategories.length===0"/>
        <b-table
                v-else
                :columns="fields" :current-page="currentPage" :fields="fields"
                :items="data.budgetTemplateMainCategories"
                :per-page="this.$data.perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :class="'no-header'"
                borderedless
                no-sort-reset
                :responsive="true"
                hover
                ref="table">

            <template v-slot:cell(name)="data">
                <span @click="toggle(data)" class="row-click"  :uk-tooltip="data.item.budgetMainCategory.name">
               {{ data.detailsShowing ? '-' : '+'}}
                {{ limit(data.item.budgetMainCategory,100) }}
                    </span>
            </template>

            <template v-slot:cell(actions)="data">
                <budget-template-main-categories-actions v-on:edit="edit" :datatable=data v-on:refresh="refresh"/>
            </template>

            <template v-slot:row-details="row">
                <b-card>
                    <vk-grid class="uk-margin-bottom">
                        <h4 class="uk-width-expand">
                        </h4>
                        <button @click="addCategory(row.item.budgetMainCategory.id)"
                                class="uk-button wpip-button uk-width-auto"
                        >Dodaj Podkategorię
                        </button>
                    </vk-grid>
                    <empty-list v-if="row.item.budgetMainCategory.budgetCategories.length===0"/>
                    <b-table v-else :columns="fields" :current-page="currentPage" :fields="fields"
                             :items="row.item.budgetMainCategory.budgetCategories"
                             :per-page="perPage"
                             :sort-by.sync="sortBy"
                             :sort-desc.sync="sortDesc"
                             borderedless
                             no-sort-reset
                             :responsive="true"
                             hover>
                        <template v-slot:cell(name)="data">
                            <span  :uk-tooltip="data.item.name">
                            {{ limit(data.item,100) }}
                            </span>
                        </template>

                        <template v-slot:cell(actions)="data">
                            <budget-template-categories-actions v-on:edit="editCategory" :datatable=data
                                                                v-on:refresh="refresh"/>
                        </template>
                    </b-table>
                </b-card>
            </template>

        </b-table>
        <b-pagination :per-page="perPage" :total-rows="totalItems" align="center" v-model="currentPage"
                      v-if="totalItems>perPage" size="sm" page-class="wpip-page" class="wpip-page"/>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import BudgetTemplateCategoriesActions from "./budgetTemplateCategoriesActions";
    import BudgetTemplateMainCategoriesActions from "./budgetTemplateMainCategoriesActions";
    import axiosInstance from "../../axiosInstance";
    import BudgetTemplateMainCategoryModal from "./budgetTemplateMainCategoryModal";
    import BudgetTemplateCategoryModal from "./budgetTemplateCategoryModal";
    import EmptyList from "../ui/emptyList";
    import charLimiter from "../charLimiter";

    let vm;
    export default {
        name: 'budgetTemplateCategoriesList',
        components: {
            EmptyList,
            BudgetTemplateMainCategoryModal,
            BudgetTemplateCategoryModal,
            BudgetTemplateCategoriesActions,
            BudgetTemplateMainCategoriesActions
        },
        data() {
            return {
                sortBy: 'name',
                sortDesc: false,
                details: [],
                data: {
                    budgetTemplateMainCategories: []
                },
                currentPage: 1,
                totalItems: '',
                fields: [
                    {key: 'name', label: '', sortable: true, class: "row-click"},
                    {key: 'actions', label: '', class: 'uk-width-expand'}
                ],
                buildingId: null
            }
        },
        mixins: [mixin, charLimiter],
        methods: {
            toggle: function (data) {
                data.toggleDetails();
                if (!data.detailsShowing) {
                    this.details.push(data.item.budgetMainCategory.id);
                } else {
                    this.details.forEach(function (category, key) {
                        if (category === data.item.budgetMainCategory.id) {
                            vm.details.splice(key, 1);
                        }
                    });
                }
                vm.$refs.table.refresh();
            },
            async get() {
                let res = await axiosInstance
                    .get(apiConfig.budgetTemplatesApiUrl + "/" + this.$route.params.templateId,
                        {
                            headers: this.$data.headers
                        });
                vm.data = res.data;
                vm.data.budgetTemplateMainCategories.forEach(function (element) {
                    vm.details.forEach(function (category) {
                        if (category === element.budgetMainCategory.id) {
                            element['_showDetails'] = true;
                        }
                    });
                });
                vm.totalItems = res.data.length;
                return vm.data;
            },
            refresh: async function () {
                await this.get();
                if (this.$refs.table) {
                    this.$refs.table.refresh();
                }
            },
            add: function () {
                this.$refs.modal.showModal(null);
            },
            addCategory: function (categoryId) {
                this.$refs.categoryModal.showModal(null, categoryId);
            },
            edit: function (category) {
                this.$refs.modal.showModal({...category.budgetMainCategory});
            },
            editCategory: function (category) {
                this.$refs.categoryModal.showModal({...category});
            }
        },
        mounted() {
            this.get();
            vm = this;
        }
    }
</script>

<style>
    .hidden {
        display: none
    }

    .row-click {
        width: 100%;
        vertical-align: middle;
        display: block;
    }

    .no-header th {
        display: none !important;
    }
</style>