<template>
    <admin>
        <div class="uk-container-expand uk-padding" id="ias-content">
            <vk-grid>
                <menuAdmin/>
                <div class="uk-width-expand">
                    <div class="uk-grid uk-margin-bottom">
                        <h3 class="uk-width-expand">Branże</h3>
                        <button @click="addIndustry"
                                class="uk-button wpip-button uk-width-auto"
                        ><vk-icon icon="plus-circle"></vk-icon> Dodaj nową branżę
                        </button>
                    </div>
                    <vk-card>
                        <industries-list v-on:edit="editIndustry" ref="table"></industries-list>
                        
                    </vk-card>
                </div>
            </vk-grid>
        </div>
        <industry-modal @refresh="refresh" ref="modal"/>
    </admin>
</template>

<script>

    import mixin from "../../components/mixin";
    import industriesList from "../../components/industries/industriesList";
    import industryModal from "../../components/industries/industryModal";

    export default {
        name: 'iasPhases',
        components: {
            industryModal,
            industriesList
        },
        mixins: [mixin],
        data() {
            return {
                sortBy: 'name',
                sortDesc: false,
                data: [],
                currentPage: 1,
                totalItems: ''
            }
        },
        methods: {
            addIndustry: function () {
                this.$refs.modal.showModal({id: null, name: ''});
            },
            editIndustry: function (industry) {
                this.$refs.modal.showModal(industry);
            },
            refresh: function () {
                this.$refs.table.refresh();
            }

        },
        async mounted() {
            this.ability = await this.$ability.getAbility();
            if (!this.ability.can('manage', 'ROLE_SUPER_ADMIN')) {
                await this.$router.push({name: 'not-allowed'});
            }
        }
    }
</script>
