<template>
  <div class="uk-flex-top uk-modal-container" container=".admin" id="resources" uk-modal>
    <div class="uk-modal-dialog uk-margin-auto-vertical">
      <button class="uk-modal-close-default" type="button" uk-close></button>
      <div class="uk-modal-body">
        <h3>{{ modal.title }}</h3>
        <div class="uk-margin">
          <input class="uk-input" data-vv-as="opis" name="description" placeholder="Opis..." type="text"
                 v-model="resource.description"
                 v-validate="'required|max:255'">
          <span class="validation-error">{{ errors.first('description') }}</span>
        </div>
        <div class="uk-margin">
          <div class="mb-2">
            Aktualny plik: {{ resource.originalFilename }}
          </div>
          <b-form-file v-model="resource.file" placeholder="Nie wybrano pliku..."/>
        </div>

        <div class="uk-margin">
          <input class="uk-input" data-vv-as="Lokalizacja pliku" name="url" placeholder="Lokalizacja pliku..." type="text"
                 v-model="resource.url">
        </div>

        <div class="uk-margin">
          <v-select :filterable="false" :options="phases" @search="search" label="@id"
                    :class="'wpip-select'" v-model="resource.iasPhaseId" :reduce="phase => phase.id" placeholder="Faza IAS">

            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{ option.name }}
              </div>
            </template>

            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.name }}
              </div>
            </template>

            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                Nie znaleziono <em>{{ search }}</em>.
              </template>
              <em style="opacity: 0.5;" v-else>Wyszukaj fazę IAS</em>
            </template>
          </v-select>
        </div>

        <div class="uk-modal-footer uk-text-right">
          <button class="uk-button uk-button-default uk-modal-close" type="button">Anuluj</button>
          <button :disabled="modal.blocked" @click="submit" class="uk-button wpip-button" type="button">
            {{ modal.button }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "../mixin";
import UIkit from "uikit";
import 'vue-datetime/dist/vue-datetime.css'
import {pl} from "vuejs-datepicker/src/locale";
import axiosInstance from "../../axiosInstance";
import apiConfig from "../../apiConfig";

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result.split('base64,')[1]);
  reader.onerror = error => reject(error);
});

export default {
  name: 'resourceModal',
  components: {},
  data() {
    return {
      pl: pl,
      modalDom: null,
      phases: [],
      resource: {
        file: null,
        url: "",
        description: null,
      },
      modal:
          {
            title: "Dodaj zasób",
            button: "Dodaj",
            blocked: false
          },

    }
  },
  mixins: [mixin],
  methods: {
    showModal: async function (resource) {
      let vm = this;
      this.resource = resource;
      if (resource.id == null) {
        vm.modal =
            {
              title: "Dodaj zasób",
              button: "Dodaj",
              blocked: false
            }
      } else {
        this.modal =
            {
              title: "Zmień zasób",
              button: "Zmień",
              blocked: false
            }
            vm.resource.iasPhaseId = vm.resource.phase.id
      }

      await this.$validator.reset();
      this.getPhases()
      this.modalDom.show();
    },
    getPhases: function (e) {
      let vm = this;
      var params = {};
      params['name'] = e;
      axiosInstance
          .get(apiConfig.iasPhasesApiUrl, {
            params,
            headers: this.$data.headers
          })
          .then(function (response) {
            vm.phases = response.data['member'];
          });
    },
    search: function (e) {
      let vm = this;
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(function () {
        vm.getPhases(e)
      }, 500);
    },
    submit: async function () {
      let vm = this;


      let postData = {
        buildingId: vm.$route.params.id,
        iasPhaseId: vm.resource.iasPhaseId,
        url: vm.resource.url,
        description: vm.resource.description
      };
      if(vm.resource.file){
        postData.fileContent = await toBase64(this.resource.file)
        postData.filename = vm.resource.file.name
      }
      vm.$validator.validate().then(async (result) => {
        if (result) {
          if (!vm.resource.id) {
            axiosInstance
                .post(apiConfig.iasResourcesApiUrl, JSON.stringify(postData), {
                  headers: this.$data.headers
                })
                .then(async function (response) {
                  if (response.status === 201) {
                    vm.$emit('refresh');
                    vm.modalDom.hide();
                  }
                  vm.modal.blocked = false;
                }).catch();
          } else {
            axiosInstance
                .put(apiConfig.iasResourcesApiUrl + "/" + vm.resource.id, JSON.stringify(postData), {
                  headers: this.$data.headers
                })
                .then(async function (response) {
                  if (response.status === 200) {
                    vm.$emit('refresh');
                    vm.modalDom.hide();
                  }
                  vm.modal.blocked = false;
                }).catch();
          }
        }
      });
    }
  },
  mounted() {
    if (this.modalDom == null) {
      this.modalDom = UIkit.modal('#resources');
    }
  }

}
</script>
