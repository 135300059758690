<template>
    <div class="uk-flex-top uk-modal-container" container=".admin" id="rental-details-modal" uk-modal>
        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close/>
            <div class="uk-modal-body">
                <h3>{{ modal.title }} {{contractor.name}}</h3>
                <empty-list v-if="totalItems===0"/>
                <b-table v-else ref="table" hover borderedless
                         :fields="fields"
                         :columns="fields"
                         :items="data"
                         :per-page="this.$data.perPage"
                         :current-page="currentPage"
                         :sort-by.sync="sortBy"
                         :sort-desc.sync="sortDesc"
                         no-sort-reset
                         :responsive="true">
                    <template v-slot:cell(actions)="data">
                        <rental-details-actions :datatable="data" @toggleInvoices="toggleInvoices" @addInvoice="addInvoice" />
                    </template>

                    <template v-slot:cell(value)="data">
                        {{ sumValue(data.item.invoices) }}
                    </template>

                    <template v-slot:cell(status)="data">
                        <span class="uk-label uk-label-success" v-if="parseFloat(sumValue(data.item.invoices))===0">Zapłacone</span>
                        <span class="uk-label uk-label-danger" v-else-if="checkDate(data.item.invoices)">Zaległe</span>
                        <span class="uk-label uk-label-primary" v-else>Wymagalne</span>
                    </template>

                    <template v-slot:cell(paymentDate)="data">
                        {{ minPaymentDate(data.item.invoices) }}
                    </template>

                    <template v-slot:row-details="groupData">

                        <empty-list v-if="groupData.item.invoices.length===0"/>
                        <b-table v-else  hover borderedless
                                 :fields="invoicesFields"
                                 :columns="invoicesFields"
                                 :items="groupData.item.invoices"
                                 :current-page="currentPage"
                                 :sort-by="'datePayment'"
                                 :sort-desc.sync="sortDesc"
                                 no-sort-reset
                                 :responsive="true">


                            <template v-slot:cell(datePayment)="data">
                                {{ getDatePayment(data.item) }}
                            </template>

                            <template v-slot:cell(value)="data">
                                <span v-if="data.item.status!==true"> {{ data.item.value.toFixed(2) }} </span>
                                <span v-else> 0.00 </span>
                            </template>

                            <template v-slot:cell(status)="data">
                                <span class="uk-label uk-label-success" v-if="data.item.status===true">Zapłacone</span>
                                <span class="uk-label uk-label-danger" v-else-if="new Date(data.item.datePayment)<new Date()">Zaległe</span>
                                <span class="uk-label uk-label-primary" v-else>Wymagalne</span>
                            </template>

                            <template v-slot:cell(actions)="data">
                                <invoice-actions @toggleStatus="toggleStatus" @edit="edit" :datatable="data" :groupData="groupData.index"/>
                            </template>
                        </b-table>
                    </template>

                </b-table>
                <b-pagination :total-rows="totalItems" :per-page="perPage" v-model="currentPage"
                              align="center" v-if="totalItems>perPage" size="sm" page-class="wpip-page"
                              class="wpip-page"/>
            </div>

        </div>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import UIkit from "uikit";
    import charLimiter from "../charLimiter";
    import axiosInstance from "../../axiosInstance";
    import apiConfig from "../../apiConfig";
    import EmptyList from "../ui/emptyList";
    import RentalDetailsActions from "./rentalDetailsActions";
    export default {
        name: 'rentalDetailsModal',
        components: {InvoiceActions, RentalDetailsActions, EmptyList},
        data() {
            return {
                filterValue: "",
                sortBy: 'month',
                sortDesc: true,
                data: [],
                details: [],
                contractor: {
                    name: ""
                },
                currentPage: 1,
                totalItems: '',
                fields: [
                    {key: 'month', label: 'Miesiąc', sortable: true},
                    {key: 'area', label: 'Powierzchnia (M2)'},
                    {key: 'value', label: 'Należność (PLN)'},
                    {key: 'paymentDate', label: 'Data Wymagalności'},
                    {key: 'status', label: 'Status'},
                    {key: 'actions', label: ''}
                ],
                'invoicesFields': [
                    {key: 'name', label: 'Nazwa', sortable: true},
                    {key: 'value', label: 'Należność (PLN)'},
                    {key: 'datePayment', label: 'Data Wymagalności'},
                    {key: 'status', label: 'Status'},
                    {key: 'actions', label: ''}
                ],
                modal:
                    {
                        title: "Szczegółowe rozliczenie - ",
                        button: "Dodaj"
                    },

            }
        },
        mixins: [mixin, charLimiter],
        methods: {
            addInvoice: function addInvoice(data){
                this.$emit('addInvoice', data)
            },
            edit: function addInvoice(data){
                this.$emit('edit', data)
            },

            showModal: function (contractor) {
                this.contractor = {...contractor}
                this.get()
                this.details = []
                if(this.$refs.table) {
                    this.$refs.table.refresh()
                }
                this.modalDom.show()
            },
            sumValue: function(invoices){
                let sum = 0
                invoices.forEach(function (invoice) {
                    if(!invoice.status) {
                        sum += invoice.value
                    }
                })
                return sum
            },
            toggleStatus: function(data){
                let vm = this
                let postData = {
                    status: !data.status
                }
                axiosInstance
                    .put(apiConfig.invoicesApiUrl + '/' + data.id, JSON.stringify(postData), {
                        headers: this.$data.headers
                    })
                    .then(async function (response) {
                        if (response.status === 200) {
                            vm.refresh()
                            vm.$emit('refresh')
                        }
                    }).catch();

            },
            minPaymentDate: function(invoices){
                let minDate = null;
                invoices.forEach(function (invoice) {
                    if(!invoice.status && (!minDate || invoice.datePayment && invoice.datePayment<minDate)){
                        minDate = invoice.datePayment
                    }
                })
                if(minDate) {
                    return new Date(minDate).toLocaleDateString()
                }
                return null
            },
            checkDate: function(invoices){
                let minDate = null;
                invoices.forEach(function (invoice) {
                    if(!invoice.status && (!minDate || invoice.datePayment && invoice.datePayment<minDate)){
                        minDate = invoice.datePayment
                    }
                })
                if(minDate) {
                    return new Date(minDate) < new Date()
                }
                return false
            },
            getDatePayment: function(invoice){
                if(invoice.datePayment) {
                    return new Date(invoice.datePayment).toLocaleDateString()
                }
                return null
            },
            toggleInvoices: function(item){
                item.toggleDetails()
                let vm = this
                if(item.item._showDetails){
                    this.details.push({id: item.item.id, month: item.item.month})
                }
                else{
                    this.details.forEach(function (details, key) {
                        if(details.id === item.item.id && details.month === item.item.month){
                            vm.details.splice(key,1)
                        }
                    })
                }
                this.$refs.table.refresh()
            },
            async get() {
                let vm = this;
                let desc = 'desc';
                if (vm.sortDesc !== true) {
                    desc = 'asc';
                }


                var key = "order[" + this.sortBy + "]";
                var params = {};
                params[key] = desc;
                params['page'] = vm.currentPage;
                params['contractor.id'] = this.contractor.id;

                let res = await axiosInstance
                    .get(apiConfig.rentalsApiUrl,
                        {
                            params,
                            headers: this.$data.headers
                        });
                vm.data = res.data['member'];
                vm.totalItems = res.data['totalItems'];
                vm.data.forEach(function (rental){
                    rental.area = 0
                    rental.spaces.forEach(function (space) {
                        rental.area += parseFloat(space.area)
                    })
                })
                let months = {}
                vm.data.forEach(function (rental) {
                    rental.invoices.forEach(function (invoice) {
                        let monthGroup = new Date(invoice.dateReceive).getFullYear() + "-"
                        if((new Date(invoice.dateReceive).getMonth()+1)<10){
                            monthGroup+=0
                        }
                        monthGroup += (new Date(invoice.dateReceive).getMonth()+1)
                        if(!months[rental.id+"-"+monthGroup]){
                            months[rental.id+"-"+monthGroup] = {...rental}
                            months[rental.id+"-"+monthGroup].month = monthGroup
                            months[rental.id+"-"+monthGroup].invoices = []
                            months[rental.id+"-"+monthGroup].invoices.push(invoice)
                        }
                        else{
                            months[rental.id+"-"+monthGroup].invoices.push(invoice)
                        }

                    })
                    let monthGroup = new Date().getFullYear() + "-"
                    if((new Date().getMonth()+1)<10){
                        monthGroup+=0
                    }
                    monthGroup += (new Date().getMonth()+1)
                    if(!months[rental.id+"-"+monthGroup]){
                        months[rental.id+"-"+monthGroup] = {...rental}
                        months[rental.id+"-"+monthGroup].month = monthGroup
                        months[rental.id+"-"+monthGroup].invoices = []
                    }
                })

                vm.data = Object.values(months)
                this.showSelected()
                return vm.data
            },
            showSelected: function(){
                let vm = this
                this.details.forEach(function (details) {
                    vm.data.forEach(function (group) {
                        if(group.id === details.id && group.month === details.month){
                            group['_showDetails'] = true

                            vm.$refs.table.refresh()
                        }
                    })
                })
            },
            refresh: function(){
                this.$emit('refresh')
                this.get()
            }
        },


        mounted() {
            this.modalDom = UIkit.modal('#rental-details-modal');
        }

    }

    import InvoiceActions from "./invoiceActions";
</script>
<style>
    .section-title {
        color: white !important;
        background-color: #bf0e01 !important;
    }

    .item-selected.list-picker-selected {
        background-color: #bf4a3f !important;
    }

    .item-selected.list-picker-selected::selection {
        background-color: #bf4a3f !important;
        color: white !important;
    }

    .item-selected::selection {
        color: #666 !important;
    }

    .user-selection-buttons {
        margin-top: 2px !important;
        color: white !important;
    }


</style>
