import Vue from 'vue'
import Router from 'vue-router'
import login from './pages/login.vue'
import logout from './pages/logout.vue'
import admin from './components/ui/admin.vue'
import pageNotFound from './components/ui/pageNotFound.vue'
//Dashboards
import dashboards from './pages/dashboards'
import dashboardView from './pages/dashboards/view'
import dashboardDetails from './pages/dashboards/details.vue'
import dashboard from './pages/dashboard'
import rentalDashboard from './pages/rentalDashboard'
import element from './components/dashboardElements/dashboardElementValue'
import tools from './pages/tools'
import infos from './pages/infos'
import calendar from './pages/calendar'
//Registers
import registers from './pages/registers'
import registerValues from './pages/registers/registerValues.vue'
//Devices
import devices from './pages/devices'
//Middleware
import middlewares from "./pages/middlewares";
import middlewareLogs from "./pages/middlewares/logs";
//EventTypes
import eventTypes from './pages/eventTypes'

//Buildings
import buildings from './pages/buildings'
import projects from './pages/buildings/select'
import buildingAdd from './pages/buildings/add'
import buildingEdit from './pages/buildings/edit'
import resources from "./pages/buildings/resources";
//Units
import units from "./pages/units"
import unitAdd from "./pages/units/add"
import unitEdit from "./pages/units/edit"
//Users
import users from "./pages/users"
//People
import people from "./pages/people";
import personAdd from "./pages/people/add";
import personEdit from "./pages/people/edit";
//Contractors
import contractors from "./pages/contractors";
import contractorContacts from "./pages/contractorContacts";
import contractorAdd from "./pages/contractors/add";
import contractorEdit from "./pages/contractors/edit";
//SWOT Analyses
import swots from "./pages/swot"
import swotElements from "./pages/swot/elements"
import swotElementsCorrelation from "./pages/swot/elementsCorrelation"
import towsElementsCorrelation from "./pages/swot/towsElementsCorrelation"
import swotResult from "./pages/swot/result"
//FMEA Analyses
import fmeas from "./pages/fmea"
import fmeaAnalysis from "./pages/fmea/analysis"
//Keizens
import keizens from "./pages/keizen"
//QFD analyses
import qfds from "./pages/qfd"
import qfdParameters from "./pages/qfd/parameters"
import qfdAnalyses from "./pages/qfd/analyses"
import qfdAnalysis from "./pages/qfd/analysis"
//ZPC
import zpcs from "./pages/zpc"
import peopleRates from "./pages/zpc/peopleRates"
import myRates from "./pages/zpc/myRates"
import benefits from "./pages/zpc/benefits"
import goals from "./pages/zpc/goals"
import detailedGoals from "./pages/zpc/detailedGoals"
import operationalGoals from "./pages/zpc/operationalGoals"
//IAS Phases
import iasPhases from "./pages/iasPhases"
//Industries
import industries from "./pages/industries"
//Budget Templates
import budgetTemplates from "./pages/budgetTemplates"
import budgetTemplateCategories from "./pages/budgetTemplateCategories"
//Budgets
import budgets from "./pages/budgets"
import budgetDetails from "./pages/budgets/details"
//Renters
import renters from "./pages/renters"
import rentalObjects from "./pages/rentalObjects"
//Function Tree
import functionTree from "./pages/functionTree"
import schema from "./pages/schema"
import myGoals from "@/pages/zpc/myGoals";
//Process Tree
import processTree from "./pages/processTree"
//IAS methods
import iasMethods from "./pages/methods"
import iasFiles from "./pages/iasFiles";

Vue.use(Router);

const routes = [
    {
        path: '/element',
        name: 'element',
        component: element
    }, {
        path: '/schema',
        name: 'schema',
        component: schema
    },
    {
        path: '/not-allowed',
        name: 'not-allowed',
        component: dashboard
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: dashboard
    },
    {
        path: '/rental-dashboard',
        name: 'rentalDashboard',
        component: rentalDashboard
    },
    {
        path: '/dashboards',
        name: 'dashboards',
        component: dashboards
    },
    {
        path: '/dashboards/:dashboardId',
        name: 'dashboardView',
        component: dashboardView
    },
    {
        path: '/dashboards/edit/:dashboardId',
        name: 'dashboardDetails',
        component: dashboardDetails
    },
    {
        path: '/',
        name: 'home',
        component: projects
    },
    {
        path: '/login',
        name: 'login',
        component: login
    },
    {
        path: '/logout',
        name: 'logout',
        component: logout
    },
    {
        path: '/admin',
        name: 'admin',
        component: admin
    },
    {
        path: '/buildings/:buildingId/tools',
        name: 'tools',
        component: tools
    },
    {
        path: '/infos',
        name: 'infos',
        component: infos
    },
    {
        path: '/ias-files',
        name: 'iasFiles',
        component: iasFiles
    },
    {
        path: '/eventTypes',
        name: 'eventTypes',
        component: eventTypes
    },
    {
        path: '/calendar',
        name: 'calendar',
        component: calendar
    },
    {
        path: '/registers',
        name: 'registers',
        component: registers
    }, {
        path: '/values/:registerId',
        name: 'registerValues',
        component: registerValues
    },
    {
        path: '/buildings',
        name: 'buildings',
        component: buildings
    },
    {
        path: '/buildings/:id/resources',
        name: 'resources',
        component: resources
    },
    {
        path: '/projects',
        name: 'projects',
        component: projects
    },
    {
        path: '/buildings/add',
        name: 'buildingAdd',
        component: buildingAdd
    },
    {
        path: '/buildings/edit/:buildingId',
        name: 'buildingEdit',
        component: buildingEdit
    },
    {
        path: '/devices',
        name: 'devicesOld',
        component: devices
    },
    {
        path: '/devices/:buildingId/middlewares',
        name: 'middlewares',
        component: middlewares
    },
    {
        path: '/devices/:buildingId/middlewares/logs/:middlewareId',
        name: 'middlewareLogs',
        component: middlewareLogs
    },
    {
        path: '/devices/:buildingId',
        name: 'devices',
        component: devices
    },
    {
        path: '/units',
        name: 'units',
        component: units
    },
    {
        path: '/units/add',
        name: 'unitAdd',
        component: unitAdd
    },
    {
        path: '/units/edit/:unitId',
        name: 'unitEdit',
        component: unitEdit
    },
    {
        path: '/users',
        name: 'users',
        component: users
    },
    {
        path: '/people',
        name: 'people',
        component: people
    },
    {
        path: '/people/add',
        name: 'personAdd',
        component: personAdd
    },
    {
        path: '/people/edit/:personId',
        name: 'personEdit',
        component: personEdit
    },
    {
        path: '/contractors',
        name: 'contractors',
        component: contractors
    },
    {
        path: '/contractors/:contractorId',
        name: 'contractorContacts',
        component: contractorContacts
    },
    {
        path: '/contractors/add',
        name: 'contractorAdd',
        component: contractorAdd
    },
    {
        path: '/contractors/edit/:contractorId',
        name: 'contractorEdit',
        component: contractorEdit
    },
    {
        path: '/buildings/:buildingId/swots',
        name: 'swots',
        component: swots
    },
    {
        path: '/buildings/:buildingId/swots/:swotId/elements',
        name: 'swotElements',
        component: swotElements
    },
    {
        path: '/buildings/:buildingId/swots/:swotId/correlation',
        name: 'swotElementsCorrelation',
        component: swotElementsCorrelation
    },
    {
        path: '/buildings/:buildingId/swots/:swotId/correlation/tows',
        name: 'towsElementsCorrelation',
        component: towsElementsCorrelation
    },
    {
        path: '/buildings/:buildingId/swots/:swotId/result',
        name: 'swotResult',
        component: swotResult
    },
    {
        path: '/buildings/:buildingId/fmeas',
        name: 'fmeas',
        component: fmeas
    },
    {
        path: '/buildings/:buildingId/fmeas/:analysisId/analysis',
        name: 'fmeaAnalysis',
        component: fmeaAnalysis
    },
    {
        path: '/buildings/:buildingId/kaizens',
        name: 'kaizens',
        component: keizens
    },
    {
        path: '/buildings/:buildingId/qfds',
        name: 'qfds',
        component: qfds
    },
    {
        path: '/buildings/:buildingId/qfdsParameters',
        name: 'qfdParameters',
        component: qfdParameters
    },
    {
        path: '/buildings/:buildingId/qfdAnalyses',
        name: 'qfdAnalyses',
        component: qfdAnalyses
    },
    {
        path: '/buildings/:buildingId/qfdAnalysis/:analysisId/:step',
        name: 'qfdAnalysis',
        component: qfdAnalysis
    },
    {
        path: '/buildings/:buildingId/zpcs',
        name: 'zpcs',
        component: zpcs
    },
    {
        path: '/buildings/:buildingId/zpcs/rates',
        name: 'peopleRates',
        component: peopleRates
    },
    {
        path: '/buildings/:buildingId/zpcs/my-rates',
        name: 'myRates',
        component: myRates
    },
    {
        path: '/buildings/:buildingId/zpcs/my-goals',
        name: 'myGoals',
        component: myGoals
    },
    {
        path: '/buildings/:buildingId/benefits',
        name: 'benefits',
        component: benefits
    },
    {
        path: '/buildings/:buildingId/goals',
        name: 'goals',
        component: goals
    },
    {
        path: '/buildings/:buildingId/goals/:goalId',
        name: 'detailedGoals',
        component: detailedGoals
    },
    {
        path: '/buildings/:buildingId/goals/:goalId/:detailedId',
        name: 'operationalGoals',
        component: operationalGoals
    },
    {
        path: '/iasPhases',
        name: 'iasPhases',
        component: iasPhases
    },
    {
        path: '/industries',
        name: 'industries',
        component: industries
    },
    {
        path: '/budget-templates',
        name: 'budgetTemplates',
        component: budgetTemplates
    },
    {
        path: '/budgets',
        name: 'budgets',
        component: budgets
    },
    {
        path: '/budgets/:budgetId',
        name: 'budgetDetails',
        component: budgetDetails
    },
    {
        path: '/budget-templates/:templateId',
        name: 'budgetTemplateCategories',
        component: budgetTemplateCategories
    },
    {
        path: '/renters',
        name: 'renters',
        component: renters
    },
    {
        path: '/rental-objects',
        name: 'rentalObjects',
        component: rentalObjects
    },
    {
        path: '/function-tree',
        name: 'functionTree',
        component: functionTree
    },
    {
        path: '/process-tree',
        name: 'processTree',
        component: processTree
    },
    {
        path: '/methods',
        name: 'iasMethods',
        component: iasMethods
    },
    {path: "*", component: pageNotFound}
];

export default new Router({
    routes: routes,
    mode: 'history'
});
