<template>
    <admin ref="admin">
        <div class="uk-container-expand uk-padding">
            <vk-grid>
                <menuAdmin/>
                <div class="uk-width-expand@m">
                    <div>
                        <buildings-list v-on:refreshBuildings="refreshBuildings()"/>
                    </div>
                </div>
            </vk-grid>
        </div>

    </admin>

</template>

<script>

    import mixin from "../../components/mixin";
    import BuildingsList from "../../components/building/buildingsList";

    export default {
        name: 'buildings',
        components: {
            BuildingsList,
        },
        mixins: [mixin],
        data() {
            return {
                sortBy: 'name',
                sortDesc: false,
                data: [],
                currentPage: 1,
                totalItems: '',
                fields: [
                    {key: 'name', label: 'Imię', sortable: true},
                    {key: 'lastName', label: 'Nazwisko', sortable: true},
                    {key: 'email', label: 'E-mail', sortable: true},
                    {key: 'isManager', label: 'Manager', class: 'text-center'},
                    {key: 'unit.name', label: 'Jednostka Organizacyjna', sortable: true},
                    {key: 'actions', label: ''},
                ]
            }
        },
        methods: {
            refreshBuildings: function(){
                this.$refs.admin.getBuildings();
            }
        },
        async mounted() {
            this.ability = await this.$ability.getAbility();
            if (!this.ability.can('manage', 'ROLE_SUPER_ADMIN')) {
                await this.$router.push({name: 'not-allowed'});
            }
        }
    }
</script>
